import { DF_INTRAOP_IMAGING } from 'app/components/caseViews/formHelpers/shared';
import { randomTeamAppreciateEmoji } from 'app/utils';
import { CASE_STATE, FEEDBACK_ADD_INFO_OPTIONS } from 'app/consts';

const getSubSubTitle = (tab, c, t) => {
  let subSubTitle;
  if (
    tab === 'or-board' &&
    c.caseStateLogs?.some(l => l.toState === CASE_STATE.READY) &&
    c.fieldValues
  ) {
    const ioiField = c.fieldValues[DF_INTRAOP_IMAGING];
    if (ioiField?.value.length > 0) {
      const values = ioiField.value.filter(fv => fv.value !== 'Other').map(fv => fv.value);
      if (ioiField.otherValue) {
        values.push(ioiField.otherValue);
      }
      if (values?.length > 0) {
        subSubTitle = `💡 ${values.join(', ')}`;
      }
    }
  }
  return subSubTitle;
};

// eslint-disable-next-line import/prefer-default-export
export { getSubSubTitle };
