import React, { useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import makeStyles from '@mui/styles/makeStyles';

const MarkdownText = ({ setOpenedAppendix, text, metaData }) => {
  const windowWidth = useRef(window.innerWidth).current;
  const windowHeight = useRef(window.innerHeight).current;
  const isMobile = windowWidth < windowHeight;

  const { stepFontMultiplier } = metaData;

  // Markdown font size scaling
  // change fontSizeMultiplier to scale all font sizes by that value.
  // or edit individual elements as needed.
  const baseFontSize = 17;
  let fontSizeMultiplier = 1;
  if (!isMobile) {
    fontSizeMultiplier = stepFontMultiplier || 1.7;
    if (windowWidth !== 1920) {
      fontSizeMultiplier *= (windowWidth / 1920) * 0.9;
    }
  }
  const textFontSize = baseFontSize * fontSizeMultiplier;
  const h1FontSize = textFontSize * 3;
  const h2FontSize = textFontSize * 1.7;
  const h3FontSize = textFontSize * 1.5;

  const useStyles = makeStyles(theme => ({
    markdownRoot: {
      '& ul, & ol': {
        listStyle: 'revert',
        padding: 'revert'
      },
      '& h1, & h2, & h3, & p': {
        margin: 'revert',
        marginBottom: '4px'
      },
      '& ul, & ol, & p': {
        fontSize: textFontSize,
        lineHeight: '1.6em'
      },
      '& h1': {
        fontSize: h1FontSize
      },
      '& h2': {
        fontSize: h2FontSize
      },
      '& h3': {
        fontSize: h3FontSize
      },
      '& .linkButton': {
        fontSize: textFontSize,
        color: 'blue',
        textDecoration: 'underline',
        overflowWrap: 'anywhere',
        textAlign: 'inherit'
      }
    }
  }));

  const classes = useStyles();

  const navigateToPage = href => {
    if (href.startsWith('http')) {
      window.open(href, '_blank');
    } else {
      setOpenedAppendix(href.replace('/', ''));
    }
  };

  return (
    <ReactMarkdown
      components={{
        a: ({ node, ...linkProps }) => {
          const { children, href } = linkProps;
          const [title] = children;
          return (
            <button type="button" className="linkButton" onClick={() => navigateToPage(href)}>
              {title}
            </button>
          );
        }
      }}
      className={classes.markdownRoot}
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[remarkGfm]}
    >
      {text}
    </ReactMarkdown>
  );
};

export default MarkdownText;
