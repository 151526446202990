import { combineReducers } from '@reduxjs/toolkit';
import cases from './casesSlice';
import dashboard from './dashboardSlice';
import admin from './adminSlice';

const dataReducers = combineReducers({
  cases,
  dashboard,
  admin
});

export default dataReducers;
