import React, { useState } from 'react';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import toast from 'app/services/toastService/toast';
import { FEEDBACK_WHAT_WENT_WELL_OPTIONS } from 'app/consts';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%'
  },
  buttons: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '400px'
  },
  button: {
    display: 'block',
    width: '100%',
    padding: '8px',
    marginBottom: theme.spacing(1),
    textAlign: 'left',
    textTransform: 'none',
    flexBasis: '31%',
    boxSizing: 'borderBox',
    '&:not(:nth-child(3n))': {
      marginRight: '8px'
    },
    '&.MuiButton-text': {
      background: '#f2f2f2',
      color: '#666'
    },
    '& .subtitle': {
      color: 'red',
      opacity: '.85',
      fontSize: '1.3rem'
    }
  },
  buttonText: {
    whiteSpace: 'pre',
    fontWeight: 'normal',
    textAlign: 'center',
    lineHeight: '2rem'
  }
}));

const options = [
  {
    value: FEEDBACK_WHAT_WENT_WELL_OPTIONS.GOT_INFO_EARLY,
    title: 'got_info_early'
  },
  {
    value: FEEDBACK_WHAT_WENT_WELL_OPTIONS.GOOD_COMMUNICATION,
    title: 'good_communication'
  },
  {
    value: FEEDBACK_WHAT_WENT_WELL_OPTIONS.EFFICIENT,
    title: 'we_were_efficient'
  },
  {
    value: FEEDBACK_WHAT_WENT_WELL_OPTIONS.HAPPY_TEAM,
    title: 'happy_team'
  },
  {
    value: FEEDBACK_WHAT_WENT_WELL_OPTIONS.PATIENT_EXPERIENCE,
    title: 'patient_experience'
  },
  {
    value: FEEDBACK_WHAT_WENT_WELL_OPTIONS.SOMETHING_ELSE,
    title: 'something_else'
  }
];

export default function WhatWentWell(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [selections, setSelections] = useState(props.feedback?.WhatWentWellSelection || []);
  const [textValue, setTextValue] = useState(props.feedback?.WhatWentWellText || '');
  const isSelected = value => {
    return selections.includes(value);
  };
  const buttonOnClick = value => {
    let sel;
    if (isSelected(value)) {
      sel = selections.filter(s => s !== value);
    } else {
      sel = [...selections, value];
    }
    setSelections(sel);
    props.setwhatWentWellSelection(sel);
    handleSubmit(sel, textValue);
  };
  const handleSubmit = async (sel, text) => {
    try {
      if (props.feedback?.id) {
        props.handlePlanFeedbackUpdate(props.feedback.id, null, null, null, null, null, sel, text);
      } else {
        props.submitPlanFeedback(null, null, null, null, null, sel, text);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  return (
    <div className={clsx(classes.container)}>
      <Typography variant="body1" gutterBottom color="primary" fontWeight="bold">
        <Trans i18nKey="what_went_well" />
      </Typography>
      <div className={clsx(classes.buttons)}>
        {options.map(option => (
          <Button
            key={option.value}
            className={clsx(classes.button)}
            onClick={() => buttonOnClick(option.value)}
            variant={isSelected(option.value) ? 'contained' : 'text'}
            color="primary"
            disabled={props.disabled}
          >
            <div className={classes.buttonText}>
              <Trans
                i18nKey={option.title}
                components={{
                  strong: <span style={{ fontWeight: 'bold' }} />
                }}
              />
            </div>
          </Button>
        ))}
      </div>
      <TextField
        name={props.feedback?.id || 'new_feedback'}
        value={textValue}
        className={clsx({ hidden: props.disabled || !(selections?.length > 0) }, 'w-full')}
        placeholder={t('plan_useful_text_hint')}
        multiline
        onChange={event => {
          setTextValue(event.target.value);
          handleSubmit(selections, event.target.value);
        }}
        disabled={props.disabled}
      />
    </div>
  );
}
