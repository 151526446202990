import React from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const navButtonStyle = {
  borderRadius: '30px',
  textTransform: 'none',
  fontWeight: 400,
  fontSize: '2.5rem',
  padding: '6px 26px'
};

const NavigationButtons = ({ moveBack, moveForward, hasNext, hasPrev, isAppendix }) => {
  const { t } = useTranslation();
  return (
    <>
      {(hasPrev || isAppendix) && (
        <Button
          variant="contained"
          color="primary"
          onClick={moveBack}
          aria-label="Back"
          sx={[navButtonStyle, { '&:hover': { backgroundColor: '#3d465c' } }]}
        >
          {t('Back')}
        </Button>
      )}
      {!isAppendix && (
        <Button
          variant="contained"
          color="secondary"
          onClick={moveForward}
          aria-label="Done and Next"
          sx={[navButtonStyle, { marginLeft: '10px', '&:hover': { backgroundColor: '#ab5144' } }]}
        >
          {t(hasNext ? 'Next' : 'Complete')}
        </Button>
      )}
    </>
  );
};

export default NavigationButtons;
