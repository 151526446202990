import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { BaseSelect as Select } from 'app/components/forms/Select';
import ConfirmationDialog from 'app/components/confirmDialog/ConfirmDialog';
import { useDispatch, useSelector } from 'react-redux';
import { addDepAdmin, loadMetas, loadUsers, removeDepAdmin } from 'app/store/data/adminSlice';

export default function General(props) {
  const { t } = useTranslation();
  const formRef = useRef(null);
  const { users, metas } = useSelector(({ data }) => data.admin);
  const admins = users.filter(user => user.roles.some(r => r.role === 'department_admin'));
  const dispatch = useDispatch();

  const [confirmation, setConfirmation] = useState({
    open: false,
    message: ''
  });

  const getUsers = useCallback(() => {
    dispatch(loadUsers());
  }, [dispatch]);
  const getMetas = useCallback(() => {
    dispatch(loadMetas());
  }, [dispatch]);

  useEffect(() => {
    getUsers();
    getMetas();
  }, [getUsers, getMetas]);

  const handleAdminChange = (newValue, formModel, event, reason) => {
    if (newValue && newValue.length > 0) {
      if (newValue.length > admins.length) {
        // Added admin
        const added = newValue.find(x => !admins.some(a => a.id === x.id));
        setConfirmation({
          open: true,
          nextValue: newValue,
          message: t(
            `Make ${added.nickName} a department admin? This will provide ${added.nickName} access to all department data.`
          ),
          op: 'added',
          changedValue: added
        });
      } else {
        // Removed admin
        const removed = admins.find(x => !newValue.some(a => a.id === x.id));
        setConfirmation({
          open: true,
          nextValue: newValue,
          message: t(
            `Remove ${removed.nickName} from department admins? ${removed.nickName} will still be able to access their own cases.`
          ),
          op: 'removed',
          changedValue: removed
        });
      }
    } else {
      // Can't be empty
      setConfirmation({
        open: true,
        nextValue: admins,
        message: t("This field can't be empty."),
        op: 'none'
      });
    }
  };

  const handleAdminConfirm = async confirm => {
    if (confirm) {
      switch (confirmation.op) {
        case 'added':
          dispatch(addDepAdmin('department_admin', confirmation.changedValue.id));
          break;
        case 'removed':
          dispatch(removeDepAdmin('department_admin', confirmation.changedValue.id));
          break;
        default:
      }
    }
    setConfirmation({ open: false });
  };

  return (
    <form className={clsx('flex flex-col px-24 pb-24 max-w-md')} ref={formRef}>
      <Select
        className="my-16"
        name="admins"
        label={t('DEPARTMENT ADMINS')}
        withFilter
        multiple
        disableClearable
        options={users}
        getOptionLabel={option => option.nickName}
        formRef={formRef}
        value={admins}
        onChange={handleAdminChange}
      />

      <ConfirmationDialog
        open={confirmation.open}
        onClose={handleAdminConfirm}
        message={confirmation.message}
      />
    </form>
  );
}
