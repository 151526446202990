import amplitude from 'amplitude-js';
import auth0Service from 'app/services/auth0Service';

amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY, '', {
  disableCookies: true,
  batchEvents: true,
  eventUploadPeriodMillis: 10 * 1000,
  eventUploadThreshold: 30
});

class AmplService {
  EVENTS = {
    PUBLISH_CASES: 'publish cases',
    DELETE_CASE: 'delete case',
    ADD_CASE: 'add case',
    FORM_UPDATE: 'form update',
    PAGE_VIEW: 'page view',
    UPDATE_CASE: 'update case',
    UPDATE_CASE_MD: 'update case meta data',
    UPDATE_CASE_ATTRIBUTES: 'case attributes update',
    NEW_CASE_STATE: 'new case state',
    FOLLOW_CASE: 'follow case',
    EXPORT_CASE_LOG: 'export_case_log',
    SHARE_CASE: 'share case',
    SHARE_MEDIA: 'share media',
    LOAD_MORE: 'load more',
    CHANGES_TO_READY: 'changes to ready',
    TMPL_SUGGESTION: 'template suggestion'
  };

  FORM_UPDATE_TYPES = {
    COMMENT: 'comment',
    PLAN_FEEDBACK: 'plan feedback',
    PRIVATE_NOTE: 'private note'
  };

  OP = {
    CREATE: 'create',
    EDIT: 'edit',
    DELETE: 'delete'
  };

  identify = false;

  client = amplitude.getInstance();

  setUserData(roles, pgy, id) {
    if (this.identify !== false) {
      return;
    }
    this.identify = new amplitude.Identify()
      .set('pgy', pgy)
      .set('roles', roles)
      .set('department', auth0Service.audPrefix);
    this.client.setUserId(id);
    this.client.identify(this.identify);
  }

  sendPageViewEvent(location) {
    const md = this.processLocation(location);
    if (!md.search || !md.search.startsWith('?case=')) {
      this.sendEvent(this.EVENTS.PAGE_VIEW, md);
    }
  }

  sendEvent(event, md = {}) {
    this.client.logEvent(event, md);
  }

  // eslint-disable-next-line class-methods-use-this
  processLocation(location) {
    const { search, pathname } = location;

    const pathParts = pathname ? pathname.split('/') : [];
    pathParts.shift();
    const page = pathParts[0];
    const tab = pathParts.length > 1 ? pathParts[1] : '';
    return {
      search,
      page,
      tab,
      fullPath: `${pathname}${search}`
    };
  }

  sendCaseEvent(event, kase, extraMd = {}) {
    this.sendEvent(event, {
      id: kase.id,
      state: kase.state,
      site: kase.site.name,
      specialtyId: kase.specialtyId,
      specialtyTitle: kase.specialtyTitle,
      procedureId: kase.procedureId,
      procedureTitle: kase.procedureTitle,
      ...extraMd
    });
  }
}
const instance = new AmplService();

export default instance;
