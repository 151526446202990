import React from 'react';
import _ from '@lodash';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Fab from '@mui/material/Fab';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'absolute',
    zIndex: 999,
    margin: 0,
    height: '38px',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    '&.Mui-disabled': {
      pointerEvents: 'auto'
    }
  }
}));

function SubmitFab(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const importedProps = _.pick(props, [
    'action',
    'buttonRef',
    'centerRipple',
    'children',
    'classes',
    'color',
    'component',
    'disabled',
    'disableRipple',
    'disableTouchRipple',
    'focusRipple',
    'href',
    'onBlur',
    'onClick',
    'onDragLeave',
    'onFocus',
    'onFocusVisible',
    'onKeyDown',
    'onKeyUp',
    'onMouseDown',
    'onMouseLeave',
    'onMouseUp',
    'onTouchEnd',
    'onTouchMove',
    'onTouchStart',
    'tabIndex',
    'TouchRippleProps',
    'type'
  ]);

  return (
    <Fab
      color="secondary"
      variant="extended"
      aria-label={t(props.label)}
      type="submit"
      {...importedProps}
      className={clsx(classes.fab, props.className)}
    >
      <Icon className="mr-6" fontSize="small">
        {props.icon || 'save_alt'}
      </Icon>
      <Typography className="mr-2" variant="caption">
        {props.label ? t(props.label) : t('SUBMIT')}
      </Typography>
    </Fab>
  );
}

export default React.memo(SubmitFab);
