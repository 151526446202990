import { createSlice } from '@reduxjs/toolkit';
import toast from 'app/services/toastService/toast';
import apiService from 'app/services/apiService';

const initialState = {
  users: [],
  metas: [],
  sites: []
};

export const loadUsers = () => async dispatch => {
  try {
    const items = await apiService.getDepAdminUsers();
    dispatch(setUsers(items.users));
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const addDepAdmin = (role, userId) => async (dispatch, getState) => {
  try {
    await apiService.addDepAdmin(role, userId);
    dispatch(loadUsers());
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const removeDepAdmin = (role, userId) => async (dispatch, getState) => {
  try {
    await apiService.removeDepAdmin(role, userId);
    dispatch(loadUsers());
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const submitUserData = (id, values) => async (dispatch, getState) => {
  const {
    firstName,
    lastName,
    nickName,
    phone,
    pgy,
    isActive,
    roles,
    sites,
    blankCaseAlways,
    followAll,
    muteUntil,
    reminderDays,
    isNurseLeader,
    vendor
  } = values;

  const userRoles = roles.map(r => ({
    userId: id,
    role: r.value
  }));
  const reminderDataArray = reminderDays.map(r => r.value);

  const userData = {
    firstName,
    lastName,
    nickName,
    phone,
    pgy,
    isActive,
    blankCaseAlways,
    followAll,
    muteUntil,
    reminderDays: reminderDataArray,
    isNurseLeader,
    vendor
  };

  await apiService.updateUserDataRolesAndSites(
    id,
    userData,
    userRoles,
    sites.map(({ value }) => ({
      userId: id,
      siteId: value
    }))
  );
  return dispatch(loadUsers());
};

export const submitNewUser = values => async (dispatch, getState) => {
  try {
    const { isSaml } = getState().metaData.enum;
    const {
      firstName,
      lastName,
      nickName,
      phone,
      pgy,
      isActive,
      roles,
      email,
      sites,
      reminderDays,
      followAll,
      vendor
    } = values;
    const userRoles = roles.map(r => ({
      role: r.value
    }));
    const reminderDataArray = reminderDays.map(r => r.value);

    const userData = {
      email: email.toLowerCase(),
      firstName,
      lastName,
      nickName,
      phone,
      pgy,
      isActive,
      avatar: '',
      roles: { data: userRoles },
      userSites: {
        data: sites.map(({ value }) => ({
          siteId: value
        }))
      },
      reminderDays: reminderDataArray,
      followAll,
      vendor
    };
    await apiService.addUser(email, nickName, userData, !isSaml);
    dispatch(loadUsers());
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};
export const submitNewSite =
  (name, isActive, specialties, attendingOnly, timezone) => async (dispatch, getState) => {
    try {
      await apiService.addSite(
        name,
        isActive,
        JSON.stringify(specialties),
        attendingOnly,
        timezone
      );
      dispatch(loadUsers());
      dispatch(loadSites());
    } catch (err) {
      console.log(err);
      toast.error(err.message);
      throw err;
    }
  };

export const submitSiteData =
  (siteId, name, isActive, specialties, attendingOnly, timezone) => async (dispatch, getState) => {
    try {
      await apiService.updateSiteData(
        siteId,
        name,
        isActive,
        JSON.stringify(specialties),
        attendingOnly,
        timezone
      );
      dispatch(loadUsers());
      dispatch(loadSites());
    } catch (err) {
      console.log(err);
      toast.error(err.message);
      throw err;
    }
  };

export const loadSites = () => async (dispatch, getState) => {
  try {
    const items = await apiService.getDepAdminSites();
    dispatch(setSites(items.sites));
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const loadMetas = () => async (dispatch, getState) => {
  try {
    const items = await apiService.getDepAdminMetas();
    dispatch(setMetas(items));
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const updatedMeta = (key, value) => async (dispatch, getState) => {
  try {
    await apiService.updateMeta(key, value);
    loadMetas();
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    resetState: state => {
      state = initialState;
    },
    setUsers: (state, action) => {
      state.users = action.payload.map(u => ({
        ...u,
        value: u.id,
        roles: u.roles
      }));
    },
    setSites: (state, action) => {
      state.sites = action.payload;
    },
    setMetas: (state, action) => {
      state.metas = action.payload;
    }
  },
  extraReducers: {}
});

export const { resetState, setUsers, setSites, setMetas } = adminSlice.actions;

export default adminSlice.reducer;
