export function isSameDate(d1, d2) {
  return (
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getYear() === d2.getYear()
  );
}

export function isToday(d) {
  return isSameDate(d, new Date());
}

export function isTomorrow(d) {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return isSameDate(d, tomorrow);
}
