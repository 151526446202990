import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import TextField from 'app/components/forms/TextField';
import Info from 'app/components/forms/InfoBox';

function OtherTextField(props) {
  const otherFieldProps = { ...props.otherFieldProps };
  const inputRef = React.useRef();
  const inputVisible = React.useRef();
  const { t } = useTranslation();
  const required = props.required === true ? ` (${t(' required')})` : '';

  let FieldComp = TextField;
  if (props.disabled) {
    FieldComp = Info;
    otherFieldProps.value = props.otherFieldProps.value;
    otherFieldProps.variant = 'plain';
  }

  React.useEffect(() => {
    if (props.isVisible !== inputVisible) {
      if (inputVisible.current === false && props.isVisible === true && inputRef?.current?.focus) {
        inputRef.current.focus();
      }
      inputVisible.current = props.isVisible;
    }
  }, [props.isVisible]);

  return (
    <FieldComp
      inputRef={inputRef}
      name={`${props.name}Other`}
      className={clsx(props.className, {
        hidden: !props.isVisible
      })}
      placeholder={t(`✎ Other ${props.label}. Separate multiple values with a comma.${required}`)}
      required={props.required}
      formRef={props.formRef}
      disabled={props.disabled}
      multiline
      {...otherFieldProps}
    />
  );
}

export default OtherTextField;
