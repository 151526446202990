/**
 * Authorization Roles
 */
const authRoles = {
  careTeam: ['care_team'],
  casesAdmin: ['department_admin'],
  depAdmin: ['department_admin'],
  depDirector: ['department_director'],
  notGuest: ['care_team', 'scheduler', 'department_director', 'department_admin'],
  onlyGuest: []
};

export default authRoles;
