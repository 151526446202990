import React, { Fragment } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const useStyles = makeStyles(theme => ({
  carousel: {
    background: '#000',
    '&, & .carousel-slider, & .slider-wrapper, & .slider, & .slide': {
      height: '100%'
    },
    '& .carousel-slider': {
      '& img': {
        maxHeight: '100%',
        maxWidth: '100%',
        width: 'auto !important',
        height: 'auto',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        margin: 'auto'
      }
    },
    '& .carousel.carousel-slider .control-arrow': {
      opacity: 0.4,
      width: '50%',
      '-webkit-tap-highlight-color': 'transparent',
      '&.control-next': {
        textAlign: 'right'
      },
      '&.control-prev': {
        textAlign: 'left'
      },
      '&:hover': {
        background: 'none',
        opacity: 0.8
      },
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    '& .carousel .slide .legend': {
      opacity: 1,
      background: 'linear-gradient(to top, #000000b3 0%, #0000004d 70%, #0000 100%)',
      bottom: 0,
      left: 0,
      right: 0,
      height: '60px',
      margin: 0,
      width: '100%',
      borderRadius: 0,
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      paddingBottom: '1.4em'
    }
  },
  close: {
    color: 'white',
    position: 'absolute',
    zIndex: 99,
    right: 8,
    top: 8
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MediaDialog(props) {
  const classes = useStyles();

  const handleClose = () => {
    props.setOpen(null);
  };

  const isIndex = i => typeof i === 'number';

  return (
    <Dialog
      fullScreen
      open={isIndex(props.open)}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <IconButton
        key="close"
        className={classes.close}
        aria-label="Close"
        color="inherit"
        onClick={handleClose}
        size="large"
      >
        <CloseIcon />
      </IconButton>
      <Carousel
        className={classes.carousel}
        infiniteLoop
        selectedItem={props.open}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        transitionTime={150}
        autoPlay={false}
        emulateTouch
        swipeable
      >
        {props.assets.map(asset => (
          <Fragment key={asset.id}>
            <img src={asset.baseUrl + asset.imagePath} alt="" />
            {asset.desc && <p className="legend">{asset.desc}</p>}
          </Fragment>
        ))}
      </Carousel>
    </Dialog>
  );
}
