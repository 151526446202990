import React from 'react';
import _ from '@lodash';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { withFormsy } from 'formsy-react';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import ChangedBy from 'app/components/userAvatar/ChangedByAvatar';

const useStyles = makeStyles(theme => ({
  // Highlight field similar to a selected Chip
  chipLike: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
    margin: '0 0 -4px',
    padding: '0px 16px 0px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.primary.main
      }
    },
    '& input, & textarea': {
      color: theme.palette.primary.contrastText
    },
    '& textarea': {
      padding: '6px 0 7px'
    },
    'label + &.MuiInput-formControl': {
      marginTop: '20px'
    },
    '&.MuiInput-underline:before': {
      display: 'none'
    },
    '&.MuiInput-root:after': {
      content: '"✎"',
      color: theme.palette.primary.contrastText,
      fontSize: '2rem',
      lineHeight: '36px',
      left: 'auto',
      marginRight: '8px',
      transform: 'none',
      border: 'none'
    }
  }
}));

function TextField(props) {
  const classes = useStyles();
  const formControlProps = _.pick(props, [
    'className',
    'disabled',
    'fullWidth',
    'label',
    'required',
    'size',
    'variant'
  ]);
  const inputProps = _.pick(props, [
    'autoComplete',
    'autoFocus',
    'children',
    'defaultValue',
    'disabled',
    'fullWidth',
    'id',
    'inputRef',
    'label',
    'multiline',
    'name',
    'onBlur',
    'onFocus',
    'placeholder',
    'readOnly',
    'required',
    'rows',
    'rowsMax',
    'type',
    'minRows'
  ]);
  const { errorMessage } = props;
  const value =
    props.value && props.value.value !== undefined ? props.value.value : props.value || '';

  const changeValue = event => {
    if (props.isFormDisabled) return;

    if (props.setValue) {
      props.setValue(event.currentTarget.value);
    }

    if (props.onChange) {
      props.onChange(event.currentTarget.value, props.formRef);
    }
  };

  const InputComp = props.variant === 'outlined' ? OutlinedInput : Input;

  const chipLike = props.chipLike && !props.disabled && value.length > 0 ? classes.chipLike : '';

  return (
    <FormControl
      {...formControlProps}
      error={Boolean((!props.isPristine && props.showRequired) || errorMessage)}
      required={props.required}
    >
      {props.changedBy && !props.isFormDisabled && <ChangedBy user={props.changedBy} />}

      {props.label && (
        <InputLabel {...props.InputLabelProps} variant={props.variant} required={props.required}>
          {props.label}
        </InputLabel>
      )}

      <InputComp
        {...inputProps}
        {...props.InputProps}
        className={clsx(chipLike)}
        onChange={changeValue}
        value={value}
        notched={props.variant === 'outlined' ? true : undefined}
      />

      {Boolean(props.errorMessage) && (
        <FormHelperText {...props.FormHelperTextProps}>{props.errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
}

export const BaseTextField = React.memo(TextField);

export default React.memo(withFormsy(TextField));
