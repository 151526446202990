import FuseAuthorization from '@fuse/core/FuseAuthorization';
import { ErrorBoundary } from 'react-error-boundary';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import history from '@history';
import * as Sentry from '@sentry/react';

import createGenerateClassName from '@mui/styles/createGenerateClassName';
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import rtl from 'jss-rtl';
import Provider from 'react-redux/es/components/Provider';
import { Router } from 'react-router-dom';
import { SnackbarConfigurator } from 'app/services/toastService/toast';
import ffService from 'app/services/ffService';
import apiService from 'app/services/apiService';
import amplService from 'app/services/amplService';
import { A2HSTip } from 'app/components/add2HomeScreen';
import WhatsNew from 'app/components/whatsNew/WhatsNew';
import ErrorPage from 'app/pages/errorPage/ErrorPage';
import { Integrations } from '@sentry/tracing';
import React, { useState } from 'react';
import { Detector } from 'react-detect-offline';
import AppContext from './AppContext';
import { Auth } from './auth';
import { MetaData } from './metaData';
import routes from './fuse-configs/routesConfig';
import store from './store';
import { useDebounce } from '../@fuse/hooks';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [new Integrations.BrowserTracing()]
  // tracesSampleRate: 1.0,
  // enabled: process.env.REACT_APP_APP_ENV !== 'LOCAL'
});
const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend(), rtl()],
  insertionPoint: document.getElementById('jss-insertion-point')
});

const generateClassName = createGenerateClassName();
history.listen((location, action) => {
  if (amplService.identify) {
    amplService.sendPageViewEvent(location);
  }
});

const App = () => {
  const [isOnline, setIsOnline] = useState(true);
  const debounceSetIsOnline = useDebounce(
    online => {
      setIsOnline(online);
    },
    1100,
    { leading: false, trailing: true }
  );

  console.log('App.js: App: isOnline:', isOnline);
  return (
    <AppContext.Provider
      value={{
        routes,
        // err logger
        Sentry,
        // api
        apiService
      }}
    >
      <ErrorBoundary fallback={<ErrorPage />}>
        <StylesProvider jss={jss} generateClassName={generateClassName}>
          <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Auth>
                <Router history={history}>
                  <FuseTheme>
                    <Detector
                      onChange={async online => {
                        if (!online) {
                          const fallbackResult = await apiService.checkNetworkFallback();
                          if (fallbackResult) {
                            return;
                          }
                        }
                        debounceSetIsOnline(online);
                      }}
                      render={() => null}
                    />
                    <MetaData isOnline={isOnline}>
                      <FuseAuthorization>
                        <FuseLayout />
                        <SnackbarConfigurator />
                        <A2HSTip />
                        <WhatsNew />
                      </FuseAuthorization>
                    </MetaData>
                  </FuseTheme>
                </Router>
              </Auth>
            </LocalizationProvider>
          </Provider>
        </StylesProvider>
      </ErrorBoundary>
    </AppContext.Provider>
  );
};

export default App;
