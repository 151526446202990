import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { ROLES } from 'app/consts';

import { useSelector } from 'react-redux';
import DashboardStatsTable from './DashboardStatsTable';
import DashboardMonthlyLineChart from './DashboardMonthlyLineChart';
import DashboardPieChart from './DashboardPieChart';
import DashboardTable from './DashboardTable';

export default function Dashboard(props) {
  const { t } = useTranslation();
  const { getDashboardData, data } = props;
  const userRoles = useSelector(({ auth }) => auth.user.role);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  // filter cases without a type yet
  const filteredCasesByType = data.casesByType?.filter(c => c.name);

  const huddleRateYearToDate = data.huddleRateYearToDate || [];
  const fltr = ytd =>
    ytd.year < new Date().getFullYear() ||
    (ytd.year === new Date().getFullYear() && ytd.month <= new Date().getMonth() + 1);
  const huddleRateYearToDateWithYou = huddleRateYearToDate?.filter(fltr).map(d => {
    return { ...d, year: `${t('You')} ${d.year}` };
  });
  const depHuddleRateYearToDate = data.depHuddleRateYearToDate || [];
  const depHuddleRateYearToDateWithDepartment = depHuddleRateYearToDate?.filter(fltr).map(d => {
    return { ...d, year: `${t('Avg.')} ${d.year}` };
  });

  return (
    <Grid className="p-12 md:p-0" container spacing={3}>
      <Grid item sm={6} xs={12}>
        <DashboardStatsTable data={data} />
      </Grid>
      {userRoles.includes(ROLES.ATTENDING) && huddleRateYearToDateWithYou?.length > 0 && (
        <Grid item sm={6} xs={12}>
          <DashboardMonthlyLineChart
            title={t('HUDDLE RATE')}
            data={[...huddleRateYearToDateWithYou, ...depHuddleRateYearToDateWithDepartment]}
            colors={{
              'You 2022': '#52ba71',
              'You 2023': '#80299d',
              'You 2024': '#3f51b5',
              'Avg. 2022': '#ffe85b',
              'Avg. 2023': '#add8e6',
              'Avg. 2024': '#f37374'
            }}
            dash={{
              'You 2022': false,
              'Avg. 2023': true,
              'You 2024': false,
              'Avg. 2022': true,
              'You 2023': false,
              'Avg. 2024': true
            }}
            percentage
          />
        </Grid>
      )}
      <Grid item sm={6} xs={12}>
        <DashboardMonthlyLineChart
          title={t('CASES YEAR TO DATE')}
          data={data.casesYearToDate}
          accumulate
        />
      </Grid>
      {filteredCasesByType && (
        <Grid item sm={6} xs={12}>
          <DashboardPieChart data={filteredCasesByType} />
        </Grid>
      )}
      {data.casesWhereUserAssistant > 0 && data.casesByAttending?.length > 0 && (
        <Grid item sm={6} xs={12}>
          <DashboardTable
            data={data.casesByAttending}
            title={`${t('YOUR CASES BY ATTENDING')} (${data.casesWhereUserAssistant})`}
            cols={['nickName', 'count']}
            headers={['', '# Cases']}
          />
        </Grid>
      )}
      {data.casesWhereUserAttending > 0 && data.casesByAssistant?.length > 0 && (
        <Grid item sm={6} xs={12}>
          <DashboardTable
            data={data.casesByAssistant}
            title={`${t('YOUR CASES BY ASSISTANT')} (${data.casesWhereUserAttending})`}
            cols={['nickName', 'count']}
            headers={['', '# Cases']}
          />
        </Grid>
      )}
      {data.recentLessons && data.recentLessons.length > 0 && (
        <Grid item sm={6} xs={12}>
          <DashboardTable
            data={data.recentLessons}
            title={t('Recent lessons learned')}
            cols={['procedure', 'text']}
            cellProps={{ className: 'text-left' }}
          />
        </Grid>
      )}
      {data.recentFeedbacks &&
        data.recentFeedbacks.length > 0 &&
        data.casesWhereUserAssistant > 0 && (
          <Grid item sm={6} xs={12}>
            <DashboardTable
              data={data.recentFeedbacks}
              title={t('Recent attending feedback')}
              cols={['procedure', 'text']}
              cellProps={{ className: 'text-left' }}
            />
          </Grid>
        )}
    </Grid>
  );
}
