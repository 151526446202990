import React, { useEffect, useState } from 'react';
import toast from 'app/services/toastService/toast';
import { Trans } from 'react-i18next';
import amplService from 'app/services/amplService';
import { followCase, loadOrCases, unfollowCase } from 'app/store/data/casesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ROLES } from 'app/consts';

const useFollow = ({ kase }) => {
  const user = useSelector(({ auth }) => auth.user);
  const userId = user.data.id;
  const isCaseFollowed = kase.caseFollowers && kase.caseFollowers.some(cf => cf.userId === userId);
  const [isFollowed, setIsFollowed] = useState(isCaseFollowed);
  const [submitting, setSubmitting] = useState(false);
  const isUserAdmin = user.role.includes(ROLES.DEPARTMENT_ADMIN);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isCaseFollowed !== isFollowed) {
      setIsFollowed(isCaseFollowed);
    }
  }, [isCaseFollowed]);
  const handleFollow = async (follow, isAutoFollow = false) => {
    amplService.sendCaseEvent(amplService.EVENTS.FOLLOW_CASE, kase, { follow, isAutoFollow });
    await dispatch(
      follow ? followCase(isUserAdmin, kase.id, userId) : unfollowCase(isUserAdmin, kase.id, userId)
    );
    dispatch(loadOrCases());
  };

  const toggleFollow = async () => {
    setSubmitting(true);
    try {
      const newFollowState = !isFollowed;
      const result = await handleFollow(newFollowState);
      if (result) {
        setIsFollowed(newFollowState);
        if (newFollowState) {
          toast.success(<Trans i18nKey="follow_case_success" />);
        }
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
    setSubmitting(false);
  };

  return {
    toggleFollow,
    submitting,
    isFollowed
  };
};

export default useFollow;
