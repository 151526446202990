import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BaseSelect as Select } from 'app/components/forms/Select';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Avatar from 'app/components/userAvatar/UserAvatar';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { userRolesToEmoji } from 'app/consts';
import toast from 'app/services/toastService/toast';

const CaseFollowers = props => {
  const { caseId, caseFollowers, users, myFollowerToAdd } = props;
  const [followers, setFollowers] = useState(caseFollowers);
  const [isShowSelect, setIsShowSelect] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setFollowers(caseFollowers);
  }, [caseFollowers]);

  const disabled = props.disabled || !props.handleSetFollowers || users.length === 0;

  const followersToValues = (opts, flrs) => {
    return opts.filter(o => flrs.some(f => f.userId === o.value));
  };

  const userOptions = users.map(u => ({
    value: u.id,
    label: u.nickName,
    avatar: u.avatar,
    firstName: u.firstName,
    lastName: u.lastName
  }));

  const handleClickAway = event => {
    if (Object.values(event.target.classList).indexOf('remove-user-button') > -1) {
      return;
    }
    setIsShowSelect(false);
  };

  const user = useSelector(({ auth }) => auth.user);
  useEffect(() => {
    if (!disabled && myFollowerToAdd) {
      const newFlrs = [...followers, { userId: myFollowerToAdd.id, user: myFollowerToAdd }];
      const vls = followersToValues(userOptions, newFlrs);
      handleFollowersChange(vls);
    }
  }, []);

  const handleFollowersChange = newValueOptions => {
    if (newValueOptions) {
      // convert options to users
      const newValuesUsers = users.filter(option =>
        newValueOptions.some(nv => nv.value === option.id)
      );

      // convert users to <caseId, userId> pairs
      const newFlrs = newValuesUsers.map(u => {
        return {
          caseId,
          userId: u.id,
          lastSeen: u.id === user.data.id ? new Date() : null
        };
      });
      const flrsToAdd = newFlrs.filter(u => !followers.some(f => f.userId === u.userId));
      const flrsToRemove = followers.filter(
        f => !newFlrs.some(newFlr => newFlr.userId === f.userId)
      );
      props.handleSetFollowers(caseId, flrsToAdd, flrsToRemove);

      // Keep data of previous followers to keep the lastSeen value
      const updatedFollowers = [
        ...followers.filter(f => !flrsToRemove.some(r => r.userId === f.userId)),
        ...flrsToAdd.map(fta => ({
          userId: fta.userId,
          lastSeen: fta.lastSeen,
          user: users.find(u => u.id === fta.userId)
        }))
      ];
      setFollowers(updatedFollowers);

      // Close the Select after each change, because there's no way to close it on mobile
      setIsShowSelect(false);
    }
  };

  const handleRemove = option => {
    const opt = userOptions.filter(o => followers.some(f => f.userId === o.value));
    const flrs = opt.filter(o => o.value !== option.userId);
    handleFollowersChange(flrs);
  };

  const filterFollowers = (filteredOptions, state) => {
    const newOptions = [];
    filteredOptions.forEach(option => {
      const values = [option.firstName, option.lastName, option.label];
      if (
        values.some(el =>
          el.replace(',', '').toLowerCase().includes(state.inputValue.toLowerCase())
        )
      ) {
        newOptions.push(option);
      }
    });
    return newOptions;
  };

  const onFollowerChipClick = follower => {
    console.log('follower', follower);

    if (follower.lastSeen) {
      const lastSeen = new Date(follower.lastSeen);
      const time = `${lastSeen.toLocaleTimeString()}, ${lastSeen.toLocaleDateString()}`;
      toast.info(
        t('follower_chip_clicked_toast_seen', {
          nickname: follower.user.nickName,
          time
        })
      );
    } else {
      toast.info(t('follower_chip_clicked_toast_not_seen', { nickname: follower.user.nickName }));
    }
  };

  return (
    <Typography component="div" className="my-16 relative" style={{ zIndex: 111 }}>
      {followers.length > 0 && (
        <div className="font-bold text-gray-700 text-sm mb-8">{t('Followed by')}:</div>
      )}
      <div className="flex flex-wrap">
        {followers.map(f => (
          <Chip
            onClick={() => onFollowerChipClick(f)}
            key={f.userId}
            className="mr-10 mb-12 pr-2 relative"
            style={{ height: '28px', cursor: 'default' }}
            sx={{
              '& .MuiChip-label': {
                overflow: 'visible'
              }
            }}
            label={
              <div className="flex -ml-8 items-center">
                <Avatar
                  className="mr-6"
                  sx={{ width: 22, height: 22, fontSize: 12 }}
                  user={f.user}
                />
                <div>{`${f.user.nickName}${userRolesToEmoji(f.user.roles)}${
                  f.lastSeen ? '👀' : ''
                }`}</div>
                {isShowSelect && (
                  <IconButton
                    className="remove-user-button"
                    title="Back"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleRemove(f);
                    }}
                    size="small"
                    style={{
                      height: '36px',
                      width: '36px',
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      transform: 'translate(40%, -44%)',
                      padding: 0
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: '#ababab',
                        borderRadius: '50%',
                        height: '18px',
                        width: '18px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        pointerEvents: 'none'
                      }}
                    >
                      <Icon
                        style={{
                          fontSize: 16,
                          fontWeight: 900,
                          color: '#fff'
                        }}
                      >
                        close
                      </Icon>
                    </div>
                  </IconButton>
                )}
              </div>
            }
          />
        ))}
        {!disabled && (
          <div className="flex items-center">
            {followers.length > 0 ? (
              !isShowSelect && (
                <IconButton
                  title="Back"
                  onClick={() => setIsShowSelect(true)}
                  size="small"
                  style={{ height: '28px', width: '28px', margin: '0 0 12px 6px' }}
                >
                  <Icon style={{ fontSize: 22 }}>edit</Icon>
                </IconButton>
              )
            ) : (
              <Button
                color="primary"
                variant="outlined"
                size="small"
                className="rounded-full"
                onClick={() => setIsShowSelect(true)}
                startIcon={<Icon>group_add</Icon>}
                style={{ fontSize: '12px', lineHeight: '26px', height: '26px', display: 'flex' }}
              >
                Add followers
              </Button>
            )}
            {isShowSelect && (
              <ClickAwayListener onClickAway={event => handleClickAway(event)}>
                <div
                  className="absolute"
                  style={{
                    backgroundColor: '#fff',
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    borderBottom: 'none',
                    borderRadius: '4px',
                    left: '50%',
                    top: '100%',
                    transform: `translate(-50%, 10px)`
                  }}
                >
                  <Select
                    name="caseFollowers"
                    withFilter
                    filterOptions={(options, state) => {
                      if (state.inputValue) {
                        return filterFollowers(options, state);
                      }
                      return options;
                    }}
                    multiple
                    disableClearable
                    options={userOptions}
                    value={followersToValues(userOptions, followers)}
                    size="small"
                    open
                    popperStyle={{ minWidth: 200 }}
                    forcePopupIcon={false}
                    onChange={handleFollowersChange}
                    fieldStyle={{ minWidth: 300 }}
                    renderTags={() => <div />}
                  />
                </div>
              </ClickAwayListener>
            )}
          </div>
        )}
      </div>
    </Typography>
  );
};

export default CaseFollowers;
