import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const PLAN_FEEDBACK_FULL_DATA = gql`
  fragment PlanFeedbackFullData on plan_feedbacks {
    id
    case_id
    addi_info_selection
    text
    what_went_well_selection
    what_went_well_text
    rating
    how_to_improve_selection
    how_to_improve_text
    created_at
    updated_at
    user {
      nick_name
      id
      avatar
      first_name
      last_name
    }
  }
`;
