import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const USER_FULL_DATA = gql`
  fragment userFullData on users {
    id
    nick_name
    pgy
    avatar
    is_pa
    is_cases_scheduler
    is_assigns_residents
    is_nurse_leader
    first_name
    last_name
    roles {
      role
    }
    user_sites {
      site_id
    }
    blank_case_always
    reset_stats_at
    follow_all
    created_at
    is_active
  }
`;
