const calcRates = ({
  briefDoneCases,
  totalBriefCases,
  depBriefDoneCases,
  depTotalBriefCases,
  debriefDoneCases,
  totalCasesAsAssistant,
  depDebriefDoneCases,
  depTotalCases,
  briefReviewedCases,
  briefsToReviewAsAttending,
  depBriefReviewedCases,
  debriefReviewedCases,
  debriefsToReviewAsAttending,
  depDebriefReviewedCases
}) => {
  const briefRate = briefDoneCases / totalBriefCases;
  const briefReviewRate = briefReviewedCases / briefsToReviewAsAttending;
  const debriefRate = debriefDoneCases / totalCasesAsAssistant;
  const debriefReviewRate = debriefReviewedCases / debriefsToReviewAsAttending;
  const userTotalBriefRate =
    (briefDoneCases + briefReviewedCases) / (totalBriefCases + briefsToReviewAsAttending);
  const userTotalDebriefRate =
    (debriefDoneCases + debriefReviewedCases) /
    (totalCasesAsAssistant + debriefsToReviewAsAttending);
  const depTotalBriefRate =
    (depBriefDoneCases + depBriefReviewedCases) / (depTotalBriefCases + depBriefDoneCases);
  const depTotalDebriefRate =
    (depDebriefDoneCases + depDebriefReviewedCases) / (depTotalCases + depDebriefDoneCases);

  return {
    totalBriefRate: {
      user: totalBriefCases > 0 ? userTotalBriefRate * 100 : 100,
      avg: depTotalBriefRate * 100
    },
    totalDebriefRate: {
      user: totalCasesAsAssistant > 0 ? userTotalDebriefRate * 100 : 100,
      avg: depTotalDebriefRate * 100
    },
    briefRate: {
      user: totalBriefCases > 0 ? briefRate * 100 : 100,
      avg: (depBriefDoneCases / depTotalBriefCases) * 100
    },
    debriefRate: {
      user: totalCasesAsAssistant > 0 ? debriefRate * 100 : 100,
      avg: (depDebriefDoneCases / depTotalCases) * 100
    },
    briefReviewRate: {
      user: briefsToReviewAsAttending > 0 ? briefReviewRate * 100 : 100,
      avg: (depBriefReviewedCases / depBriefDoneCases) * 100
    },
    debriefReviewRate: {
      user: debriefsToReviewAsAttending > 0 ? debriefReviewRate * 100 : 100,
      avg: (depDebriefReviewedCases / depDebriefDoneCases) * 100
    }
  };
};

export default {
  calcRates
};
