import React from 'react';
import ConfirmationDialog from 'app/components/confirmDialog/ConfirmDialog';
import { useTranslation, Trans } from 'react-i18next';
import toast from 'app/services/toastService/toast';

function TemplateInsightConfirmationDialog(props) {
  const { t } = useTranslation();
  const { values } = props;

  if (!values) {
    return null;
  }

  let toastMessage;
  let dialogMessage;
  if (values.isAdd) {
    toastMessage = 'template_insight_add_toast';
    dialogMessage = 'template_insight_add_message';
  } else {
    toastMessage = 'template_insight_remove_toast';
    dialogMessage = 'template_insight_remove_message';
  }

  const handleClose = ok => {
    if (ok) {
      toast.success(
        t(toastMessage, {
          value: values.value,
          procedure: values.procedureTitle
        })
      );
    }

    if (props.onClose) {
      props.onClose(ok);
    }
  };

  console.debug('values', values);

  return (
    <ConfirmationDialog
      open={props.open}
      onClose={handleClose}
      title={t('template_insight_title')}
      message={t(dialogMessage, {
        value: values.value
      })}
      center
      ok={t('template_insight_ok')}
      cancel={t('template_insight_no')}
      actionsProps={{ className: 'justify-between' }}
    />
  );
}

export default TemplateInsightConfirmationDialog;
