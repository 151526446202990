import MobileDetect from 'mobile-detect';

export const isMobile = () => {
  const md = new MobileDetect(window.navigator.userAgent);
  return md.mobile() || localStorage.getItem('emulate-iPhone');
};

export const isInstalled = () => {
  return navigator.standalone || localStorage.getItem('emulate-installed');
};
