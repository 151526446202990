import React from 'react';
import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import LogoutConfig from 'app/pages/logout/LogoutConfig';
import LoginConfig from 'app/pages/login/LoginConfig';
import AcudConfig from 'app/pages/acud/AcudConfig';
import CallbackConfig from 'app/pages/callback/CallbackConfig';
import CasesConfig from 'app/pages/cases/CasesConfig';
import MyDepartmentConfig from 'app/pages/myDepartment/MyDepartmentConfig';
import ScheduleConfig from 'app/pages/schedule/ScheduleConfig';
import ImportsConfig from 'app/pages/imports/ImportsConfig';
import AdminConfig from 'app/pages/admin/AdminConfig';
import PStepsConfig from 'app/pages/pSteps/PStepsConfig';

const routeConfigs = [
  AcudConfig,
  LoginConfig,
  CallbackConfig,
  CasesConfig,
  MyDepartmentConfig,
  LogoutConfig,
  ScheduleConfig,
  AdminConfig,
  PStepsConfig,
  ImportsConfig
];
const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin']),
  {
    path: '/',
    component: () => <Redirect to="/cases" />
  }
];

export default routes;
