import i18n from 'app/services/i18n';
import { USER_CASE_ROLES } from 'app/consts';

// eslint-disable-next-line import/prefer-default-export
export const getMediaField = (
  id,
  assets,
  handleCreateCaseAsset,
  handleUpdateCaseAsset,
  handleSubmitCaseAsset,
  handleDeleteCaseAsset,
  getUploadcareSignature,
  userRole
) => ({
  name: 'media',
  label: i18n.t('Images'),
  type: 'media',
  value: assets.map(asset => ({
    id: asset.id,
    desc: asset.description,
    meta: asset.meta,
    baseUrl: getUploadCareUrl(asset.externalId),
    thumbPath: getThumbPath(asset),
    imagePath: getImagePath(asset)
  })),
  handleSubmit: handleSubmitCaseAsset,
  handleUpload: handleCreateCaseAsset,
  handleUpdate: handleUpdateCaseAsset,
  handleDelete: handleDeleteCaseAsset,
  getSignature: async () => {
    if (userRole !== USER_CASE_ROLES.NONE) {
      return getUploadcareSignature(id);
    }
    return '';
  }
});

const getUploadCareUrl = uuid => `${process.env.REACT_APP_UPLOAD_CARE_CDN}/${uuid}/`;
const getImagePath = asset =>
  asset.meta?.cdnUrlModifiers
    ? `${asset.meta.cdnUrlModifiers}-/preview/1200x1200/?token=${asset.token}`
    : `-/preview/1200x1200/?token=${asset.token}`;

const getThumbPath = asset =>
  asset.meta?.cdnUrlModifiers
    ? `${asset.meta.cdnUrlModifiers}-/smart_resize/300x300/?token=${asset.token}`
    : `-/smart_resize/300x300/?token=${asset.token}`;
