import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { withFormsy } from 'formsy-react';
import { useTranslation, Trans } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { REMEMBER_PREFERENCE_OPTIONS } from 'app/consts';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(6),
    width: 300
  },
  buttons: {
    marginTop: theme.spacing(3)
  },
  button: {
    display: 'block',
    width: '100%',
    padding: '6px 8px',
    marginBottom: theme.spacing(1),
    textAlign: 'left',
    textTransform: 'none',
    '&.MuiButton-text': {
      background: '#f2f2f2',
      color: '#666'
    },
    '& .subtitle': {
      opacity: '.85',
      fontSize: '1.3rem'
    }
  },
  blankCaseMsg: {
    marginTop: theme.spacing(3)
  }
}));

const getRememberPreferencesValueDefault = (
  neverSaveDefaults,
  hasAttendingValues,
  isPmmValuesDirty
) => {
  // @todo: uncomment when the widget is moved to before "I want to work on".
  // let rememberPreferencesValue = false;
  let rememberPreferencesValue = REMEMBER_PREFERENCE_OPTIONS.YES;
  if (neverSaveDefaults) {
    rememberPreferencesValue = REMEMBER_PREFERENCE_OPTIONS.NEVER;
  } else if (hasAttendingValues) {
    if (isPmmValuesDirty) {
      rememberPreferencesValue = REMEMBER_PREFERENCE_OPTIONS.NOT_THIS_TIME;
    } else {
      rememberPreferencesValue = REMEMBER_PREFERENCE_OPTIONS.YES;
    }
  }
  return rememberPreferencesValue;
};

function RememberPreferences(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { procName, case: kase, user, className, isPmmValuesDirty, setValue } = props;
  const neverSaveDefaults = kase.attendingDefaults
    ? kase.attendingDefaults.never_save_defaults
    : false;

  const options = [
    {
      value: REMEMBER_PREFERENCE_OPTIONS.YES,
      title: t(
        kase.attendingDefaults && !neverSaveDefaults && !isPmmValuesDirty
          ? 'I want this to be the new template'
          : 'Save as template'
      ),
      subtitle: t('For all future {{proc}} cases', { proc: procName })
    },
    {
      value: REMEMBER_PREFERENCE_OPTIONS.NOT_THIS_TIME,
      title: t('Do not use as template'),
      subtitle: t('Keep my previous preferences')
    },
    {
      value: REMEMBER_PREFERENCE_OPTIONS.NEVER,
      title: t('Never for this procedure'),
      subtitle: t('Start without defaults for all {{proc}} cases', { proc: procName })
    }
  ];

  const [rememberPreferencesValueDefault, setRememberPreferencesValueDefault] = useState(
    getRememberPreferencesValueDefault(neverSaveDefaults, kase.attendingDefaults, isPmmValuesDirty)
  );

  useEffect(() => {
    setRememberPreferencesValueDefault(
      getRememberPreferencesValueDefault(
        neverSaveDefaults,
        kase.attendingDefaults,
        isPmmValuesDirty
      )
    );
  }, [neverSaveDefaults, kase.attendingDefaults, isPmmValuesDirty]);

  const [curRememberPreferencesValue, setCurRememberPreferencesValue] = useState(false);
  const rememberPreferencesValue =
    curRememberPreferencesValue === false
      ? rememberPreferencesValueDefault
      : curRememberPreferencesValue;
  useEffect(() => {
    if (curRememberPreferencesValue === false) {
      setValue(rememberPreferencesValueDefault);
    }
  }, [rememberPreferencesValueDefault, curRememberPreferencesValue, setValue]);
  function isSelected(option) {
    return option.value === rememberPreferencesValue;
  }

  return (
    <FormControl required={!user.blankCaseAlways} className={clsx(classes.root, className)}>
      <img className="h-24 absolute right-0 -mt-4" src="assets/images/icons/magic.svg" alt="" />

      <InputLabel>{t('Remember Preferences?')}</InputLabel>

      {user.blankCaseAlways && (
        <Typography
          className="px-15 normal-case text-left mt-20"
          color="textSecondary"
          variant="body5"
        >
          <Trans i18nKey="remember_preferences_disabled" />
        </Typography>
      )}

      {!user.blankCaseAlways && (
        <div className={clsx(classes.buttons)}>
          {options.map(option => (
            <Button
              key={option.value}
              className={clsx(classes.button)}
              onClick={() => {
                setCurRememberPreferencesValue(option.value);
                props.setValue(option.value);
              }}
              variant={isSelected(option) ? 'contained' : 'text'}
              color="primary"
            >
              <Typography variant="body1" className="">
                {option.title}
              </Typography>
              <Typography variant="body2" className="subtitle">
                {option.subtitle}
              </Typography>
            </Button>
          ))}
        </div>
      )}
    </FormControl>
  );
}

export default React.memo(withFormsy(RememberPreferences));
