import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { EST_SAVINGS_PER_CASE } from 'app/components/casesStats/CasesStats';
import DepartmentDashboardStatsTable from './DepartmentDashboardStatsTable';
import DashboardMonthlyLineChart from './DashboardMonthlyLineChart';
import DashboardPieChart from './DashboardPieChart';
import DashboardBarChart from './DashboardBarChart';
import DashboardTable from './DashboardTable';

const prettyItem = (item, prefix) => {
  // remove prefix from item
  let ret = item.replace(prefix, '');
  // capitalize first letter
  ret = ret.charAt(0).toUpperCase() + ret.slice(1);
  // replace underscores with spaces
  ret = ret.replace(/_/g, ' ');
  return ret;
};

export default function DepartmentDashboard(props) {
  const { t } = useTranslation();
  const { getDashboardData, data } = props;

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  // filter cases without a type yet
  const filteredCasesByType = data.casesByType?.filter(c => c.name);

  const huddleRateYearToDate = data.huddleRateYearToDate?.filter(
    d => d.year === new Date().getFullYear()
  );

  return (
    <Grid container spacing={3}>
      <Grid item sm={6} xs={12}>
        <DashboardBarChart
          title={t('CASES IN CHIEFY')}
          total={data.casesInChiefy}
          subtitle={`${t('est_department_savings')}: $${(
            data.briefedCases * EST_SAVINGS_PER_CASE
          ).toLocaleString()}`}
          bottomtitle={t('★ Future cases are not counted')}
          data={
            // show bar chart only if there's more then one site
            data.casesBySite.length > 1
              ? {
                  labels: data.casesBySite.map(d => d.name),
                  values: data.casesBySite.map(d => d.count)
                }
              : {}
          }
          height={170}
          // yLabel={t('Cases per site')}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <DashboardMonthlyLineChart
          title={t('HUDDLE RATE')}
          data={huddleRateYearToDate}
          percentage
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <DashboardMonthlyLineChart
          title={t('CASES YEAR TO DATE')}
          data={data.casesYearToDate}
          accumulate
        />
      </Grid>
      {data.casesInChiefy > 0 && (
        <Grid item sm={6} xs={12}>
          <DepartmentDashboardStatsTable title={t('COMPLIANCE (% OF CASES)')} data={data} />
        </Grid>
      )}
      {filteredCasesByType && (
        <Grid item sm={6} xs={12}>
          <DashboardPieChart data={filteredCasesByType} />
        </Grid>
      )}
      {data.casesByAssistant && data.casesByAssistant.length > 0 && (
        <Grid item sm={6} xs={12}>
          <DashboardTable
            data={data.casesByAssistant}
            title={t('CASES BY ASSISTANT')}
            cols={['nickName', 'count', 'percent']}
            headers={['', '# Cases', '% of Cases']}
            percent={row => `${parseFloat(((100 * row.count) / data.casesInChiefy).toFixed(1))}%`}
          />
        </Grid>
      )}
      {data.casesByAttending && data.casesByAttending.length > 0 && (
        <Grid item sm={6} xs={12}>
          <DashboardTable
            data={data.casesByAttending}
            title={t('CASES BY ATTENDING')}
            cols={['nickName', 'count', 'percent']}
            headers={['', '# Cases', '% of Cases']}
            percent={row => `${parseFloat(((100 * row.count) / data.casesInChiefy).toFixed(1))}%`}
          />
        </Grid>
      )}
    </Grid>
  );
}
