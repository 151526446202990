import React from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';

const DEFAULT_DURATION_MS = 5000;

let useSnackbarRef;
const setUseSnackbarRef = ref => {
  useSnackbarRef = ref;
};

function InnerSnackbarConfigurator(props) {
  props.setUseSnackbarRef(useSnackbar());
  return null;
}

function dismissSnackbar(key) {
  useSnackbarRef.closeSnackbar(key);
}

export function SnackbarConfigurator() {
  return (
    <SnackbarProvider
      maxSnack={1}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      autoHideDuration={DEFAULT_DURATION_MS}
      preventDuplicate
    >
      <InnerSnackbarConfigurator setUseSnackbarRef={setUseSnackbarRef} />
    </SnackbarProvider>
  );
}

export default {
  success(msg, duration = DEFAULT_DURATION_MS) {
    this.show(msg, 'success', duration);
  },
  warning(msg) {
    this.show(msg, 'warning');
  },
  info(msg) {
    this.show(msg, 'info');
  },
  error(msg) {
    this.show(
      `Sorry, something went wrong 🤷‍♂️. A screenshot of this message would help our developers understand: ${msg}`,
      'warning'
    );
  },
  show(msg, variant, duration = DEFAULT_DURATION_MS) {
    const key = useSnackbarRef.enqueueSnackbar(msg, {
      variant,
      onClick: () => dismissSnackbar(key),
      autoHideDuration: duration
    });
  }
};
