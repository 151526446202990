import OneSignal from 'react-onesignal';
import apiService from 'app/services/apiService/apiService';
import ONE_SIGNAL_CONFIG from './oneSignalServiceConfig';

class OneSignalService {
  client = OneSignal;

  isInit = false;

  async init() {
    if (this.isInit) {
      return;
    }
    if (!ONE_SIGNAL_CONFIG.appId) {
      throw Error('No app id for OneSignal');
    }
    await this.client.init({ appId: ONE_SIGNAL_CONFIG.appId });
    this.isInit = true;
  }

  showSlidedownPrompt(force = false) {
    this.client.showSlidedownPrompt({
      force
    });
  }

  registerToSubscriptionChangedEvent(callback) {
    this.client.on('subscriptionChange', callback);
  }

  getUserId() {
    return this.client.getUserId();
  }

  async showOneSignalPopup(currentUserId, force = false) {
    try {
      await this.init();
      this.showSlidedownPrompt(force);
      this.registerToSubscriptionChangedEvent(async isSubscribed => {
        try {
          console.log('subscription changed isSubscribed:', isSubscribed);

          if (!isSubscribed) {
            return;
          }
          const hash = await apiService.getIdHash();
          if (!hash) {
            return;
          }
          await this.client.setExternalUserId(currentUserId, hash);
          await apiService.updateUserPnStatus(true);
        } catch (error) {
          console.log('subscription changed error:', error);
        }
      });
    } catch (error) {
      console.log('showOneSignalPopup error:', error);
    }
  }
}
const instance = new OneSignalService();

export default instance;
