export const REMEMBER_PREFERENCE_OPTIONS = {
  YES: 'yes',
  NOT_THIS_TIME: 'not_this_time',
  NEVER: 'never'
};

export const FEEDBACK_ADD_INFO_OPTIONS = {
  PROCEDURE_INACCURATE: 'procedure_inaccurate',
  BOOKING_CONFLICT: 'booking_conflict',
  EQUIPMENT_MISMATCH: 'equipment_mismatach',
  IMPLANT_VENDOR_MISMATCH: 'implant_vendor_mismatch',
  OTHER: 'other'
};
export const FEEDBACK_HOW_TO_IMPROVE_OPTIONS = {
  FCOTS: 'fcots',
  TURNOVER: 'turnover',
  TIME_WASTE: 'time_waste',
  RESOURCE_WASTE: 'resource_waste',
  EQUIPMENT_NOT_USED: 'equipment_not_used',
  SUPPLY_WASTE: 'supply_waste'
};

export const FEEDBACK_WHAT_WENT_WELL_OPTIONS = {
  GOT_INFO_EARLY: 'got_info_early',
  GOOD_COMMUNICATION: 'good_communication',
  EFFICIENT: 'we_were_efficient',
  HAPPY_TEAM: 'happy_team',
  PATIENT_EXPERIENCE: 'patient_experience',
  SOMETHING_ELSE: 'something_else'
};

export const FORM_STAGE = {
  BRIEF: 'brief',
  DEBRIEF: 'debrief'
};
export const CASE_STATE = {
  NEW: 'new',
  IN_BRIEF: 'in_brief',
  BRIEF_IN_REVIEW: 'brief_in_review',
  READY: 'ready',
  IN_DEBRIEF: 'in_debrief',
  DEBRIEF_IN_REVIEW: 'debrief_in_review',
  COMPLETED: 'completed',
  ARCHIVED: 'archived'
};
export const CASES_STATE_ORDER = [
  CASE_STATE.NEW,
  CASE_STATE.IN_BRIEF,
  CASE_STATE.BRIEF_IN_REVIEW,
  CASE_STATE.READY,
  CASE_STATE.COMPLETED,
  CASE_STATE.ARCHIVED
];
export const RESIDENT_ROLE = {
  ASSISTANT: 'assistant',
  SENIOR: 'senior',
  LEAD: 'lead'
};
export const PATIENT_TYPE = {
  ADULT: 'adult',
  PEDIATRIC: 'pediatric'
};
export const PATIENT_GENDER = {
  FEMALE: 'female',
  MALE: 'male'
};
export const TRACKABLE_GOAL_RESULT = {
  MET: 'met',
  PARTIALLY: 'partially',
  NOT_MET: 'not_met'
};

export function caseStateDebriefPlus(state) {
  return [
    CASE_STATE.IN_DEBRIEF,
    CASE_STATE.DEBRIEF_IN_REVIEW,
    CASE_STATE.COMPLETED,
    CASE_STATE.ARCHIVED
  ].includes(state);
}

const CASE_STATES = {
  [CASE_STATE.NEW]: {
    icon: 'edit',
    color: 'pre',
    label: 'Pending',
    orLabel: 'or_board_case_plan_not_ready'
  },
  [CASE_STATE.IN_BRIEF]: {
    icon: 'edit',
    color: 'pre',
    label: 'In planning',
    orLabel: 'or_board_case_plan_not_ready',
    visibleToRoles: ['resident', 'pa'],
    needAttentionFrom: ['resident']
  },
  [CASE_STATE.BRIEF_IN_REVIEW]: {
    icon: 'visibility',
    color: 'pre',
    label: 'Waiting to huddle',
    orLabel: 'or_board_case_plan_not_ready',
    needAttentionFrom: ['attending']
  },
  [CASE_STATE.READY]: {
    icon: 'flight',
    color: '',
    label: 'Ready to go',
    orLabel: 'or_board_case_plan_ready'
  },
  [CASE_STATE.IN_DEBRIEF]: {
    icon: 'edit',
    color: 'post',
    label: 'Waiting for debrief',
    orLabel: 'or_board_case_plan_ready',
    visibleToRoles: ['resident', 'pa'],
    needAttentionFrom: ['resident', 'pa']
  },
  [CASE_STATE.DEBRIEF_IN_REVIEW]: {
    icon: 'visibility',
    color: 'post',
    label: 'Waiting for debrief review',
    orLabel: 'or_board_case_plan_ready',
    needAttentionFrom: ['attending']
  },
  [CASE_STATE.COMPLETED]: {
    icon: 'done_outline',
    color: '',
    label: 'How did it go?',
    subLabel: 'Give the team feedback and put your lessons learned for next time',
    orLabel: 'or_board_case_plan_ready',
    needAttentionFrom: ['attending'],
    visibleToRoles: ['attending']
  },
  [CASE_STATE.ARCHIVED]: {
    icon: 'history',
    color: '',
    label: 'Case Log'
  }
};

// eslint-disable-next-line import/prefer-default-export
export { CASE_STATES };

export function caseStateInBriefToReady(state) {
  return [CASE_STATE.IN_BRIEF, CASE_STATE.BRIEF_IN_REVIEW, CASE_STATE.READY].includes(state);
}

export function caseStateInBriefAndReview(state) {
  return [CASE_STATE.IN_BRIEF, CASE_STATE.BRIEF_IN_REVIEW].includes(state);
}

export const USER_CASE_ROLES = {
  ATTENDING: 'attending',
  RESIDENT: 'resident',
  NONE: 'none'
};

export const ROLES = {
  ANESTHESIA: 'anesthesia',
  ANESTHESIA_RESIDENT: 'anesthesia_resident',
  CRNA: 'crna',
  APP: 'app',
  SURGICAL_FELLOW: 'surgical_fellow',
  RESIDENT: 'resident',
  NEUROPHYSIOLOGIST: 'neurophysiologist',
  NURSE: 'nurse',
  NURSE_MANAGER: 'nurse_manager',
  CST: 'cst',
  ORSTAFF: 'orstaff',
  ATTENDING: 'attending',
  VENDOR_REP: 'vendor_rep',
  OTHER: 'other',
  DEPARTMENT_ADMIN: 'department_admin',
  CARE_TEAM: 'care_team',
  INVITER: 'inviter'
};

export const CARE_TEAM_ROLES = [
  ROLES.ANESTHESIA,
  ROLES.ANESTHESIA_RESIDENT,
  ROLES.CRNA,
  ROLES.APP,
  ROLES.SURGICAL_FELLOW,
  ROLES.RESIDENT,
  ROLES.NEUROPHYSIOLOGIST,
  ROLES.NURSE,
  ROLES.NURSE_MANAGER,
  ROLES.CST,
  ROLES.ORSTAFF,
  ROLES.ATTENDING,
  ROLES.VENDOR_REP,
  ROLES.OTHER
];

export const getSavedPrefsInfoBoxMsg = (kase, userRoleInCase, t) => {
  let savedPrefsInfoBoxMsg;
  const neverSaveDefaults = kase.attendingDefaults?.never_save_defaults;
  const hasResidentValues =
    kase && kase.valuesByUser && kase.valuesByUser.includes(kase.residentId);
  if (kase && caseStateInBriefAndReview(kase.state) && userRoleInCase !== USER_CASE_ROLES.NONE) {
    if (kase.attendingDefaults && !neverSaveDefaults) {
      if (kase.attendingDefaults.values) {
        // There are default attending values, and the attending did not select to start with blank cases
        if (userRoleInCase === USER_CASE_ROLES.RESIDENT) {
          savedPrefsInfoBoxMsg = t(
            "Filled out based on {{attending}}'s past preferences. Take a minute to review and ask questions, and send to {{attending}}.",
            {
              attending: kase.attending.nickName.trim()
            }
          );
        } else if (kase.resident) {
          // user is attending, and resident is defined for this case
          savedPrefsInfoBoxMsg = t(
            `Filled out based on your previous cases. ${
              hasResidentValues
                ? '{{resident}} has made a few additional changes.'
                : '{{resident}} did not make any additional changes.'
            }`,
            {
              resident: kase.resident.nickName.trim()
            }
          );
        } else {
          // user is attending, and resident is NOT defined for this case
          savedPrefsInfoBoxMsg = t(`Filled out based on your previous cases`);
        }
      } else if (userRoleInCase === USER_CASE_ROLES.RESIDENT) {
        // The attending always preferes to start with blank cases and user is resident
        savedPrefsInfoBoxMsg = t(
          '{{attending}} prefers that all case plans start blank. Take a minute to review and ask questions, and send to {{attending}}.',
          {
            attending: kase.attending.nickName.trim()
          }
        );
      }
    } else if (userRoleInCase === USER_CASE_ROLES.RESIDENT) {
      // There are not default values
      if (kase.state === CASE_STATE.IN_BRIEF) {
        savedPrefsInfoBoxMsg = t(
          'Take a minute to review and ask questions, and send to {{attending}}.',
          { attending: kase.attending.nickName.trim() }
        );
      } else if (kase.state === CASE_STATE.BRIEF_IN_REVIEW) {
        savedPrefsInfoBoxMsg = t(
          'Waiting for {{attending}} to approve the plan. You can still make changes.',
          { attending: kase.attending.nickName.trim() }
        );
      }
    }
  }
  return savedPrefsInfoBoxMsg;
};

export const userRolesToEmoji = roles => {
  const findRole = role => roles.find(r => r.role === role);

  if (!roles) {
    return '';
  }

  if (findRole(ROLES.ATTENDING) || findRole(ROLES.RESIDENT) || findRole(ROLES.PA)) {
    return ' 🥼';
  }
  if (findRole(ROLES.ANESTHESIA)) {
    return ' 💉';
  }
  if (findRole(ROLES.ORSTAFF) || findRole(ROLES.SCRUB_TECH)) {
    return ' 🩺';
  }

  return '';
};
