import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation, Trans } from 'react-i18next';
import { darken } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from 'app/auth/store/userSlice';
import { setLoading } from 'app/metaData/store/enumSlice';
// import history from '@history';
import auth0Service from 'app/services/auth0Service';
import FuseAnimate from '@fuse/core/FuseAnimate';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useHistory, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Alert from '@mui/material/Alert';
import urlParams from 'app/services/urlParams';
import { NOT_FOUND_IN_HASURA_ERROR } from 'app/services/auth0Service/auth0Service';

const useStyles = makeStyles(theme => ({
  root: {
    background: `linear-gradient(to left,
      ${darken(theme.palette.primary.dark, 0.4)} 0%,
      ${darken(theme.palette.primary.dark, 0.7)} 100%)`,
    color: theme.palette.primary.contrastText,
    '& #login-form-container': {
      minHeight: '200px',
      '& .auth0-lock-header': {
        display: 'none'
      },
      '& .auth0-lock-cred-pane-internal-wrapper': {
        height: 'auto'
      }
    }
  },
  leftSection: {},
  rightSection: {
    background: `linear-gradient(to right,
      ${darken(theme.palette.primary.main, 0.3)} 0%,
      ${darken(theme.palette.primary.main, 0.5)} 100%)`,
    color: theme.palette.primary.contrastText
  }
}));

function Login() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const hasError = search === '?hasError=true';
  const showInactiveError = search === '?inactive=true';
  const [showLoginFaster, setShowLoginFaster] = useState(false);
  const wrongPasswordError = useSelector(({ auth }) => auth.user.wrongPasswordError);

  // eslint-disable-next-line
  const tou = <a href={t('login_tou_url')} />;
  // eslint-disable-next-line
  const pp = <a href={t('login_pp_url')} />;
  useEffect(() => {
    const redirectTo = urlParams.get('redirectTo');
    if (redirectTo && redirectTo !== '/logout') {
      console.log('redirectTo', redirectTo);
      localStorage.setItem('redirectTo', redirectTo);
      const redirectToSearch =
        search !== `?redirectTo=${redirectTo}`
          ? search.replace(`&redirectTo=${redirectTo}`, '')
          : '';
      localStorage.setItem('redirectToSearch', redirectToSearch);
    } else {
      localStorage.removeItem('redirectTo');
      localStorage.removeItem('redirectToSearch');
    }
    auth0Service.login();

    auth0Service.onAuthenticated(() => {
      Sentry.captureMessage('login onAuthenticated');
      Sentry.addBreadcrumb({
        category: 'login',
        message: `login onAuthenticated`,
        level: Sentry.Severity.Info
      });
      dispatch(setUserData({ role: [''], data: {} }));
      dispatch(setLoading(true));

      history.push({
        pathname: '/'
      });
    });
  }, [dispatch, search, history]);

  useEffect(() => {
    setShowLoginFaster(wrongPasswordError);
  }, [wrongPasswordError]);
  return (
    <div
      className={clsx(
        classes.root,
        'flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24'
      )}
    >
      <FuseAnimate animation="transition.expandIn">
        <div className="flex w-full max-w-400 md:max-w-3xl rounded-12 shadow-2xl overflow-hidden">
          <Card
            className={clsx(
              classes.leftSection,
              'flex flex-col w-full max-w-sm items-center justify-center'
            )}
            square
            elevation={0}
          >
            <CardContent className="flex flex-col items-center justify-center w-full py-96">
              <FuseAnimate delay={300}>
                <div className="flex flex-col">
                  <div className="flex items-center justify-center">
                    <img className="logo-icon w-48" src="assets/logo/logo.svg" alt="logo" />
                    <Typography className="ml-10 text-24 font-800 logo-text" color="primary">
                      {t('CHIEFY')}
                    </Typography>
                  </div>
                  {!hasError && (
                    <Typography
                      className="mt-10 text-center font-bold"
                      variant="caption"
                      color="primary"
                    >
                      {t('login_subtitle')}
                    </Typography>
                  )}
                  {hasError && (
                    <Typography className="mt-10 text-center" variant="caption">
                      {t('login_error_message')}
                    </Typography>
                  )}
                  {showLoginFaster && (
                    <Alert severity="info" className="m-12 text-14">
                      <Trans
                        i18nKey="want_to_login_faster"
                        components={{ url: <a href={t('want_to_login_faster_url')} /> }} // eslint-disable-line
                      />
                    </Alert>
                  )}
                  {showInactiveError && (
                    <Alert severity="info" className="m-12 text-14">
                      {t('login_error_message_inactive')}
                    </Alert>
                  )}
                  <div id="login-form-container" className="mb-32" />
                  <Typography color="textSecondary" variant="caption">
                    <Trans
                      i18nKey="login_tou_pp"
                      components={{
                        tou,
                        pp
                      }}
                    />
                  </Typography>
                </div>
              </FuseAnimate>
            </CardContent>
          </Card>

          <div
            className={clsx(
              classes.rightSection,
              'hidden md:flex flex-1 items-center justify-center p-64'
            )}
          >
            <div className="max-w-320">
              <FuseAnimate animation="transition.slideUpIn" delay={400}>
                <Typography variant="h3" color="inherit" className="font-800 leading-tight">
                  {t('Welcome')} <br />
                  {t('to')} <br />
                  {t('CHIEFY!')}
                </Typography>
              </FuseAnimate>

              <FuseAnimate delay={500}>
                <Typography variant="subtitle1" color="inherit" className="mt-32">
                  {t('Powerful PMM')}
                </Typography>
              </FuseAnimate>
            </div>
          </div>
        </div>
      </FuseAnimate>
    </div>
  );
}

export default Login;
