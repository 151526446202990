import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { selectContrastMainTheme } from 'app/store/fuse/settingsSlice';

function FusePageSimpleHeader(props) {
  const theme = useTheme();
  const contrastTheme = useSelector(selectContrastMainTheme(theme.palette.primary.main));

  return (
    <div className={props.classes.header}>
      {props.header && <StyledEngineProvider injectFirst>
        <ThemeProvider theme={contrastTheme}>{props.header}</ThemeProvider>
      </StyledEngineProvider>}
    </div>
  );
}

export default FusePageSimpleHeader;
