import React from 'react';
import RiskListItem from './RiskListItem';

const RisksList = ({ risks }) => {
  return (
    <>
      {risks.map(risk => (
        <RiskListItem key={risk.id} risk={risk} />
      ))}
    </>
  );
};

export default RisksList;
