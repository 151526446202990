import React, { Fragment } from 'react';
import _ from '@lodash';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { withFormsy } from 'formsy-react';
import ChangedBy from 'app/components/userAvatar/ChangedByAvatar';

function RadioGroupFormsy(props) {
  const importedProps = _.pick(props, [
    'name',
    'disabled',
    'onBlur',
    'onChange',
    'onKeyDown',
    'variant'
  ]);

  // An error message is returned only if the component is invalid
  const { errorMessage, value } = props;

  const changeValue = (event, val) => {
    if (props.isFormDisabled) return;

    const newVal = props.options.find(o => o.value === val);
    props.setValue(newVal);
    if (props.onChange) {
      props.onChange(newVal, props.formRef);
    }
  };

  const isSelected = option => {
    if (Array.isArray(value)) {
      return value.some(v => v.value === option.value);
    }

    return option.value === value.value;
  };

  return (
    <FormControl
      error={Boolean((!props.isPristine && props.showRequired) || errorMessage)}
      className={props.className}
    >
      <FormControl component="fieldset" required={props.required} error={Boolean(errorMessage)}>
        {props.label && <FormLabel component="legend">{props.label}</FormLabel>}
        <RadioGroup {...importedProps} value={value} onChange={changeValue}>
          {props.options.map((option, i) =>
            // show only selected options when disabled, remove 'Other' option
            props.disabled &&
            (!isSelected(option) || option.value.toLowerCase() === 'other') ? null : (
              <FormControlLabel
                key={option.value + i}
                value={option.value}
                control={<Radio color="primary" />}
                checked={isSelected(option)}
                label={
                  <Fragment>
                    {option.label}
                    {isSelected(option) && props.changedBy && !props.isFormDisabled && (
                      <ChangedBy className="right-0 mr-4" user={props.changedBy} />
                    )}
                  </Fragment>
                }
              />
            )
          )}
        </RadioGroup>
        {Boolean(errorMessage) && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </FormControl>
  );
}

export default React.memo(withFormsy(RadioGroupFormsy));
