import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import React from 'react';
import { getSavedPrefsInfoBoxMsg } from 'app/consts';

const SavedPrefsInfoBox = props => {
  const { case: c, userRoleInCase } = props;
  const { t } = useTranslation();
  const savedPrefsInfoBoxMsg = getSavedPrefsInfoBoxMsg(c, userRoleInCase, t);
  if (!savedPrefsInfoBoxMsg) {
    return null;
  }
  return (
    <Alert
      severity="info"
      className="mb-12 text-14"
      icon={
        <img
          className="h-20 w-20"
          style={{ minWidth: '2rem' }}
          src="assets/images/icons/magic.svg"
          alt=""
        />
      }
    >
      {savedPrefsInfoBoxMsg}
    </Alert>
  );
};

export default SavedPrefsInfoBox;
