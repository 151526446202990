import React, { Fragment, useEffect, useState } from 'react';
import _ from '@lodash';
import clsx from 'clsx';
import { lightBlue, grey, deepOrange } from '@mui/material/colors';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import EmptyCasesList from 'app/components/emptyCasesList/EmptyCasesList';
import EmptyPastCasesList from 'app/components/emptyCasesList/EmptyPastCasesList';
import CsvDownloader from 'react-csv-downloader';
import Typography from '@mui/material/Typography';
import amplService from 'app/services/amplService';
import { CASE_STATES } from 'app/consts';
import { localISODate } from 'app/pages/cases/helper';
import CasesListItem from './CasesListItem';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    paddingBottom: 0,
    '&:last-of-type': {
      marginBottom: '5rem'
    }
  },
  groupHeader: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.5rem',
    lineHeight: '2.5',
    fontWeight: 800,
    color: 'black',
    backgroundColor: theme.palette.background.default
  },
  subLabel: {
    backgroundColor: theme.palette.background.default
  },
  avatar: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1.5),
    color: grey[800],
    backgroundColor: lightBlue[50]
  },
  avatarIcon: {
    fontSize: 15
  },
  pre: {
    background: `linear-gradient(to right, ${deepOrange[300]} 25%, ${lightBlue[50]} 25%)`
  },
  post: {
    background: `linear-gradient(to right, ${lightBlue[50]} 75%, ${lightBlue[100]} 75%)`
  },
  dot: {
    alignSelf: 'normal',
    color: theme.palette.secondary.main,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    marginLeft: '0.5rem'
  },
  fab: {
    position: 'fixed',
    zIndex: 99,
    bottom: theme.spacing(10),
    right: theme.spacing(3),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  }
}));

export default function CasesList(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const userRoles = useSelector(({ auth }) => auth.user.role);
  const [loadedTab, setLoadedTab] = useState('');
  const {
    cases,
    totalCases,
    loadCases,
    tab,
    clearCases,
    setTab,
    casesLimitInitValue,
    setCasesLimit,
    casesLimit,
    casesLimitIncrement,
    useLoadMore,
    canLoadMore,
    loadMoreCases
  } = props;

  let grouped;
  let groups;
  if (props.groupByDate) {
    grouped = _.groupBy(cases, c => localISODate(c.caseDate));
    groups = Object.keys(grouped);
  } else {
    grouped = _.groupBy(cases, c => c.state);
    groups = Object.keys(CASE_STATES);
  }
  useEffect(() => {
    if (clearCases) clearCases();
    if (loadCases) loadCases();
    if (setLoadedTab) setLoadedTab(tab);
    if (setTab) setTab(tab);
  }, [loadCases, clearCases, tab, setTab]);

  const handleClick = async (c, editCaseAttributes) => {
    if (props.loadCaseById) {
      await props.loadCaseById(c.id, editCaseAttributes);
    }
  };

  const handleClickNew = async () => {
    props.newCase();
  };

  const handleDelete = async kase => {
    await props.handleDelete(kase);
  };

  const handleArchive = async kase => {
    await props.handleArchive(kase);
  };

  const needsAttention = status => {
    return false && userRoles.some(role => CASE_STATES[status]?.needAttentionFrom?.includes(role));
  };

  const statusVisibleToUser = status => {
    const roles = CASE_STATES[status] && CASE_STATES[status].visibleToRoles;
    // filter 'my-cases' tab only (i.e. not on Schedule -> Published)
    // if 'visibleToRoles' is empty, return true
    return tab === 'my-cases' && roles ? userRoles.some(role => roles.includes(role)) : true;
  };

  const visibleCasesCount = groups.reduce((count, group) => {
    return grouped[group] && statusVisibleToUser(group) ? count + grouped[group].length : count;
  }, 0);

  return (
    <Fragment>
      {tab === loadedTab &&
        groups.map(
          group =>
            grouped[group] &&
            statusVisibleToUser(group) && (
              <List key={group} className={classes.root} subheader={<li />}>
                {CASE_STATES[group] && (
                  <ListSubheader className={classes.groupHeader}>
                    <Avatar
                      title={_.startCase(group)}
                      className={clsx(
                        classes.avatar,
                        CASE_STATES[group].color && classes[CASE_STATES[group].color]
                      )}
                    >
                      <Icon className={classes.avatarIcon}>{CASE_STATES[group].icon}</Icon>
                    </Avatar>
                    {t(CASE_STATES[group].label)}
                    {needsAttention(group) && <span className={classes.dot}>{'\u25CF'}</span>}
                    {props.handleExport && (
                      <CsvDownloader
                        className="ml-auto"
                        datas={props.handleExport}
                        filename="cases-log"
                      >
                        <Button
                          title={t('Export')}
                          className="ml-auto h-24"
                          size="small"
                          variant="outlined"
                        >
                          {t('Export')}
                        </Button>
                      </CsvDownloader>
                    )}
                  </ListSubheader>
                )}
                {CASE_STATES[group]?.subLabel && (
                  <Typography
                    className={clsx('text-xs pl-20 pb-4', classes.subLabel)}
                  >{`\n${CASE_STATES[group].subLabel}`}</Typography>
                )}
                {grouped[group].map((c, index) => (
                  <CasesListItem
                    kase={c}
                    key={c.id}
                    tab={loadedTab}
                    handleClick={handleClick}
                    handleDelete={handleDelete}
                    handleArchive={props.handleArchive}
                    handleShare={props.handleShare}
                    indexInList={index}
                  />
                ))}
                {useLoadMore && (totalCases > casesLimitInitValue || canLoadMore) && (
                  <Button
                    style={{
                      width: '100%',
                      textTransform: 'none',
                      backgroundColor: '#f5f5f5',
                      border: 'none',
                      borderRadius: 'unset'
                    }}
                    onClick={() => {
                      if (!(totalCases > casesLimit) && !canLoadMore) {
                        return;
                      }
                      if (totalCases > casesLimit) {
                        setCasesLimit(
                          casesLimit + casesLimitIncrement <= totalCases
                            ? casesLimit + casesLimitIncrement
                            : totalCases
                        );
                        amplService.sendEvent(amplService.EVENTS.LOAD_MORE);
                      } else {
                        loadMoreCases();
                      }
                    }}
                  >
                    {totalCases > casesLimit || canLoadMore
                      ? t('Load more')
                      : t("That's all. Here's a flower: 🌷")}
                  </Button>
                )}
              </List>
            )
        )}

      {/* Welcome message - shown if 0 cases */}
      {tab === 'my-cases' && totalCases === 0 && <EmptyCasesList casesCount={visibleCasesCount} />}

      {/* Empty case-log message */}
      {tab === 'my-past-cases' && <EmptyPastCasesList casesCount={visibleCasesCount} />}

      {props.newCase && (
        <Fab onClick={() => handleClickNew()} className={classes.fab} aria-label="add">
          <Icon>add</Icon>
        </Fab>
      )}
    </Fragment>
  );
}
