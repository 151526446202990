import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ConfirmButton from 'app/components/confirmButton/ConfirmButton';
import MediaUpload from './MediaUpload';

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  gridList: {
    display: 'flex',
    flexWrap: 'nowrap',
    transform: 'translateZ(0)'
  },
  gridItem: {
    marginRight: theme.spacing(0.1),
    aspectRatio: 1,
    width: '36vw',
    minWidth: '36vw',
    '@media (min-width:555px)': { minWidth: '200px', width: '200px' }
  },
  gridItemAdd: {
    backgroundColor: theme.palette.grey[200],
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      fill: theme.palette.grey[400],
      width: '80%',
      height: '80%'
    },
    '&:first-child': {
      minWidth: '75px',
      width: '75px'
    }
  },
  gridItemTile: {
    backgroundColor: '#000',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  gridImg: {
    height: 'auto',
    maxWidth: 'none'
  },
  title: {
    fontSize: '1.4rem'
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  },
  delete: {
    color: '#fff',
    paddingRight: '0',
    minWidth: '0',
    width: '3.6rem'
  }
}));

export default function MediaGrid(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = (i = 0) => {
    props.onClick(i);
  };

  return (
    <div className={classes.root}>
      <ImageList className={classes.gridList} cols={3}>
        {props.assets.map((asset, i) => (
          <ImageListItem
            key={asset.id}
            classes={{
              root: classes.gridItem,
              item: classes.gridItemTile
            }}
          >
            <img
              className={classes.gridImg}
              src={asset.baseUrl + asset.thumbPath}
              alt={asset.title}
              onClick={() => handleClick(i)}
              onKeyPress={() => handleClick(i)}
              role="presentation"
            />

            <ImageListItemBar
              title={asset.desc}
              classes={{
                root: classes.titleBar,
                title: classes.title
              }}
              actionIcon={
                props.disabled ? null : (
                  <ConfirmButton
                    title={t('Delete')}
                    icon="delete_forever"
                    message={t('Are you sure you want to delete this asset?')}
                    onConfirm={() => props.handleDelete(asset)}
                    buttonProps={{
                      className: classes.delete,
                      disableRipple: true,
                      variant: 'text'
                    }}
                  />
                )
              }
            />
          </ImageListItem>
        ))}
        {props.assets.length < 10 && !props.disabled && (
          <ImageListItem
            key="add"
            cols={1}
            classes={{
              root: `${classes.gridItem} ${classes.gridItemAdd}`
            }}
          >
            <MediaUpload
              handleUpload={props.handleUpload}
              handleUpdate={props.handleUpdate}
              handleSubmit={props.handleSubmit}
              getSignature={props.getSignature}
              disabled={props.disabled}
            />
          </ImageListItem>
        )}
      </ImageList>
    </div>
  );
}
