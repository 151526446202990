import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#fff',
    borderRadius: '8px'
  },
  curStep: {
    border: '1px solid',
    borderRadius: '8px',
    borderColor: theme.palette.grey[400],
    width: '100%',
    marginRight: '20px'
  },
  closeButton: {
    padding: theme.spacing(0.2),
    zIndex: 9999,
    color: theme.palette.grey[500],
    backgroundColor: '#ffffffaa',
    width: '30px',
    height: '30px',
    margin: '0 0 4px auto'
  }
}));

const ProgressStrip = ({
  props,
  curStepIndex,
  steps,
  next,
  appendixTitle,
  previewMode,
  onClose
}) => {
  const { title } = steps[curStepIndex];
  const classes = useStyles(props);
  const stripText = next
    ? `NEXT: ${title}`
    : `${previewMode ? 'Preview ' : ''}${curStepIndex + 1}/${steps.length}: ${title}`;

  return (
    <div className={clsx('p-steps-list-header flex items-center', classes.container)}>
      <div className={clsx('text-xl font-700 px-10 py-8', classes.curStep)}>
        {`${stripText}${appendixTitle ? ` → ${appendixTitle}` : ''}`}
      </div>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} size="large">
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default ProgressStrip;
