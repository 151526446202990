import React from 'react';
import _ from '@lodash';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { withFormsy } from 'formsy-react';
import ChangedBy from 'app/components/userAvatar/ChangedByAvatar';

function SwitchFormsy(props) {
  const importedProps = _.pick(props, [
    'checked',
    'checkedIcon',
    'classes',
    'color',
    'defaultChecked',
    'disabled',
    'disableRipple',
    'edge',
    'icon',
    'name',
    'inputProps',
    'required',
    'size'
  ]);

  // An error message is returned only if the component is invalid
  const { errorMessage, value } = props;

  const changeValue = event => {
    if (props.isFormDisabled) return;

    props.setValue(event.target.checked);

    if (props.onChange) {
      props.onChange(event.target.checked, props.formRef);
    }
  };

  return (
    <FormControl
      error={Boolean((!props.isPristine && props.showRequired) || errorMessage)}
      className={props.className}
    >
      {props.changedBy && !props.isFormDisabled && <ChangedBy user={props.changedBy} />}

      <FormControlLabel
        control={<Switch {...importedProps} checked={value} onChange={changeValue} />}
        label={props.label}
      />
      {Boolean(errorMessage) && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}

export default React.memo(withFormsy(SwitchFormsy));
