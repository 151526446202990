import i18n from 'app/services/i18n';
import { CASE_STATE, USER_CASE_ROLES } from 'app/consts';
import { processPMMFields } from './PMMFields';
import { getMediaField } from './media';

// eslint-disable-next-line import/prefer-default-export
export const getBriefFields = (
  kase,
  user,
  userRoleInCase,
  handleCaseValueUpdate,
  handlePmmValueUpdate,
  attendingFieldValues,
  handleCreateCaseAsset,
  handleUpdateCaseAsset,
  handleSubmitCaseAsset,
  handleDeleteCaseAsset,
  getUploadcareSignature,
  isPmmValuesDirty,
  userRoles
) => {
  const { id, assets } = kase;

  const fields = [];

  fields.push({
    name: 'lessonsLearned',
    label: i18n.t('LEARN FROM SIMILAR CASES'),
    type: 'lessonsLearned'
  });
  const pmmFields = processPMMFields(handlePmmValueUpdate, kase, attendingFieldValues, userRoles);

  fields.push(...pmmFields);

  const rememberPreferencesField = getRememberPreferencesField(
    kase,
    user,
    userRoleInCase,
    isPmmValuesDirty
  );

  fields.push(rememberPreferencesField);

  const mediaFields = getMediaField(
    id,
    assets.filter(a => a.meta && a.stage === 'brief'),
    handleCreateCaseAsset,
    handleUpdateCaseAsset,
    handleSubmitCaseAsset,
    handleDeleteCaseAsset,
    getUploadcareSignature,
    userRoleInCase
  );
  fields.push(mediaFields);

  return fields;
};
const getRememberPreferencesField = (kase, user, userRoleInCase, isPmmValuesDirty) => ({
  name: 'rememberPreferences',
  type: 'rememberPreferences',
  procName: kase.procedureTitle,
  case: kase,
  user,
  isPmmValuesDirty,
  shouldRender: () => {
    return (
      userRoleInCase === USER_CASE_ROLES.ATTENDING &&
      (kase.state === CASE_STATE.IN_BRIEF || kase.state === CASE_STATE.BRIEF_IN_REVIEW)
    );
  }
});
