import React, { useEffect } from 'react';
import preval from 'preval.macro';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import oneSignalService from 'app/services/oneSignalService';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { A2HSButton } from 'app/components/add2HomeScreen';
import { isInstalled, isMobile } from 'app/components/add2HomeScreen/helper';
import toast from 'app/services/toastService/toast';
import apiService from 'app/services/apiService';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    width: 'auto'
  }
}));

export default function NavbarFooter(props) {
  const classes = useStyles();
  const currentUserId = useSelector(({ auth }) => auth.user.data.id);
  const { t } = useTranslation();
  let devModeCount = 0;
  useEffect(() => {
    if (!isMobile() || isInstalled()) {
      return;
    }
    const pnPopupShown = localStorage.getItem('pnPopupShown');
    if (pnPopupShown) {
      return;
    }
    if (true) {
      oneSignalService.showOneSignalPopup(currentUserId);
      localStorage.setItem('pnPopupShown', 'true');
    } else {
      setTimeout(() => {
        oneSignalService.showOneSignalPopup(currentUserId);
        localStorage.setItem('pnPopupShown', 'true');
      }, 1000 * 60 * 3);
    }
  }, []);
  const handleBuildClick = () => {
    const clicks = 5;
    devModeCount = (devModeCount + 1) % clicks;

    if (devModeCount === 1) {
      // Do not show on 1st click, in case of accidental
      return;
    }

    const oldDevmode = localStorage.getItem('devMode');
    if (devModeCount === 0) {
      localStorage.setItem('devMode', oldDevmode === 'true' ? 'false' : 'true');
      toast.success(`Developer mode ${oldDevmode === 'true' ? 'disabled' : 'enabled 🦾'}`);
    } else {
      toast.success(
        `Click ${clicks - devModeCount} more time(s) to ${
          oldDevmode === 'true' ? 'disable' : 'enable'
        } developer mode`
      );
    }
  };

  const ontVersion = apiService.currentOntVersion();

  return (
    <div className={clsx('navbar-footer mt-auto', classes.root)}>
      <A2HSButton />
      <Typography
        variant="caption"
        color="textSecondary"
        component="p"
        className="my-10 px-24"
        onClick={handleBuildClick}
        noWrap
      >
        {preval`module.exports = new Date();`}-{ontVersion}
      </Typography>
    </div>
  );
}
