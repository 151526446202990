import i18n from 'app/services/i18n';
import { getDebounceUpdate, getBasicFieldsChangedBy } from './shared';

// eslint-disable-next-line import/prefer-default-export
export const getBasicFields = (kase, handleCaseValueUpdate, attendingFieldValues) => {
  const debounceDescriptionUpdate = getDebounceUpdate('description', handleCaseValueUpdate);
  return [
    {
      name: 'description',
      label: i18n.t('Description'),
      placeholder: i18n.t('case_description_hint'),
      type: 'text',
      value: kase.description,
      minRows: 1,
      multiline: true,
      onChange: debounceDescriptionUpdate,
      changedBy: getBasicFieldsChangedBy('description', kase, attendingFieldValues),
      disabled: ['completed', 'archived'].includes(kase.state)
    }
  ];
};
