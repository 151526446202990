import auth0Service from 'app/services/auth0Service';
import i18n from 'app/services/i18n';
import _ from '@lodash';
import { ROLES } from 'app/consts';
import { OptionSynonyms, OptionIgnore } from 'app/consts/optionSynonyms';
import {
  getPmmFieldsChangedBy,
  genOptionFromStr,
  getUserFromCase,
  DF_COMMENTS_TO_ANESTH,
  DF_COMMENTS_TO_NURSING,
  DF_ESTIMATED_CASE_LENGTH,
  DEBOUNCE_MSEC
} from './shared';

// eslint-disable-next-line import/prefer-default-export
const getOptionChangedBy = (fieldId, kase, option, attendingFieldValues) => {
  const pmmFieldIdx = attendingFieldValues.pmmFields.findIndex(pf => pf.id === fieldId);
  if (pmmFieldIdx === -1) {
    return option;
  }

  const pmmField = pmmFieldIdx > -1 ? attendingFieldValues.pmmFields[pmmFieldIdx] : false;
  const idx = pmmFieldIdx > -1 ? pmmField.values.findIndex(v => v.value === option.value) : -1;
  return idx !== -1 ? { ...option, changedBy: getUserFromCase(kase) } : option;
};

export const findOptionInDescription = (option, description) => {
  if (!option || !description) {
    return null;
  }
  if (option.toLowerCase() in OptionIgnore) {
    return null;
  }

  const opts = OptionSynonyms[option] || [];
  opts.push(option);

  for (const opt of opts) {
    // Build the following regular expression:
    // 1. Start with a word boundary, then the option, then a word boundary.
    // 2. Allow optional space between each character. This will allow catching options that are single word in our system but multiple in the description.
    if (!opt) {
      // eslint-disable-next-line no-continue
      continue;
    }
    try {
      const optionCleanRe = new RegExp(
        `\\b${opt
          .replace(new RegExp('[\\s-]', 'g'), '') // Remove hyphens and spaces from option. They'll be added anyway in the next step.
          .replace(new RegExp('', 'g'), '[\\s-]?')}\\b`,
        'i'
      );
      const match = description.match(optionCleanRe);
      if (match) {
        return {
          option,
          match
        };
      }
    } catch (error) {
      console.log(error);
    }
  }

  return null;
};

export const processFieldValues = kase => {
  const {
    caseFieldValues,
    procedureData: {
      subType: {
        form: { formFields },
        procedureSubTypeFieldOptions: fieldOptions
      },
      procedureFieldDefaults: fieldDefaults
    },
    attendingDefaults,
    description
  } = kase;

  kase.fieldValues = {};
  for (const { field } of formFields) {
    const fv = {};

    fv.name = field.name;
    fv.label = field.name;
    fv.type = ['chips', 'select'].includes(field.type) ? 'select' : 'text';
    fv.multiple = field.type === 'chips';
    fv.hasOther = field.hasOther;

    // Search for field options specific for this client
    let fOpt = fieldOptions.find(
      opt => opt.fieldId === field.id && opt.clientId === auth0Service.audPrefix
    );
    // If didn't find options per client, bring the default (or ignore clientId to support old ontology that didn't have it)
    if (!fOpt) {
      fOpt = fieldOptions.find(
        opt => opt.fieldId === field.id && (!opt.clientId || opt.clientId === 'default')
      );
    }

    if (fv.type === 'text') {
      fv.parsedOptions = [];
    } else if (fOpt) {
      const { options } = fOpt;
      fv.parsedOptions = field.hasOther ? [...options, 'Other'] : [...options];
    } else {
      // eslint-disable-next-line no-continue
      continue;
    }
    let defaults = [];
    let otherValue;

    const fieldValue = caseFieldValues.find(val => val.fieldId === field.id);
    if (!fieldValue) {
      if (
        attendingDefaults &&
        !attendingDefaults.never_save_defaults &&
        attendingDefaults.values &&
        field.id in attendingDefaults.values
      ) {
        if (field.id in attendingDefaults.values) {
          if (field.type === 'text') {
            defaults = attendingDefaults.values[field.id].values.map(genOptionFromStr);
          } else {
            defaults = attendingDefaults.values[field.id].values
              .filter(v => fv.parsedOptions.includes(v))
              .map(genOptionFromStr);
          }
        }

        otherValue =
          field.id in attendingDefaults.values &&
          attendingDefaults.values[field.id].otherValues.length
            ? attendingDefaults.values[field.id].otherValues[0]
            : '';
      } else if (
        !attendingDefaults ||
        (attendingDefaults.values && !attendingDefaults.never_save_defaults)
      ) {
        /* Use default in two cases:
           1. attendingDefault is NOT defined.
           2. attendingDefault IS defined and 'values' is not defined, which means attendingDefaults is totally empty -> start with an empty case */
        const fieldDefault = fieldDefaults.find(def => def.fieldId === field.id);
        defaults = fieldDefault
          ? fieldDefault.values.filter(v => fv.parsedOptions.includes(v)).map(genOptionFromStr)
          : [];
      }

      // If description contains one of the options, add it to defaults
      if (fv.parsedOptions?.length && description) {
        for (const option of fv.parsedOptions) {
          const optionFromDesc = findOptionInDescription(option, description);
          if (optionFromDesc) {
            defaults.push(genOptionFromStr(optionFromDesc.option));
          }
        }
      }
    } else {
      defaults = fieldValue.values ? fieldValue.values.map(genOptionFromStr) : [];
      otherValue =
        fieldValue.otherValues && fieldValue.otherValues.length ? fieldValue.otherValues[0] : '';
    }
    fv.value = fv.multiple ? defaults : defaults.length ? defaults[0] : '';
    if (otherValue) {
      fv.otherValue = otherValue;
    }

    kase.fieldValues[field.id] = fv;
  }
};

// eslint-disable-next-line import/prefer-default-export
export const processPMMFields = (
  handlePmmValueUpdate,
  kase,
  attendingFieldValues,
  userRoles = []
) => {
  const processedFields = [];

  Object.keys(kase.fieldValues).forEach(fieldId => {
    const field = kase.fieldValues[fieldId];
    const submitPmmValues = (fv, ov, oldValue, otherChanged = false) => {
      const newOtherValue = field.hasOther ? [ov] : [];
      handlePmmValueUpdate(
        fieldId,
        fv,
        newOtherValue,
        field.type,
        field.multiple,
        oldValue,
        otherChanged
      );
    };
    const debounceUpdate = _.debounce(
      (fv, ov, oldValue, otherChanged) => {
        submitPmmValues(fv, ov, oldValue, otherChanged);
      },
      DEBOUNCE_MSEC,
      {
        leading: false,
        trailing: true
      }
    );

    const f = {
      hasOther: field.hasOther,
      changedBy: field.multiple
        ? false
        : getPmmFieldsChangedBy(fieldId, kase, attendingFieldValues),
      otherFieldProps: {
        value: field.otherValue,
        changedBy: getPmmFieldsChangedBy(`${fieldId}Other`, kase, attendingFieldValues),
        onChange: (newValue, formRef) => {
          const val = formRef.current.getCurrentValues()[field.name];
          debounceUpdate(val, newValue, [], true);
        }
      },
      onChange: (newValue, formRef) => {
        const otherField = formRef.current.inputs.find(
          input => input.props.name === `${field.name}Other`
        );
        if (newValue) {
          const hasOther = field.multiple
            ? newValue.find(val => val.value.toLowerCase() === 'other')
            : newValue.value && newValue.value.toLowerCase() === 'other';
          if (!hasOther && otherField && otherField.getValue() !== '') {
            otherField.setValue('');
          }
        }
        debounceUpdate(
          newValue,
          formRef.current.getCurrentValues()[`${field.name}Other`],
          formRef.current.getModel()[field.name],
          false
        );
      },
      name: field.name,
      label: field.label,
      id: fieldId,
      isPmm: true,
      multiline: field.type === 'text',
      options: field.parsedOptions.map(genOptionFromStr).map(option => {
        return attendingFieldValues
          ? getOptionChangedBy(fieldId, kase, option, attendingFieldValues)
          : option;
      }),
      value: field.value,
      type: field.type,
      multiple: field.multiple
    };
    // Show comments to anesth/nursing only to relevant department. Show on top for anesth/nursing roles.
    if (f.id === DF_COMMENTS_TO_ANESTH) {
      f.placeholder = i18n.t('comments_to_anesthesia_hint');
      if (userRoles.includes(ROLES.ANESTHESIA)) {
        processedFields.unshift(f);
      } else {
        processedFields.push(f);
      }
    } else if (f.id === DF_COMMENTS_TO_NURSING) {
      f.placeholder = i18n.t('comments_to_nursing_hint');
      if (userRoles.includes(ROLES.ORSTAFF)) {
        processedFields.unshift(f);
      } else {
        processedFields.push(f);
      }
    } else {
      if (f.id === DF_ESTIMATED_CASE_LENGTH) {
        f.placeholder = i18n.t('estimated_case_length_hint');
      }
      processedFields.push(f);
    }
  });

  return processedFields;
};
