import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';
import Schedule from './Schedule';

const ScheduleConfig = {
  settings: {
    layout: {
      config: {
        footer: {
          display: true
        }
      }
    }
  },
  auth: authRoles.casesAdmin,
  routes: [
    {
      path: '/schedule/*',
      component: Schedule
    },
    {
      path: '/schedule',
      component: () => <Redirect to="/schedule/plan" />
    }
  ]
};

export default ScheduleConfig;
