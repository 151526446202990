const settingsConfig = {
  layout: {
    style: 'layout1', // layout-1 layout-2 layout-3
    config: {
      footer: {
        display: true // ToDo: check why setting false here cause a page re-render
      }
    } // checkout default layout configs at app/fuse-layouts for example  app/fuse-layouts/layout1/Layout1Config.js
  },
  customScrollbars: false,
  animations: true,
  direction: 'ltr', // rtl, ltr
  theme: {
    main: 'default',
    navbar: 'default',
    footer: 'default'
  }
};

export default settingsConfig;
