import { createSlice } from '@reduxjs/toolkit';
import sha from 'js-sha256';
import history from '@history';
import { setInitialSettings } from 'app/store/fuse/settingsSlice';
import { resetEnums } from 'app/metaData/store/enumSlice';
import auth0Service from 'app/services/auth0Service';
import amplService from 'app/services/amplService';
import { ROLES } from 'app/consts';

export const setUserData = user => async (dispatch, getState) => {
  /*
                You can redirect the logged-in user to a specific route depending on his role
                 */
  const tokenData = auth0Service.getTokenData();
  const role = tokenData['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'];
  const pgy = tokenData['https://hasura.io/jwt/claims']['x-hasura-user-pgy'];
  const id = tokenData['https://hasura.io/jwt/claims']['x-hasura-user-id'];

  // history.location.state = {
  //   redirectUrl: user.redirectUrl // for example 'apps/academy'
  // };

  /*
            Set User Settings
             */
  amplService.setUserData(role, pgy, sha.sha256(id));

  amplService.sendPageViewEvent(history.location);

  if (user.data.isCasesScheduler) {
    role.push(ROLES.SCHEDULER);
  }
  if (user.data.isAssignsResidents) {
    role.push(ROLES.ASSIGNS_RESIDENTS);
  }
  if (user.data.isNurseLeader) {
    role.push(ROLES.NURSE_LEADER);
  }
  dispatch(setUser({ ...user, role, loggedIn: true }));
};

// eslint-disable-next-line consistent-return
export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState().auth;

  if (!user.role || user.role.length === 0) {
    // is guest
    return null;
  }

  auth0Service.logout(true);

  // we want a page reload to clear state
  // history.push will not refresh the page
  setTimeout(() => {
    dispatch(setInitialSettings());
    dispatch(resetEnums());

    dispatch(userLoggedOut());

    // redirectToLogin();
  }, 2000);
};

const initialState = {
  loggedIn: false,
  role: [], // guest
  data: {
    nickName: '',
    photoURL: '',
    email: '',
    shortcuts: [],
    id: '',
    pgy: '',
    isPa: false,
    isCasesScheduler: false,
    blankCaseAlways: false,
    createdAt: '',
    followAll: false,
    muteUntil: null,
    isAssignsResidents: false,
    isNurseLeader: false,
    reminderDays: [],
    isActive: false
  },
  wrongPasswordError: false
};

const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (state, action) => action.payload,
    userLoggedOut: (state, action) => initialState,
    setWrongPasswordError: (state, action) => {
      state.wrongPasswordError = action.payload;
    }
  },
  extraReducers: {}
});

export const { setUser, userLoggedOut, setWrongPasswordError } = userSlice.actions;

export default userSlice.reducer;
