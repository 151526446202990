import { CASE_STATE, USER_CASE_ROLES } from 'app/consts';
import { getBasicFields } from './basic';
import { getDebriefFields } from './debrief';
import { getBriefFields } from './brief';
// eslint-disable-next-line import/prefer-default-export
export const getFormFields = (
  kase,
  user,
  handleCaseValueUpdate,
  handlePmmValueUpdate,
  userRoleInCase,
  handleCreateCaseAsset,
  handleUpdateCaseAsset,
  handleSubmitCaseAsset,
  handleDeleteCaseAsset,
  getUploadcareSignature,
  isPmmValuesDirty,
  userRoles,
  nextCases,
  overrideState = false
) => {
  const attendingFieldValues =
    userRoleInCase === USER_CASE_ROLES.RESIDENT ? kase.attendingValues : false;
  const basicFields = getBasicFields(kase, handleCaseValueUpdate, attendingFieldValues);
  const formCaseState = overrideState || kase.state;
  let extraFields = [];
  switch (formCaseState) {
    case CASE_STATE.IN_BRIEF:
    case CASE_STATE.BRIEF_IN_REVIEW:
    case CASE_STATE.READY:
      extraFields = getBriefFields(
        kase,
        user,
        userRoleInCase,
        handleCaseValueUpdate,
        handlePmmValueUpdate,
        attendingFieldValues,
        handleCreateCaseAsset,
        handleUpdateCaseAsset,
        handleSubmitCaseAsset,
        handleDeleteCaseAsset,
        getUploadcareSignature,
        isPmmValuesDirty,
        userRoles
      );
      break;
    case CASE_STATE.IN_DEBRIEF:
    case CASE_STATE.DEBRIEF_IN_REVIEW:
    case CASE_STATE.COMPLETED:
    case CASE_STATE.ARCHIVED:
      extraFields = getDebriefFields(
        kase,
        user,
        handleCaseValueUpdate,
        userRoleInCase,
        attendingFieldValues,
        handleCreateCaseAsset,
        handleUpdateCaseAsset,
        handleSubmitCaseAsset,
        handleDeleteCaseAsset,
        getUploadcareSignature,
        formCaseState
      );
      break;
    default:
      break;
  }

  const doneButton = getButtonField(kase, userRoleInCase, nextCases);

  return [...basicFields, ...extraFields, ...doneButton];
};

const getButtonField = (kase, userRoleInCase, nextCases) => {
  const fields = [];

  if (
    userRoleInCase === USER_CASE_ROLES.NONE ||
    [CASE_STATE.ARCHIVED, CASE_STATE.NEW, CASE_STATE.READY].includes(kase.state)
  ) {
    return fields;
  }
  let label = '';
  let icon = 'send';
  switch (kase.state) {
    case CASE_STATE.IN_BRIEF:
    case CASE_STATE.BRIEF_IN_REVIEW:
      label =
        userRoleInCase === USER_CASE_ROLES.ATTENDING
          ? nextCases?.length > 0
            ? 'approve_plan_and_next'
            : 'approve_plan'
          : nextCases?.length > 0
          ? 'res_brief_done_and_next'
          : 'res_brief_done';
      break;
    case CASE_STATE.IN_DEBRIEF:
      label = userRoleInCase === USER_CASE_ROLES.ATTENDING ? 'approve_debrief' : 'debrief_done';
      break;
    case CASE_STATE.DEBRIEF_IN_REVIEW:
      label = 'approve_debrief';
      break;
    case CASE_STATE.COMPLETED:
      label = 'Done';
      icon = 'save';
      break;
    default:
      console.log('need to handle');
  }
  fields.push({
    name: 'doneButton',
    label,
    icon,
    type: 'submit'
  });

  return fields;
};
