import React from 'react';
import MarkdownText from './MarkdownText';

const RiskListItem = ({ risk }) => {
  const { text, title, metaData } = risk;
  return (
    <>
      <div className="p-risk-item-title">{title}</div>
      <MarkdownText className="p-risk-item-text" text={text} />
    </>
  );
};
export default RiskListItem;
