import i18n from 'app/services/i18n';
import _ from '@lodash';
import { USER_CASE_ROLES } from 'app/consts';

export const DEBOUNCE_MSEC = 500;

export const DF_COMMENTS_TO_ANESTH = 'c_df_comment_to_anesthesia';
export const DF_COMMENTS_TO_NURSING = 'c_df_comment_to_nursing';
export const DF_INTRAOP_IMAGING = 'c_df_intraop_imaging';
export const DF_ESTIMATED_CASE_LENGTH = 'c_df_estimated_case_length';

export const genOptionFromStr = str => ({
  label: str === 'Other' ? i18n.t('Add your own') : str,
  value: str
});

export const getPmmFieldsChangedBy = (fieldId, kase, attendingFieldValues) => {
  if (!attendingFieldValues) {
    return false;
  }
  const pmmFieldIdx = attendingFieldValues.pmmFields.findIndex(pf => pf.id === fieldId);

  return pmmFieldIdx > -1 ? getUserFromCase(kase) : false;
};

export const getUserFromCase = c => {
  const user = c.attending;
  return {
    nickName: user.nickName,
    photoURL: user.avatar,
    email: user.email,
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName
  };
};

export const getBasicFieldsChangedBy = (fieldName, kase, attendingFieldValues) => {
  if (!attendingFieldValues) {
    return false;
  }
  return fieldName in attendingFieldValues.caseFields ? getUserFromCase(kase) : false;
};

export const getDebounceUpdate = (field, handleCaseValueUpdate) =>
  _.debounce(newValue => handleCaseValueUpdate(field, newValue), DEBOUNCE_MSEC, {
    leading: false,
    trailing: true
  });

export const getUserRoleInCase = (kase, user) => {
  let userRoleInCase;
  switch (user.id) {
    case kase.attendingId:
      userRoleInCase = USER_CASE_ROLES.ATTENDING;
      break;
    case kase.residentId:
      userRoleInCase = USER_CASE_ROLES.RESIDENT;
      break;
    default:
      if (user.isNurseLeader) {
        userRoleInCase = USER_CASE_ROLES.RESIDENT;
      } else {
        userRoleInCase = USER_CASE_ROLES.NONE;
      }
  }
  return userRoleInCase;
};

export const getMetaFieldsChangedBy = (fieldName, kase, attendingFieldValues) => {
  if (!attendingFieldValues) {
    return false;
  }
  return fieldName in attendingFieldValues.metaFields ? getUserFromCase(kase) : false;
};
