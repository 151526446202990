import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import withReducer from 'app/store/withReducer';
import Formsy from 'formsy-react';
import Typography from '@mui/material/Typography';
import SubmitFab from 'app/components/forms/SubmitFab';
import DateTime from 'app/components/forms/DateTime';
import Select from 'app/components/forms/Select';
import TextField from 'app/components/forms/TextField';
import ApiService from 'app/services/apiService';
import ConfirmationDialog from 'app/components/confirmDialog/ConfirmDialog';
import MomentAdapter from '@date-io/moment';
import Alert from '@mui/material/Alert';
import clsx from 'clsx';
import { isToday, isTomorrow } from 'app/components/caseViews/timeHelper';
import toast from 'app/services/toastService/toast';
import { ROLES } from 'app/consts';
import reducer from './store';
import {
  createCase,
  isCurrentUserToHandleCase,
  newCaseToState,
  updateCaseValues
} from './store/newCaseSlice';
import { procedureWordEquivalents } from './consts';
import CaseFollowers from './CaseFollowers';
import useProcedureSuggestions from './hooks/useProcedureSuggestions';

const filterUsersBySite = (userSites, siteId) => userSites.some(us => us.siteId === siteId);

function NewCase(props) {
  const { attendings, residents, careTeam, procedures, sites } = props.options;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isFormValid, setIsFormValid] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [attendingsOptions, setAttendingsOptions] = useState(attendings);
  const [residentsOptions, setResidentsOptions] = useState(residents);
  const [dateTimeMsg, setDateTimeMsg] = useState(false);
  const [submitLabel, setSubmitLabel] = useState(t(''));
  const [caseFollowers, setCaseFollowers] = useState(props.case.caseFollowers || []);
  const calculatedDisplayIds = useSelector(({ data }) => data.cases.calculatedDisplayIds);

  const formRef = useRef(null);
  const { site, displayId, procedure, description } = formRef?.current
    ? formRef.current.getCurrentValues()
    : { site: false, displayId: null, procedure: null, description: null };
  const formRefAttending = formRef.current?.getCurrentValues().attending;
  const calculatedDisplayId = calculatedDisplayIds[props.case.id];

  const siteId = sites.length === 1 ? sites[0].id : site ? site.id : false;
  const { user } = props;
  const isUserAdmin = user.role.includes(ROLES.DEPARTMENT_ADMIN);
  const isUserAssignsResidents = user.role.includes(ROLES.ASSIGNS_RESIDENTS);

  const currentUser = useSelector(({ auth }) => auth.user.data);
  const currentUserId = currentUser.id;
  const isEdit = props.editCaseAttributes && props.case !== 'new';
  const isPartialDataCase =
    !props.case.procedureId &&
    isCurrentUserToHandleCase(props.case, user.data) &&
    !props.editCaseAttributes &&
    props.case !== 'new';

  const [confirmation, setConfirmation] = useState({
    open: false,
    message: ''
  });

  useEffect(() => {
    if (formRef.current && siteId) {
      const { resident, attending } = formRef.current.getCurrentValues();
      const filteredOptions = {
        attendings: attendings.filter(att => filterUsersBySite(att.userSites, siteId)),
        residents: residents.filter(res => filterUsersBySite(res.userSites, siteId))
      };
      if (attending && !filteredOptions.attendings.some(att => att.id === attending.id)) {
        const attendingInput = formRef.current.inputs.find(
          input => input.props.name === 'attending'
        );
        attendingInput.setValue('');
      }
      if (resident && !filteredOptions.residents.some(res => res.id === resident.id)) {
        const residentInput = formRef.current.inputs.find(input => input.props.name === 'resident');
        residentInput.setValue('');
      }
      setAttendingsOptions(filteredOptions.attendings);
      setResidentsOptions(filteredOptions.residents);
    }
  }, [siteId, attendings, residents]);

  useEffect(() => {
    if (!siteId) {
      return;
    }

    let flrs;
    if (props.case?.siteId === siteId) {
      flrs = props.case.caseFollowers;
    } else {
      flrs = careTeam
        .filter(u => u.followAll && u.userSites.some(us => us.siteId === siteId))
        .map(ct => ({
          userId: ct.id,
          user: ct
        }));
    }
    setCaseFollowers(flrs);
  }, [siteId]);

  function getResidentForSelfCase() {
    let defaultResidentValue;
    if (user.role.includes('attending') && user.role.includes('resident')) {
      // If user is Assistant AND Attending, set resident field to be this user. Both fields are editable.
      defaultResidentValue = {
        ...residents.find(opt => opt.id === user.data.id),
        disabled: false
      };
    } else if (user.role.includes('resident')) {
      // Else, if user is Assistant, the assistant is read-only with the current user
      defaultResidentValue = {
        ...residents.find(opt => opt.id === user.data.id),
        disabled: true
      };
    }
    return defaultResidentValue;
  }

  function getAttendingForSelfCase() {
    let defaultAttendingValue;
    if (user.role.includes('attending') && !user.role.includes('resident')) {
      // Else If user is Attending (and not resident), the attending is read-only with the current user, and need to select assistant
      defaultAttendingValue = {
        ...attendings.find(opt => opt.id === user.data.id),
        disabled: true
      };
    }
    return defaultAttendingValue;
  }

  const kase =
    props.selfCase && !props.case.id && props.options
      ? {
          /* Self case default values logic */
          attending: getAttendingForSelfCase(),
          resident: getResidentForSelfCase()
        }
      : props.case;

  const disableButton = () => {
    setIsFormValid(false);
  };

  const enableButton = () => {
    setIsFormValid(true);
  };
  const handleSubmit = async model => {
    disableButton();

    let currentCase = kase;
    let newCase;
    try {
      let reloadList = false;
      const isResidentOwnCase = currentCase.resident?.id === currentUserId;
      if (currentCase?.id) {
        const modelForUpdate = model;
        // In case display id is still calculated, use the original display id so that we won't override it with the calculated one
        if (calculatedDisplayId === model.displayId) {
          // remove displayId field from  modelForUpdate.displayId;
          delete modelForUpdate.displayId;
        }
        modelForUpdate.caseDate = isPartialDataCase
          ? new Date(props.case.caseDate)
          : model.caseDate;
        currentCase = await dispatch(
          updateCaseValues(
            currentCase.id,
            modelForUpdate,
            isUserAdmin,
            props.adminCase,
            isResidentOwnCase
          )
        );
        reloadList = true;
      } else {
        newCase = await dispatch(createCase(model, isUserAdmin, props.adminCase));
      }

      const caseIdForFollowers = newCase?.id || currentCase.id;
      const cfs = caseFollowers.map(cf => {
        return { userId: cf.userId, caseId: caseIdForFollowers };
      });
      const cccfs = currentCase?.caseFollowers;
      // Add followers that are in the new followers list but not in the current case
      const cfsAdd = cccfs ? cfs.filter(cf => !cccfs.find(cccf => cccf.userId === cf.userId)) : cfs;
      // Remove followers that are in the current case but not in the new followers list
      const cfsRemove = cccfs
        ? cccfs.filter(cccf => !cfs.find(cf => cf.userId === cccf.userId))
        : [];

      props.handleSetFollowers(caseIdForFollowers, cfsAdd, cfsRemove);

      // If the case is a self case in the correct state, open the case after creating it
      let openCaseId;
      if (newCase && isCurrentUserToHandleCase(newCase, user.data)) {
        openCaseId = newCase.id;
      }

      props.closeDialog(reloadList, (isPartialDataCase && props.case.id) || openCaseId);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
    enableButton();
  };

  const getDefaultTime = () => {
    const defaultDate = new Date();
    defaultDate.setHours(0, 0, 0, 0);

    const result = defaultDate.toISOString();

    return result;
  };

  const validateForm = () => {
    setValidationErrors({});
  };

  const updateFab = values => {
    const { resident, attending, caseDate } = values;
    const isResidentOwnCase = resident?.id === currentUserId;
    const state = newCaseToState(caseDate, !resident, isResidentOwnCase);
    const handle = isCurrentUserToHandleCase(
      { residentId: resident?.id, attendingId: attending?.id, caseDate, state },
      currentUser
    );

    setSubmitLabel(
      t(
        isPartialDataCase
          ? 'HUDDLE'
          : !isEdit && !props.adminCase && handle
          ? 'SUBMIT & OPEN'
          : 'SUBMIT'
      )
    );
  };

  useEffect(() => {
    if (!formRef.current) {
      return;
    }

    updateFab(formRef.current.getCurrentValues());
  }, []);

  const handleChange = values => {
    updateFab(values);
    validateForm();
  };

  const confirmAttendingProcedureAndDate = (attending, procedureId, date, setValue, newValue) => {
    if (props.adminCase || isPartialDataCase) {
      setValue(newValue);
      return;
    }

    const dateLib = new MomentAdapter();
    const TIME_FORMAT = 'ddd MMM D YYYY HH:mm:ss ZZ';
    const minDate = dateLib.date(date).add(-30, 'minutes').format(TIME_FORMAT);
    const maxDate = dateLib.date(date).add(30, 'minutes').format(TIME_FORMAT);

    // @todo: replace with async await.
    ApiService.getAttendingNearCases(attending.id, procedureId, minDate, maxDate, kase?.id).then(
      result => {
        if (result?.length > 0) {
          setConfirmation({
            open: true,
            message: t('already_has_similar_case', { attending: attending.nickName }),
            setValue: () => setValue(newValue),
            title: t('Duplicated case?'),
            ok: t('Show me'),
            cancel: t("It's OK")
          });
        } else {
          setValue(newValue);
        }
      }
    );
  };

  function containsEquivalentWord(wordEquivalents, word, str) {
    let wordFound = false;
    wordEquivalents.forEach(we => {
      if (wordFound) return;

      if (we.some(w => w.includes(word))) {
        wordFound = we.some(ww => str.includes(ww));
      }
    });

    return wordFound || str.includes(word);
  }

  const handleDateTimeConfirm = (newValue, setValue) => {
    const model = formRef.current.getModel();
    const currentValue = model.caseDate;

    const dateLib = new MomentAdapter();
    const today = dateLib.moment();
    today.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const isDateInPast = today.isAfter(dateLib.date(newValue));

    if (isDateInPast) {
      setDateTimeMsg(
        'This case is in the past. We will skip the planning and go straight to debrief.'
      );
      setValue(newValue);
    } else {
      setDateTimeMsg(false);
      if (model.procedure && newValue && currentValue !== newValue && model.attending) {
        confirmAttendingProcedureAndDate(
          model.attending,
          model.procedure.value,
          newValue,
          setValue,
          newValue
        );
      } else {
        setValue(newValue);
      }
    }
  };

  const handleAttendingConfirm = (newValue, setValue) => {
    const model = formRef.current.getModel();
    const currentValue = model.attending;

    if (model.procedure && newValue && currentValue !== newValue) {
      confirmAttendingProcedureAndDate(
        newValue,
        model.procedure.value,
        model.caseDate,
        setValue,
        newValue
      );
    } else {
      setValue(newValue);
    }
  };

  const handleProcedureConfirm = (newValue, setValue) => {
    const model = formRef.current.getModel();
    const currentValue = model.procedure;

    if (newValue && model.attending && currentValue !== newValue) {
      confirmAttendingProcedureAndDate(
        model.attending,
        newValue.value,
        model.caseDate,
        setValue,
        newValue
      );
    } else {
      setValue(newValue);
    }
  };

  const handleConfirm = confirm => {
    if (confirm) {
      props.closeDialog(false, null, true);
    } else {
      confirmation.setValue();
    }
    setConfirmation({ open: false });
  };

  const dateLib = new MomentAdapter();
  const caseDate = new Date(kase.caseDate);

  let partialDataCaseAlertKey;
  let partialDataCaseAlertValues;
  if (isToday(caseDate)) {
    partialDataCaseAlertKey = 'partial_case_data_info_today_tomorrow';
    partialDataCaseAlertValues = { day: 'today' };
  } else if (isTomorrow(caseDate)) {
    partialDataCaseAlertKey = 'partial_case_data_info_today_tomorrow';
    partialDataCaseAlertValues = { day: 'tomorrow' };
  } else {
    partialDataCaseAlertKey = 'partial_case_data_info_date';
    partialDataCaseAlertValues = { date: dateLib.date(kase.caseDate).format('dddd MM/DD') };
  }

  const procSuggestions = useProcedureSuggestions({
    procedures,
    displayId: displayId || kase.displayId,
    description: description || kase.description,
    siteId: siteId || kase.siteId,
    attendingId: formRefAttending?.id || kase.attending
  });

  // For departments where site == specialty, filter the procedures by specialty.
  // If there are no procedures for the specialty, show all procedures.
  const filteredProcedures =
    site && sites.length > 0
      ? procedures.filter(option => site.specialties.includes(option.specialtyId))
      : [];
  let procedureOptions = filteredProcedures.length === 0 ? procedures : filteredProcedures;

  procedureOptions = procedureOptions.map(option => {
    const count = formRefAttending?.id
      ? option.countPerSiteAndAtt?.[siteId]?.[formRefAttending.id]
      : 0;
    return { ...option, count };
  });

  procedureOptions = procedureOptions.sort((a, b) => {
    return a.count === b.count ? a.name.localeCompare(b.name) : (b.count || 0) - (a.count || 0);
  });

  const filterAttendingResident = (filteredOptions, state) => {
    const newOptions = [];
    filteredOptions.forEach(option => {
      const values = [option.firstName, option.lastName, option.nickName];
      if (
        values.some(el =>
          el.replace(',', '').toLowerCase().includes(state.inputValue.toLowerCase())
        )
      ) {
        newOptions.push(option);
      }
    });
    return newOptions;
  };

  const mostFreqGroupByTitle = formRefAttending
    ? `${
        props.case.attendingId === currentUserId ? 'Your' : formRefAttending.nickName
      }'s Most Frequent`
    : 'Most Frequent';
  const showResidentField =
    (!site || !site.attendingOnly) &&
    sites.some(s => !s.attendingOnly) &&
    props.case.attendingId !== currentUserId;

  const caseDateValue = formRef.current?.getModel().caseDate || kase.caseDate || getDefaultTime();

  return (
    <React.Fragment>
      <Typography className="h2 my-10">
        {isPartialDataCase
          ? t('Select template')
          : isEdit
          ? t('change_assignment')
          : t('SCHEDULE NEW CASE')}
      </Typography>
      <Formsy
        onValidSubmit={handleSubmit}
        onValid={enableButton}
        onInvalid={disableButton}
        onChange={handleChange}
        validationErrors={validationErrors}
        ref={formRef}
        className="flex flex-col justify-center"
      >
        {isPartialDataCase && (
          <Alert severity="info" className="mb-12 text-14">
            <Trans i18nKey={partialDataCaseAlertKey} values={partialDataCaseAlertValues} />
          </Alert>
        )}
        {/* DateTime */}
        <DateTime
          className={clsx({ hidden: isPartialDataCase }, 'my-16')}
          label={t('DATE')}
          name="caseDate"
          format="ll h:mma"
          value={new Date(caseDateValue)}
          confirmValue={handleDateTimeConfirm}
          roundToNextHour
        />
        {dateTimeMsg && (
          <Alert severity="info" className="mb-12 text-14">
            {dateTimeMsg}
          </Alert>
        )}
        {/* Service */}
        <Select
          className={clsx({ hidden: isPartialDataCase || sites?.length === 1 }, 'my-16')}
          name="site"
          label={t('SERVICE')}
          options={sites}
          getOptionLabel={option => option.name}
          value={sites?.length === 1 ? sites[0] : kase.site || undefined}
          required
        />
        {/* Attending */}
        <Select
          className={clsx({ hidden: isPartialDataCase }, 'my-16')}
          name="attending"
          label={t('ATTENDING')}
          options={attendingsOptions}
          getOptionLabel={option => option.nickName}
          value={kase.attending || undefined}
          required
          formRef={formRef}
          confirmValue={handleAttendingConfirm}
          filterOptions={(options, state) => {
            const { resident } = formRef.current.getCurrentValues();
            const filteredOptions =
              !props.selfCase && resident?.id
                ? options.filter(opt => opt.id !== resident.id)
                : options;
            if (state.inputValue) {
              return filterAttendingResident(filteredOptions, state);
            }
            return filteredOptions;
          }}
        />
        <TextField
          className={clsx('my-16')}
          label={t('Case Title')}
          name="displayId"
          value={calculatedDisplayId || kase.displayId}
          placeholder={t('case_id_hint')}
          multiline
        />
        {kase.description && props.adminCase && (
          <TextField
            className={clsx('my-16')}
            label={t('Description')}
            name="description"
            value={kase.description}
            multiline
          />
        )}
        {/* Resident */}
        <Select
          className={clsx({ hidden: !showResidentField }, 'my-16')}
          name="resident"
          label={t('ASSISTANT / RESIDENT')}
          options={residentsOptions}
          getOptionLabel={option => option.nickName}
          value={kase.resident || undefined}
          // disabled={kase.resident && kase.resident.disabled}
          formRef={formRef}
          // required={props.selfCase}
          filterOptions={(options, state) => {
            const { attending } = formRef.current.getCurrentValues();
            const filteredOptions =
              !props.selfCase && attending && attending.id
                ? options.filter(opt => opt.id !== attending.id)
                : options;
            if (state.inputValue) {
              return filterAttendingResident(filteredOptions, state);
            }
            return filteredOptions;
          }}
        />
        {/* Procedure */}
        <Select
          className="my-16"
          name="procedure"
          label={t(isPartialDataCase ? 'procedure_title_select' : 'procedure_title')}
          noOptionsText={t('no_procedures_found')}
          confirmValue={handleProcedureConfirm}
          options={procedureOptions}
          withFilter
          filterOptions={(options, { inputValue }) => {
            if (!inputValue) return options;

            const terms = inputValue.toLowerCase().split(' ');
            return options.filter(option => {
              const procedureName = option.name.toLowerCase();
              const separatorRegex = /[,-\s]/;
              const firstLetters = procedureName
                .split(separatorRegex)
                .map(word => word[0])
                .join('');
              return terms.every(
                term =>
                  containsEquivalentWord(procedureWordEquivalents, term, procedureName) ||
                  firstLetters.includes(term)
              );
            });
          }}
          getOptionLabel={option => option.name}
          optionsSuggestions={procedure ? [] : procSuggestions}
          groupBy={option => {
            // Add attending name
            return option.count ? mostFreqGroupByTitle : 'Alphabetical';
          }}
          value={
            // If the procedure is already selected, keep it selected
            // If there is only one procedure in this site, select it
            // If there is only one procedure in the suggestions and it's a match from past cases, select it
            kase.procedureId
              ? { name: kase.procedureTitle, value: kase.procedureId }
              : procedureOptions.length === 1
              ? procedureOptions[0]
              : procSuggestions?.length === 1 && procSuggestions[0].pastCasesMatch
              ? procSuggestions[0]
              : undefined
          }
          required={isPartialDataCase}
          placeholder={
            procSuggestions?.length > 0
              ? t('proc_suggestions_placeholder_or')
              : t('proc_suggestions_placeholder_choose')
          }
        />
        {isEdit && (
          <TextField
            className={clsx({ hidden: isPartialDataCase }, 'my-16')}
            label={t('Room')}
            name="room"
            value={kase.room}
          />
        )}
        {!isPartialDataCase && (
          <CaseFollowers
            caseId={props.case.id}
            caseFollowers={caseFollowers}
            users={props.options.careTeam.filter(u => u.userSites.some(s => s.siteId === siteId))}
            handleSetFollowers={(caseId, toAdd, toRemove) => {
              let newFollowers = caseFollowers.filter(
                cf => !toRemove.some(tr => tr.userId === cf.userId)
              );
              const toAddUsers = careTeam
                .filter(ct => toAdd.some(ta => ct.id === ta.userId))
                .map(u => ({ userId: u.id, user: u }));
              newFollowers = [...newFollowers, ...toAddUsers];
              setCaseFollowers(newFollowers);
            }}
          />
        )}
        <SubmitFab
          label={submitLabel}
          icon={isPartialDataCase ? 'edit' : 'save'}
          type="submit"
          disabled={!isFormValid}
        />
      </Formsy>

      <ConfirmationDialog
        open={confirmation.open}
        onClose={handleConfirm}
        message={confirmation.message}
        title={confirmation.title}
        ok={confirmation.ok}
        cancel={confirmation.cancel}
      />
    </React.Fragment>
  );
}

export default withReducer('caseViews', reducer)(React.memo(NewCase));
