import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import _ from '@lodash';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import apiService from 'app/services/apiService';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import PublishIcon from '@mui/icons-material/Publish';
import MomentAdapter from '@date-io/moment';
import Button from '@mui/material/Button';
import useFollow from 'app/components/casesList/useFollow';
import { getSubSubTitle } from 'app/components/casesList/helper';
import { CASE_STATE, ROLES, USER_CASE_ROLES } from 'app/consts';
import toast from 'app/services/toastService/toast';
import { DEBOUNCE_MSEC } from 'app/components/caseViews/formHelpers/shared';
import { followCase } from 'app/store/data/casesSlice';

const dateLib = new MomentAdapter();

const useStyles = makeStyles(theme => ({
  follow: {
    fontWeight: 400,
    fontSize: '1.1rem',
    height: '2.6rem',
    display: 'flex',
    alignItems: 'flex-start',
    borderRadius: '15px',
    marginRight: '-10px',
    '.kebab-menu + .MuiListItemText-root &': {
      marginRight: '-3.6rem'
    },
    '& .MuiButton-startIcon': {
      marginRight: '4px'
    }
  },
  residentSelect: {
    display: 'flex',
    alignItems: 'end',
    '& .MuiFormLabel-root': {
      textTransform: 'capitalize'
    }
  }
}));

function CasesListItemText({
  tab,
  kase,
  handleArchive,
  isYou,
  caseStateLogstoWordle,
  userRoleInCase,
  residentOptions,
  handleSelectResidentCb
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const subSubTitle = getSubSubTitle(tab, kase, t);
  const calculatedDisplayIds = useSelector(({ data }) => data.cases.calculatedDisplayIds);
  const [selectedResidentId, setSelectedResidentId] = useState(kase.draftResidentId);
  const currentUserId = useSelector(({ auth }) => auth.user.data.id);
  const userRole = useSelector(({ auth }) => auth.user.role);

  const { role } = useSelector(({ auth }) => auth.user);
  const showAssignResidents = role.includes(ROLES.ASSIGNS_RESIDENTS) && !kase.site.attendingOnly;
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedResidentId !== kase.draftResidentId) {
      setSelectedResidentId(kase.draftResidentId);
    }
  }, [kase.draftResidentId]);

  const handleSelectResident = (event, value) => {
    const id = value ? value.id : null;
    setSelectedResidentId(id);
    const c = apiService.updateCaseValues(kase.id, {
      draftResidentId: id
    });

    handleSelectResidentCb(kase, id);
  };

  const handlePublishResident = async () => {
    const res = residentOptionsForSelect.find(r => r.id === selectedResidentId);

    await apiService.updateCaseValues(kase.id, {
      draftResidentId: null
    });
    dispatch(followCase(false, kase.id, selectedResidentId, null));
    setSelectedResidentId(null);

    toast.success(
      t('draft_publish_resident', {
        res: res.label
      })
    );
  };

  const residentToOption = resident => {
    return resident ? { label: resident.nickName, id: resident.id } : null;
  };

  const updateCaseRoom = useCallback(
    _.debounce(
      val =>
        apiService.updateCaseValues(kase.id, {
          room: val
        }),
      DEBOUNCE_MSEC
    ),
    []
  );

  const residentOptionsForSelect = residentOptions
    ? residentOptions.filter(resident => resident.id !== kase.residentId).map(residentToOption)
    : [];

  const { toggleFollow, submitting, isFollowed } = useFollow({ kase });

  const primaryNamesAndRoom =
    tab === 'or-board'
      ? `${isYou(kase.attending ? kase.attending.nickName : '')}${
          kase.resident ? ' / ' : ''
        }${isYou(kase.resident ? kase.resident.nickName : '')}${kase.room ? ` | ${kase.room}` : ''}`
      : '';

  const getFollowingResidentsStr = () => {
    return kase.caseFollowers
      .map(cf => {
        const res = residentOptionsForSelect.find(r => r.id === cf.userId);
        return res ? res.label : '';
      })
      .filter(label => label !== '');
  };

  const followingResStr = getFollowingResidentsStr().join(', ');

  return (
    <Fragment>
      <ListItemText
        disableTypography
        classes={{ primary: 'capitalize' }}
        primary={
          <div>
            {tab !== 'or-board' && (
              <Typography variant="caption" className="flex flex-row" color="textSecondary">
                {dateLib.date(kase.caseDate).format('l')}
              </Typography>
            )}
            {primaryNamesAndRoom && (
              <Typography
                variant="body"
                className="font-medium flex flex-row"
                style={{ fontWeight: 'bold' }}
              >
                {primaryNamesAndRoom}
              </Typography>
            )}
          </div>
        }
        secondary={
          <div style={{ marginTop: 4, paddingRight: 10 }}>
            <Typography variant="body2" color="textSecondary">
              {calculatedDisplayIds[kase.id]
                ? calculatedDisplayIds[kase.id]
                : kase.displayId
                ? kase.displayId
                : kase.procedureTitle}
            </Typography>
            {subSubTitle && (
              <Typography
                variant="body2"
                style={{ fontSize: '90%', fontWeight: 'bold', marginTop: 4 }}
              >
                {subSubTitle}
              </Typography>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: 4
              }}
            >
              {tab === 'or-board' && (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={
                    kase.caseStateLogs?.some(l => l.toState === CASE_STATE.READY)
                      ? 'plan-ready'
                      : 'plan-not-ready'
                  }
                >
                  {kase.caseStateLogs?.some(l => l.toState === CASE_STATE.READY)
                    ? t('or_board_case_plan_ready')
                    : kase.caseFieldValues?.some(cfv => cfv.createdById === currentUserId)
                    ? t('or_board_case_plan_not_ready_edited_by_you')
                    : t('or_board_case_plan_not_ready')}
                </Typography>
              )}
              {tab === 'published' && (
                <Typography variant="body2" style={{ fontSize: '70%' }}>
                  {caseStateLogstoWordle(kase)}
                </Typography>
              )}
              {tab === 'or-board' &&
                (userRoleInCase === USER_CASE_ROLES.NONE ||
                  userRole.includes(ROLES.NURSE_LEADER)) && (
                  <Button
                    disabled={submitting}
                    color={isFollowed ? 'secondary' : 'primary'}
                    variant={isFollowed ? 'contained' : 'outlined'}
                    size="small"
                    aria-label="follow"
                    className={clsx(classes.follow, { followed: isFollowed })}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      toggleFollow();
                    }}
                    // startIcon={<Icon>{isFollowed ? 'group' : 'group_add'}</Icon>}
                  >
                    {isFollowed ? t('Following') : t('Follow')}
                  </Button>
                )}
              {tab === 'my-cases' && kase.state === CASE_STATE.COMPLETED && (
                <div style={{ marginLeft: 'auto' }}>
                  <Button
                    disabled={submitting}
                    color="primary"
                    variant="outlined"
                    size="small"
                    aria-label={t('Skip')}
                    className={clsx(classes.follow)}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleArchive();
                    }}
                    startIcon={<Icon>done</Icon>}
                  >
                    {t('Skip Debrief')}
                  </Button>
                </div>
              )}
            </div>
            {showAssignResidents && tab === 'or-board' && (
              <div>
                {followingResStr && (
                  <div>
                    {'Following residents: '}
                    {followingResStr}
                  </div>
                )}
                <Box className={classes.residentSelect} onClick={e => e.stopPropagation()}>
                  <div style={{ display: 'flex', marginRight: 20, marginTop: 8 }}>
                    <FormControl variant="standard">
                      <Autocomplete
                        size="small"
                        options={residentOptionsForSelect}
                        sx={{ width: 220 }}
                        ListboxProps={{
                          style: {
                            maxHeight: '200px'
                          }
                        }}
                        value={
                          residentOptionsForSelect.find(r => r.id === selectedResidentId) || null
                        }
                        renderInput={params => (
                          <>
                            <TextField {...params} placeholder={t('no_resident')} />
                          </>
                        )}
                        onChange={handleSelectResident}
                        onOpen={e => e.stopPropagation()}
                      />
                    </FormControl>
                    <IconButton
                      disabled={!selectedResidentId}
                      color="primary"
                      className={clsx('ml-16 mr-20 p-0')}
                      onClick={() => handlePublishResident()}
                    >
                      <PublishIcon />
                    </IconButton>
                  </div>
                  <div style={{ display: 'flex', width: 55 }}>
                    <TextField
                      placeholder={t('Room')}
                      onChange={e => updateCaseRoom(e.target.value)}
                      defaultValue={kase.room}
                    />
                  </div>
                </Box>
              </div>
            )}
          </div>
        }
      />
    </Fragment>
  );
}

export default CasesListItemText;
