import React, { Fragment } from 'react';
import { VelocityComponent } from 'velocity-react';
import clsx from 'clsx';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import Form from 'app/components/forms/DynamicForm';
import Comments from 'app/components/comments/Comments';
import StepsBriefSection from 'app/components/pSteps/StepsBriefSection';
import HuddleFeedback from 'app/components/planFeedback/HuddleFeedback';
import Divider from '@mui/material/Divider';
import {
  CASE_STATE,
  caseStateInBriefAndReview,
  caseStateInBriefToReady,
  USER_CASE_ROLES,
  ROLES
} from 'app/consts';
import Alert from '@mui/material/Alert';
import { createPlanFeedback, updatePlanFeedback } from '../store/caseViewSlice';

const DebriefView = ({
  kase,
  showBrief,
  handledAnimationComplete,
  formClasses,
  setFormClasses,
  userRoleInCase,
  briefApprovedByAtt,
  fields,
  tmpSubmitValues,
  setConfirmSubmitWithDraftComment,
  setConfirmSubmitWithCommentQuestionTitle,
  user,
  setConfirmSubmitWithCommentQuestionMessage,
  setConfirmSubmitWithCommentQuestion,
  submitForm,
  caseId,
  tab,
  recommendations
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const showPlanFeedbacks = [ROLES.NURSE_LEADER, ROLES.ORSTAFF, ROLES.SCRUB_TECH].some(role =>
    user.role.includes(role)
  );

  const nameForFeedback =
    userRoleInCase === USER_CASE_ROLES.ATTENDING ? user.data.nickName : user.data.firstName;

  const handlePlanFeedbackUpdate = (
    planFeedbackId,
    addiInfoSelection,
    text,
    rating,
    howToImproveSelection,
    howToImproveText,
    whatWentWellSelection,
    whatWentWellText
  ) => {
    dispatch(
      updatePlanFeedback(
        planFeedbackId,
        addiInfoSelection,
        text,
        rating,
        howToImproveSelection,
        howToImproveText,
        whatWentWellSelection,
        whatWentWellText
      )
    );
  };

  const submitPlanFeedback = (
    addiInfoSelection,
    text,
    rating,
    howToImproveSelection,
    howToImproveText,
    whatWentWellSelection,
    whatWentWellText
  ) => {
    dispatch(
      createPlanFeedback(
        caseId,
        addiInfoSelection,
        text,
        rating,
        howToImproveSelection,
        howToImproveText,
        whatWentWellSelection,
        whatWentWellText
      )
    );
  };

  const submitToServer = async (model, formRef, resetModel, updateInputsWithError, event) => {
    tmpSubmitValues.model = model;
    tmpSubmitValues.formRef = formRef;

    if (formClasses.includes('has-chat-draft')) {
      // Show confirm dialog if there is an unsent chat message
      setConfirmSubmitWithDraftComment(true);
    } else if (kase.state === CASE_STATE.BRIEF_IN_REVIEW) {
      let lastComment;

      if (kase.comments?.length > 0) {
        lastComment = kase.comments[kase.comments.length - 1];
      }

      if (lastComment?.comment.includes('?') && lastComment.user.id !== user.data.id) {
        setConfirmSubmitWithCommentQuestionTitle(
          t("Did you see {{nickname}}'s question in the chat?", {
            nickname: lastComment.user.nickName
          })
        );
        setConfirmSubmitWithCommentQuestionMessage(
          t('"{{comment}}"', {
            comment: lastComment.comment
          })
        );
        setConfirmSubmitWithCommentQuestion(true);
      } else {
        await submitForm(model, formRef);
      }
    } else {
      await submitForm(model, formRef);
    }
  };

  // Attending/Resident till Ready
  // None always except archived
  const showComments =
    (userRoleInCase !== USER_CASE_ROLES.NONE && caseStateInBriefToReady(kase.state)) ||
    (userRoleInCase === USER_CASE_ROLES.NONE && kase.state !== CASE_STATE.ARCHIVED);

  const showSteps = kase.hasStepsData;

  const resCannotChangeAnymore =
    userRoleInCase === USER_CASE_ROLES.RESIDENT && kase.state === CASE_STATE.READY;

  return (
    <Fragment>
      {/* Show fields to care team, or to followers only if case was briefed */}
      <VelocityComponent
        animation={`transition.slideLeft${!showBrief ? 'In' : 'Out'}`}
        duration={!showBrief ? 400 : 0}
        delay={!showBrief ? 400 : 0}
        complete={handledAnimationComplete}
      >
        <div>
          {showSteps && <StepsBriefSection case={kase} />}
          {resCannotChangeAnymore && (
            <Alert severity="info" className="mb-12 text-14">
              <Trans
                i18nKey="res_cannot_change_brief_anymore"
                values={{ att: kase.attending.nickName }}
              />
            </Alert>
          )}
          {(userRoleInCase !== USER_CASE_ROLES.NONE ||
            (userRoleInCase === USER_CASE_ROLES.NONE && briefApprovedByAtt)) && (
            <Form
              kase={kase}
              tab={tab}
              fields={fields}
              disableSubmit={false}
              disabled={userRoleInCase === USER_CASE_ROLES.NONE || resCannotChangeAnymore}
              submitToServer={submitToServer}
              className={clsx(formClasses)}
              recommendations={recommendations}
            />
          )}
          {/* </FuseAnimateGroup> */}
          <Comments
            case={kase}
            formClasses={{ classes: formClasses, set: setFormClasses }}
            showComments={showComments}
          />
          {showPlanFeedbacks && (
            <div>
              <Divider variant="middle" className="mt-20 mb-20" />
              <HuddleFeedback
                submitPlanFeedback={submitPlanFeedback}
                handlePlanFeedbackUpdate={handlePlanFeedbackUpdate}
                case={kase}
                name={nameForFeedback}
              />
            </div>
          )}
        </div>
      </VelocityComponent>
    </Fragment>
  );
};

export default DebriefView;
