import React, { useState } from 'react';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import toast from 'app/services/toastService/toast';
import { FEEDBACK_ADD_INFO_OPTIONS } from 'app/consts';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(6),
    width: '100%'
  },
  buttons: {
    marginTop: theme.spacing(2)
  },
  button: {
    display: 'block',
    width: '100%',
    padding: '6px 8px',
    marginBottom: theme.spacing(1),
    textAlign: 'left',
    textTransform: 'none',
    '&.MuiButton-text': {
      background: '#f2f2f2',
      color: '#666'
    },
    '& .subtitle': {
      opacity: '.85',
      fontSize: '1.3rem'
    }
  }
}));

export const options = [
  {
    value: FEEDBACK_ADD_INFO_OPTIONS.PROCEDURE_INACCURATE,
    title: 'feedback_add_into_options_procedure_inaccurate'
  },
  {
    value: FEEDBACK_ADD_INFO_OPTIONS.EQUIPMENT_MISMATCH,
    title: 'feedback_add_into_options_equipment_mismatach'
  },
  {
    value: FEEDBACK_ADD_INFO_OPTIONS.IMPLANT_VENDOR_MISMATCH,
    title: 'feedback_add_into_options_implant_vendor_mismatch'
  },
  {
    value: FEEDBACK_ADD_INFO_OPTIONS.BOOKING_CONFLICT,
    title: 'feedback_add_into_options_booking_conflict'
  },
  {
    value: FEEDBACK_ADD_INFO_OPTIONS.OTHER,
    title: 'feedback_add_into_options_other'
  }
];

export default function AdditionalInfo(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [selections, setSelections] = useState(props.feedback?.addiInfoSelection || []);
  const [textValue, setTextValue] = useState(props.feedback?.text || '');
  const isSelected = value => {
    return selections.includes(value);
  };
  const buttonOnClick = value => {
    let sel;
    if (isSelected(value)) {
      sel = selections.filter(s => s !== value);
    } else {
      sel = [...selections, value];
    }
    setSelections(sel);
    props.setAddiInfoSelection(sel);
    handleSubmit(sel, textValue);
  };
  const handleSubmit = async (sel, text) => {
    try {
      if (props.feedback?.id) {
        props.handlePlanFeedbackUpdate(props.feedback.id, sel, text, null, null, null, null, null);
      } else {
        props.submitPlanFeedback(sel, text, null, null, null, null, null);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <Typography variant="body1" gutterBottom color="primary" fontWeight="bold">
        <Trans i18nKey="root_cause" />
      </Typography>
      <div className={clsx(classes.buttons)}>
        {options.map(option => (
          <Button
            key={option.value}
            className={clsx(classes.button)}
            onClick={() => buttonOnClick(option.value)}
            variant={isSelected(option.value) ? 'contained' : 'text'}
            color="primary"
            disabled={props.disabled}
          >
            <Typography variant="body1" className="">
              {t(option.title)}
            </Typography>
            <Typography variant="body2" className="subtitle">
              {option.subtitle}
            </Typography>
          </Button>
        ))}
      </div>
      <TextField
        name={props.feedback?.id || 'new_feedback'}
        value={textValue}
        className={clsx({ hidden: props.disabled || !(selections?.length > 0) }, 'w-full')}
        placeholder={t('plan_useful_text_hint')}
        multiline
        onChange={event => {
          setTextValue(event.target.value);
          handleSubmit(selections, event.target.value);
        }}
        disabled={props.disabled}
      />
    </div>
  );
}
