import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import DropMenu from 'app/components/dropMenu/DropMenu';
import Button from '@mui/material/Button';
import apiService from 'app/services/apiService';
import Dialog from '@mui/material/Dialog';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ConfirmButton from 'app/components/confirmButton/ConfirmButton';
import toast from '../../services/toastService/toast';

const useStyles = makeStyles(theme => ({
  xlsFileInput: {
    display: 'none'
  },
  dialog: {
    '& .MuiPaper-root': {
      maxWidth: 'none'
    }
  }
}));

function ImportCasesFromFile(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [text, setText] = useState(false);
  const [isShowXlsDialog, setIsShowXlsDialog] = useState(false);
  const [xlsResult, setXlsResult] = useState(null);

  const handleOpenFileSelect = isDry => {
    document.getElementById('fileSelect').click();
  };

  const handleFileChange = async event => {
    if (event.target.files[0]) {
      const fileTxt = await event.target.files[0].text();
      setText(fileTxt);

      try {
        const result = await apiService.importXls(fileTxt, true);
        setXlsResult(result);
        setIsShowXlsDialog(true);
      } catch (e) {
        console.log('e', e);
      }
    }

    // Clear file input to allow selected the same file again for the next run. Otherwise, onChange will not be fired
    event.target.value = null;
  };

  const handleChooseFile = () => {
    document.getElementById('fileSelect').click();
  };

  const handleImport = async () => {
    try {
      const result = await apiService.importXls(text, false);
      setXlsResult(result);
      console.log('result', result);

      let errors = 0;
      let updated = 0;
      let added = 0;
      let toDelete = 0;
      result.results.forEach(row => {
        if (row.hasError) {
          errors += 1;
          return;
        }
        if (row.update) {
          updated += 1;
          return;
        }
        if (row.delete) {
          toDelete += 1;
          return;
        }
        if (row.insert) {
          added += 1;
        } else {
          throw new Error('Unknown result. Should be insert/update/toDelete.');
        }
      });
      if (errors > 0) {
        toast.error(`${errors} errors. ${added} added. ${updated} updated. To delete: ${toDelete}`);
        setIsShowXlsDialog(true);
      } else {
        toast.success(`${added} added. ${updated} updated. To delete: ${toDelete}`);
        setIsShowXlsDialog(false);
      }

      props.onImport();
    } catch (e) {
      console.log('e', e);
    }
  };

  const handleDone = () => {
    setIsShowXlsDialog(false);
  };

  const header = ['insert', 'update', 'delete', 'line', 'msg', 'hasWarning', 'hasError', 'success'];
  return (
    <div>
      <Dialog
        open={isShowXlsDialog}
        className={classes.dialog}
        onClose={() => setIsShowXlsDialog(false)}
      >
        {xlsResult && (
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  {header.map(name => (
                    <TableCell key={name} align="center">
                      {name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {xlsResult.results.map((row, key) => (
                  <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    {header.map(name => (
                      <TableCell key={name + key} align="center">
                        {row[name].toString() === 'true'
                          ? '+'
                          : row[name].toString() === 'false'
                          ? ''
                          : row[name].toString()}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className="flex justify-end my-20">
              <Button
                className="mx-8"
                onClick={handleChooseFile}
                variant="contained"
                color="primary"
              >
                Choose file
              </Button>
              <Button className="mx-8" onClick={handleImport} variant="contained" color="primary">
                Apply
              </Button>
              <Button
                className="ml-8 mr-16"
                onClick={handleDone}
                variant="contained"
                color="primary"
              >
                close
              </Button>
            </div>
          </div>
        )}
      </Dialog>
      <input
        type="file"
        id="fileSelect"
        className={classes.xlsFileInput}
        onChange={e => handleFileChange(e)}
      />
      <DropMenu>
        <Button
          title={t('import_csv')}
          className="px-16 hover:bg-transparent"
          disableRipple
          variant="text"
          onClick={handleOpenFileSelect}
        >
          <Typography variant="inherit" className="ml-12">
            {t('import_csv')}
          </Typography>
        </Button>
        <ConfirmButton
          label={t('delete_selected')}
          icon="delete"
          title={t('delete_selected_cases_popup_title')}
          message={t('delete_selected_cases_popup_title')}
          onConfirm={props.handleDeleteSelected}
          buttonProps={{
            className: 'px-16 hover:bg-transparent',
            disableRipple: true,
            variant: 'text'
          }}
          confirmationProps={{
            ok: t('delete_selected')
          }}
        />
      </DropMenu>
    </div>
  );
}

export default ImportCasesFromFile;
