import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShareCaseFilesDialog({ open, handleClose, onSubmit }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      style={{ zIndex: 2000 }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{t('share_case_files_dialog_title')}</DialogTitle>
      <DialogActions className="justify-between">
        <Button onClick={handleClose}>{t('NO THANKS')}</Button>
        <Button onClick={onSubmit}>{t('SHARE')}</Button>
      </DialogActions>
    </Dialog>
  );
}
