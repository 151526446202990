import { MentionsInput, Mention } from 'react-mentions';
import React, { Fragment, useRef, useState } from 'react';
import _ from '@lodash';
import Formsy from 'formsy-react';
import FormLabel from '@mui/material/FormLabel';
import { TextFieldFormsy } from '@fuse/core/formsy';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { useTranslation, Trans } from 'react-i18next';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import ChangedBy from 'app/components/userAvatar/ChangedByAvatar';
import toast from 'app/services/toastService/toast';
import { lightBlue } from '@mui/material/colors';
import { DEBOUNCE_MSEC } from 'app/components/caseViews/formHelpers/shared';
import { ROLES, CASE_STATE } from 'app/consts';
import { useScroll } from 'app/components/caseViews/hooks/useScroll';

import {
  createComment,
  deleteComment,
  updateCasePrivateNote,
  updateComment
} from '../caseViews/store/caseViewSlice';

const COMMENT_ROLES_TITLE = [ROLES.RESIDENT, ROLES.ANESTHESIA, ROLES.ORSTAFF, ROLES.CARE_TEAM];

const useStyles = makeStyles(theme => ({
  root: {
    '&.readonly': {
      '& .MuiOutlinedInput-notchedOutline': {
        opacity: 0.5
      }
    }
  },
  messageRow: {
    '&.contact': {
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      paddingLeft: 46,
      '& .avatar': {
        left: 0
      },
      '& .bubble': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        '& .time': {
          marginLeft: 12
        }
      }
    },
    '&.me': {
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      paddingRight: 46,
      '& .avatar': {
        right: 0
      },
      '& .bubble': {
        marginLeft: 'auto',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        '& .time': {
          justifyContent: 'flex-end',
          right: 0,
          marginRight: 12
        }
      }
    },
    '&.contact + .me, &.me + .contact': {
      paddingTop: 10,
      marginTop: 10
    }
  },
  chatSubmit: {
    transition: 'all 0.1s'
  },
  actions: {
    color: 'white',
    fontSize: 12,
    opacity: 0.6,
    minWidth: 'auto',
    padding: 0,
    textTransform: 'none',
    marginBottom: '-8px',
    marginTop: '4px',
    '& ~ button': {
      marginLeft: 6
    }
  },
  mentionsInput: {
    width: '78%',
    marginLeft: 14,
    padding: '10px 0 10px 2px',
    alignItems: 'center',
    '&__highlighter': {
      padding: '0 2px !important'
    },
    '&__control': {
      fontSize: 16
    },
    '&__input': {
      margin: '0 !important',
      outline: 'none',
      top: '10px !important',
      paddingLeft: 4
    },
    '&__suggestions': {
      top: '20px !important',
      border: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: '10px',
      boxShadow: `0 0 8px ${theme.palette.grey[200]}`,
      width: 'max-content',
      maxHeight: 200,
      overflow: 'auto',
      paddingRight: '10px !important',
      fontSize: 16
    },
    '&__suggestions__item': {
      padding: '4px 8px'
    },
    '&__suggestions__item--focused': {
      backgroundColor: lightBlue[50]
    }
  },
  mention: {
    color: '#4ABDD0',
    position: 'relative',
    zIndex: 1,
    top: -1,
    left: -1,
    textShadow: '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff'
  },
  mentionsPlaceholder: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 14,
    color: 'rgba(0, 0, 0, 0.40)',
    fontSize: 16,
    pointerEvents: 'none',
    paddingLeft: 4
  }
}));

export default function Comments(props) {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const user = useSelector(({ auth }) => auth.user);
  const [isFormValid, setIsFormValid] = useState(false);
  const [commentEdit, setCommentEdit] = useState(false);
  const [addPrivate, setAddPrivate] = useState(false);
  const {
    case: {
      state: caseState,
      comments,
      privateNote: casePrivateNote,
      id: caseId,
      attendingId,
      caseFollowers,
      attending,
      resident
    },
    disabled,
    showPrivateNotes,
    showComments,
    formClasses
  } = props;
  const dispatch = useDispatch();
  useScroll();
  const handlePrivateNoteUpdate = note => {
    dispatch(updateCasePrivateNote(caseId, note));
  };
  const handleDelete = cid => {
    dispatch(deleteComment(caseId, cid));
  };
  const handleUpdatePrivateNoteDebounce = _.debounce(handlePrivateNoteUpdate, DEBOUNCE_MSEC, {
    leading: false,
    trailing: true
  });
  const submitToServer = async () => {
    // For some reason, the mention plugin adds square brackets around the mention. So replacing @[name] with @name
    const text = mentionValue.replace(/@\[([^\]]+)\]+/g, '@$1');
    await dispatch(createComment(caseId, text));
    setMentionValue('');
  };

  const handleUpdate = async (cid, comment) => {
    if (comment) {
      await dispatch(updateComment(caseId, cid, comment));
    }
  };
  const formRef = useRef(null);
  const privateNote = casePrivateNote?.id
    ? casePrivateNote
    : {
        id: false,
        note: ''
      };

  const disableButton = () => {
    setIsFormValid(false);
  };

  const enableButton = () => {
    setIsFormValid(true);
  };

  const handleChatFocus = e => {
    if (e.type === 'focus') {
      setCommentEdit('new');
      formClasses.set(_.union(formClasses.classes, ['hide-submit']));
    } else if (e.type === 'blur') {
      setCommentEdit(false);
      formClasses.set(formClasses.classes.filter(c => c !== 'hide-submit'));
    }
  };

  const handleChatDrafts = e => {
    if (e.length > 0) {
      formClasses.set(_.union(formClasses.classes, ['has-chat-draft']));
    } else {
      formClasses.set(formClasses.classes.filter(c => c !== 'has-chat-draft'));
    }
  };

  const handleSubmit = async () => {
    disableButton();
    handleChatDrafts('');
    try {
      await submitToServer();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
    enableButton();
  };

  const hideComments = disabled || !showComments;

  const [mentionValue, setMentionValue] = useState('');

  const usersUnsorted = caseFollowers.map(follower => ({
    id: follower.user.id,
    display: follower.user.nickName
  }));
  usersUnsorted.push({
    id: attending.id,
    display: attending.nickName
  });
  if (resident) {
    usersUnsorted.push({
      id: resident.id,
      display: resident.nickName
    });
  }
  const users = usersUnsorted.sort((a, b) => a.display.localeCompare(b.display));

  return (
    <div id="comments" className={clsx('flex flex-col relative', classes.root)}>
      <Formsy
        onValid={enableButton}
        onInvalid={disableButton}
        onValidSubmit={handleSubmit}
        ref={formRef}
      >
        {/* Comments Chat */}
        {!hideComments && (
          <div className="mt-16">
            <FormLabel className="mt-16" component="legend">
              {t('Team chat 💬')}
            </FormLabel>
            <div className="flex flex-col py-6 ">
              {comments?.map((comment, i) => {
                const ownComment = user.data.id === comment.user.id;
                return (
                  <div
                    key={comment.id}
                    className={clsx(
                      classes.messageRow,
                      'flex flex-col flex-grow-0 flex-shrink-0 items-start justify-end relative px-16 pt-4',
                      ownComment ? 'me' : 'contact'
                    )}
                  >
                    <ChangedBy
                      user={comment.user}
                      className={clsx('avatar absolute bottom-0 m-0')}
                      showAlways
                    />
                    <div
                      className={clsx('bubble p-12 max-w-full shadow-1', {
                        'w-full': commentEdit === comment.id
                      })}
                    >
                      {commentEdit === comment.id ? (
                        <TextFieldFormsy
                          className="flex w-full"
                          inputProps={{ style: { color: 'white' } }}
                          name={comment.id}
                          value={comment.comment}
                          multiline
                        />
                      ) : (
                        <div>
                          {/* User name + role only in the first consequitive message per user */}
                          <div className="leading-tight whitespace-pre-wrap break-words font-bold text-xs">
                            {(i === 0 || (i > 0 && comments[i - 1].user.id !== comment.user.id)) &&
                              !ownComment &&
                              `${comment.user.nickName}${comment.user.roles
                                .filter(r => COMMENT_ROLES_TITLE.includes(r.role))
                                .map(r => `, ${t(r.role)}`)}\n`}
                          </div>
                          {/* The comment iteslf */}
                          <div className="leading-tight whitespace-pre-wrap break-words">
                            {comment.comment}
                          </div>
                        </div>
                      )}
                      {ownComment && !disabled && (
                        <div className="actions flex justify-end">
                          {commentEdit === comment.id ? (
                            <Fragment>
                              <Button
                                className={clsx(classes.actions)}
                                onClick={async () => {
                                  await handleUpdate(
                                    comment.id,
                                    formRef.current.getModel()[comment.id]
                                  );
                                  setCommentEdit(false);
                                }}
                              >
                                {t('Save')}
                              </Button>
                              <Button
                                className={clsx(classes.actions)}
                                onClick={() => setCommentEdit(false)}
                              >
                                {t('Cancel')}
                              </Button>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <Button
                                className={clsx(classes.actions)}
                                onClick={() => setCommentEdit(comment.id)}
                              >
                                {t('Edit')}
                              </Button>
                              <Button
                                className={clsx(classes.actions)}
                                onClick={() => handleDelete(comment.id)}
                              >
                                {t('Delete')}
                              </Button>
                            </Fragment>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            {!disabled && (
              <Paper
                className="flex items-center relative rounded-24 mentions-container"
                elevation={1}
              >
                <MentionsInput
                  className={classes.mentionsInput}
                  value={mentionValue}
                  onFocus={handleChatFocus}
                  onBlur={handleChatFocus}
                  onChange={e => {
                    setMentionValue(e.target.value);
                    handleChatDrafts(e.target.value);
                  }}
                  spellCheck={false}
                  autoFocus={false}
                >
                  <Mention
                    data={users}
                    className={classes.mention}
                    displayTransform={nickname => `@${nickname} `}
                    markup="@[__display__] "
                  />
                </MentionsInput>
                {!mentionValue && (
                  <div className={classes.mentionsPlaceholder}>{t('Type your message')}</div>
                )}
                <IconButton
                  disabled={!isFormValid}
                  className="absolute ltr:right-0 rtl:left-0 top-0 bottom-0"
                  type="submit"
                  size="large"
                >
                  <Icon
                    className={clsx(
                      classes.chatSubmit,
                      commentEdit === 'new' ? 'text-44' : 'text-24',
                      commentEdit === 'new' ? 'text-red-400' : 'text-gray-500'
                    )}
                  >
                    send
                  </Icon>
                </IconButton>
              </Paper>
            )}
            {/* {!disabled && (
              <div>
                <Paper className="flex items-center relative rounded-24 mt-20" elevation={1}>
                  <TextFieldFormsy
                    name="new"
                    required
                    id="message-input"
                    className="flex-1"
                    variant="standard"
                    multiline
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        root: 'flex flex-grow flex-shrink-0 mx-16 ltr:mr-48 rtl:ml-48 my-8',
                        input: ''
                      },
                      placeholder: t('Type your message')
                    }}
                    InputLabelProps={{
                      shrink: false,
                      className: classes.bootstrapFormLabel
                    }}
                    onFocus={handleChatFocus}
                    onBlur={handleChatFocus}
                    onChange={handleChatDrafts}
                  />
                  <IconButton
                    disabled={!isFormValid}
                    className="absolute ltr:right-0 rtl:left-0 top-0 bottom-0"
                    type="submit"
                    size="large"
                  >
                    <Icon
                      className={clsx(
                        classes.chatSubmit,
                        commentEdit === 'new' ? 'text-44' : 'text-24',
                        commentEdit === 'new' ? 'text-red-400' : 'text-gray-500'
                      )}
                    >
                      send
                    </Icon>
                  </IconButton>
                </Paper>
              </div>
            )} */}
          </div>
        )}

        {/* Private Notes */}
        {showPrivateNotes && (
          <FormLabel component="legend" className="flex mt-16">
            {t('YOUR PRIVATE CASE NOTES 🔒')}
          </FormLabel>
        )}
        {privateNote.note || addPrivate ? (
          <Fragment>
            {showPrivateNotes && (
              <TextFieldFormsy
                className="flex mt-12"
                onChange={handleUpdatePrivateNoteDebounce}
                name="privateNote"
                value={privateNote.note}
                rows={4}
                variant="outlined"
                multiline
                autoFocus={addPrivate}
                overrideFormDisabled
              />
            )}
            {showPrivateNotes && (
              <Typography className="px-10 py-4 text-xs" color="textSecondary">
                <Icon fontSize="small" className="align-bottom mr-4">
                  lock
                </Icon>
                {t('Only you can see your private notes')}
              </Typography>
            )}
          </Fragment>
        ) : (
          <Button className="mt-4 p-0" type="button" onClick={() => setAddPrivate(true)}>
            {showPrivateNotes && (
              <Typography className="px-15 normal-case text-left text-xs" color="textSecondary">
                {t('private_notes_hint')}
              </Typography>
            )}
          </Button>
        )}
      </Formsy>
    </div>
  );
}
