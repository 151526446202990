import { gql } from '@apollo/client';

import { COMMENT_FULL_DATA } from './fragments/comment';

const create = gql`
  mutation CareTeamCreateComment($object: comments_insert_input!) {
    insert_comments_one(object: $object) {
      ...CommentFullData
    }
  }
  ${COMMENT_FULL_DATA}
`;
const updateComment = gql`
  mutation CareTeamUpdateComment($id: uuid!, $comment: String) {
    update_comments_by_pk(pk_columns: { id: $id }, _set: { comment: $comment }) {
      ...CommentFullData
    }
  }
  ${COMMENT_FULL_DATA}
`;

const deleteComment = gql`
  mutation CareTeamDeleteComment($id: uuid!) {
    delete_comments_by_pk(id: $id) {
      id
    }
  }
`;

const queries = {};

const mutations = {
  create,
  deleteComment,
  updateComment
};

const api = {
  queries,
  mutations
};

export default api;
