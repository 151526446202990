import React from 'react';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75'
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47'
  }
});

export default function CustomizedRating(props) {
  const handleChange = (event, newValue) => {
    let newVal = event.target.value;
    if (newValue === null) {
      if (event.target.value % 1 !== 0) {
        newVal = event.target.value - 0.5;
      } else {
        newVal = event.target.value - 1;
      }
    }
    if (newVal === 0) {
      newVal = '0';
    }
    props.onChange(newVal);
  };

  return (
    <StyledRating
      name="customized-color"
      size="large"
      getLabelText={value => `${value} Heart${value !== 1 ? 's' : ''}`}
      precision={0.5}
      icon={<FavoriteIcon fontSize="3rem" />}
      emptyIcon={<FavoriteBorderIcon fontSize="3rem" />}
      value={props.value}
      onChange={(event, newValue) => handleChange(event, newValue)}
      sx={{
        fontSize: '6rem',
        marginBottom: '20px'
      }}
    />
  );
}
