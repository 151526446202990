import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MomentAdapter from '@date-io/moment';
import { loadFullCaseData, loadMyPastCasesForLL } from 'app/store/data/casesSlice';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import { CASE_STATE } from 'app/consts';
import LessonCaseDialog from './LessonCaseDialog';

const dateLib = new MomentAdapter();

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  emptyGrid: {
    height: 'auto'
  },
  contentGrid: {
    height: 175
  },
  grid: {
    overflow: 'auto',
    width: 'calc(100% + 32px)',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(4),
    'mask-image':
      'linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 10px, rgba(0, 0, 0, 1) 60px)',
    '-webkit-mask-image':
      'linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 10px, rgba(0, 0, 0, 1) 60px)'
  },
  card: {
    position: 'relative',
    border: '1px solid #eee',
    maxWidth: 548
  },
  content: {
    display: 'block',
    textAlign: 'inherit',
    position: 'static',
    width: 280,
    maxHeight: 325,
    minHeight: 100,
    paddingBottom: 45
  },
  actions: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    paddingTop: 30,
    background: 'linear-gradient(transparent, white 50%)'
  },
  icon: {
    fontSize: '2.6em'
  },
  iconEmpty: {
    display: 'block',
    margin: '0 auto 16px',
    fontSize: '8rem'
  }
}));

const PAST_CASES_REQUIRED_STATES = [
  CASE_STATE.BRIEF_IN_REVIEW,
  CASE_STATE.DEBRIEF_IN_REVIEW,
  CASE_STATE.READY
];

export default function LessonsLearned(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [lessonCase, setLessonCase] = useState(false);
  const user = useSelector(({ auth }) => auth.user);
  const openCase = useSelector(({ data }) => data.cases.openCase);
  const cases = useSelector(({ data }) => data.cases.items);
  const [pastCases, setPastCases] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const calculatedDisplayIds = useSelector(({ data }) => data.cases.calculatedDisplayIds);

  if (user.data.isNurseLeader) {
    return null;
  }

  useEffect(() => {
    const loadPastCasesEffect = async () => {
      setPastCases(await loadMyPastCasesForLL(openCase.case.procedureId));
      setDataLoaded(true);
    };
    loadPastCasesEffect();
  }, []);
  const isAttending = openCase.case.attendingId === user.data.id;
  const allPastCases = pastCases.filter(item =>
    item.caseStateLogs.some(log => PAST_CASES_REQUIRED_STATES.includes(log.toState))
  );
  const withSameUser = allPastCases.filter(item =>
    isAttending
      ? item.residentId === openCase.case.residentId
      : item.attendingId === openCase.case.attendingId
  );
  const withOtherUsers = allPastCases.filter(item =>
    isAttending
      ? item.residentId !== openCase.case.residentId
      : item.attendingId !== openCase.case.attendingId
  );
  const filteredPastCases = [...withSameUser, ...withOtherUsers];

  const goToCase = async id => {
    setLessonCase(id ? await loadFullCaseData(id, user.data.id) : false);
  };

  return (
    <div className={clsx('lessons-learned', classes.root)}>
      <FormLabel component="legend">{props.label}</FormLabel>

      <Grid
        container
        wrap="nowrap"
        spacing={2}
        className={clsx(
          'flex',
          filteredPastCases.length > 0 || !dataLoaded ? classes.contentGrid : '',
          classes.grid
        )}
      >
        {filteredPastCases.length > 0 || !dataLoaded ? (
          filteredPastCases.map(c => {
            const nickname = isAttending ? c.resident?.nickName : c.attending.nickName;

            return (
              <Grid item key={c.id} className={clsx('flex')}>
                <Card className={classes.card}>
                  <ButtonBase className={classes.content} onClick={() => goToCase(c.id)}>
                    <CardContent>
                      <Typography color="textSecondary">
                        {nickname ? `${nickname} | ` : ''}
                        {dateLib.date(c.caseDate).format('l')}
                        {calculatedDisplayIds[c.id] ||
                          (c.displayId && ` | ${calculatedDisplayIds[c.id] || c.displayId}`)}
                      </Typography>
                      {c.description && (
                        <Typography variant="body2" gutterBottom>
                          {c.description}
                        </Typography>
                      )}
                      {c.learned && (
                        <Fragment>
                          <Typography variant="caption" color="textSecondary">
                            {t('Main Lesson Learned')}:
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            {c.learned}
                          </Typography>
                        </Fragment>
                      )}
                      {c.feedback && (
                        <Fragment>
                          <Typography variant="caption" color="textSecondary">
                            {t('Your Feedback')}:
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            {c.feedback}
                          </Typography>
                        </Fragment>
                      )}
                      {c.privateNotes?.length > 0 && (
                        <Fragment>
                          <Typography variant="caption" color="textSecondary">
                            {t('🔒 Your Private Case Notes')}:
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            {c.privateNotes[0].note}
                          </Typography>
                        </Fragment>
                      )}
                    </CardContent>
                    <CardActions className={classes.actions}>
                      <Typography variant="button" className="justify-start px-8 py-0 text-11">
                        {t('Go to case')}
                      </Typography>
                    </CardActions>
                  </ButtonBase>
                </Card>
              </Grid>
            );
          })
        ) : (
          <Grid item className={classes.emptyGrid}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="body3" gutterBottom>
                  <Trans i18nKey="empty_lessons" values={{ proc: openCase.case.procedureTitle }} />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>

      <LessonCaseDialog
        tab={props.tab}
        closeCaseDialog={goToCase}
        openCase={lessonCase}
        selfCase
        cases={cases}
      />
    </div>
  );
}
