import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloudOff from '@mui/icons-material/CloudOff';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.secondary.main
  }
}));

function OfflinePopup() {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  console.log(theme);

  return (
    <Dialog open PaperProps={{ classes: { root: 'px-10 py-20' } }}>
      <CloudOff
        className="my-10 mx-auto"
        style={{ fontSize: '110px', color: theme.palette.primary.light }}
      />
      <DialogTitle
        classes={{ root: clsx(classes.title, 'whitespace-pre-line text-center text-xl') }}
      >
        {t('no_internet_popup_title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText classes={{ root: clsx(classes.subtitle, 'text-center text-base') }}>
          {t('no_internet_popup_subtitle')}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default OfflinePopup;
