import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles(theme => ({
  table: {
    '& tr': {
      '&:last-child td': {
        borderBottom: 'none'
      }
    },
    '& td, & th': {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      textAlign: 'center',
      '&:first-child': {
        paddingLeft: theme.spacing(1.5),
        textAlign: 'left',
        fontWeight: 'bold'
      },
      '&:last-child': {
        paddingRight: theme.spacing(1.5)
      },
      '&.text-left': {
        textAlign: 'left'
      },
      '&.text-right': {
        textAlign: 'right'
      }
    }
  }
}));

export default function DashboardTable(props) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className="h-full p-16">
      <Typography className="h3" color="textSecondary">
        {props.title}
      </Typography>
      <Table className={classes.table} aria-label={props.title}>
        {props.headers && (
          <TableHead>
            <TableRow>
              {props.headers.map(header => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {props.data.map(row => (
            <TableRow key={row.id ? row.id : row[props.cols[0]]}>
              {props.cols.map(cell => (
                <TableCell key={cell} {...props.cellProps}>
                  {props[cell] ? props[cell](row) : row[cell]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
