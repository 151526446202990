import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useLocation, useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import urlParams from 'app/services/urlParams';
import { useSelector } from 'react-redux';
import { getUserRoleInCase } from 'app/components/caseViews/formHelpers/shared';
import { USER_CASE_ROLES } from 'app/consts';
import CaseView from './CaseView';
import NewCase from './NewCase';

const useStyles = makeStyles(theme => ({
  paper: {
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      maxWidth: 900
    }
  },
  paperMinHeight: {
    minHeight: '90%'
  },
  content: {
    'overflow-x': 'hidden',
    paddingBottom: theme.spacing(12),
    height: '100%'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1.5),
    top: theme.spacing(1.5),
    padding: theme.spacing(0.2),
    zIndex: 9999,
    color: theme.palette.grey[500],
    backgroundColor: '#ffffffaa'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CaseDialog(props) {
  const classes = useStyles(props);
  const user = useSelector(({ auth }) => auth.user);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();

  const prevCaseParam = useRef();
  const { openCase, loadCaseById, closeCaseDialog, cases } = props;
  const casesLoaded = cases?.length > 0;
  const history = useHistory();

  useEffect(() => {
    const caseParam = urlParams.get('case');

    if (openCase.case && caseParam !== openCase.case.id) {
      // Case opened, set URL param
      urlParams.set('case', openCase.case.id, { scrollToTop: false });
    }
    if (
      caseParam &&
      !openCase.case &&
      caseParam !== prevCaseParam.current &&
      user.data.id &&
      casesLoaded
    ) {
      // Init - page opened with 'case' URL param
      // or new 'case' URL param appeared in the URL
      const bKase = cases ? cases.find(c => c.id === caseParam) : false;

      if (bKase) {
        loadCaseById(caseParam);
        prevCaseParam.current = caseParam;
      }
    }
    if (openCase?.case?.id === caseParam) {
      prevCaseParam.current = caseParam;
    }

    if (prevCaseParam.current && !caseParam) {
      // 'case' URL param disappeared from the URL
      urlParams.set('case', undefined, { scrollToTop: false });
      closeCaseDialog();
      prevCaseParam.current = caseParam;
    }
  }, [location, openCase, loadCaseById, closeCaseDialog, user, cases, casesLoaded]);

  const handleClose = (refreshList, idToOpen, goToOrBoard) => {
    urlParams.set('case', undefined, { scrollToTop: false });

    closeCaseDialog(refreshList);
    if (idToOpen) {
      loadCaseById(idToOpen);
    }
    if (goToOrBoard) {
      history.push('/cases/or-board');
    }
  };
  const userRoleInCase =
    openCase?.case && openCase.case !== 'new' ? getUserRoleInCase(openCase.case, user.data) : false;
  const renderCase = () => {
    return props.adminCase ||
      openCase.case === 'new' ||
      openCase.editCaseAttributes ||
      (!openCase.case.procedureId && userRoleInCase !== USER_CASE_ROLES.NONE) ? (
      <NewCase
        case={openCase.case}
        editCaseAttributes={openCase.editCaseAttributes}
        options={props.options}
        closeDialog={handleClose}
        adminCase={props.adminCase}
        selfCase={props.selfCase}
        user={user}
        userRole={userRoleInCase}
        handleSetFollowers={props.handleSetFollowers}
      />
    ) : (
      <CaseView
        case={openCase.case}
        closeDialog={handleClose}
        loadCaseById={loadCaseById}
        handleShare={props.handleShare}
        setShouldMoveToNextCase={props.setShouldMoveToNextCase}
        tab={props.tab}
        nextCases={props.nextCases}
        totalNextCases={props.totalNextCases}
        setCaseSubmittedComplete={props.setCaseSubmittedComplete}
        followEnabled
        handleSetFollowers={props.handleSetFollowers}
        options={props.options}
      />
    );
  };

  return (
    <Dialog
      classes={{
        paper: clsx(
          classes.paper,
          'w-full',
          props.openCase.case !== 'new' && classes.paperMinHeight
        )
      }}
      TransitionComponent={Transition}
      onClose={() => handleClose()}
      open={props.openCase.case !== false}
      fullScreen={fullScreen}
    >
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={() => handleClose()}
        size="large"
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className="px-16 md:px-24">
        <div className={classes.content}>{renderCase()}</div>
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(CaseDialog);
