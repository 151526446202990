import React from 'react';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// eslint-disable-next-line import/no-cycle
import CaseView from 'app/components/caseViews/CaseView';

const useStyles = makeStyles(theme => ({
  paper: {
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      maxWidth: 800
    }
  },
  paperMinHeight: {
    minHeight: '90%'
  },
  content: {
    paddingBottom: theme.spacing(12)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1.5),
    top: theme.spacing(1.5),
    padding: theme.spacing(0.2),
    zIndex: 9999,
    color: theme.palette.grey[500],
    backgroundColor: '#ffffffaa'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function LessonCaseDialog(props) {
  const classes = useStyles(props);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { openCase, closeCaseDialog } = props;

  const handleClose = () => closeCaseDialog();

  return (
    <Dialog
      classes={{
        paper: clsx(classes.paper, 'w-full')
      }}
      TransitionComponent={Transition}
      open={props.openCase !== false}
      fullScreen={fullScreen}
    >
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={handleClose}
        size="large"
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div className={classes.content}>
          <CaseView case={openCase} closeDialog={handleClose} tab={props.tab} />
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default LessonCaseDialog;
