import React from 'react';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useDispatch } from 'react-redux';
import withReducer from 'app/store/withReducer';
import { navbarCloseMobile } from 'app/store/fuse/navbarSlice';
import { showA2HS } from './store/a2hsSlice';
import reducer from './store';
import { isInstalled, isMobile } from './helper';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    height: 40,
    justifyContent: 'flex-start',
    width: 'calc(100% - 16px)',
    borderRadius: '0 20px 20px 0',
    paddingRight: 12,
    paddingLeft: 24
  },
  icon: {
    marginRight: 16,
    color: '#000000aa'
  }
}));

function A2HSButton(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('lg'));

  const isI = isInstalled();
  const isM = isMobile();
  const shouldRender = isMobile() && !isInstalled();

  const showA2HSTip = () => {
    dispatch(showA2HS(true));
    if (mdDown) {
      dispatch(navbarCloseMobile());
    }
  };

  return shouldRender ? (
    <Button className={clsx(classes.root, 'text-14')} onClick={showA2HSTip}>
      <AddBoxIcon className={clsx(classes.icon, 'text-16')} /> {t('Add to Home Screen')}
    </Button>
  ) : null;
}

export default withReducer('a2hs', reducer)(React.memo(A2HSButton));
