import { snakeCase } from 'lodash';
import * as Sentry from '@sentry/react';
import createHumps from 'lodash-humps/lib/createHumps';
import MomentAdapter from '@date-io/moment';
import auth0Service from 'app/services/auth0Service';
import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import humps from 'lodash-humps';
import _ from '@lodash';
import { processFieldValues } from 'app/components/caseViews/formHelpers/PMMFields';
import { CASE_STATE } from 'app/consts';
import API_CONFIG from './apiServiceConfig';
import api from './api';
import amplService from '../amplService';

const dateLib = new MomentAdapter();

const DATE_FORMAT = 'l';

const snakes = createHumps(snakeCase);

const filterNotValidCases = c => {
  const notNullFields = ['site', 'attending', { key: 'caseFollowers', value: 'user' }];

  return !notNullFields.some(field => {
    return _.isString(field)
      ? field in c && c[field] === null
      : field.key in c && c[field.key].some(sField => sField[field.value] === null);
  });
};

const parseResult = (res, parseResults) => {
  if (res.errors && res.errors.length > 0) {
    console.log(res.errors);
    return false;
  }

  return parseResults ? humps(res.data) : res.data;
};

const addIdFromToken = (values, fields = ['createdById']) => {
  const data = auth0Service.getTokenData();
  const id = data['https://hasura.io/jwt/claims']['x-hasura-user-id'];
  const idFields = {};
  for (const f of fields) {
    idFields[f] = id;
  }
  return {
    ...values,
    ...idFields
  };
};

const getAuthHeaders = opName => {
  const token = auth0Service.getAccessToken();
  let role = 'guest';
  let userRoles = [];
  if (token) {
    const data = auth0Service.getTokenData();
    userRoles = data['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'];

    if (!userRoles.includes('department_admin')) {
      throw new Error('User is not department admin');
    }
    role = 'department_admin';
  }
  Sentry.addBreadcrumb({
    category: 'apiCall',
    message: `try to call to ${opName}`,
    level: Sentry.Severity.Info,
    data: {
      role,
      userRoles
    }
  });
  return {
    'x-hasura-role': role,
    authorization: token ? `Bearer ${token}` : ''
  };
};

export const redirectToLogin = () => {
  const search = window.location.search === '' ? '?' : window.location.search;
  window.location =
    window.location.pathname !== '/logout'
      ? `/login${search}&redirectTo=${window.location.pathname}`
      : '/login';
};

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ originalError, message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, OrgError: ${originalError}`
      );
      if (message.indexOf('JWT') > -1) {
        redirectToLogin();
      }
    });
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const parseCaseStepsData = stepsData => {
  return stepsData
    .filter(step => step.enabled)
    .map(step => ({
      ...step,
      risks: step.risks.filter(risk => risk.enabled),
      stepMedia: step.stepMedia.filter(media => media.enabled)
    }));
};

const wait = async sec => {
  return new Promise(resolve => {
    setTimeout(resolve, sec);
  });
};

class ApiService {
  client;

  enums = {};

  static processCases(cases) {
    const processedCases = cases.filter(filterNotValidCases).map(c => {
      return ApiService.parseCase(c);
    });
    if (cases.length !== processedCases.length) {
      Sentry.captureException(new Error('not valid case in cases list'));
    }
    return processedCases;
  }

  getUserFromId(userId) {
    return this.enums.users.find(u => u.id === userId);
  }

  getSiteFromId(siteId) {
    return this.enums.sites.find(s => s.id === siteId);
  }

  static parseCase(c) {
    // c.site = c.siteId ? this.enums.sites.find(s => s.id === c.siteId) : null;
    c.formatedDate = dateLib.date(c.caseDate).format(DATE_FORMAT);
    // c.formatedDate = dateLib.date(c.caseDate).format(DATE_FORMAT);

    c.attendingValues =
      c.attendingValues !== undefined && c.attendingValues !== null
        ? c.attendingValues
        : { caseFields: {}, pmmFields: [], metaFields: [] };

    if (c.attendingDefaults) {
      c.attendingDefaults = JSON.parse(c.attendingDefaults);
    }

    if (c.procedureData) {
      processFieldValues(c);
    }

    c.hasStepsData = c?.procedureData?.steps && c?.procedureData?.steps.length > 0;
    if (c.hasStepsData) {
      c.procedureData.steps = parseCaseStepsData(c.procedureData.steps);
    }
    return c;
  }

  setEnums(enums) {
    this.enums = enums;
  }

  init() {
    const authLink = setContext((op, { headers }) => {
      // get the authentication token from local storage if it exists
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          ...getAuthHeaders(op.operationName)
        }
      };
    });
    const httpLink = new HttpLink({
      uri: `https://${auth0Service.audPrefix}.${API_CONFIG.graphqlUrl}`
    });
    this.client = new ApolloClient({
      link: authLink.concat(errorLink).concat(httpLink),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore'
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all'
        }
      }
    });
  }

  getDepartment() {
    if (!this.audPrefix) {
      this.audPrefix = auth0Service.audPrefix;
    }
    return this.audPrefix;
  }

  audPrefix = false;

  async runMutation(mutation, variables, parseVariables = true, parseResults = true) {
    const isAuth = auth0Service.isAuthenticated();

    if (!isAuth) {
      redirectToLogin();
      await wait(2000);
      return;
    }
    const parsedValues = parseVariables ? snakes(variables) : variables;

    try {
      const res = await this.client.mutate({
        mutation,
        variables: parsedValues
      });
      // eslint-disable-next-line consistent-return
      const result = parseResult(res, parseResults);
      if (result === false) {
        throw new Error('api error');
      }

      // eslint-disable-next-line consistent-return
      return result;
    } catch (err) {
      Sentry.captureException(err);
      throw err;
    }
  }

  async runQuery(query, variables = {}, parseResults = true) {
    const isAuth = auth0Service.isAuthenticated();
    if (!isAuth) {
      redirectToLogin();
      await wait(2000);
      return;
    }
    const parsedValues = snakes(variables);

    try {
      const res = await this.client.query({
        query,
        variables: parsedValues
      });
      // eslint-disable-next-line consistent-return
      const result = parseResult(res, parseResults);
      if (result === false) {
        throw new Error('api error');
      }
      // eslint-disable-next-line consistent-return
      return result;
    } catch (err) {
      Sentry.captureException(err);
      throw err;
    }
    // eslint-disable-next-line consistent-return
  }

  async runSubscription(query, variables, onData, parseResults = true) {
    const isAuth = auth0Service.isAuthenticated();
    if (!isAuth) {
      redirectToLogin();
      await wait(2000);
      return;
    }
    const parsedValues = snakes(variables);
    const sub = this.client.subscribe({
      query,
      variables: parsedValues
    });
    // eslint-disable-next-line consistent-return
    return sub.subscribe(
      {
        next: data => {
          const parsedData = parseResult(data, parseResults);
          onData(parsedData);
        }
      },
      error => {
        onError(error);
      }
    );
  }

  async subscribeToOrCases(dateGte, dateLte, onData) {
    return this.runSubscription(
      api.case.subscriptions.getOrCasesSubscription,
      { dateGte, dateLte },
      data => onData(ApiService.processCases(data.cases))
    );
  }

  async subscribeToCaseComments(caseId, onData) {
    return this.runSubscription(
      api.case.subscriptions.getCaseCommentsSubscription,
      { caseId },
      onData
    );
  }

  getOnt(url, version) {
    return this.runQuery(api.case.queries.getOnt, {
      url,
      version
    });
  }

  // eslint-disable-next-line class-methods-use-this
  currentOntVersion() {
    const overRideCurrentOntVersion = localStorage.getItem('overRideCurrentOntVersion');
    return overRideCurrentOntVersion || process.env.REACT_APP_ONT_VERSION;
  }

  async getSites() {
    const data = await this.runQuery(api.site.queries.getAll);
    data.sites.forEach(site => {
      site.specialties = JSON.parse(site.specialties);
    });
    return data;
  }

  async getAllowedSites(sitesIds) {
    const data = await this.runQuery(api.site.queries.getAllowedSites, { sitesIds });
    data.sites.forEach(site => {
      site.specialties = JSON.parse(site.specialties);
    });
    return data;
  }

  getUsers() {
    return this.runQuery(api.user.queries.getAll);
  }

  getAllowedUsers() {
    return this.runQuery(api.user.queries.getAllowedUsers);
  }

  async getDepDirectorUser(id) {
    const data = await this.runQuery(api.user.queries.getDepDirectorUser, { id });
    return data.usersByPk;
  }

  async getFollowerUser(id) {
    const data = await this.runQuery(api.user.queries.getFollowerUser, { id });
    return data.usersByPk;
  }

  async getAdminUser(id) {
    const data = await this.runQuery(api.user.queries.getAdminUser, { id });
    return data.usersByPk;
  }

  async getCareTeamUser(id) {
    const data = await this.runQuery(api.user.queries.getCareTeamUser, { id });
    return data.usersByPk;
  }

  async getDepAdminSitesMeta() {
    const data = await this.runQuery(api.site.queries.getDepAdminSitesMeta);
    data.sites.forEach(site => {
      site.specialties = JSON.parse(site.specialties);
    });
    return data;
  }

  getMetas(keys) {
    return this.runQuery(api.site.queries.getMetas, { keys });
  }

  getCareTeamSpecialities() {
    return this.runQuery(api.site.queries.getCareTeamSpecialities);
  }

  getSpecialities() {
    return this.runQuery(api.site.queries.getSpecialities);
  }

  getCareTeamUploadcareKey() {
    return this.runQuery(api.site.queries.getCareTeamUploadcareKey);
  }

  getDepAdminUsersMeta() {
    return this.runQuery(api.user.queries.getDepAdminUsersMeta);
  }

  async getDepAdminMetas() {
    const data = await this.runQuery(api.depAdmin.queries.getMetas);
    return data.meta;
  }

  async getDepAdminSites() {
    const data = await this.runQuery(api.depAdmin.queries.getSites);
    data.sites.forEach(site => {
      site.specialties = JSON.parse(site.specialties);
    });
    return data;
  }

  async getDepAdminImports() {
    const data = await this.runQuery(api.depAdmin.queries.getImports);
    return data.imports;
  }

  getDepAdminUsers() {
    return this.runQuery(api.depAdmin.queries.getUsers);
  }

  async notify(userId, msg) {
    await this.runMutation(api.depAdmin.mutations.notify, {
      userId,
      msg
    });
    return true;
  }

  async addUser(
    email,
    nickName,
    userData,
    createAuth0User = true,
    resendInvite = false,
    restoreByChiefy = false
  ) {
    if (!resendInvite) {
      await this.runMutation(api.depAdmin.mutations.addUser, { userData });
    }

    await this.runMutation(api.depAdmin.mutations.addUserWebHook, {
      email,
      nickName,
      createAuth0User,
      resendInvite,
      restoreByChiefy
    });
    return true;
  }

  async getMyCases() {
    const data = await this.runQuery(api.case.queries.getMyCases, addIdFromToken({}, ['user_id']));
    return ApiService.processCases(data.cases);
  }

  getCasesByDescriptionOrDisplayId(description, displayId, siteId, isUserAdmin) {
    const query = isUserAdmin
      ? api.case.queries.adminGetCasesByDescriptionOrDisplayId
      : api.case.queries.getCasesByDescriptionOrDisplayId;
    return this.runQuery(query, {
      description,
      displayId,
      siteId
    });
  }

  async getOrCases(dateGte, dateLte) {
    const data = await this.runQuery(api.case.queries.getOrCases, { dateGte, dateLte });
    return ApiService.processCases(data.cases);
  }

  async getMyPastCasesForLL(procedureId) {
    const data = await this.runQuery(
      api.case.queries.getMyPastCasesForLL,
      addIdFromToken({ procedureId }, ['user_id'])
    );
    return ApiService.processCases(data.cases);
  }

  async getMyPastCases(offset, limit) {
    const data = await this.runQuery(
      api.case.queries.getMyPastCases,
      addIdFromToken({ offset, limit }, ['user_id'])
    );
    return ApiService.processCases(data.cases);
  }

  async getAttReadyCasesForTemplateRecommendations(userId, procedureId, limit) {
    const data = await this.runQuery(api.case.queries.getAttReadyCasesForTemplateRecommendations, {
      userId,
      procedureId,
      limit
    });
    return ApiService.processCases(data.cases);
  }

  async getCases(isUserAdmin) {
    const query = isUserAdmin ? api.case.queries.adminGetAll : api.case.queries.getAll;
    const data = await this.runQuery(query);
    return data.cases.map(c => ApiService.parseCase(c));
  }

  async getNewCases(isUserAdmin) {
    const query = isUserAdmin ? api.case.queries.adminGetNewCases : api.case.queries.getNewCases;
    const data = await this.runQuery(query);
    return data.cases.map(c => ApiService.parseCase(c));
  }

  async getNotNewCases(isUserAdmin) {
    const query = isUserAdmin
      ? api.case.queries.adminGetNotNewCases
      : api.case.queries.getNotNewCases;

    const fromDate = new Date();
    fromDate.setMonth(fromDate.getMonth() - 1);
    const data = await this.runQuery(query, { fromDate: fromDate.toISOString() });

    return data.cases.map(c => ApiService.parseCase(c));
  }

  async createCaseAsset(caseId, externalId, stage, type = 'image') {
    const data = await this.runMutation(api.caseAsset.mutations.create, {
      object: {
        caseId,
        externalId,
        type,
        stage
      }
    });
    return data.insertCaseAssetsOne;
  }

  followCase(isUserAdmin, caseId, userId, lastSeen = null) {
    // not relevant for admin, so calling to CareTeam API
    if (lastSeen) {
      return this.runMutation(api.case.mutations.followCaseWithLastSeen, {
        caseId,
        userId,
        lastSeen
      });
    }

    const query = isUserAdmin ? api.case.mutations.adminFollowCase : api.case.mutations.followCase;
    return this.runMutation(query, { caseId, userId });
  }

  updateLastSeen(caseId, userId) {
    return this.runMutation(api.case.mutations.updateLastSeen, {
      caseId,
      userId,
      lastSeen: new Date()
    });
  }

  async setResetStatsAt() {
    const data = await this.runMutation(
      api.dashboard.mutations.setResetStatsAt,
      addIdFromToken({ resetStatsAt: new Date() }, ['user_id'])
    );
    return data.updateUsersByPk.resetStatsAt;
  }

  unfollowCase(isUserAdmin, caseId, userId) {
    const query = isUserAdmin
      ? api.case.mutations.adminUnfollowCase
      : api.case.mutations.unfollowCase;
    return this.runMutation(query, { caseId, userId });
  }

  async importXls(id, dryRun) {
    const data = await this.runMutation(api.case.mutations.importXls, { id, dryRun }, false);
    return data.importXls;
  }

  async updateCaseAsset(id, set) {
    const data = await this.runMutation(api.caseAsset.mutations.update, {
      id,
      set
    });
    return data.updateCaseAssetsByPk;
  }

  deleteCaseAsset(id) {
    return this.runMutation(api.caseAsset.mutations.delete, { id });
  }

  getUploadcareSignature(id) {
    return this.runQuery(api.caseAsset.queries.getUploadcareSignature, { caseId: id });
  }

  async createCasePrivateNote(caseId, note) {
    amplService.sendEvent(amplService.EVENTS.FORM_UPDATE, {
      type: amplService.FORM_UPDATE_TYPES.PRIVATE_NOTE
    });
    const data = await this.runMutation(api.privateNote.mutations.create, {
      object: {
        caseId,
        note
      }
    });
    return data.insertCasesOne;
  }

  async getCasePrivateNote(caseId, userId) {
    const data = await this.runQuery(api.privateNote.queries.getCasePrivateNote, {
      caseId,
      userId
    });
    return data.privateNotes;
  }

  async getCaseAssets(caseId) {
    const countData = await this.runQuery(api.caseAsset.queries.getCaseAssetsCount, { caseId });
    const { count } = countData.caseAssetsAggregate.aggregate;
    if (count === 0) {
      return [];
    }
    const data = await this.runQuery(api.caseAsset.queries.getCaseAssets, {
      caseId
    });
    return data.getCaseAssets;
  }

  editPrivateNote(id, note) {
    return this.runMutation(api.privateNote.mutations.updatePrivateNote, {
      id,
      note
    });
  }

  deletePrivateNote(id) {
    return this.runMutation(api.privateNote.mutations.deletePrivateNote, { id });
  }

  addDepAdmin(role, userId) {
    return this.runMutation(api.depAdmin.mutations.addDepAdmin, { role, userId });
  }

  updateUserDataRolesAndSites(userId, userData, userRoles, userSites) {
    const roleNames = userRoles.map(r => r.role);
    const siteIds = userSites.map(site => site.siteId);

    return this.runMutation(api.depAdmin.mutations.updateUserDataRolesAndSites, {
      userId,
      userData,
      userRoles,
      userSites,
      roleNames,
      siteIds
    });
  }

  updateSiteData(siteId, name, isActive, specialties, attendingOnly, timezone) {
    return this.runMutation(api.depAdmin.mutations.updateSiteData, {
      siteId,
      name,
      isActive,
      specialties,
      attendingOnly,
      timezone
    });
  }

  addSite(name, isActive, specialties, attendingOnly, timezone) {
    return this.runMutation(api.depAdmin.mutations.addSite, {
      name,
      isActive,
      specialties,
      attendingOnly,
      timezone
    });
  }

  removeDepAdmin(role, userId) {
    return this.runMutation(api.depAdmin.mutations.removeDepAdmin, { role, userId });
  }

  async getAttendingNearCases(userId, procedureId, dateGte, dateLte, caseIdToIgnore) {
    let data;
    if (caseIdToIgnore) {
      data = await this.runQuery(api.case.queries.getAttendingNearCasesExcludeId, {
        user_id: userId,
        date_gte: dateGte,
        date_lte: dateLte,
        case_id: caseIdToIgnore,
        procedure_id: procedureId
      });
    } else {
      data = await this.runQuery(api.case.queries.getAttendingNearCases, {
        user_id: userId,
        date_gte: dateGte,
        date_lte: dateLte,
        procedure_id: procedureId
      });
    }
    return data.cases.map(c => ApiService.parseCase(c));
  }

  async getCasesByProcedureId() {
    const data = await this.runQuery(api.case.queries.getCasesByProcedureId);
    return data.casesByProcedureId;
  }

  async getCase(id) {
    const data = await this.runQuery(api.case.queries.getCase, {
      id
    });
    return {
      ...ApiService.parseCase(data.casesByPk),
      valuesByUser: data.caseFieldValuesAggregate.nodes.map(n => n.user.id)
    };
  }

  async getCaseValues(id) {
    const data = await this.runQuery(api.case.queries.getCaseValues, {
      id
    });
    return data.caseFieldValues;
  }

  async getDefaults(isUserAdmin, pid, form, userRoleInCase, userId, blankCase) {
    if (blankCase) {
      return '{}';
    }

    const query = isUserAdmin
      ? api.case.queries.adminGetDefaults
      : api.case.queries.careTeamGetDefaults;

    const data = await this.runQuery(
      query,
      {
        pid,
        form,
        userRoleInCase,
        userId
      },
      false
    );
    return data.care_team_defaults ? JSON.stringify(data.care_team_defaults[0]) : null;
  }

  async getAdminCase(id) {
    const data = await this.runQuery(api.case.queries.getAdminCase, {
      id
    });
    return {
      ...ApiService.parseCase(data.casesByPk),
      valuesByUser: data.caseFieldValuesAggregate.nodes.map(n => n.user.id)
    };
  }

  updateCasesState(isUserAdmin, ids, state) {
    if (state === CASE_STATE.IN_BRIEF) {
      amplService.sendEvent(amplService.EVENTS.PUBLISH_CASES, { casesCount: ids.length });
    }
    const mut = isUserAdmin
      ? api.case.mutations.adminUpdateCasesState
      : api.case.mutations.updateCasesState;
    return this.runMutation(mut, { ids, state });
  }

  updateMeta(key, value) {
    return this.runMutation(api.depAdmin.mutations.updateMeta, { key, value });
  }

  updateCaseValues(caseId, values) {
    return this.runMutation(api.case.mutations.updateCaseValues, { caseId, values });
  }

  adminUpdateCaseValues(caseId, values) {
    return this.runMutation(api.case.mutations.adminUpdateCaseValues, { caseId, values });
  }

  deleteCaseStateLogsAfterTime(caseId, time) {
    return this.runMutation(api.case.mutations.deleteCaseStateLogsAfterTime, { caseId, time });
  }

  deleteCaseStateLogs(caseId, fromState, toState) {
    return this.runMutation(api.case.mutations.deleteCaseStateLogs, { caseId, fromState, toState });
  }

  updatePmmValues(caseId, fieldId, pmmValues, attendingValues) {
    return this.runMutation(api.case.mutations.updatePmmValues, {
      caseId,
      fieldId,
      pmmValues: [pmmValues[0]],
      values: { attendingValues }
    });
  }

  submitCareTeamDefaults(procedureId, values, form, userRoleInCase, neverSaveDefaults) {
    return this.runMutation(
      api.case.mutations.submitCareTeamDefaults,
      {
        procedure_id: procedureId,
        values,
        form,
        user_role_in_case: userRoleInCase,
        never_save_defaults: neverSaveDefaults
      },
      false
    );
  }

  updateCareTeamDefaultsStopInsights(procedureId, stopInsights, form, userRoleInCase) {
    return this.runMutation(
      api.case.mutations.updateCareTeamDefaultsStopInsights,
      addIdFromToken(
        {
          procedure_id: procedureId,
          stop_insights: stopInsights,
          form,
          user_role_in_case: userRoleInCase
        },
        ['created_by_id']
      ),
      false
    );
  }

  submitBriefForm(caseId, pmmValues) {
    return this.runMutation(api.case.mutations.submitBriefForm, {
      caseId,
      pmmValues
    });
  }

  deleteCase(kase, sendEvent = true) {
    if (sendEvent) {
      amplService.sendCaseEvent(amplService.EVENTS.DELETE_CASE, kase);
    }
    return this.runMutation(api.case.mutations.deleteCase, { id: kase.id });
  }

  adminDeleteCase(kase, sendEvent = true) {
    if (sendEvent) {
      amplService.sendCaseEvent(amplService.EVENTS.DELETE_CASE, kase);
    }
    return this.runMutation(api.case.mutations.adminDeleteCase, { id: kase.id });
  }

  createCase(values) {
    return this.runMutation(api.case.mutations.create, {
      object: values
    });
  }

  adminCreateCase(values) {
    return this.runMutation(api.case.mutations.adminCreate, {
      object: values
    });
  }

  updateCaseState(caseId, state) {
    return this.runMutation(api.case.mutations.updateCaseState, { caseId, state });
  }

  createComment(caseId, comment) {
    amplService.sendEvent(amplService.EVENTS.FORM_UPDATE, {
      type: amplService.FORM_UPDATE_TYPES.COMMENT,
      op: amplService.OP.CREATE
    });
    return this.runMutation(api.comment.mutations.create, {
      object: {
        caseId,
        comment
      }
    });
  }

  editComment(id, comment) {
    amplService.sendEvent(amplService.EVENTS.FORM_UPDATE, {
      type: amplService.FORM_UPDATE_TYPES.COMMENT,
      op: amplService.OP.EDIT
    });
    return this.runMutation(api.comment.mutations.updateComment, {
      id,
      comment
    });
  }

  deleteComment(id) {
    amplService.sendEvent(amplService.EVENTS.FORM_UPDATE, {
      type: amplService.FORM_UPDATE_TYPES.COMMENT,
      op: amplService.OP.DELETE
    });
    return this.runMutation(api.comment.mutations.deleteComment, { id });
  }

  async createPlanFeedback(
    caseId,
    addiInfoSelection,
    text,
    rating,
    howToImproveSelection,
    howToImproveText,
    whatWentWellSelection,
    whatWentWellText
  ) {
    amplService.sendEvent(amplService.EVENTS.FORM_UPDATE, {
      type: amplService.FORM_UPDATE_TYPES.PLAN_FEEDBACK,
      op: amplService.OP.CREATE
    });
    const result = await this.runMutation(api.planFeedback.mutations.create, {
      object: {
        caseId,
        addiInfoSelection,
        text,
        rating,
        howToImproveSelection,
        howToImproveText,
        whatWentWellSelection,
        whatWentWellText
      }
    });
    return result?.insertPlanFeedbacksOne;
  }

  editPlanFeedback(
    id,
    addiInfoSelection,
    text,
    rating,
    howToImproveSelection,
    howToImproveText,
    whatWentWellSelection,
    whatWentWellText
  ) {
    amplService.sendEvent(amplService.EVENTS.FORM_UPDATE, {
      type: amplService.FORM_UPDATE_TYPES.PLAN_FEEDBACK,
      op: amplService.OP.EDIT
    });
    const object = {};
    if (addiInfoSelection) {
      object.addiInfoSelection = addiInfoSelection;
    }
    if (text) {
      object.text = text;
    }
    if (rating) {
      object.rating = rating;
    }
    if (howToImproveSelection) {
      object.howToImproveSelection = howToImproveSelection;
    }
    if (howToImproveText) {
      object.howToImproveText = howToImproveText;
    }
    if (whatWentWellText) {
      object.whatWentWellText = whatWentWellText;
    }
    if (whatWentWellSelection) {
      object.whatWentWellSelection = whatWentWellSelection;
    }
    return this.runMutation(api.planFeedback.mutations.updatePlanFeedback, { id, object });
  }

  deletePlanFeedback(id) {
    amplService.sendEvent(amplService.EVENTS.FORM_UPDATE, {
      type: amplService.FORM_UPDATE_TYPES.PLAN_FEEDBACK,
      op: amplService.OP.DELETE
    });
    return this.runMutation(api.planFeedback.mutations.deletePlanFeedback, { id });
  }

  async getAllPublishedCasesCount() {
    const data = await this.runQuery(api.dashboard.queries.getAllPublishedCasesCount, {
      now: new Date()
    });
    return data.casesAggregate.aggregate.count;
  }

  async getCareTeamAllPublishedCasesCount(fromDate = false) {
    if (!fromDate) {
      fromDate = new Date(0).toISOString();
    }

    const data = await this.runQuery(
      api.dashboard.queries.getCareTeamDashboardAllPublishedCasesCount,
      addIdFromToken({ fromDate, now: new Date() })
    );
    return data.casesAggregate.aggregate.count;
  }

  async getMyPublishedCasesCount() {
    const data = await this.runQuery(
      api.dashboard.queries.getMyPublishedCasesCount,
      addIdFromToken({ now: new Date() }, ['user_id'])
    );
    return data.casesAggregate.aggregate.count;
  }

  async getMyPublishedCases(fromDate = false) {
    if (!fromDate) {
      fromDate = new Date(0).toISOString();
    }

    const data = await this.runQuery(
      api.dashboard.queries.getMyPublishedCases,
      addIdFromToken({ fromDate, now: new Date() }, ['user_id'])
    );
    return data.cases;
  }

  async getMyPublishedCasesCountAsAssistant(fromDate = false) {
    if (!fromDate) {
      fromDate = new Date(0).toISOString();
    }

    const data = await this.runQuery(
      api.dashboard.queries.getMyPublishedCasesCountAsAssistant,
      addIdFromToken({ fromDate, now: new Date() }, ['user_id'])
    );
    return data.casesAggregate.aggregate.count;
  }

  async getMyPublishedCasesCountAsAttending(fromDate = false) {
    if (!fromDate) {
      fromDate = new Date(0).toISOString();
    }

    const data = await this.runQuery(
      api.dashboard.queries.getMyPublishedCasesCountAsAttending,
      addIdFromToken({ fromDate, now: new Date() }, ['user_id'])
    );
    return data.casesAggregate.aggregate.count;
  }

  async getMyAddOnPublishedCasesCount(fromDate = false) {
    if (!fromDate) {
      fromDate = new Date(0).toISOString();
    }

    const addOnData = await this.runQuery(
      api.dashboard.queries.getMyAddOnPublishedCasesCount,
      addIdFromToken({ fromDate, now: new Date() }, ['user_id'])
    );
    const totalAddOn = addOnData.casesAggregate.aggregate.count;
    const briefAddOnData = await this.runQuery(
      api.dashboard.queries.getMyAddOnBriefDoneCasesCount,
      addIdFromToken({ fromDate, now: new Date() }, ['user_id'])
    );
    const briefAddOn = briefAddOnData.casesAggregate.aggregate.count;
    return totalAddOn - briefAddOn;
  }

  async getAllAddOnPublishedCasesCount() {
    const addOnData = await this.runQuery(api.dashboard.queries.getAllAddOnPublishedCasesCount, {
      now: new Date()
    });
    const totalAddOn = addOnData.casesAggregate.aggregate.count;
    const briefAddOnData = await this.runQuery(
      api.dashboard.queries.getAllAddOnBriefDoneCasesCount,
      { now: new Date() }
    );
    const briefAddOn = briefAddOnData.casesAggregate.aggregate.count;
    return totalAddOn - briefAddOn;
  }

  async getCareTeamAllAddOnPublishedCasesCount(fromDate = false) {
    if (!fromDate) {
      fromDate = new Date(0).toISOString();
    }

    const addOnData = await this.runQuery(
      api.dashboard.queries.getCareTeamDashboardAllAddOnPublishedCasesCount,
      { fromDate, now: new Date() }
    );
    const totalAddOn = addOnData.casesAggregate.aggregate.count;
    const briefAddOnData = await this.runQuery(
      api.dashboard.queries.getCareTeamDashboardAllAddOnBriefDoneCasesCount,
      { fromDate, now: new Date() }
    );
    const briefAddOn = briefAddOnData.casesAggregate.aggregate.count;
    return totalAddOn - briefAddOn;
  }

  async getMyCasesCountByStateAsAttending(fromDate = false) {
    if (!fromDate) {
      fromDate = new Date(0).toISOString();
    }

    const data = await this.runQuery(
      api.dashboard.queries.getMyCasesCountByStateAsAttending,
      addIdFromToken({ fromDate }, ['user_id'])
    );
    return data.countMyCasesByStateAsAttending;
  }

  async getMyCasesDataMerged(fromDate = false) {
    if (!fromDate) {
      fromDate = new Date(0).toISOString();
    }

    const data = await this.runQuery(
      api.dashboard.queries.getCareTeamMyCasesDataMerged,
      addIdFromToken({ fromDate }, ['user_id'])
    );
    const { countMyCasesByStateAsAttending, countMyCasesByStateAsAssistant, countCasesByState } =
      data;
    return { countMyCasesByStateAsAttending, countMyCasesByStateAsAssistant, countCasesByState };
  }

  async getMyCasesCountByStateAsAssistant(fromDate = false) {
    if (!fromDate) {
      fromDate = new Date(0).toISOString();
    }

    const data = await this.runQuery(
      api.dashboard.queries.getMyCasesCountByStateAsAssistant,
      addIdFromToken({ fromDate }, ['user_id'])
    );

    return data.countMyCasesByStateAsAssistant;
  }

  async getMyBriefDoneCasesCount(fromDate = false) {
    if (!fromDate) {
      fromDate = new Date(0).toISOString();
    }

    const data = await this.runQuery(
      api.dashboard.queries.getMyBriefDoneCasesCount,
      addIdFromToken({ fromDate, now: new Date() }, ['user_id'])
    );
    return data.casesAggregate.aggregate.count;
  }

  async getMyBriefsToReviewAsAttending(fromDate = false) {
    if (!fromDate) {
      fromDate = new Date(0).toISOString();
    }

    const data = await this.runQuery(
      api.dashboard.queries.getMyBriefsToReviewAsAttending,
      addIdFromToken({ fromDate, now: new Date() }, ['user_id'])
    );
    return data.casesAggregate.aggregate.count;
  }

  async getMyBriefsToReviewAsAttendingCases(fromDate = false) {
    if (!fromDate) {
      fromDate = new Date(0).toISOString();
    }

    const data = await this.runQuery(
      api.dashboard.queries.getMyBriefsToReviewAsAttendingCases,
      addIdFromToken({ fromDate, now: new Date() }, ['user_id'])
    );
    return data.cases;
  }

  async getMyBriefsReviewedAsAttendingCases(fromDate = false) {
    if (!fromDate) {
      fromDate = new Date(0).toISOString();
    }

    const data = await this.runQuery(
      api.dashboard.queries.getMyBriefsReviewedAsAttendingCases,
      addIdFromToken({ fromDate, now: new Date() }, ['user_id'])
    );
    return data.cases;
  }

  async getAllBriefsToReviewAsAttending(fromDate = false) {
    if (!fromDate) {
      fromDate = new Date(0).toISOString();
    }

    const data = await this.runQuery(api.dashboard.queries.getAllBriefsToReviewAsAttending, {
      fromDate,
      now: new Date()
    });
    return data.casesAggregate.aggregate.count;
  }

  async getAllBriefsToReviewAsAttendingCases(fromDate = false) {
    if (!fromDate) {
      fromDate = new Date(0).toISOString();
    }

    const data = await this.runQuery(api.dashboard.queries.getAllBriefsToReviewAsAttendingCases, {
      fromDate,
      now: new Date()
    });
    return data.cases;
  }

  async getAllBriefsReviewedAsAttendingCases(fromDate = false) {
    if (!fromDate) {
      fromDate = new Date(0).toISOString();
    }

    const data = await this.runQuery(api.dashboard.queries.getAllBriefsReviewedAsAttendingCases, {
      fromDate,
      now: new Date()
    });
    return data.cases;
  }

  async getWeeklyReportBriefsToReviewCases(fromDate, toDate, siteId, attendingIds) {
    const data = await this.runQuery(api.dashboard.queries.getWeeklyReportBriefsToReviewCases, {
      fromDate,
      toDate,
      siteId,
      attendingIds
    });
    return data.cases;
  }

  async getWeeklyReportBriefsReviewedCases(fromDate, toDate, siteId, attendingIds) {
    const data = await this.runQuery(api.dashboard.queries.getWeeklyReportBriefsReviewedCases, {
      fromDate,
      toDate,
      siteId,
      attendingIds
    });
    return data.cases;
  }

  async getNonParticipatingAttendings(siteId, attendingIds) {
    const data = await this.runQuery(api.dashboard.queries.getNonParticipatingAttendings, {
      siteId,
      attendingIds
    });
    return data.users;
  }

  // the next two functions are the same: one for care team and one for director
  async careTeamGetAllBriefDoneCasesCount(fromDate = false) {
    if (!fromDate) {
      fromDate = new Date(0).toISOString();
    }
    const data = await this.runQuery(api.dashboard.queries.careTeamGetAllBriefDoneCasesCount, {
      fromDate,
      now: new Date()
    });
    return data.casesAggregate.aggregate.count;
  }

  async getAllBriefDoneCasesCount(fromDate = false) {
    if (!fromDate) {
      fromDate = new Date(0).toISOString();
    }
    const data = await this.runQuery(api.dashboard.queries.getAllBriefDoneCasesCount, {
      fromDate,
      now: new Date()
    });
    return data.casesAggregate.aggregate.count;
  }

  async getAllCasesCountByState() {
    const data = await this.runQuery(api.dashboard.queries.getAllCasesCountByState);
    return data.countCasesByState;
  }

  async getCareTeamAllCasesCountByState() {
    const data = await this.runQuery(api.dashboard.queries.getCareTeamAllCasesCountByState);
    return data.countCasesByState;
  }

  async getAllCasesCountBySite() {
    const data = await this.runQuery(api.dashboard.queries.getAllCasesCountBySite);
    return data.countCasesBySite;
  }

  async getMyFeedbackCasesCount() {
    const data = await this.runQuery(
      api.dashboard.queries.getMyFeedbackCasesCount,
      addIdFromToken({}, ['user_id'])
    );
    return data.casesAggregate.aggregate.count;
  }

  async getAllFeedbackCasesCount() {
    const data = await this.runQuery(api.dashboard.queries.getAllFeedbackCasesCount);
    return data.casesAggregate.aggregate.count;
  }

  async getCareTeamAllFeedbackCasesCount() {
    const data = await this.runQuery(
      api.dashboard.queries.getCareTeamDashboardAllFeedbackCasesCount
    );
    return data.casesAggregate.aggregate.count;
  }

  async getMyTopFeedback() {
    const data = await this.runQuery(
      api.dashboard.queries.getMyTopFeedback,
      addIdFromToken({}, ['user_id'])
    );
    return data.cases;
  }

  async getMyTopLearned() {
    const data = await this.runQuery(
      api.dashboard.queries.getMyTopLearned,
      addIdFromToken({}, ['user_id'])
    );
    return data.cases;
  }

  async getMyCasesCountByAttending() {
    const data = await this.runQuery(
      api.dashboard.queries.getMyCasesCountByAttending,
      addIdFromToken({}, ['user_id'])
    );
    return data.countMyCasesByAttending;
  }

  async getAllCasesCountByAttending() {
    const data = await this.runQuery(api.dashboard.queries.getAllCasesCountByAttending);
    return data.casesByAttending;
  }

  async getMyCurrentYearCasesCountByMonthAsAttending() {
    const data = await this.runQuery(
      api.dashboard.queries.getMyCurrentYearCasesCountByMonthAsAttending,
      addIdFromToken({}, ['user_id'])
    );
    return data.myCurrentYearCasesByMonthAsAttending;
  }

  async getMyCurrentYearCasesCountByMonthAsAssistant() {
    const data = await this.runQuery(
      api.dashboard.queries.getMyCurrentYearCasesCountByMonthAsAssistant,
      addIdFromToken({}, ['user_id'])
    );
    return data.myCurrentYearCasesByMonthAsAssistant;
  }

  async getAllCurrentYearCasesCountByMonth() {
    const data = await this.runQuery(api.dashboard.queries.getAllCurrentYearCasesCountByMonth);
    return data.currentYearCasesByMonth;
  }

  async getMyCasesCountByAssistant() {
    const data = await this.runQuery(
      api.dashboard.queries.getMyCasesCountByAssistant,
      addIdFromToken({}, ['user_id'])
    );
    return data.countMyCasesByAssistant;
  }

  async getAllCasesCountByAssistant() {
    const data = await this.runQuery(api.dashboard.queries.getAllCasesCountByAssistant);
    return data.casesByAssistant;
  }

  async getMyCasesCountByTypeAsAttending() {
    const data = await this.runQuery(
      api.dashboard.queries.getMyCasesCountByTypeAsAttending,
      addIdFromToken({}, ['user_id'])
    );
    return data.countMyCasesByTypeAsAttending;
  }

  async getMyCasesCountByTypeAsAssistant() {
    const data = await this.runQuery(
      api.dashboard.queries.getMyCasesCountByTypeAsAssistant,
      addIdFromToken({}, ['user_id'])
    );
    return data.countMyCasesByTypeAsAssistant;
  }

  async getAllCasesCountByType() {
    const data = await this.runQuery(api.dashboard.queries.getAllCasesCountByType);
    return data.casesByType;
  }

  async startStep(caseId, stepId) {
    const data = await this.runMutation(api.psteps.mutations.startStep, {
      object: {
        stepId,
        caseId,
        start: new Date()
      }
    });
    return data.startStep;
  }

  async deleteCaseSteps(caseId) {
    const data = await this.runMutation(api.psteps.mutations.deleteSteps, {
      caseId
    });
    return data.startStep;
  }

  async endStep(caseId, stepId) {
    const data = await this.runMutation(api.psteps.mutations.endStep, {
      stepId,
      caseId,
      end: new Date()
    });
    return data.endStep;
  }

  async getCaseSteps(caseId) {
    const data = await this.runQuery(api.psteps.queries.getCaseSteps, {
      caseId
    });
    return data.psteps;
  }

  async getReportPerUserInDateRange(fromTs, untilTs, siteId, attendingIds) {
    const data = await this.runQuery(api.dashboard.queries.getReportPerUserInDateRange, {
      fromTs,
      untilTs,
      siteId,
      attendingIds
    });
    return data.reportPerUserInDateRangeAndSite;
  }

  async getCaseFollowersInDateRange(fromTs, untilTs, siteId, attendingIds) {
    const data = await this.runQuery(api.dashboard.queries.getCaseFollowersInDateRange, {
      fromTs,
      untilTs,
      siteId,
      attendingIds
    });
    return data.caseFollowers;
  }

  async getCaseFeedbackInDateRange(fromTs, untilTs, siteId, attendingIds) {
    const data = await this.runQuery(api.dashboard.queries.getCaseFeedbackInDateRange, {
      fromTs,
      untilTs,
      siteId,
      attendingIds
    });
    return data.planFeedbacks;
  }

  async getChatCountInDataRange(fromTs, untilTs, siteId, attendingIds) {
    const data = await this.runQuery(api.dashboard.queries.getChatCountInDataRange, {
      fromTs,
      untilTs,
      siteId,
      attendingIds
    });
    return data.casesAggregate;
  }

  async getReadyCaseStateLogsTimeInDataRange(fromTs, untilTs, siteId, attendingIds) {
    const data = await this.runQuery(api.dashboard.queries.getReadyCaseStateLogsTimeInDataRange, {
      fromTs,
      untilTs,
      siteId,
      attendingIds
    });
    return data.caseStateLogs;
  }

  updateUserPnStatus(hasPn) {
    return this.runMutation(
      api.user.mutations.updateUserPnStatus,
      addIdFromToken({ hasPn }, ['user_id'])
    );
  }

  async getIdHash() {
    try {
      const result = await this.runQuery(api.user.queries.getIdHash, {});
      return result?.hashId?.hash;
    } catch (error) {
      console.log(error);
    }
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  async checkNetworkFallback() {
    try {
      const url = `https://${auth0Service.audPrefix}.${API_CONFIG.graphqlUrl}`.replace(
        'v1/graphql',
        'healthz'
      );
      const response = await fetch(url);
      return response.ok;
    } catch (error) {
      console.log(error);
    }
    return false;
  }
}

const instance = new ApiService();

export default instance;
