import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import React, { useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';

const useEnhancedEffect = typeof window === 'undefined' ? useEffect : useLayoutEffect;

function FuseTheme(props) {
  const direction = useSelector(({ fuse }) => fuse.settings.defaults.direction);
  const mainTheme = useSelector(selectMainTheme);

  useEnhancedEffect(() => {
    document.body.dir = direction;
  }, [direction]);

  // console.warn('FuseTheme:: rendered',mainTheme);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mainTheme}>{props.children}</ThemeProvider>
    </StyledEngineProvider>
  );
}

export default React.memo(FuseTheme);
