import React, { Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import { caseStateDebriefPlus, USER_CASE_ROLES } from 'app/consts';
// eslint-disable-next-line import/no-cycle
import BriefForm from './BriefForm';
import DebriefView from './DebriefView';
import useTemplateRecommendations from '../hooks/useTemplateRecommendations';
import Recommendations from './Recommendations';

const BriefView = ({
  kase,
  showBrief,
  setShowBrief,
  handledAnimationComplete,
  formClasses,
  setFormClasses,
  briefFields,
  userRoleInCase,
  briefedByRes,
  briefApprovedByAtt,
  wasBriefReviewed,
  fields,
  tmpSubmitValues,
  setConfirmSubmitWithDraftComment,
  setConfirmSubmitWithCommentQuestionTitle,
  user,
  setConfirmSubmitWithCommentQuestionMessage,
  setConfirmSubmitWithCommentQuestion,
  submitForm,
  caseId,
  tab
}) => {
  const { t } = useTranslation();
  const [recommendations, setRecommendations] = useState(null);

  const showRecommendations = userRoleInCase !== USER_CASE_ROLES.ATTENDING && !briefApprovedByAtt;

  const onCaseRecommendations = rec => {
    console.debug('onCaseRecommendation.rec:', rec);
    setRecommendations(rec);
  };

  useTemplateRecommendations({
    userId: kase.attendingId,
    case: kase,
    onCaseRecommendations
  });

  return (
    <Fragment>
      <BriefForm
        userRoleInCase={userRoleInCase}
        briefedByRes={briefedByRes}
        briefApprovedByAtt={briefApprovedByAtt}
        wasBriefReviewed={wasBriefReviewed}
        kase={kase}
        showBrief={showBrief}
        setShowBrief={setShowBrief}
        handledAnimationComplete={handledAnimationComplete}
        formClasses={formClasses}
        setFormClasses={setFormClasses}
        briefFields={briefFields}
        tab={tab}
      />
      {caseStateDebriefPlus(kase.state) &&
        !(briefedByRes || briefApprovedByAtt) &&
        userRoleInCase === USER_CASE_ROLES.NONE && (
          <Alert severity="info" className="mb-12">
            <Trans
              i18nKey="case_not_briefed"
              values={{ attending: kase.attending.nickName.trim() }}
            />
          </Alert>
        )}
      <DebriefView
        userRoleInCase={userRoleInCase}
        briefApprovedByAtt={briefApprovedByAtt}
        kase={kase}
        showBrief={showBrief}
        handledAnimationComplete={handledAnimationComplete}
        formClasses={formClasses}
        setFormClasses={setFormClasses}
        fields={fields}
        tmpSubmitValues={tmpSubmitValues}
        setConfirmSubmitWithDraftComment={setConfirmSubmitWithDraftComment}
        setConfirmSubmitWithCommentQuestionTitle={setConfirmSubmitWithCommentQuestionTitle}
        user={user}
        setConfirmSubmitWithCommentQuestionMessage={setConfirmSubmitWithCommentQuestionMessage}
        setConfirmSubmitWithCommentQuestion={setConfirmSubmitWithCommentQuestion}
        submitForm={submitForm}
        caseId={caseId}
        tab={tab}
        recommendations={recommendations}
      />
      {showRecommendations && <Recommendations recommendations={recommendations} />}
    </Fragment>
  );
};

export default BriefView;
