import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import withReducer from 'app/store/withReducer';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import SvgIcon from '@mui/material/SvgIcon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { showA2HS } from './store/a2hsSlice';
import reducer from './store';

import { ReactComponent as IconIosShare } from './ios_share.svg';
import { isInstalled, isMobile } from './helper';

const useStyles = makeStyles(theme => ({
  root: {
    bottom: '15px',
    '& .MuiPaper-root': {
      color: theme.palette.text.primary,
      background: '#fff',
      border: '1px solid #a3a3a3'
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: '100%',
      marginLeft: '-11px',
      width: 0,
      height: 0,
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderTop: '10px solid #8c8c8c'
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: '100%',
      marginLeft: '-11px',
      marginTop: '-1px',
      width: 0,
      height: 0,
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderTop: '10px solid #fff'
    },
    '& .icon': {
      display: 'inline-block'
    }
  },
  img: {
    width: '180px',
    margin: '0 auto 16px',
    '@media (max-height:625px)': { display: 'none' }
  },
  button: {
    marginTop: 16,
    fontSize: 16,
    width: '100%',
    textAlign: 'center',
    color: theme.palette.primary.main
  },
  closeBtn: {
    position: 'absolute',
    right: 8,
    top: 8
  }
}));

function A2HSTip(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const state = useSelector(({ a2hs }) => a2hs.a2hs) || {};

  const shouldRender = isMobile() && !isInstalled();

  const showTip = useCallback(() => {
    if (shouldRender) {
      const tipSeen = localStorage.getItem('A2HSTip');
      if (!tipSeen) {
        setOpen(true);
      }
    }
  }, [shouldRender]);

  useEffect(() => {
    // Show on login screen (if not marked "I prefer the website")
    if (location.pathname === '/login') {
      setTimeout(() => {
        showTip();
      }, 2000);
    }
  }, [location.pathname, showTip]);

  useEffect(() => {
    // Show on sidebar button click
    if (state.open) {
      setOpen(true);
      dispatch(showA2HS(false));
    }
  }, [state.open, dispatch]);

  const handlePreferWebsite = () => {
    setOpen(false);
    localStorage.setItem('A2HSTip', Date.now());
  };

  return (
    shouldRender && (
      <Snackbar
        className={clsx(classes.root)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        TransitionComponent={Slide}
        open={open}
        message={
          <div className="text-16">
            <h2 className="p-16">{t("Let's add the app to your home screen")}</h2>
            <img className={classes.img} src="assets/images/a2hs/A2HS.png" alt="" />
            <List className={classes.benefits} aria-label="benefits">
              {[
                t('Use push notifications instead of text messages'),
                t('Save your password for quick login'),
                t('Fast app access & full screen view')
              ].map(m => (
                <ListItem className="py-2" key={m}>
                  <ListItemIcon className="min-w-0 pr-6">
                    <StarIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={m} />
                </ListItem>
              ))}
            </List>
            <div className="p-16">
              <h3 className="text-lg font-bold">{t("It's easy")}:</h3>
              <List className={classes.list} aria-label="benefits">
                <ListItem className="p-2 block">
                  {t('1. Click the')}{' '}
                  <SvgIcon
                    component={IconIosShare}
                    alt="icon iOS Share Icon"
                    className="icon align-bottom"
                    style={{ color: '#0D5FFF', width: '24px', height: '24px' }}
                  />{' '}
                  {t('at the bottom of the screen, and ‘Add to home screen’.')}
                </ListItem>
                <ListItem className="p-2 block">
                  {t('2. Go to your home screen and click the Chiefy icon.')}
                </ListItem>
              </List>
              <button className={classes.button} type="button" onClick={handlePreferWebsite}>
                {t("Don't ask again.")}
              </button>
            </div>
            <IconButton
              className={clsx(classes.closeBtn)}
              size="small"
              aria-label="close"
              onClick={handlePreferWebsite}
            >
              <CloseIcon />
            </IconButton>
          </div>
        }
      />
    )
  );
}

// export default A2HSTip;
export default withReducer('a2hs', reducer)(React.memo(A2HSTip));
