import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import AdditionalInfo from 'app/components/planFeedback/AdditionalInfo';
import HowToImprove from 'app/components/planFeedback/HowToImprove';
import WhatWentWell from 'app/components/planFeedback/WhatWentWell';
import Typography from '@mui/material/Typography';
import HuddleRating from 'app/components/planFeedback/HuddleRating';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(6),
    width: '100%'
  },
  buttons: {
    marginTop: theme.spacing(4)
  },
  button: {
    display: 'block',
    width: '100%',
    padding: '6px 8px',
    marginBottom: theme.spacing(1),
    textAlign: 'left',
    textTransform: 'none',
    '&.MuiButton-text': {
      background: '#f2f2f2',
      color: '#666'
    },
    '& .subtitle': {
      opacity: '.85',
      fontSize: '1.3rem'
    }
  },
  title: {
    color: theme.palette.secondary.main
  },
  subTitle: {
    color: theme.palette.primary.main
  }
}));

export default function HuddleFeedback(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const currentUserId = useSelector(({ auth }) => auth.user.data.id);
  const currentUserPlanFeedbacks = props.case.planFeedbacks?.filter(
    pfb => pfb.user.id === currentUserId
  );
  const feedback = currentUserPlanFeedbacks?.length > 0 ? currentUserPlanFeedbacks[0] : null;
  const [rating, setRating] = React.useState(feedback?.rating || 0);
  const [howToImproveSelection, setHowToImproveSelection] = React.useState(
    feedback?.howToImproveSelection || []
  );
  const [whatWentWellSelection, setWhatWentWellSelection] = React.useState(
    feedback?.whatWentWellSelection || []
  );
  const [addiInfoSelection, setAddiInfoSelection] = React.useState(
    feedback?.addiInfoSelection || []
  );

  const handleSetRating = value => {
    if (rating === 0 || rating === '0') {
      const element = document.getElementById('title');
      if (element != null) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
      }
    }
    setRating(value);
  };

  return (
    <div>
      <InputLabel className={clsx(classes.title, 'mt-34 mb-6')} id="title">
        {t('your_plan_feedback_title')}
      </InputLabel>
      <Typography variant="body3" gutterBottom color="primary">
        <Trans i18nKey="your_plan_feedback_subtitle" values={{ name: props.name }} />
      </Typography>
      <HuddleRating
        submitPlanFeedback={props.submitPlanFeedback}
        handlePlanFeedbackUpdate={props.handlePlanFeedbackUpdate}
        case={props.case}
        feedback={feedback}
        setRating={value => handleSetRating(value)}
      />
      {(rating > 0 ||
        whatWentWellSelection.length > 0 ||
        howToImproveSelection?.length > 0 ||
        addiInfoSelection.length > 0) && (
        <div>
          <WhatWentWell
            submitPlanFeedback={props.submitPlanFeedback}
            handlePlanFeedbackUpdate={props.handlePlanFeedbackUpdate}
            case={props.case}
            feedback={feedback}
            setwhatWentWellSelection={setWhatWentWellSelection}
          />
          <HowToImprove
            submitPlanFeedback={props.submitPlanFeedback}
            handlePlanFeedbackUpdate={props.handlePlanFeedbackUpdate}
            case={props.case}
            feedback={feedback}
            setHowToImproveSelection={setHowToImproveSelection}
          />
          {(howToImproveSelection?.length > 0 || addiInfoSelection.length > 0) && (
            <AdditionalInfo
              submitPlanFeedback={props.submitPlanFeedback}
              handlePlanFeedbackUpdate={props.handlePlanFeedbackUpdate}
              case={props.case}
              feedback={feedback}
              setAddiInfoSelection={setAddiInfoSelection}
            />
          )}
        </div>
      )}
    </div>
  );
}
