import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';
import Cases from './Cases';

const CasesConfig = {
  settings: {
    layout: {
      config: {
        footer: {
          display: true
        }
      }
    }
  },
  auth: authRoles.careTeam,
  routes: [
    {
      path: '/cases/*',
      component: Cases
    },
    {
      path: '/cases',
      component: () => <Redirect to="/cases/my-cases" />
    }
  ]
};

export default CasesConfig;
