import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import CaseDialog from 'app/components/caseViews/CaseDialog';
import { useSelector, useDispatch } from 'react-redux';
import { followCase, unfollowCase, updateLastSeen } from 'app/store/data/casesSlice';
import { optionsSelector } from 'app/metaData/store/enumSlice';
import ConfirmationDialog from 'app/components/confirmDialog/ConfirmDialog';
import ShareCaseFilesDialog from 'app/components/caseViews/ShareCaseFilesDialog';
import { ROLES } from 'app/consts';

const CasesDialogs = ({
  nextCases,
  nextCaseIndex,
  totalNextCases,
  loadCaseById,
  closeCaseDialog,
  handleShare,
  tab,
  setShouldMoveToNextCase,
  setCaseSubmittedComplete,
  onShareDialogClose,
  showFilesDialog,
  shareCaseFiles,
  showNextCase,
  setShowNextCase,
  moveToNextCase,
  showNextCaseMessage
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const openCase = useSelector(({ data }) => data.cases.openCase);
  const cases = useSelector(({ data }) => data.cases.items);
  const options = useSelector(optionsSelector);
  const user = useSelector(({ auth }) => auth.user);
  const isUserAdmin = user.role.includes(ROLES.DEPARTMENT_ADMIN);

  const showNextCaseTitle =
    nextCaseIndex + 1 === totalNextCases
      ? t('You have one more case to huddle')
      : t('You have more cases to huddle');

  const handleSetCaseFollowers = (caseId, followersToAdd, followersToRemove) => {
    followersToAdd.forEach(f => {
      dispatch(followCase(isUserAdmin, caseId, f.userId, f.lastSeen));
    });
    followersToRemove.forEach(f => {
      dispatch(unfollowCase(isUserAdmin, caseId, f.userId));
    });
  };

  return (
    <Fragment>
      <CaseDialog
        nextCases={
          nextCases.length && nextCaseIndex < nextCases.length ? nextCases.slice(nextCaseIndex) : []
        }
        totalNextCases={totalNextCases}
        loadCaseById={loadCaseById}
        closeCaseDialog={closeCaseDialog}
        openCase={openCase}
        options={options}
        selfCase
        cases={cases}
        handleShare={handleShare}
        tab={tab}
        setShouldMoveToNextCase={setShouldMoveToNextCase}
        setCaseSubmittedComplete={setCaseSubmittedComplete}
        handleSetFollowers={handleSetCaseFollowers}
      />
      <ShareCaseFilesDialog
        handleClose={() => {
          onShareDialogClose();
        }}
        open={showFilesDialog}
        onSubmit={shareCaseFiles}
      />
      <ConfirmationDialog
        open={showNextCase}
        onClose={ok => {
          setShowNextCase(false);
          if (ok) {
            moveToNextCase();
          } else {
            closeCaseDialog();
          }
        }}
        title={showNextCaseTitle}
        message={showNextCaseMessage}
        ok={t(`Let's do it`)}
        cancel={t(`Not now`)}
        actionsProps={{ className: 'justify-between' }}
        okProps={{ variant: 'outlined', color: 'primary' }}
        cancelProps={{ variant: 'text', color: 'inherit' }}
      />
    </Fragment>
  );
};

export default CasesDialogs;
