import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'app/services/i18n';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Button from '@mui/material/ButtonBase';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import MomentAdapter from '@date-io/moment';
import { useSelector, useDispatch } from 'react-redux';
import {
  loadMyCasesDataShort,
  myCasesDataSelector,
  updateResetStatsAt
} from 'app/store/data/dashboardSlice';
import { Link } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import DropMenu from 'app/components/dropMenu/DropMenu';
import { ROLES } from 'app/consts';

export const EST_SAVINGS_PER_CASE = 630;

const dateLib = new MomentAdapter();

const numAttr = num => {
  let label;
  let color;
  const noValue = Number.isNaN(Number(num.user));
  if (num.user > 90 || noValue) {
    label = i18n.t('Excellent');
    color = 'green';
  } else if (num.user >= 80) {
    label = i18n.t('Good');
    color = 'blue';
  } else if (num.user < num.avg) {
    label = i18n.t('Below average');
    color = 'orange';
  }
  return {
    label,
    color
  };
};

const parseNum = num => {
  const n = numAttr(num);
  return (
    <Typography component="span" className={`text-18 font-700 text-${n.color}-600 align-middle`}>
      {`${parseFloat(num.user.toFixed(1))}%`}
    </Typography>
  );
};

const parseChip = num => {
  const n = numAttr(num);
  return n.label ? (
    <Chip
      size="small"
      label={n.label}
      className={`text-xs bg-${n.color}-100 text-${n.color}-900 align-middle mx-4`}
    />
  ) : null;
};

export default function CasesStats(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const casesData = useSelector(myCasesDataSelector);
  const statsResetAt = useSelector(({ auth }) => auth.user.data.resetStatsAt);
  const userRoles = useSelector(({ auth }) => auth.user.role);

  const { cases } = props;

  useEffect(() => {
    if (!('length' in cases)) {
      return;
    }
    dispatch(loadMyCasesDataShort());
  }, [statsResetAt, dispatch, cases]);

  const resetStatsRange = async () => {
    await dispatch(updateResetStatsAt());
  };

  const notAvailable = !casesData.loaded;

  const yourHuddleRate = userRoles.includes(ROLES.ATTENDING)
    ? casesData.briefReviewRate
    : null; /* Disabled for residents for now. casesData.briefRate */

  return (
    <Card className="flex flex-col rounded-8 shadow-1 p-16 m-12 md:mx-auto md:mt-0 max-w-xl">
      <Grid container spacing={0} columns={yourHuddleRate ? 12 : 1}>
        {yourHuddleRate && (
          <Grid className="text-right" item xs={12}>
            <Typography className="text-12 -mr-4 -mb-32 -mt-10" color="textSecondary">
              <DropMenu
                buttonProps={{
                  className: 'kebab-menu -mr-16 order-last'
                }}
              >
                <Button
                  title={t('reset_planning_rate')}
                  aria-label={t('reset_planning_rate')}
                  onClick={resetStatsRange}
                  className="px-16 hover:bg-transparent"
                  disableRipple
                  variant="text"
                >
                  <Icon>restart_alt</Icon>
                  <Typography variant="inherit" className="ml-12">
                    {t('reset_planning_rate')}
                  </Typography>
                </Button>
              </DropMenu>
            </Typography>
          </Grid>
        )}
        {yourHuddleRate && (
          <Grid className="text-center border-r border-gray-300" item xs={6}>
            <div className="pb-16 flex flex-col items-center">
              {notAvailable ? (
                <CircularProgress classes={{ root: 'my-12' }} size={20} />
              ) : (
                <Fragment>
                  {parseNum(yourHuddleRate)}
                  <Typography className="text-12 mb-4" color="textSecondary">
                    {t('Your huddle rate')}
                    {statsResetAt && ` (${t('since')} ${dateLib.date(statsResetAt).format('l')})`}
                  </Typography>
                  {parseChip(yourHuddleRate)}
                </Fragment>
              )}
            </div>
          </Grid>
        )}
        <Grid className="text-center" item xs={6}>
          <div className="pb-16">
            {notAvailable ? (
              <CircularProgress classes={{ root: 'my-12' }} size={20} />
            ) : (
              <Typography component="span" className="text-18 font-700 text-green-600 align-middle">
                {`$${(casesData.depBriefedCases * EST_SAVINGS_PER_CASE).toLocaleString()}`}
              </Typography>
            )}
            <Typography className="text-12 mb-4" color="textSecondary">
              {t('est_department_savings')}
            </Typography>
          </div>
        </Grid>
        {yourHuddleRate && (
          <Grid className="text-left" item xs={6}>
            <Typography className="underline text-12 -mr-4 -mb-10 -mt-10" color="primary">
              <Link to="/cases/dash" role="button">
                {t('See your insights')}
              </Link>
            </Typography>
          </Grid>
        )}
        <Grid item xs={6}>
          <Typography className="underline text-12 ml-16 -mb-10 -mt-10" color="primary">
            <a
              href="https://www.chiefyteam.com/estimating-cost-savings"
              role="button"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('How is this calculated?')}
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
