import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';
import Admin from './Admin';

const ExampleConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  auth: authRoles.depAdmin,
  routes: [
    {
      path: '/admin/*',
      component: Admin
    },
    {
      path: '/admin',
      component: () => <Redirect to="/admin/users" />
    }
  ]
};

export default ExampleConfig;
