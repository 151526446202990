import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CASE_ASSET_FULL_DATA = gql`
  fragment CaseAssetFullData on GetCaseAssetsOutput {
    created_at
    created_by_id
    description
    external_id
    id
    type
    updated_at
    token
    stage
    meta
  }
`;
