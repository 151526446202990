import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';
import MyDepartment from './MyDepartment';

const MyDepartmentConfig = {
  settings: {
    layout: {}
  },
  auth: authRoles.depDirector,
  routes: [
    {
      path: '/analytics/*',
      component: MyDepartment
    },
    {
      path: '/analytics',
      component: () => <Redirect to="/analytics/data" />
    }
  ]
};

export default MyDepartmentConfig;
