import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Trans, useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  paper: {
    width: '400px',
    maxWeight: '90%'
  },
  dialogContentRoot: {
    fontSize: '1.8rem'
  }
}));

export default function ConfirmationDialog(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClose, open, message } = props;

  const [textFieldValue, setTextFieldValue] = React.useState(props.textFieldValue);

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true, textFieldValue);
  };

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      classes={{
        paper: classes.paper,
        ...props.classes
      }}
    >
      <DialogTitle textAlign={props.center ? 'center' : 'inherit'}>
        {props.title || t('Confirm')}
      </DialogTitle>
      <DialogContent
        // center the messge to the middle of the dialog
        dividers
        classes={{
          root: classes.dialogContentRoot
        }}
      >
        {message && (
          <Typography align={props.center ? 'center' : 'inherit'}>
            <Trans i18nKey={message} components={[<s />]} />
          </Typography>
        )}
        {props.textFieldValue && (
          <TextField
            value={textFieldValue}
            onChange={e => {
              setTextFieldValue(e.target.value);
            }}
            multiline
          />
        )}
      </DialogContent>
      <DialogActions {...props.actionsProps}>
        <Button onClick={handleCancel} {...props.cancelProps}>
          {props.cancel || t('Cancel')}
        </Button>
        <Button autoFocus onClick={handleOk} variant="outlined" color="primary" {...props.okProps}>
          {props.ok || t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};
