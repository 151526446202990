import Alert from '@mui/material/Alert';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CASE_STATE, caseStateInBriefAndReview, USER_CASE_ROLES } from 'app/consts';

const CaseInfoBox = props => {
  const {
    userRoleInCase,
    case: { state: caseState },
    totalNextCases,
    nextCases
  } = props;
  const { t } = useTranslation();
  if (!totalNextCases) {
    return null;
  }
  if (
    !(userRoleInCase === USER_CASE_ROLES.RESIDENT && caseState === CASE_STATE.IN_BRIEF) &&
    !(userRoleInCase === USER_CASE_ROLES.ATTENDING && caseStateInBriefAndReview(caseState))
  ) {
    return null;
  }
  return (
    <Alert severity="info" className="mb-12 text-14">
      {t('case_info_box_x_of_y_cases', {
        current: totalNextCases + 1 - nextCases.length,
        total: totalNextCases + 1
      })}
    </Alert>
  );
};
export default CaseInfoBox;
