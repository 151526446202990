import { createSlice } from '@reduxjs/toolkit';
import apiService from 'app/services/apiService';
import toast from 'app/services/toastService/toast';
import history from '@history';
import MomentAdapter from '@date-io/moment';
import { moveToNextState } from 'app/components/caseViews/store/caseViewSlice';
import amplService from 'app/services/amplService/amplService';

const dateLib = new MomentAdapter();
const TIME_FORMAT = 'ddd MMM D YYYY HH:mm:ss ZZ';
export const MIN_DATE = dateLib.moment().startOf('day').format(TIME_FORMAT);
export const MAX_DATE = dateLib.moment().endOf('day').format(TIME_FORMAT);
const getImagePath = asset =>
  asset.meta?.cdnUrlModifiers
    ? `${asset.meta.cdnUrlModifiers}-/format/jpeg/-/preview/1200x1200/?token=${asset.token}`
    : `-/format/jpeg/-/preview/1200x1200/?token=${asset.token}`;

const getFullPath = asset =>
  asset.meta ? `${asset.meta.originalUrl}${getImagePath(asset)}` : null;
export const loadFullCaseData = async (id, userId) => {
  const [caseInfo, caseAssets] = await Promise.all([
    apiService.getCase(id),
    // apiService.getCasePrivateNote(id, userId),
    apiService.getCaseAssets(id)
  ]);
  const files = [];
  const casePrivateNotes = [];

  for (const asset of caseAssets) {
    const url = getFullPath(asset);

    if (url) {
      files.push({
        name: asset.meta.name,
        url,
        type: asset.meta.mimeType
      });
    }
  }

  return {
    ...caseInfo,
    privateNote: casePrivateNotes.length > 0 ? casePrivateNotes[0] : false,
    files
  };
};

export const loadCase =
  (id, userId, editCaseAttributes = false) =>
  async (dispatch, getState) => {
    try {
      const kase = await loadFullCaseData(id, userId);

      dispatch(handleSetOpenCase({ case: kase, editCaseAttributes }));
      amplService.sendCaseEvent(
        amplService.EVENTS.PAGE_VIEW,
        kase,
        amplService.processLocation(history.location)
      );
      return kase;
    } catch (err) {
      console.log(err);
      toast.error(err.message);
      throw err;
    }
  };

export const getCaseSteps = async caseId => {
  try {
    return await apiService.getCaseSteps(caseId);
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const deleteCaseSteps = async caseId => {
  try {
    return await apiService.deleteCaseSteps(caseId);
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const startStep = async (caseId, stepId) => {
  try {
    return await apiService.startStep(caseId, stepId);
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const endStep = async (caseId, stepId) => {
  try {
    return await apiService.endStep(caseId, stepId);
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const loadAdminCase = id => async (dispatch, getState) => {
  try {
    const kase = await apiService.getAdminCase(id);
    dispatch(handleSetOpenCase({ case: kase, editCaseAttributes: true }));
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const loadMyCases = () => async (dispatch, getState) => {
  try {
    const cases = await apiService.getMyCases();
    dispatch(setItems(cases));
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const loadOrCases = () => async (dispatch, getState) => {
  try {
    const cases = await apiService.getOrCases(MIN_DATE, MAX_DATE);
    dispatch(setItems(cases));
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const loadMyPastCasesForLL = async procedureId => {
  try {
    return await apiService.getMyPastCasesForLL(procedureId);
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const loadPastCases = (offset, limit) => async (dispatch, getState) => {
  try {
    const cases = await apiService.getMyPastCases(offset, limit);
    if (offset === 0) {
      dispatch(setPastCases(cases));
    } else {
      dispatch(appendPastCases(cases));
    }
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const loadCases = isUserAdmin => async (dispatch, getState) => {
  try {
    const cases = await apiService.getCases(isUserAdmin);
    dispatch(setItems(cases));
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const loadNewCases = isUserAdmin => async (dispatch, getState) => {
  try {
    const cases = await apiService.getNewCases(isUserAdmin);
    dispatch(setItems(cases));
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const loadNotNewCases = isUserAdmin => async (dispatch, getState) => {
  try {
    const cases = await apiService.getNotNewCases(isUserAdmin);
    dispatch(setItems([...getState().data.cases.items, ...cases]));
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const updateCasesState = (isUserAdmin, ids, state) => async (dispatch, getState) => {
  try {
    await apiService.updateCasesState(isUserAdmin, ids, state);
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const adminDeleteCase = kase => async (dispatch, getState) => {
  try {
    await apiService.adminDeleteCase(kase);
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const deleteCase = kase => async (dispatch, getState) => {
  try {
    await apiService.deleteCase(kase);
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const archiveCase = kase => async (dispatch, getState) => {
  try {
    await dispatch(moveToNextState(kase));
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const followCase = (isUserAdmin, caseId, userId, lastSeen) => async (dispatch, getState) => {
  try {
    await apiService.followCase(isUserAdmin, caseId, userId, lastSeen);
    return true;
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const updateLastSeen = (caseId, userId) => async (dispatch, getState) => {
  try {
    await apiService.updateLastSeen(caseId, userId);
    return true;
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const unfollowCase = (isUserAdmin, caseId, userId) => async (dispatch, getState) => {
  try {
    await apiService.unfollowCase(isUserAdmin, caseId, userId);
    return true;
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const handleSetOpenCase = action => {
  const overRideEditCaseAttributes =
    action.case && action.case !== 'new' && !action.case.procedureId;

  return setOpenCase({
    ...action,
    editCaseAttributes: action.editCaseAttributes
  });
};

const pastCasesLimitInitValue = 5;

const initialState = {
  pastCases: [],
  items: [],
  calculatedDisplayIds: {},
  openCase: {
    case: false,
    editCaseAttributes: false,
    planFeedbacks: []
  },
  pastCasesLimit: pastCasesLimitInitValue,
  pastCasesLimitInitValue
};

const updateItemsCalculatedDisplayId = state => {
  const getKey = c => `${c.displayId || c.procedureTitle},${c.attendingId},${c.caseDate}`;

  const cases = state.items;

  // collate similar cases
  const casesByProcedureId = new Map();
  cases.forEach(c => {
    if (!c.procedureId && !c.displayId) {
      return;
    }

    const key = getKey(c);
    const casesForProcedure = casesByProcedureId.get(key) || [];
    casesForProcedure.push(c);
    casesByProcedureId.set(key, casesForProcedure);
  });

  // go over all cases and change the title to the new one
  cases.forEach(c => {
    let calculatedDisplayId;
    if (c.displayId || c.procedureId) {
      const key = getKey(c);
      const casesForProcedure = casesByProcedureId.get(key) || [];

      if (casesForProcedure?.length > 1) {
        const idx = casesForProcedure.findIndex(item => item.id === c.id);
        if (idx >= 0) {
          calculatedDisplayId = `(#${idx + 1} of ${casesForProcedure.length}) ${
            c.displayId || c.procedureTitle
          }`;
        }
      }
    }

    state.calculatedDisplayIds[c.id] = calculatedDisplayId;
  });
};

const casesSlice = createSlice({
  name: 'cases',
  initialState,
  reducers: {
    setOpenCase: (state, action) => {
      state.openCase = action.payload;
    },
    addComment: (state, action) => {
      state.openCase.case.comments = [...state.openCase.case.comments, action.payload];
    },
    removeComment: (state, action) => {
      state.openCase.case.comments = state.openCase.case.comments.filter(
        c => c.id !== action.payload.id
      );
    },
    setComment: (state, action) => {
      const cidx = state.openCase.case.comments.findIndex(item => item.id === action.payload.id);
      state.openCase.case.comments[cidx].comment = action.payload.comment;
    },
    setComments: (state, action) => {
      if (state?.openCase?.case?.comments) {
        state.openCase.case.comments = action.payload.comments;
      }
    },

    addPlanFeedback: (state, action) => {
      state.openCase.case.planFeedbacks = [...state.openCase.case.planFeedbacks, action.payload];
    },
    setPlanFeedback: (state, action) => {
      const pidx = state.openCase.case.planFeedbacks.findIndex(
        item => item.id === action.payload.id
      );
      if (action.payload.addiInfoSelection) {
        state.openCase.case.planFeedbacks[pidx].addiInfoSelection =
          action.payload.addiInfoSelection;
      }
      if (action.payload.text) {
        state.openCase.case.planFeedbacks[pidx].text = action.payload.text;
      }
      if (action.payload.rating) {
        state.openCase.case.planFeedbacks[pidx].rating = action.payload.rating;
      }
      if (action.payload.howToImproveSelection) {
        state.openCase.case.planFeedbacks[pidx].howToImproveSelection =
          action.payload.howToImproveSelection;
      }
      if (action.payload.howToImproveText) {
        state.openCase.case.planFeedbacks[pidx].howToImproveText = action.payload.howToImproveText;
      }
    },
    addAsset: (state, action) => {
      state.openCase.case.assets = [...state.openCase.case.assets, action.payload];
    },
    setAssets: (state, action) => {
      state.openCase.case.assets = action.payload;
    },
    removeAsset: (state, action) => {
      state.openCase.case.assets = action.payload;
    },
    setItems: (state, action) => {
      state.items = action.payload;
      updateItemsCalculatedDisplayId(state);
      state.loaded = true;
    },
    setPastCases: (state, action) => {
      state.pastCases = action.payload;
    },
    setPastCasesLimit: (state, action) => {
      state.pastCasesLimit = action.payload;
    },
    resetPastCasesLimit: state => {
      state.pastCasesLimit = pastCasesLimitInitValue;
    },
    appendPastCases: (state, action) => {
      state.pastCases = [...state.pastCases, ...action.payload];
    },
    addItem: (state, action) => {
      state.items = [action.payload, ...state.items].sort((a, b) => {
        const aDate = new Date(a.caseDate);
        const bDate = new Date(b.caseDate);
        return aDate.getTime() === bDate.getTime() ? a.indexInDay - b.indexInDay : aDate - bDate;
      });
      updateItemsCalculatedDisplayId(state);
    },
    updateItem: (state, action) => {
      const idx = state.items.findIndex(item => item.id === action.payload.id);
      state.items[idx] = action.payload;
      updateItemsCalculatedDisplayId(state);
    },
    updateAttendingValues: (state, action) => {
      state.openCase.case.attendingValues = action.payload.attendingValues;
    },
    updateOpenCaseDescription: (state, action) => {
      state.openCase.case.description = action.payload.description;
    },
    setCaseValues: (state, action) => {
      const idx = state.items.findIndex(item => item.id === action.payload.caseId);
      state.items[idx] = { ...state.items[idx], ...action.payload.values };
    },
    setCasePrivateNote: (state, action) => {
      const idx = state.items.findIndex(item => item.id === action.payload.caseId);
      state.items[idx].privateNote = action.payload.privateNote;
    },
    resetItems: (state, action) => {
      state.loaded = false;
      state.items = [];
    }
  },
  extraReducers: {}
});

export const {
  setPastCases,
  setItems,
  addItem,
  updateItem,
  addComment,
  removeComment,
  setComment,
  setComments,

  addPlanFeedback,
  setPlanFeedback,
  setCaseValues,
  setOpenCase,
  setCasePrivateNote,
  resetItems,
  updateAttendingValues,
  addAsset,
  removeAsset,
  setAssets,
  appendPastCases,
  setPastCasesLimit,
  resetPastCasesLimit,
  updateOpenCaseDescription
} = casesSlice.actions;

export default casesSlice.reducer;
