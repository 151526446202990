import React from 'react';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import { useTranslation } from 'react-i18next';
import { CASE_STATES } from 'app/consts';

const EditAttributes = props => {
  const {
    case: { state: caseState, id: caseId },
    loadCaseById,
    children
  } = props;

  const { t } = useTranslation();
  return (
    <Button
      variant="outlined"
      color="inherit"
      aria-label={t('Edit case attributes')}
      className="relative block w-full text-left font-normal normal-case py-0 mb-12 mt-24 border-gray-400"
      onClick={() => loadCaseById(caseId, true)}
    >
      {children}
      <Icon fontSize="small" className="absolute bottom-0 right-0 m-10 bg-white opacity-75">
        edit
      </Icon>
    </Button>
  );
};

export default EditAttributes;
