const { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID } = process.env;

const getAudPrefix = () => {
  let prefix = window.location.host.split('.')[0];
  if (window.location.host.endsWith(':3000')) {
    prefix = process.env.REACT_APP_LOCALHOST_AUD_PREFIX;
  }
  if (prefix === 'stage-admin') {
    prefix = 'stage';
  }
  if (prefix === 'zipora-admin') {
    prefix = 'zipora';
  }
  if (prefix.endsWith('chf-tay-dev')) {
    prefix = 'dev';
  }
  return prefix;
};
const depClientId =
  process.env[`REACT_APP_AUTH0_${getAudPrefix().toUpperCase().replace('-', '_')}_CLIENT_ID`];
const AUTH_CONFIG = {
  audPrefix: getAudPrefix(),
  domain: REACT_APP_AUTH0_DOMAIN,
  clientId: depClientId || REACT_APP_AUTH0_CLIENT_ID,
  callbackUrl: `${window.location.protocol}//${window.location.host}/callback/`
  // callbackUrl: REACT_APP_AUTH0_CALLBACK_URL
};
export default AUTH_CONFIG;
