import { ROLES } from 'app/consts';

export const followerRoles = {
  [ROLES.ANESTHESIA]: '💉',
  [ROLES.ORSTAFF]: '🩺',
  [ROLES.ATTENDING]: '🥼',
  [ROLES.RESIDENT]: '🥼',
  [ROLES.PA]: '🥼',
  '': ''
};

export const procedureWordEquivalents = [
  ['shunt', 'catheter'],
  ['placement', 'insertion', 'implantation'],
  ['revision', 'replacement']
];
