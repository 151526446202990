import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { CASE_STATE } from 'app/consts';
import Avatar from './UserAvatar';

const useStyles = makeStyles(theme => ({
  float: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  inline: {
    display: 'inline-block'
  },
  floatAvatar: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(-2),
    marginRight: theme.spacing(-1)
  },
  avatar: {
    fontWeight: 500,
    backgroundColor: theme.palette.primary.light
  },
  small: {
    fontSize: 14,
    height: 26,
    width: 26
  }
}));

const shouldShow = (user, openCase) => {
  if (!user || !openCase) {
    return false;
  }

  const briefInReviewLog = openCase.caseStateLogs.find(
    log =>
      log.fromState === CASE_STATE.IN_BRIEF &&
      log.toState === CASE_STATE.BRIEF_IN_REVIEW &&
      log.createdById === user.id
  );

  const show = openCase.residentId === user.id && briefInReviewLog;

  return show;
};

export default function ChangedByAvatar(props) {
  const classes = useStyles();

  const openCase = useSelector(({ data }) => data.cases.openCase.case);
  const user = useSelector(({ auth }) => auth.user.data);

  if (!props.showAlways && !shouldShow(user, openCase)) {
    return null;
  }

  return (
    <div className={clsx(props.inline ? classes.inline : classes.float)}>
      <Avatar
        className={clsx(
          classes.avatar,
          !props.inline && classes.floatAvatar,
          props.small && classes.small,
          props.className
        )}
        user={props.user}
      />
    </div>
  );
}
