import React, { Fragment } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function DropMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  };

  const handleClose = e => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  const hasItems = React.Children.toArray(props.children).length > 0;

  return hasItems ? (
    <Fragment>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        onMouseDown={e => e.stopPropagation()}
        {...props.buttonProps}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onMouseDown={e => e.stopPropagation()}
      >
        {React.Children.toArray(props.children).map((child, idx) => (
          <MenuItem
            key={(child.props.label || child.props.title) + idx}
            disableGutters
            onClick={handleClose}
          >
            {child}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  ) : null;
}
