import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import { useDispatch, useSelector } from 'react-redux';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import MomentAdapter from '@date-io/moment';
import MuiSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import { loadSites } from 'app/store/data/adminSlice';
import i18n from 'app/services/i18n';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { CASE_STATE, ROLES } from 'app/consts';
import Button from '@mui/material/Button';
import { useHistory, useLocation } from 'react-router-dom';
import DashboardTable from './DashboardTable';
import DashboardBarChart from './DashboardBarChart';
import DashboardWeeklyLineChart from './DashboardWeeklyLineChart';
import { optionsSelector } from '../../metaData/store/enumSlice';
import { lite } from '../../services/apiService/api/lite';
import toast from '../../services/toastService/toast';
import {
  applyTzOffset,
  applyTzOffsetReverse,
  calcFollowers,
  calcRowTotal,
  calcTimeline,
  huddlesPerWeekToArr,
  mergeArrs,
  usersToArr,
  userToRoles
} from './weeklyReport';

export default function DepartmentData(props) {
  const { t } = useTranslation();
  const { getDashboardData, data: dashboardData } = props;
  const { sites } = useSelector(({ data }) => data.admin);
  const [siteId, setSiteId] = useState('');
  const [attendingId, setAttendingId] = useState('');
  const [rowTotal, setRowTotal] = useState({ key: 'total' });
  const [timeline, setTimeline] = useState({});
  const [siteUsers, setSiteUsers] = useState([]);
  const [attendings, setAttendings] = useState([]);
  const [tz, setTz] = useState('America/New_York');
  const options = useSelector(optionsSelector);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const location = useLocation();
  const history = useHistory();

  const setSearchParams = params => {
    const searchParams = new URLSearchParams(search);

    Object.keys(params).forEach(key => {
      if (params[key] !== undefined) {
        searchParams.set(key, params[key]);
      } else {
        searchParams.delete(key);
      }
    });

    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  };

  const parseParam = paramName => {
    const arr = search.split('&');
    const param = arr.find(a => a.toLowerCase().includes(`${paramName.toLowerCase()}=`));
    const val = param ? param.split('=')[1] : '';
    // decode val to handle special characters
    const decodedVal = decodeURIComponent(val);
    return decodedVal;
  };

  const handleSearchParamsService = () => {
    const serviceVal = parseParam('service');
    if (serviceVal) {
      const sId = sites.find(s => s.name.toLowerCase() === serviceVal.toLowerCase())?.id;
      if (!sId) {
        console.log('sites', JSON.stringify(sites));
        throw new Error(`Service not found for name ${serviceVal}`);
      }
      setSiteId(sId);
    }
  };

  const handleSearchParamsSurgeon = () => {
    const surgeonVal = parseParam('surgeon');
    if (!surgeonVal) {
      return;
    }

    const aId = options.attendings.find(
      a => a.nickName.toLowerCase() === surgeonVal.toLowerCase()
    )?.id;
    if (!aId) {
      console.log('attendings', JSON.stringify(options.attendings));
      throw new Error(`Surgeon not found for name ${surgeonVal}`);
    }
    setAttendingId(aId);
  };

  const handleSearchParamsDates = () => {
    const fromDateVal = parseParam('fromDate');
    if (fromDateVal) {
      setFromDate(dateLib.moment(fromDateVal));
    }

    const toDateVal = parseParam('toDate');
    if (toDateVal) {
      setToDate(dateLib.moment(toDateVal));
    }
  };

  const getDefaultFromDate = () => {
    return dateLib.moment().startOf('day').subtract(7, 'days');
  };

  const getAdjustedFirstDayOf2024 = () => {
    // set defaultFromDate to be the first day of 2024 that is the same weekday as current day. For example, if today is Tuesday, defaultFromDate will be the first Tuesday of 2024.
    // Check which day is today, and then calculate the first day of 2024 that is the same weekday as today.
    const firstDayOf2024 = dateLib.moment('2024-01-01').day();
    const currentWeekday = dateLib.moment().day();
    const toAdd = (currentWeekday - firstDayOf2024 + 7) % 7;
    const defaultFromDate = dateLib.moment('2024-01-01').add(toAdd, 'days');
    return defaultFromDate;
  };

  const dateLib = new MomentAdapter();
  const defaultFromDate = getDefaultFromDate();
  const defaultToDate = dateLib.moment().startOf('day').subtract(1, 'days');
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const [findingFirstHuddleWeek, setFindingFirstHuddleWeek] = useState(false);

  const getSites = useCallback(() => {
    dispatch(loadSites());
  }, [dispatch]);
  useEffect(() => {
    getSites();
  }, [getSites]);

  useEffect(() => {
    if (!sites?.length > 0) {
      return;
    }

    setSiteId(sites.find(s => s.isActive)?.id);
    handleSearchParamsService();
  }, [sites]);

  useEffect(() => {
    if (!siteId) {
      return;
    }
    const site = sites.find(s => s.id === siteId);
    if (!site) {
      throw new Error(`Site not found for id ${siteId}`);
    }
    setTz(site.timezone);

    const usrs = options.careTeam.filter(u => u.userSites.some(s => s.siteId === siteId));
    setSiteUsers(usrs);
    setAttendingId('');
    setAttendings(usrs.filter(u => u.roles.some(r => r.role === ROLES.ATTENDING)));

    handleSearchParamsDates();
    handleSearchParamsSurgeon();
  }, [siteId]);

  useEffect(() => {
    if (!siteId || !siteUsers) {
      return;
    }

    const attendingIds = attendingId ? [attendingId] : attendings.map(a => a.id);
    const toDateEndOfDay = toDate.endOf('day');
    getDashboardData(
      applyTzOffset(tz, fromDate).toISOString(),
      applyTzOffset(tz, toDateEndOfDay).toISOString(),
      siteId,
      attendingIds
    );
  }, [getDashboardData, fromDate, toDate, attendingId, siteUsers]);

  useEffect(() => {
    if (findingFirstHuddleWeek) {
      // find the first week with huddles, and set fromData to that date
      const firstWeek = dashboardData.huddlesPerWeek.find(week => week.ratio > 0);
      setFindingFirstHuddleWeek(false);
      if (firstWeek) {
        const firstDay = applyTzOffsetReverse(tz, dateLib.moment(firstWeek.date));
        setFromDate(firstDay);
      }
      return;
    }

    setRowTotal(
      calcRowTotal(dashboardData.nonParticipatingAttendings, dashboardData.reportPerUserInDateRange)
    );
    setTimeline(calcTimeline(dashboardData.readyCaseStateLogsTimeInDataRange));
  }, [dashboardData]);

  const pre10PmRatio =
    !timeline?.total ||
    Math.round(
      ((timeline.total.before4pm + timeline.total.between4pm7pm + timeline.total.between7pm10pm) *
        100) /
        (timeline.total.before4pm +
          timeline.total.between4pm7pm +
          timeline.total.between7pm10pm +
          timeline.total.between10pm6am +
          timeline.total.after6am)
    );

  const getSurgeonForCopy = () => {
    if (attendingId) {
      return siteUsers.find(u => u.id === attendingId);
    }

    const firstSurgeon = options.attendings.filter(u =>
      u.userSites.some(s => s.siteId === siteId)
    )[0];
    return siteUsers.find(u => u.id === firstSurgeon.id);
  };

  const on7DaysClick = () => {
    setFromDate(getDefaultFromDate());
  };
  const onFindFirstCaseCLick = () => {
    const from = getAdjustedFirstDayOf2024();
    setFindingFirstHuddleWeek(true);
    setFromDate(from);
  };

  const onCopyToClipboardClick = () => {
    const surgeon = getSurgeonForCopy();

    const totalHuddles = dashboardData.huddlesPerWeek.reduce((acc, row) => acc + row.ratio, 0);
    const thisWeekHuddles =
      dashboardData.huddlesPerWeek[dashboardData.huddlesPerWeek.length - 1].ratio;
    const { followersAvg: followersAvgThisWeek, followersViewed: followersViewedThisWeek } =
      calcFollowers(dashboardData.caseFollowersInDateRangeLastWeek, thisWeekHuddles);
    const { followersAvg: followersAvgTotal, followersViewed: followersViewedTotal } =
      calcFollowers(dashboardData.caseFollowersInDateRange, totalHuddles);

    const generalArr = [
      ['Surgeon name'],
      [surgeon.nickName],
      ['Email'],
      [surgeon.email],
      ['Avg followers total'],
      [followersAvgTotal],
      ['Avg viewed total'],
      [followersViewedTotal],
      ['Avg followers last week'],
      [followersAvgThisWeek],
      ['Avg viewed last week'],
      [followersViewedThisWeek]
    ];
    const str = mergeArrs([
      generalArr,
      huddlesPerWeekToArr(dashboardData.huddlesPerWeek),
      usersToArr(siteUsers)
    ]);
    console.log(str);
    navigator.clipboard.writeText(str);
    toast.success(`Report for ${surgeon.nickName} copied to clipboard 🌸💐`);
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item sm={12} xs={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
          <div style={{ marginRight: '16px' }}>
            <Button variant="contained" onClick={onFindFirstCaseCLick}>
              First case
            </Button>
          </div>
          <div style={{ marginRight: '16px' }}>
            <Button variant="contained" onClick={on7DaysClick}>
              7 days
            </Button>
          </div>
          <div style={{ marginRight: '16px' }}>
            <DatePicker
              label="From date"
              inputFormat="MM/DD/YYYY"
              value={fromDate}
              onChange={value => {
                // setSearchParams({ fromDate: value.format('YYYY-MM-DD') });
                // handleSearchParamsDates();
                setFromDate(value);
              }}
              shouldDisableDate={date => {
                // disable if day is not Wednesday
                return date.day() !== 3;
              }}
              renderInput={params => <TextField {...params} />}
            />
          </div>
          <div style={{ marginRight: '16px' }}>
            <DatePicker
              label="To date"
              inputFormat="MM/DD/YYYY"
              value={toDate}
              minDate={fromDate}
              maxDate={dateLib.moment()}
              onChange={value => {
                // setSearchParams({ toDate: value.format('YYYY-MM-DD') });
                // handleSearchParamsDates();
                setToDate(value);
              }}
              renderInput={params => <TextField {...params} />}
              shouldDisableDate={date => {
                // disable if day is not Tuesday
                return date.day() !== 2;
              }}
            />
          </div>
          <div style={{ marginRight: '16px' }}>
            <MuiSelect
              value={siteId}
              onChange={(e, child) => {
                // setSearchParams({ service: encodeURIComponent(child.props.name) });
                // handleSearchParamsService();
                setSiteId(e.target.value);
              }}
              label="site"
            >
              {sites
                .filter(s => s.isActive)
                .map(s => (
                  <MenuItem key={s.id} value={s.id} name={s.name}>
                    {s.name}
                  </MenuItem>
                ))}
            </MuiSelect>
          </div>
          <div style={{ marginRight: '16px' }}>
            <MuiSelect
              value={attendingId}
              onChange={(e, child) => {
                // setSearchParams({ surgeon: encodeURIComponent(child.props.name) });
                // handleSearchParamsSurgeon();
                setAttendingId(e.target.value);
              }}
              label="attending"
            >
              {attendings.map(att => (
                <MenuItem key={att.id} value={att.id} name={att.nickName}>
                  {att.nickName}
                </MenuItem>
              ))}
            </MuiSelect>
          </div>
          <Button variant="contained" onClick={onCopyToClipboardClick}>
            Copy report to clipboard
          </Button>
        </Grid>
        {!attendingId && (
          <Grid item sm={6} xs={12}>
            <DashboardTable
              data={[
                {
                  ...rowTotal,
                  rate: `${Math.round((rowTotal.attBriefedCount * 100) / rowTotal.attAllCount)}%`,
                  pre10Pm: `${pre10PmRatio}%`
                }
              ]}
              title="Summary"
              cols={['attAllCount', 'attBriefedCount', 'rate', 'pre10Pm']}
              headers={[
                'Cases participating surgeons this week',
                'Huddled',
                'Compliance of participating surgeons',
                'Huddles before 10pm the day before'
              ]}
            />
          </Grid>
        )}
        {dashboardData.huddleRatePerWeek && (
          <Grid item sm={6} xs={12}>
            <DashboardWeeklyLineChart
              title={t('Weekly huddle rate')}
              data={dashboardData.huddleRatePerWeek}
              percentage
            />
          </Grid>
        )}
        {dashboardData.huddlesPerWeek && (
          <Grid item sm={6} xs={12}>
            <DashboardWeeklyLineChart
              title={t('Weekly huddles')}
              data={dashboardData.huddlesPerWeek}
            />
          </Grid>
        )}
        {!attendingId && dashboardData.reportPerUserInDateRange?.length > 0 && (
          <Grid item sm={6} xs={12}>
            <DashboardTable
              data={[
                ...dashboardData.reportPerUserInDateRange
                  .filter(
                    row =>
                      row.attAllCount > 0 &&
                      !dashboardData.nonParticipatingAttendings.some(u => u.id === row.userId)
                  )
                  .map(row => ({
                    key: `${row.userId}${row.siteId}`,
                    id: `${row.userId}${row.siteId}`,
                    name: row.user.nickName,
                    attBriefPercent: row.attBriefPercent,
                    attBriefPercentStr: `${row.attBriefPercent}%`,
                    timelineMedal:
                      row.attBriefPercent === 100 &&
                      timeline[row.user.nickName]?.after6am === 0 &&
                      timeline[row.user.nickName]?.between10pm6am === 0
                        ? 'All huddles before 10pm 🥇'
                        : ''
                  }))
                  .sort((a, b) =>
                    a.attBriefPercent === b.attBriefPercent
                      ? a.name.localeCompare(b.name)
                      : b.attBriefPercent - a.attBriefPercent
                  )
              ]}
              title="Surgeon huddle rates"
              cols={['name', 'attBriefPercentStr', 'timelineMedal']}
            />
          </Grid>
        )}
        {!lite && timeline?.total && (
          <Grid item sm={6} xs={12}>
            <DashboardBarChart
              title={t('Huddles timeline')}
              data={{
                labels: ['Before 4pm', '4pm-7pm', '7pm-10pm', '10pm-6am', 'After 6am'],
                values: [
                  timeline.total.before4pm,
                  timeline.total.between4pm7pm,
                  timeline.total.between7pm10pm,
                  timeline.total.between10pm6am,
                  timeline.total.after6am
                ]
              }}
              height={170}
              yLabel={t('Huddles')}
            />
          </Grid>
        )}
        <Grid item sm={12} xs={12}>
          <Card className="flex flex-col justify-between w-full h-full rounded-8 shadow-1 p-16">
            <div className="text-14">
              <div style={{ fontWeight: '600', marginBottom: 6 }}>
                Team that followed cases this week:
              </div>
              <div style={{ display: 'flex', fontWeight: '400' }}>
                <div style={{ color: '#7CB342' }}>
                  {dashboardData.caseFollowersInDateRange
                    ?.filter(row => row.user.roles.every(r => r.role !== ROLES.ATTENDING))
                    .map(row => `${row.user.nickName} (${userToRoles(row.user)})`)
                    .join(', ')}
                </div>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Card className="flex flex-col justify-between w-full h-full rounded-8 shadow-1 p-16">
            <div className="text-14">
              <div style={{ fontWeight: '600', marginBottom: 6 }}>
                Team that viewed cases this week:
              </div>
              <div style={{ display: 'flex', fontWeight: '400' }}>
                <div style={{ color: '#7CB342' }}>
                  {dashboardData.caseFollowersInDateRange
                    ?.filter(
                      row => row.user.roles.every(r => r.role !== ROLES.ATTENDING) && row.views > 0
                    )
                    .map(row => `${row.user.nickName} (${userToRoles(row.user)})`)
                    .join(', ')}
                </div>
              </div>
            </div>
          </Card>
        </Grid>
        {!attendingId && (
          <Grid item sm={12} xs={12}>
            <Card className="flex flex-col justify-between w-full h-full rounded-8 shadow-1 p-16">
              <div className="text-14">
                <div style={{ fontWeight: '600', marginBottom: 6 }}>
                  Non participating surgeons:
                </div>
                <div style={{ display: 'flex', fontWeight: '400' }}>
                  <div style={{ color: '#7CB342' }}>
                    {dashboardData.nonParticipatingAttendings.map(att => att.nickName).join(', ')}
                  </div>
                </div>
              </div>
            </Card>
          </Grid>
        )}
        {!attendingId && dashboardData.chatCountInDataRange?.aggregate && (
          <Grid item sm={12} xs={12}>
            <DashboardTable
              data={[
                {
                  key: 'total',
                  chats: dashboardData.chatCountInDataRange.aggregate.count,
                  rate: `${Math.round(
                    (dashboardData.chatCountInDataRange.aggregate.count * 100) /
                      rowTotal.attAllCount
                  )}%`
                }
              ]}
              title="Team engagement"
              cols={['chats', 'rate']}
              headers={[
                '# of cases where the team engaged in chat',
                '% of cases where team engaged in chat'
              ]}
            />
          </Grid>
        )}
        {dashboardData.reportPerUserInDateRange?.length > 0 && (
          <Grid item sm={12} xs={12}>
            <DashboardTable
              data={[
                ...dashboardData.reportPerUserInDateRange
                  .map(row => ({
                    ...row,
                    id: `${row.userId}${row.siteId}`,
                    name: row.user.nickName,
                    site: row.site.name,
                    resBriefPercent: row.resBriefPercent == null ? '-' : `${row.resBriefPercent}%`,
                    attBriefPercent: row.attBriefPercent == null ? '-' : `${row.attBriefPercent}%`,
                    attDebriefPercent:
                      row.attDebriefPercent == null ? '-' : `${row.attDebriefPercent}%`,
                    timelineMedal:
                      row.attBriefPercent === 100 &&
                      timeline[row.user.nickName]?.after6am === 0 &&
                      timeline[row.user.nickName]?.between10pm6am === 0
                        ? '🥇'
                        : ''
                  }))
                  .sort((a, b) => a.name.localeCompare(b.name)),
                rowTotal
              ]}
              title="Numbers, numbers, numbers 📊"
              cols={[
                'name',
                'site',
                'attAllCount',
                'attBriefedCount',
                'attBriefPercent',
                'timelineMedal'
              ]}
              headers={[
                'Name',
                'Service',
                'Attending (total)',
                'Attending (planned)',
                'Attending (%)',
                'Timeline'
              ]}
            />
          </Grid>
        )}
        {!lite && timeline?.total && (
          <Grid item sm={12} xs={12}>
            <DashboardTable
              data={[{ ...timeline.total, total: 'Total' }]}
              title="Timeline"
              cols={[
                'total',
                'before4pm',
                'between4pm7pm',
                'between7pm10pm',
                'between10pm6am',
                'after6am'
              ]}
              headers={['-', 'Before 4pm', '4pm-7pm', '7pm-10pm', '10pm-6am', 'After 6am']}
            />
          </Grid>
        )}
        {dashboardData.caseFollowersInDateRange?.length > 0 && (
          <Grid item sm={12} xs={12}>
            <DashboardTable
              data={dashboardData.caseFollowersInDateRange
                .map(row => ({
                  ...row,
                  id: `${row.user.id}${row.case.site.name}`,
                  name: row.user.nickName,
                  site: row.case.site.name,
                  roles: row.user.roles.map(r => i18n.t(r.role)).join(', ')
                }))
                .sort((a, b) => a.name.localeCompare(b.name))}
              title="Followers"
              cols={['site', 'name', 'roles', 'count', 'views']}
              headers={['Service', 'Name', 'Roles', 'Count', 'Views']}
            />
          </Grid>
        )}
        {dashboardData.caseFeedbackInDateRange?.length > 0 && (
          <Grid item sm={12} xs={12}>
            <DashboardTable
              data={dashboardData.caseFeedbackInDateRange
                .map(row => ({
                  ...row,
                  id: `${row.createdById}${row.caseId}`,
                  caseId: row.caseId,
                  huddled: row.case.caseStateLogs.some(
                    csl =>
                      [CASE_STATE.IN_BRIEF, CASE_STATE.BRIEF_IN_REVIEW].includes(csl.fromState) &&
                      csl.toState === CASE_STATE.READY
                  )
                    ? 'Yes'
                    : 'No',
                  procedureTitle: row.case.procedureTitle,
                  howToImproveSelection: row.howToImproveSelection
                    ?.map(sel => t(sel).replace(/(<([^>]+)>)/gi, ''))
                    .join(', '),
                  whatWentWellSelection: row.whatWentWellSelection
                    ?.map(sel => t(sel).replace(/(<([^>]+)>)/gi, ''))
                    .join(', '),
                  addiInfoSelection: row.addiInfoSelection
                    ?.map(sel => {
                      if (!options?.find) {
                        console.log('No find in options. options', options);
                        return sel;
                      }

                      const val = options?.find(o => o.value === sel);
                      if (val) {
                        return t(val.title);
                      }
                      return sel;
                    })
                    .join(', '),
                  name: row.user.nickName,
                  roles: row.user.roles
                    .filter(r => !['department_admin', 'department_director'].includes(r.role))
                    .map(r => t(r.role))
                    .join(', ')
                }))
                .sort((a, b) => b.id - a.id)}
              title="Feedbacks"
              cols={[
                'caseId',
                'procedureTitle',
                'huddled',
                'rating',
                'whatWentWellSelection',
                'whatWentWellText',
                'howToImproveSelection',
                'addiInfoSelection',
                'text',
                'name',
                'roles',
                'selection'
              ]}
              headers={[
                'Case',
                'Procedure',
                'Huddled',
                'Rating',
                'What went well selection',
                'What went well text',
                'How to improve',
                'Root cause selection',
                'Root cause text',
                'Name',
                'Roles',
                'Selection (old)'
              ]}
            />
          </Grid>
        )}
        {dashboardData.chatCountInDataRange && (
          <Grid item sm={12} xs={12}>
            <TableContainer component={Paper} className="h-full">
              Comments:
              {dashboardData.chatCountInDataRange.aggregate.count}
            </TableContainer>
          </Grid>
        )}
        {!attendingId && (
          <Grid item sm={6} xs={12}>
            <DashboardTable
              data={siteUsers.map(su => ({
                key: su.id,
                ...su,
                roles: su.roles.map(r => i18n.t(r.role)).join(', ')
              }))}
              title="Users in service"
              cols={['nickName', 'roles']}
              headers={[`Name (${siteUsers?.length})`, 'Roles']}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}
