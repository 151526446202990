const locale = {
  login_subtitle: 'Better. Faster. Safer. Surgery',
  login_error_message:
    'Something went wrong 🙏. Please try again, or contact us at hello@chiefyteam.com and we will prompty assist.',
  login_error_message_inactive:
    'User is inactive. Contact us at hello@chiefyteam.com and we will prompty assist.',
  login_tou_pp:
    'By continuing, you agree to our <tou>Terms of Use</tou> and <pp>Privacy Policy</pp>.',
  login_tou_url: 'https://www.chiefyteam.com/terms-of-use',
  login_pp_url: 'https://www.chiefyteam.com/privacy-policy',
  'SCHEDULE NEW CASE': 'CREATE A NEW CASE',
  change_assignment: 'UPDATE BOOKING',
  view_case: 'VIEW CASE',
  move_up: 'Move up',
  move_down: 'Move down',
  new: 'New',
  in_brief: 'In planning',
  brief_in_review: 'Waiting to huddle',
  ready: 'Ready',
  in_debrief: 'In debrief',
  debrief_in_review: 'Debrief in review',
  completed: 'Completed',
  archived: 'Archived',
  'Waiting for debrief': 'Waiting for debrief',
  'Waiting for debrief review': 'Debrief in review',
  'Case completed': 'Recently completed',
  publish: 'Publish',
  publish_residents: 'Publish residents for {{date}}',
  share_resident_assignment: 'Share resident assignment',
  no_resident: 'Add resident as follower',
  share_plan: 'Share plan',
  copy_to_clipboard: 'Copy to clipboard',
  plan_copied: 'Plan copied to clipboard',

  anesthesia: 'Anesthesiologist',
  anesthesia_resident: 'Anesthesia resident',
  crna: 'CRNA',
  app: 'APP',
  surgical_fellow: 'Surgical fellow',
  resident: 'Surgical resident',
  neurophysiologist: 'Neurophysiologist',
  nurse: 'Nurse',
  nurse_manager: 'Nursing management',
  cst: 'Surgical tech',
  orstaff: 'OR staff',
  attending: 'Surgeon',
  vendor_rep: 'Vendor rep',
  other: 'Other',
  care_team: 'Care team',
  inviter: 'Inviter',
  department_director: 'Department director',
  department_admin: 'Department admin',

  case_description_hint: 'One liner of the plan (e.g. pathology, location, levels)',
  'Other Equipment...': 'Other equipment details',
  'Other Pre-procedure Meds/Interventions...': 'Other pre-procedure/meds details',
  'Other Neuromonitoring...': 'Other neuromonitoring details',
  'Trackable goal': 'Quantifiable goal',
  case_id_hint: 'This will be displayed in the OR Board',

  'Type your message': 'Type a message. Use @ to mention.',
  private_notes_hint: 'Add private case notes that only you can see',
  comments_to_nursing_hint:
    'For example:\n- Have the bone scalpel on standby\n- Drill pedal on the left, bipolar on the right\n- Two step stools on each side',
  comments_to_anesthesia_hint:
    'For example:\n- Awake fiberoptic intubation\n- Keep MAP > 70 throughout the case\n- Pre-flip baseline neuromonitoring',
  estimated_case_length_hint: 'skin-to-skin',

  empty_lessons:
    "This is your first <strong>{{proc}}</strong> in Chiefy 🌱.<br>Next time, we'll show your Feedback, Lessons Learned and Private Case Notes right here.",

  res_brief_done: 'SEND TO ATTENDING',
  res_brief_done_and_next: 'SEND TO ATTENDING AND OPEN NEXT',
  approve_plan: 'APPROVE HUDDLE',
  approve_plan_and_next: 'APPROVE HUDDLE AND OPEN NEXT',
  debrief_done: 'SEND TO ATTENDING',
  approve_debrief: 'DONE',
  SUBMIT: 'SAVE',

  attending_own_case_success_with_res:
    'Great. We created the new case, and notified {{ resident }}. You will see the case when the huddle is ready for your review.',
  care_team_not_own_case_success_with_res:
    'Great. We created the new case, and notified {{resident}}.',
  care_team_own_case_in_debrief:
    'Great. We created the new past case, and it is waiting for debrief.',

  confirm_case_submit: "You didn't send your comment. It will be deleted.",

  'Other Result...': 'Other result details',
  archived_leassons_learned_title: 'Lessons learned',
  debrief_leassons_learned_title: 'Leassons learned to remember?',
  debrief_leassons_learned_hint: 'You will see this in future similar cases',

  summary_hint: 'Got anything to add to summarize this case? This is the place',
  feedback_hint:
    "Anything but 'great job' 😉\nIt's best to provide actionable feedback for improving next time (dexterity, speed, precision, preparation, etc.).",
  'Your feedback rate': 'Feedback rate',
  'DEPARTMENT ADMINS': 'ADMINISTRATORS',
  'EDIT USER': 'UPDATE USER',
  inactive_user_hint:
    'Inactive users cannot access Chiefy.\nTheir data is retained and will be available if re-activated.',
  blank_case_always_hint:
    'For surgeons: if this checkbox is selected Chiefy will not remember prefernces, and all cases will start from blank defaults',
  follow_all_hint: "For users who want to get automatic notifications on all their sites' cases",

  schedule_plan_hint:
    'Cases in this list are visible only to admins until published.<br/>To publish, select one or more cases and click "Publish". This will notify the relevant team members.',

  greeting_night: 'Good night, {{name}} 🌃',
  greeting_evening: 'Good evening, {{name}} 🌙',
  greeting_afternoon: 'Good afternoon, {{name}} 🌸',
  greeting_morning: 'Good morning, {{name}} 🌞',
  greeting_middle_night: 'Quiet night, {{name}} 🛏️?',

  empty_cases_welcome_a_my_cases:
    '{{greeting}}<br/><br/>You have no upcoming cases, but don’t worry - you will receive a notification when a case is waiting for you.',
  empty_cases_welcome_a_or_board:
    '{{greeting}}<br/><br/>No upcoming huddles yet.<br/>New cases will appear here, for you to follow and see the huddle.',
  acud_welcome:
    'Welcome to Chiefy 🌸<br/><br/>You are still not on the approved user list, but no worries.<br>An administrator has been notified and you will receive an email with your login details within 24 hours.',
  empty_cases_welcome_b_my_cases:
    "Want to add a case yourself?<br/>Click the '+' at the bottom-right.<br/><br/>",
  empty_cases_welcome_c_my_cases:
    'Got a minute? See how Chiefy works for <a>surgeons</a>, <b>anesthesiologists</b>, <c>nurses & scrub techs</c>, <d>fellows & residents</d> or <e>PAs</e>.',
  empty_cases_welcome_c_or_board:
    'Got a minute? See how Chiefy works for <c>nurses & scrub techs</c>, <b>anesthesiologists</b>, <a>surgeons</a>, <e>PAs</e> or <d>fellows & residents</d>.',
  empty_past_cases:
    'Completed cases will appear here. You can export for your tracking or ACME logging, or browse and learn.',
  whats_in_it_att: 'https://www.chiefyteam.com/surgeon-getting-started',
  whats_in_it_anesth: 'https://www.chiefyteam.com/anesthesiology-getting-started',
  whats_in_it_followers: 'https://www.chiefyteam.com/followcases',
  whats_in_it_res: 'https://www.chiefyteam.com/residentgetstarted',
  whats_in_it_pa: 'https://www.chiefyteam.com/pagetstarted',

  OR_board_intro:
    "The case doesn't start in the OR - it starts here.<br/><strong>Follow a case</strong> to see the <strong>huddle</strong>. We'll send you a notification when it's ready.<br/><br/>Take a minute to learn <a>how it works 😎</a>.",
  OR_board_intro_page: 'https://www.youtube.com/watch?v=5LbSwfCgaLY',

  case_not_briefed: 'The huddle for this case is not ready yet🤷‍♂️',
  brief_not_reviewed: 'This huddle was not reviewed by {{ attending }}.',

  follow_case_info: 'You will be able to see the huddle once {{ attending }} reviews it 🌻.',

  follow_case_success: "Done. We'll notify you on any huddle updates.",

  'Powerful PMM': '',
  'Brief rate': 'Huddled',
  'Debrief rate': 'Debrief',
  'Brief review rate': 'Brief review',
  'Feedback rate': 'Feedback',

  'Phone Number': 'Phone Number',

  whats_new_title: 'Hey, Surgical Team!',

  whats_new: `
    💡 You can now see that followers indeed saw a case. Search for the 👀 indication in the case followers list.<br><br>
    ❓ Got questions? Have any ideas? <a>Email us</a>. We're very quick to respond.
  `,
  whats_new_version: 16,

  'Add your own': 'Add your own ✎',
  contact_us_email: 'mailto:hello@chiefyteam.com',

  your_feedback_to_res: 'Feedback to {{resident}}?',
  feedback_from_att: 'you got feedback from {{attending}}',

  brief_toast_1: 'Hooray! You have just improved team communication 🎉',
  brief_toast_2: 'Well done! Patient safety starts with an aligned team 💪',
  brief_toast_3: 'Keep it up! Team communication is the key to top quality in surgery 🗝️',
  brief_toast_4: 'Hooray! You are a communication star 🥇',
  brief_toast_5: 'Unbeatable quality starts with good communication. Well done! 👏',
  brief_toast_6:
    'You did it! The team gets early access to the huddle, and you get a happy team 😃',
  brief_toast_7: 'Top communicator! You make it look easy 🤘',
  brief_toast_8: 'First class communication. Well done! 🏅',

  debrief_toast_1: 'Top communicator! You make it look easy 🤘',
  debrief_toast_2: 'Keep it up! You have just contributed to team communication 💪',
  debrief_toast_3: 'Well done. Team communication rocks ✨',
  debrief_toast_4: 'Way to go! Better communication starts here 🌱',
  debrief_toast_5: 'Unbeatable quality starts with good communication. Well done 👏',
  debrief_toast_6: 'Great communicators rule. Keep it up! 🙌',
  debrief_toast_7: 'You rock! Communication is the #1 team success factor 🍀',
  debrief_toast_8: 'First class communication. Well done! 🏅',

  debrief_case_logs_subtitle:
    'Fill the information now, and have it ready in one place when submitting your ACMGE case logs',

  procedure_title: 'TEMPLATE',
  procedure_title_select: 'Select a template:',

  download_success_toast:
    "Hooray! Your case log was successfully exported to a spreadsheet. Don't worry, no patient information in there! Can't open on your phone? Try this on a desktop.",

  share_case_after_confirm_title: 'Other team members can now see the huddle! 🤘',
  share_case_after_confirm_body: 'Want to share with others by text?',

  share_case_after_confirm_title_attending_only: "Great! Let's sync the team 🤘",
  share_case_after_confirm_body_attending_only:
    'Want to share with others (nurses, anesthesiologists, office etc.)?',

  or_board_case_plan_ready: 'Huddle ready',
  or_board_case_plan_not_ready: 'Waiting for huddle',
  or_board_case_plan_not_ready_edited_by_you: 'Waiting for huddle (edited by you)',
  plan_useful_text_hint: 'Add more details here',

  your_plan_feedback_title: 'Teams fighting for quality 🥷',
  your_plan_feedback_subtitle:
    '{{name}}, digital huddles improve team readiness and help catch miscommunications.',

  feedback_add_into_options_procedure_inaccurate: 'Procedure code/name incorrect',
  feedback_add_into_options_booking_conflict: 'Booking incorrect',
  feedback_add_into_options_equipment_mismatach: 'Equipment incorrect',
  feedback_add_into_options_implant_vendor_mismatch: 'Implant/vendor system incorrect',
  feedback_add_into_options_other: 'Something else',

  team_appreciate_prefix: '{{emoji}} Team members appreciated your huddle',
  team_appreciate_suffix: 'Team members appreciated your huddle {{emoji}}',

  share_case_files_dialog_title: "Share this huddle's image attachements as well?",
  no_internet_popup_title: 'No internet connection.\nTrying to reconnect...',
  no_internet_popup_subtitle: "Are you on the hospital's guest network again? 😂",
  remember_preferences_disabled:
    'You are working without huddle templates. Contact hello@chiefyteam.com to enable this feature.',
  case_info_box_x_of_y_cases: '{{ current }} of {{ total }} huddles to review 🌷',

  delete_case_popup_title: "Delete {{ attending }}'s case for everyone",
  delete_case_popup_msg: "Are you sure you want to delete {{ day }}'s {{ title }}?",
  delete_selected_cases_popup_title: 'Delete selected cases for everyone',
  delete_selected_cases_popup_msg: 'Are you sure?',
  delete_selected: 'Delete selected',
  partial_case_data_info_today_tomorrow: `Please <strong>select the best template</strong> to generate a huddle for {{ day }}'s case.`,
  partial_case_data_info_date: `Please <strong>select the best template</strong> to generate a huddle for the case on {{ date }}.`,

  invite_again_menu_item: 'Invite again',
  invite_again_restore_by_chiefy_menu_item: 'Restore by chiefy',
  notify: 'Notify',
  duplicate_user: 'Duplicate',
  reset_planning_rate: 'Reset huddle rate',

  you_are_now_following:
    "You're now following this case.\nWe will notify you on any changes and comments to the huddle.",
  want_to_login_faster:
    'Want faster login? Use FaceID and AutoFill password.<br><url>Read more</url>.',
  want_to_login_faster_url: 'https://www.chiefyteam.com/faster-login',

  est_department_savings: 'Est. hospital savings',

  notify_default_msg:
    "{{name}}, don't forget to follow cases for tomorrow in Chiefy https://{{tenant}}.app.chiefyteam.com",

  no_procedures_found:
    '💡Cannot find a specific procedure? Select a similar one, and use the Case Title field above to override the procedure name.',

  draft_res_to_all_attending_cases:
    "Assigned {{ res }} to all of {{ att }}'s cases on the same day 🌷",
  draft_publish_all_residents: 'Published and notified residents for {{ date }} 💐',
  draft_publish_resident: 'Added {{ res }} as follower and notified them 🌸',

  res_cannot_change_brief_anymore:
    '{{ att }} has approved the huddle. Ask questions or discuss changes on the chat 🌻.',

  already_has_similar_case:
    '{{ attending }} has a similar case on the same day - do you want to check out the OR Board before you add another one?',

  template_insight_add_toast: '{{ value }} will be selected by default in similar huddles 🌷',
  template_insight_remove_toast:
    '{{ value }} will not be selected by default in similar huddles 🌷',
  template_insight_title: 'New insight 💡',
  template_insight_add_message:
    'You usually select <strong>{{ value }}</strong> in this procedure.<br>Next time we will suggest it automatically.',
  template_insight_remove_message:
    'You usually remove <strong>{{ value }}</strong> in this procedure.<br>Next time we will not select it automatically.',
  template_insight_ok: 'Great 👍',
  template_insight_no: 'No thanks',
  import_csv: 'Import from CSV',

  follower_chip_clicked_toast_seen: '{{ nickname }} last viewed this huddle on {{ time }} 🪴',
  follower_chip_clicked_toast_not_seen: "{{ nickname }} hasn't viewed this huddle yet 🌱",
  new_case_procedure_hint: 'Select the closest one to optimize the huddle',

  proc_suggestions_placeholder_or: 'or choose the closest one from the list',
  proc_suggestions_placeholder_choose: 'Choose the closest one from the list',

  error_top: 'Something went wrong.',
  error_back: '<or_board>Back to OR Board</or_board>',
  error_bottom:
    "We keep track of these errors, but feel free to <a>contact us</a> if refreshing doesn't fix things.",

  how_did_this_case_go: 'How did this case go?',
  root_cause: 'Root cause',
  what_can_be_improved: 'What can be improved?',
  what_went_well: 'What went well?',

  fcots: 'Earlier \n<strong>FCOTS</strong>',
  turnover: 'Faster \n<strong>turnover</strong>',
  time_waste: 'Save \n<strong>time</strong>',
  resource_waste: 'Avoid \n<strong>resource \nwaste</strong>',
  equipment_not_used: 'Better \n<strong>utilize \nequipment</strong>',
  supply_waste: 'Avoid \n<strong>supply \nwaste</strong>',

  got_info_early: 'Got info \n<strong>early</strong>',
  good_communication: 'Good \n<strong>communication</strong>',
  we_were_efficient: 'We were \n<strong>efficient</strong>',
  happy_team: 'Happy \n<strong>team</strong>',
  patient_experience: 'Good <strong>patient \nexperience</strong>',
  something_else: '<strong>Something \nelse</strong>',

  description_updated: 'Updated description 🌹',
  descriptionMismatchMsg:
    'We suggest to use this description without <strong>{{ term }}</strong>:<br><br>"{{ new }}"',
  descriptionMismatchTitle: 'Case description may confuse others',
  descriptionMismatchButtonOk: 'Update description'
};

export default locale;
