import { createSlice } from '@reduxjs/toolkit';
import {
  addComment,
  updateItem,
  removeComment,
  setComment,
  addPlanFeedback,
  setPlanFeedback,
  updateAttendingValues,
  setAssets,
  removeAsset,
  setComments,
  setCaseValues,
  updateOpenCaseDescription
} from 'app/store/data/casesSlice';
import apiService from 'app/services/apiService';
import toast from 'app/services/toastService/toast';
import amplService from 'app/services/amplService/amplService';
import {
  CASE_STATE,
  CASES_STATE_ORDER,
  FORM_STAGE,
  REMEMBER_PREFERENCE_OPTIONS,
  USER_CASE_ROLES
} from 'app/consts';

export const createComment = (caseId, comment) => async (dispatch, getState) => {
  try {
    const createCommentRes = await apiService.createComment(caseId, comment);
    dispatch(addComment(createCommentRes.insertCommentsOne));
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const deleteComment = (caseId, id) => async (dispatch, getState) => {
  try {
    await apiService.deleteComment(id);
    dispatch(removeComment({ id }));
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

const getValueFromModel = (modelValue, type, multi) => {
  return type === 'select'
    ? getSelectValueFromModel(modelValue, multi)
    : getTextValueFromModel(modelValue);
};

const parseToTextArray = array =>
  JSON.stringify(array).replace('[', '{').replace(']', '}').replaceAll('\\n', '\n');

const getTextValueFromModel = modelValue =>
  Array.isArray(modelValue) &&
  modelValue[0] &&
  typeof modelValue[0] === 'object' &&
  'value' in modelValue[0]
    ? [modelValue[0].value]
    : modelValue;

const getSelectValueFromModel = (modelValue, multi = false) => {
  if (!modelValue || (modelValue.length === 1 && modelValue[0] === null)) {
    return multi ? [] : '';
  }
  if (multi) {
    return Array.isArray(modelValue) ? modelValue.map(v => v.value) : [modelValue.value];
  }
  return modelValue.value;
};

export const parseCaseValuesToText = fields => {
  const fieldValues = Object.keys(fields).map(fieldId => {
    const f = fields[fieldId];

    let fValue = Array.isArray(f.value)
      ? f.value
          .map(v => v.value)
          .filter(val => val !== 'Other')
          .join(', ')
      : f.value.label || f.value;

    if (f.otherValue) {
      fValue = `${fValue}${fValue ? ', ' : ''}${f.otherValue}`;
    }

    return fValue ? `${f.label}: ${fValue}` : null;
  });

  return fieldValues.filter(f => f);
};

export const parseCaseCommentsToText = comments => {
  let arr = comments.map(comment => `${comment.user.nickName}: ${comment.comment}`);
  if (arr.length > 0) {
    arr = ['Comments & questions:', ...arr, '-------------------'];
  }

  return arr;
};

export const submitBriefForm = (caseId, fields, values) => async (dispatch, getState) => {
  const pmmFields = fields.filter(f => f.isPmm);
  const pmmValues = pmmFields.map(f => {
    const fVals = values[f.name] === '' ? [] : values[f.name];
    const ov = values[`${f.name}Other`];
    const multiple = f.type === 'select' ? f.multiple : false;
    const hasOther = multiple
      ? fVals.find(val => val.value.toLowerCase() === 'other')
      : fVals && fVals.value && fVals.value.toLowerCase() === 'other';
    const otherValues = hasOther ? [ov] : [];

    const parsedValues = getValueFromModel(Array.isArray(fVals) ? fVals : [fVals], f.type, true);
    return {
      caseId,
      fieldId: f.id,
      values: parseToTextArray(parsedValues),
      otherValues: parseToTextArray(otherValues)
    };
  });
  await apiService.submitBriefForm(caseId, pmmValues);
};

const notifyCache = new Map();

const SEC = 1000;
const MIN = 60 * SEC;
const NOTIFY_CACHE_THROTTLE = 2 * MIN;

const isCacheExpired = id =>
  notifyCache.has(id) && Date.now() - notifyCache.get(id) > NOTIFY_CACHE_THROTTLE;

const shouldNotifyOnChanges = (caseId, caseState) => {
  if (caseState !== CASE_STATE.READY) {
    return false;
  }
  if (isCacheExpired(caseId)) {
    notifyCache.delete(caseId);
  }
  return !notifyCache.has(caseId);
};

const handleNotifyOnChanges = (caseId, caseState, oldValues) => {
  if (shouldNotifyOnChanges(caseId, caseState)) {
    amplService.sendEvent(amplService.EVENTS.CHANGES_TO_READY);
    notifyCache.set(caseId, Date.now());
    apiService.updateCaseValues(caseId, {
      old_values: { ...oldValues, ts: Date.now() }
    });
  }
};

const getValuesFromCase = ({ caseFieldValues }) => ({
  caseFieldValues
});

export const updateCaseValue =
  (caseId, field, value, type, multi, userRoleInCase) => async (dispatch, getState) => {
    try {
      // @todo: undo on error
      const state = getState();

      const { case: kase } = state.data.cases.openCase;
      const { attendingValues, id, state: caseState } = kase;

      await handleNotifyOnChanges(id, caseState, getValuesFromCase(kase));
      const parsedValue = getValueFromModel(value, type, multi);
      const newAttedValues = {
        caseFields: { ...attendingValues.caseFields },
        pmmFields: [...attendingValues.pmmFields],
        metaFields: { ...attendingValues.metaFields }
      };

      if (userRoleInCase === USER_CASE_ROLES.ATTENDING) {
        // insert to attending values
        newAttedValues.caseFields[field] = parsedValue;
      } else if (field in attendingValues.caseFields) {
        delete newAttedValues.caseFields[field];
      }

      dispatch(updateAttendingValues({ id: caseId, attendingValues: newAttedValues }));

      apiService.updateCaseValues(caseId, {
        [field]: parsedValue,
        attendingValues: newAttedValues
      });
    } catch (err) {
      console.log(err);
      toast.error(err.message);
      throw err;
    }
  };

export const updateCasePrivateNote = (caseId, note) => async () => {
  try {
    apiService.createCasePrivateNote(caseId, note);
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const createCaseAsset = (caseId, externalId, stage) => async () => {
  try {
    await apiService.createCaseAsset(caseId, externalId, stage);
    const assets = await apiService.getCaseAssets(caseId);
    return assets.find(a => a.externalId === externalId);
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const updateCaseAsset = (caseId, id, externalId, meta) => async (dispatch, getState) => {
  try {
    await apiService.updateCaseAsset(id, { meta });
    const assets = await apiService.getCaseAssets(caseId);
    return assets.find(a => a.externalId === externalId);
    // return dispatch(setAssets(assets));
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const submitCaseAsset = (caseId, id, description) => async (dispatch, getState) => {
  try {
    await apiService.updateCaseAsset(id, { description });
    const assets = await apiService.getCaseAssets(caseId);
    return dispatch(setAssets(assets));
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const deleteCaseAsset = id => async (dispatch, getState) => {
  try {
    await apiService.deleteCaseAsset(id);
    dispatch(removeAsset({ id }));
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const getUploadcareSignature = async caseId => {
  try {
    const data = await apiService.getUploadcareSignature(caseId);

    return data.uploadcareSignature;
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const updatePmmValues =
  (caseId, fieldId, values, otherValues, type, multi, userRoleInCase, oldValues, otherChanged) =>
  async (dispatch, getState) => {
    try {
      // @todo: undo on error
      const state = getState();
      const { case: kase } = state.data.cases.openCase;
      const { attendingValues, id, state: caseState } = kase;
      await handleNotifyOnChanges(id, caseState, getValuesFromCase(kase));
      const parsedValues = getValueFromModel(
        Array.isArray(values) ? values : [values],
        type,
        multi
      );
      const newAttedValues = {
        caseFields: { ...attendingValues.caseFields },
        pmmFields: [...attendingValues.pmmFields],
        metaFields: { ...attendingValues.metaFields }
      };
      const pmmValues = [
        {
          caseId,
          fieldId,
          values: multi ? parseToTextArray(parsedValues) : parsedValues,
          otherValues: multi ? parseToTextArray(otherValues) : otherValues
        }
      ];
      let op = otherChanged ? 'other' : '';
      let changedValue;
      const changedFieldId = otherChanged ? `${fieldId}Other` : fieldId;
      const isMulti = Array.isArray(oldValues);

      if (!otherChanged && isMulti && type !== 'text') {
        const removedValues = oldValues
          .filter(oldValue => !values.some(value => value.value === oldValue.value))
          .map(v => v.value);
        const addedValues = values
          .filter(value => !oldValues.some(oldValue => oldValue.value === value.value))
          .map(v => v.value);

        if (addedValues.length === 1 || removedValues.length === 1) {
          if (addedValues.length > 0) {
            op = 'added';
            [changedValue] = addedValues;
          } else if (removedValues.length > 0) {
            op = 'removed';
            [changedValue] = removedValues;
          }
        }
      } else if (otherChanged) {
        [changedValue] = otherValues;
      } else if (type === 'text') {
        changedValue = values;
      } else if (!isMulti) {
        if (values) {
          op = 'added';
          changedValue = values.value;
        } else {
          op = 'removed';
          changedValue = oldValues.value;
        }
      }

      const pmmFieldIdx = newAttedValues.pmmFields.findIndex(pf => pf.id === changedFieldId);
      const pmmField = pmmFieldIdx > -1 ? newAttedValues.pmmFields[pmmFieldIdx] : false;
      const idx =
        pmmFieldIdx > -1 && !otherChanged && Array.isArray(oldValues)
          ? pmmField.values.findIndex(v => v.value === changedValue)
          : -1;
      if (pmmFieldIdx > -1) {
        newAttedValues.pmmFields.splice(pmmFieldIdx, 1);
      }
      let newPmmField;
      if (!pmmField) {
        newPmmField = { id: changedFieldId, values: [] };
      } else {
        newPmmField = { id: pmmField.id, values: [...pmmField.values] };
      }
      if (userRoleInCase === USER_CASE_ROLES.ATTENDING) {
        if (idx > -1) {
          newPmmField.values.splice(idx, 1);
        }
        if (isMulti && type !== 'text' && !otherChanged) {
          newPmmField.values.push({
            value: changedValue,
            op
          });
        } else {
          newPmmField.values = [
            {
              value: changedValue,
              op
            }
          ];
        }
        newAttedValues.pmmFields.push(newPmmField);
      } else if (userRoleInCase === USER_CASE_ROLES.RESIDENT && op !== '') {
        if (idx > -1) {
          newPmmField.values.splice(idx, 1);
          newAttedValues.pmmFields.push(newPmmField);
        }
      }

      apiService.updatePmmValues(caseId, fieldId, pmmValues, newAttedValues);
      dispatch(updateAttendingValues({ id: caseId, attendingValues: newAttedValues }));
    } catch (err) {
      console.log(err);
      toast.error(err.message);
      throw err;
    }
  };

export const updateComment = (caseId, id, comment) => async (dispatch, getState) => {
  try {
    // @todo: undo on error
    apiService.editComment(id, comment);
    dispatch(setComment({ id, comment }));
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const updateDescription = (caseId, description) => async (dispatch, getState) => {
  try {
    dispatch(setCaseValues({ caseId, values: { description } }));
    dispatch(updateOpenCaseDescription({ description }));
    await apiService.updateCaseValues(caseId, {
      description
    });
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const updateComments = (caseId, comments) => (dispatch, getState) => {
  dispatch(setComments({ caseId, comments }));
};

export const createPlanFeedback =
  (
    caseId,
    addiInfoSelection,
    text,
    rating,
    howToImproveSelection,
    howToImproveText,
    whatWentWellSelection,
    whatWentWellText
  ) =>
  async (dispatch, getState) => {
    try {
      // @todo: undo on error
      const planFeedback = await apiService.createPlanFeedback(
        caseId,
        addiInfoSelection,
        text,
        rating,
        howToImproveSelection,
        howToImproveText,
        whatWentWellSelection,
        whatWentWellText
      );
      dispatch(addPlanFeedback(planFeedback));
    } catch (err) {
      console.log(err);
      toast.error(err.message);
      throw err;
    }
  };
export const updatePlanFeedback =
  (
    id,
    addiInfoSelection,
    text,
    rating,
    howToImproveSelection,
    howToImproveText,
    whatWentWellSelection,
    whatWentWellText
  ) =>
  async (dispatch, getState) => {
    try {
      // @todo: undo on error
      apiService.editPlanFeedback(
        id,
        addiInfoSelection,
        text,
        rating,
        howToImproveSelection,
        howToImproveText,
        whatWentWellSelection,
        whatWentWellText
      );
      dispatch(
        setPlanFeedback({
          id,
          addiInfoSelection,
          text,
          rating,
          howToImproveSelection,
          howToImproveText,
          whatWentWellSelection,
          whatWentWellText
        })
      );
    } catch (err) {
      console.log(err);
      toast.error(err.message);
      throw err;
    }
  };

const dbgGetPrevState = curState =>
  CASES_STATE_ORDER.findIndex(item => item === curState) - 1 > 0
    ? CASES_STATE_ORDER[CASES_STATE_ORDER.findIndex(item => item === curState) - 1]
    : CASES_STATE_ORDER[CASES_STATE_ORDER.length - 1];

const getNextState = curState =>
  CASES_STATE_ORDER.findIndex(item => item === curState) + 1 < CASES_STATE_ORDER.length
    ? CASES_STATE_ORDER[CASES_STATE_ORDER.findIndex(item => item === curState) + 1]
    : CASES_STATE_ORDER[0];

export const dbgMoveToPrevState = kase => async (dispatch, getState) => {
  try {
    const nextState = dbgGetPrevState(kase.state);

    const updatedCase = await apiService.updateCaseState(kase.id, nextState);
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const moveToNextState = (kase, userRoleInCase) => async (dispatch, getState) => {
  try {
    let nextState;
    if (userRoleInCase === USER_CASE_ROLES.ATTENDING && kase.state === CASE_STATE.IN_BRIEF) {
      nextState = CASE_STATE.READY;
    } else if (
      userRoleInCase === USER_CASE_ROLES.RESIDENT &&
      kase.state === CASE_STATE.BRIEF_IN_REVIEW
    ) {
      nextState = CASE_STATE.BRIEF_IN_REVIEW;
    } else if (
      userRoleInCase === USER_CASE_ROLES.ATTENDING &&
      kase.state === CASE_STATE.IN_DEBRIEF
    ) {
      nextState = CASE_STATE.COMPLETED;
    } else {
      nextState = getNextState(kase.state);
    }

    const updatedCase = await apiService.updateCaseState(kase.id, nextState);
    updatedCase.updateCasesByPk.resident = updatedCase.updateCasesByPk.residentId
      ? apiService.getUserFromId(updatedCase.updateCasesByPk.residentId)
      : null;
    updatedCase.updateCasesByPk.attending = updatedCase.updateCasesByPk.attendingId
      ? apiService.getUserFromId(updatedCase.updateCasesByPk.attendingId)
      : null;
    updatedCase.updateCasesByPk.site = {
      name: apiService.getSiteFromId(updatedCase.updateCasesByPk.siteId)
    };

    amplService.sendCaseEvent(amplService.EVENTS.NEW_CASE_STATE, updatedCase.updateCasesByPk, {
      prevState: kase.state,
      trigger: 'user'
    });
    return dispatch(updateItem(updatedCase.updateCasesByPk));
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

export const submitCareTeamBriefFormAttendingValues =
  (caseId, pid, rememberPreferences) => async (dispatch, getState) => {
    const valuesMap = {};
    if (rememberPreferences === REMEMBER_PREFERENCE_OPTIONS.YES) {
      const rawValues = await apiService.getCaseValues(caseId);
      for (const v of rawValues) {
        valuesMap[v.fieldId] = { values: v.values, otherValues: v.otherValues };
      }
    }

    return apiService.submitCareTeamDefaults(
      pid,
      valuesMap,
      FORM_STAGE.BRIEF,
      USER_CASE_ROLES.ATTENDING,
      rememberPreferences === REMEMBER_PREFERENCE_OPTIONS.NEVER
    );
  };

const initialState = {};

const newCaseSlice = createSlice({
  name: 'newCase',
  initialState,
  reducers: {}
});

export default newCaseSlice.reducer;
