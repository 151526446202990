import React from 'react';
import ConfirmationDialog from 'app/components/confirmDialog/ConfirmDialog';
import { useTranslation, Trans } from 'react-i18next';

function DescriptionMismatchDialog(props) {
  const { t } = useTranslation();
  const { description, input } = props;

  if (!input) {
    return null;
  }

  const message = 'descriptionMismatchMsg';
  const re = new RegExp(`${input.match[0]}[\\s]?[,]?`);
  const newDescriptionForSaving = description.replace(re, '');
  const newDescriptionForDialog = description.replace(re, `<0>${input.match[0]}</0>`);

  return (
    <ConfirmationDialog
      open={props.open}
      onClose={ok => {
        props.onClose(ok, newDescriptionForSaving);
      }}
      title={t('descriptionMismatchTitle')}
      message={t(message, {
        old: description.replace(input.match[0], `<strong>${input.match[0]}</strong>`),
        term: input.match[0],
        new: newDescriptionForDialog
      })}
      center
      ok={t('descriptionMismatchButtonOk')}
      cancel={t('No')}
      actionsProps={{ className: 'justify-between' }}
    />
  );
}

export default DescriptionMismatchDialog;
