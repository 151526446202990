import NoSleep from 'nosleep.js';

const noSleep = new NoSleep();

const enable = async () => {
  if (isEnabled()) {
    console.log('no sleep already enabled');
    return;
  }
  try {
    console.log('enabling no sleep');
    await noSleep.enable();
  } catch (err) {
    console.log('cannot enable no sleep');
    console.log(err);
  }
};

const disable = () => {
  if (isEnabled()) {
    noSleep.disable();
  }
};

const isEnabled = () => {
  return noSleep.isEnabled;
};

export default {
  enable,
  disable,
  isEnabled
};
