import React, { Fragment } from 'react';
import i18n from 'app/services/i18n';
import Icon from '@mui/material/Icon';

const menuConfig = {
  cases: [
    {
      label: i18n.t('MY CASES'),
      icon: (
        <Fragment>
          <Icon>play_arrow</Icon>
        </Fragment>
      ),
      url: '/cases/my-cases'
    },
    {
      label: i18n.t('OR BOARD'),
      icon: (
        <Fragment>
          <Icon>assignment</Icon>
        </Fragment>
      ),
      url: '/cases/or-board'
    },
    {
      label: i18n.t('INSIGHTS'),
      icon: <Icon>dashboard</Icon>,
      url: '/cases/dash'
    }
  ],
  schedule: [
    {
      label: i18n.t('PENDING'),
      icon: <Icon>schedule</Icon>,
      url: '/schedule/plan'
    },
    {
      label: i18n.t('PUBLISHED'),
      icon: <Icon>menu</Icon>,
      url: '/schedule/published'
    }
  ],
  analytics: [
    {
      label: i18n.t('ANALYTICS'),
      icon: <Icon>analytics</Icon>,
      url: '/analytics/dash'
    },
    {
      label: i18n.t('REPORT'),
      icon: <Icon>data_usage</Icon>,
      url: '/analytics/data',
      dev: 'true'
    }
  ]
};

export default menuConfig;
