import React from 'react';
import _ from '@lodash';
import clsx from 'clsx';
import { lightBlue, grey } from '@mui/material/colors';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import Icon from '@mui/material/Icon';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { CASE_STATE, CASE_STATES } from 'app/consts';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    paddingBottom: theme.spacing(0.5),
    marginBottom: '6rem'
  },
  groupHeader: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    fontSize: '1.4rem',
    lineHeight: '2',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
    fontWeight: 800
  },
  avatar: {
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    marginRight: theme.spacing(1.5),
    color: grey[800],
    backgroundColor: lightBlue[50]
  },
  alert: {
    backgroundColor: theme.palette.background.paper
  },
  message: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    maxWidth: '550px',
    margin: 'auto',
    justifyContent: 'space-around'
  }
}));

function EmptyPastCasesList(props) {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const loaded = useSelector(({ data }) => data.cases.loaded);

  const shouldRender = loaded && props.casesCount === 0;

  const group = CASE_STATE.ARCHIVED;

  return shouldRender ? (
    <List className={classes.root} subheader={<li />}>
      <ListSubheader className={classes.groupHeader}>
        <Avatar
          title={_.startCase(group)}
          className={clsx(
            classes.avatar,
            CASE_STATES[group].color && classes[CASE_STATES[group].color]
          )}
        >
          <Icon>{CASE_STATES[group].icon}</Icon>
        </Avatar>
        {t(CASE_STATES[group].label)}
        {/* TODO temporary hiding the export button till implementing */}
        {false && (
          <Button
            // Export
            title={t('Export')}
            className="ml-auto"
            size="small"
            variant="outlined"
            disabled
          >
            {t('Export')}
          </Button>
        )}
      </ListSubheader>
      <Alert
        severity="info"
        icon={false}
        classes={{
          root: classes.alert,
          message: classes.message
        }}
      >
        <Typography className="sm:text-xl sm:font-light">
          <Trans i18nKey="empty_past_cases" />
        </Typography>
        <img
          className="w-96 sm:w-128 ml-16 sm:ml-32"
          src="assets/images/emptyCasesList/emptyPastCasesList.svg"
          alt=""
        />
      </Alert>
    </List>
  ) : null;
}

export default EmptyPastCasesList;
