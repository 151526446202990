import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    paddingTop: '100px !important',
    '& .MuiAlert-message': {
      display: 'flex'
    },
    '& a': {
      textDecoration: 'underline'
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '500px',
    maxWidth: '250px'
  },
  message: {
    margin: '0 auto'
  }
}));

const ErrorPage = props => {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const basePath = window.location.origin;

  return (
    <Alert
      severity="info"
      icon={false}
      classes={{
        root: classes.root
      }}
    >
      <div className={classes.content}>
        <Typography>
          <div style={{ fontSize: 22 }}>
            <Trans i18nKey="error_top" />
          </div>
        </Typography>
        <Typography>
          <div style={{ fontSize: 16, marginTop: 20 }}>
            <Trans
              i18nKey="error_back"
              components={{
                or_board: <a style={{ fontSize: 22 }} href={`${basePath}/cases/or-board`} /> // eslint-disable-line
              }}
            />
          </div>
        </Typography>
        <img
          style={{ height: '240px' }}
          className="my-auto mx-auto"
          src="assets/images/icons/pretzel.svg"
          alt=""
        />
        <Typography>
          <div style={{ fontSize: 16 }}>
            <Trans
              i18nKey="error_bottom"
              components={{ a: <a href={t('contact_us_email')} /> }} // eslint-disable-line
            />
          </div>
        </Typography>
      </div>
    </Alert>
  );
};

export default ErrorPage;
