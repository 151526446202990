import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FusePageSimple from '@fuse/core/FusePageSimple';
import MomentAdapter from '@date-io/moment';
import OrBoardCasesList from 'app/components/orBoard/OrBoardCasesList';
import Dashboard from 'app/components/dashboard/Dashboard';
import CasesStats from 'app/components/casesStats/CasesStats';
import { useSelector, useDispatch } from 'react-redux';
import {
  loadMyCases,
  loadOrCases,
  handleSetOpenCase,
  loadCase,
  resetItems,
  deleteCase,
  resetPastCasesLimit
} from 'app/store/data/casesSlice';
import { loadMyDashboardData, myDashboardDataSelector } from 'app/store/data/dashboardSlice';
import { getNextCases, isCaseInNextCases } from 'app/pages/cases/helper';
import urlParams from 'app/services/urlParams';
import usePastCases from 'app/pages/cases/hooks/usePastCases';
import useShare from 'app/pages/cases/hooks/useShare';
import { isToday, isTomorrow } from 'app/components/caseViews/timeHelper';
import MyCases from './casesComponents/MyCases';
import CasesDialogs from './casesComponents/CasesDialogs';

function CasesPage() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const cases = useSelector(({ data }) => data.cases.items);
  const dashboardData = useSelector(myDashboardDataSelector);
  const [tab, setTab] = useState('');

  const [caseSubmittedComplete, setCaseSubmittedComplete] = useState(false);
  const [nextCases, setNextCases] = useState([]);
  const [nextCaseIndex, setNextCaseIndex] = useState(0);
  const [totalNextCases, setTotalNextCases] = useState(0);
  const [shouldMoveToNextCase, setShouldMoveToNextCase] = useState(false);
  const [showNextCase, setShowNextCase] = useState(false);
  const [showNextCaseMessage, setShowNextCaseMessage] = useState('');
  const calculatedDisplayIds = useSelector(({ data }) => data.cases.calculatedDisplayIds);

  const onAfterShare = () => {
    setCaseSubmittedComplete(true);
  };
  const { shareCaseFiles, handleShare, onShareDialogClose, showFilesDialog } = useShare({
    onAfterShare
  });
  const openCase = useSelector(({ data }) => data.cases.openCase);
  const { loadInitPastCases } = usePastCases();
  const [casesLoaded, setCasesLoaded] = useState(false);

  const curCaseId = openCase?.case?.id;

  const getNextCaseTitle = c => {
    let title = calculatedDisplayIds[c.id] || c.displayId || c.procedureTitle;
    title = title ? `"${title}"` : 'case';

    const date = new Date(c.caseDate);
    let str;
    if (isToday(date)) {
      str = "See tha plan for today's {{ proc }}";
    } else if (isTomorrow(date)) {
      str = "See tha plan for tomorrow's {{ proc }}";
    } else {
      str = 'See tha plan for the upcoming {{ proc }}';
    }
    return t(str, {
      proc: title
    });
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!openCase?.nextMode && (!curCaseId || !isCaseInNextCases(user, openCase.case))) {
      setNextCases([]);
      setNextCaseIndex(0);
      setTotalNextCases(0);
    } else if (!nextCases.length) {
      const nc = getNextCases(cases, user, openCase.case.id);
      setNextCases(nc);
      setTotalNextCases(nc.length);
    }
  }, [cases, curCaseId, setNextCases, setNextCaseIndex]);
  const moveToNextCase = useCallback(() => {
    if (nextCaseIndex === -1) {
      return;
    }
    if (nextCaseIndex >= nextCases.length) {
      closeCaseDialog();
      return;
    }
    setNextCaseIndex(nextCaseIndex + 1);
    closeCaseDialog(false, true);
    loadCaseById(nextCases[nextCaseIndex].id);
  }, [nextCaseIndex, nextCases]);
  useEffect(() => {
    if (caseSubmittedComplete) {
      setCaseSubmittedComplete(false);
      if (shouldMoveToNextCase) {
        setShouldMoveToNextCase(false);
        if (nextCaseIndex === -1) {
          return;
        }
        if (nextCaseIndex >= nextCases.length) {
          closeCaseDialog();
          return;
        }

        setShowNextCaseMessage(getNextCaseTitle(nextCases[nextCaseIndex]));
        setShowNextCase(true);
      }
    }
  }, [caseSubmittedComplete, setCaseSubmittedComplete]);

  const newCase = () => {
    dispatch(resetPastCasesLimit());
    dispatch(handleSetOpenCase({ case: 'new', editCaseAttributes: true }));
  };

  const closeCaseDialog = async (reload = false, nextMode = false) => {
    urlParams.set('case', undefined, { scrollToTop: false });

    dispatch(handleSetOpenCase({ case: false, editCaseAttributes: false, nextMode }));

    if (reload === true) {
      if (tab === 'my-cases') {
        getCases();
      } else {
        getOrCases();
      }
    }
    if (tab === 'my-cases') {
      loadInitPastCases();
    }
  };

  const loadCaseById = (id, editCaseAttributes) => {
    dispatch(resetPastCasesLimit());
    return dispatch(loadCase(id, user.data.id, editCaseAttributes));
  };

  const getMyDashboardData = useCallback(() => {
    dispatch(loadMyDashboardData());
  }, [dispatch]);

  const getCases = useCallback(() => {
    setCasesLoaded(false);
    const handleLoad = async () => {
      await dispatch(loadMyCases());
      setCasesLoaded(true);
    };
    handleLoad();
  }, []);

  const getOrCases = useCallback(() => {
    dispatch(loadOrCases());
  }, [dispatch]);

  const clearCases = useCallback(() => {
    dispatch(resetItems());
  }, [dispatch]);

  return (
    <FusePageSimple
      header={
        <div className="p-24 pl-40">
          <h2>{t('CASES')}</h2>
        </div>
      }
      content={
        <div className="md:p-24">
          <Switch>
            <Route exact path="/cases/my-cases">
              <CasesStats cases={cases} />
              <MyCases
                loadCaseById={loadCaseById}
                newCase={newCase}
                handleShare={handleShare}
                getCases={getCases}
                clearCases={clearCases}
                setTab={setTab}
                casesLoaded={casesLoaded}
              />
            </Route>
            <Route exact path="/cases/or-board">
              <OrBoardCasesList
                // cases filtered by status
                cases={cases}
                loadCaseById={loadCaseById}
                newCase={newCase}
                handleDelete={async kase => {
                  // ToDo: this will only work for admins
                  await dispatch(deleteCase(kase));
                  dispatch(loadOrCases());
                }}
                loadCases={getOrCases}
                tab="or-board"
                clearCases={clearCases}
                handleShare={handleShare}
                setTab={setTab}
              />
            </Route>
            <Route exact path="/cases/dash">
              <Dashboard getDashboardData={getMyDashboardData} data={dashboardData} />
            </Route>
          </Switch>
          <CasesDialogs
            nextCases={nextCases}
            nextCaseIndex={nextCaseIndex}
            totalNextCases={totalNextCases}
            loadCaseById={loadCaseById}
            closeCaseDialog={closeCaseDialog}
            handleShare={handleShare}
            tab={tab}
            setShouldMoveToNextCase={setShouldMoveToNextCase}
            setCaseSubmittedComplete={setCaseSubmittedComplete}
            onShareDialogClose={onShareDialogClose}
            showFilesDialog={showFilesDialog}
            shareCaseFiles={shareCaseFiles}
            showNextCase={showNextCase}
            setShowNextCase={setShowNextCase}
            moveToNextCase={moveToNextCase}
            showNextCaseMessage={showNextCaseMessage}
          />
        </div>
      }
    />
  );
}

export default CasesPage;
