import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import toast from 'app/services/toastService/toast';
import { grey } from '@mui/material/colors';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: '40px 30px 20px 30px',
    position: 'relative'
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '26px'
  },
  closeButton: {
    position: 'absolute',
    right: 4,
    top: 4
  },
  content: {
    border: '1px solid red',
    borderColor: grey[200],
    borderRadius: '4px',
    position: 'relative',
    padding: '14px 24px 10px 10px',
    fontSize: '16px'
  },
  copyButton: {
    height: '30px',
    width: '30px',
    backgroundColor: '#fff',
    position: 'absolute',
    border: '1px solid',
    borderColor: grey[300],
    right: -12,
    top: -18,
    '&:hover': {
      backgroundColor: grey[200]
    }
  }
}));

export default function ResidentPublishPopup(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { config, setConfig } = props;

  const handleClose = () => {
    setConfig({
      ...config,
      open: false
    });
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(config.content);
    toast.success(t('plan_copied'));
  };

  return (
    <Dialog open={config.open} onClose={handleClose}>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.title}>{`${config.date} ${t('Assignment')}`}</div>
        <IconButton
          className={classes.closeButton}
          onClick={() => {
            handleClose();
          }}
          size="small"
        >
          <CloseIcon style={{ fontSize: '20px' }} />
        </IconButton>
        <div className={classes.content}>
          <div style={{ whiteSpace: 'pre-wrap' }}>{config.content}</div>
        </div>
        <Button
          title={t('copy_to_clipboard')}
          aria-label={t('copy_to_clipboard')}
          onClick={() => {
            handleCopyToClipboard();
          }}
          className="flex mt-20 ml-auto"
          variant="outlined"
          size="small"
          // align button to center
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
        >
          <ContentCopyIcon style={{ fontSize: '18px' }} />
          <Typography variant="inherit" className="ml-6">
            {t('copy_to_clipboard')}
          </Typography>
        </Button>
      </DialogContent>
    </Dialog>
  );
}
