import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from '@lodash';
import Autocomplete from '@mui/material/Autocomplete';
import makeStyles from '@mui/styles/makeStyles';
import { withFormsy } from 'formsy-react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import MuiSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import ChangedBy from 'app/components/userAvatar/ChangedByAvatar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Trans } from 'react-i18next';
import { grey } from '@mui/material/colors';
import FuseAnimate from '@fuse/core/FuseAnimate';

Select.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array
};

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiInputLabel-animated': {
      pointerEvents: 'none'
    }
  },
  // Highlight field similar to a selected Chip
  chipLike: {
    '& .MuiInput-formControl': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: theme.shadows[2],
      borderRadius: theme.shape.borderRadius,
      padding: '3px 0 1px 16px',
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: theme.palette.primary.main
        }
      },
      '&.MuiInput-underline:after, &.MuiInput-underline:before': {
        display: 'none'
      },
      '& .MuiSelect-icon': {
        color: '#ffffffcc'
      }
    }
  },
  procSuggestionsLabels: {
    fontSize: '14px',
    marginBottom: '16px'
  }
}));

function Select(props) {
  const classes = useStyles();
  const importedAutoCompleteProps = _.pick(props, autoCompletePropList);
  const importedSelectProps = _.pick(props, selectPropList);
  const [filteredOptions, setFilteredOptions] = useState(props.options || []);
  const [inputValue, setInputValue] = useState('');

  const { errorMessage } = props;

  // Show auto-complete text filter if
  // - option list is long (>15) AND `withFilter` is not false
  // - props.withFilter is true
  const enableAutocomplete =
    (props.options.length > 4 && props.withFilter !== false) || props.withFilter === true;

  const filterOptions = () => {
    setFilteredOptions(
      props.filterOptions && props.formRef?.current
        ? props.filterOptions(props.options, props.formRef)
        : props.options
    );
  };

  // Initial filter
  useEffect(filterOptions, [props.options]);

  const changeValue = (event, newValue, reason) => {
    if (props.isFormDisabled) return;
    if (props.setValue) {
      if (props.confirmValue) {
        // Allow parent comp to hook-in before setting value
        props.confirmValue(newValue, props.setValue);
      } else {
        props.setValue(newValue);
      }
    }
    if (props.onChange) {
      props.onChange(newValue, props.formRef, event, reason);
    }
  };

  const getOptionLabel = option => {
    return props.getOptionLabel ? props.getOptionLabel(option) : option.label;
  };

  const renderSuggestions = () => {
    return (
      <div>
        <InputLabel style={{ position: 'static', marginBottom: 6 }}>{props.label}</InputLabel>
        {props.optionsSuggestions.slice(0, 5).map(option => {
          return (
            <FuseAnimate delay="60" key={option.value}>
              <Button
                className="flex justify-start text-12 text-left mb-10"
                variant="outlined"
                color="primary"
                onClick={event => {
                  changeValue(event, option);
                }}
              >
                {option.name}
              </Button>
            </FuseAnimate>
          );
        })}
      </div>
    );
  };

  const chipLike =
    props.chipLike && props.value && props.value?.value?.toLowerCase() !== 'other'
      ? classes.chipLike
      : '';

  const renderInput = params => (
    <Fragment>
      {inputValue === '' && props.optionsSuggestions?.length > 0 && renderSuggestions()}
      <TextField
        {...params}
        onChange={e => setInputValue(e.target.value)}
        value={inputValue}
        placeholder={props.placeholder}
        style={props.fieldStyle}
        error={Boolean(props.errorMessage)}
        label={props.optionsSuggestions?.length > 0 ? null : props.label}
        required={props.required}
        InputProps={{
          ...params.InputProps,
          disableUnderline: props.disableUnderline || false
        }}
      />
    </Fragment>
  );

  return (
    <FormControl
      className={clsx(classes.root, props.className, chipLike)}
      error={Boolean((!props.isPristine && props.showRequired) || errorMessage)}
      required={props.required}
    >
      {props.changedBy && !props.isFormDisabled && <ChangedBy user={props.changedBy} />}

      {enableAutocomplete ? (
        <Autocomplete
          {...importedAutoCompleteProps}
          value={props.value || (props.multiple ? [] : null)}
          options={filteredOptions}
          onChange={changeValue}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionLabel={getOptionLabel}
          noOptionsText={props.noOptionsText}
          filterOptions={props.filterOptions}
          popupIcon={props.popupIcon || undefined}
          componentsProps={{
            paper: {
              sx: props.popperStyle
            }
          }}
          renderInput={renderInput}
        />
      ) : (
        <Fragment>
          <InputLabel disabled={props.disabled}>{props.label}</InputLabel>
          <MuiSelect
            {...importedSelectProps}
            value={props.value ? getOptionLabel(props.value) : ''}
            onChange={(e, child) => {
              changeValue(e, child.props.option);
            }}
            onOpen={filterOptions}
            label={props.label}
          >
            {filteredOptions.map(opt => (
              <MenuItem key={getOptionLabel(opt)} value={getOptionLabel(opt)} option={opt}>
                {getOptionLabel(opt)}
              </MenuItem>
            ))}
          </MuiSelect>
        </Fragment>
      )}
      {Boolean(props.errorMessage) && <FormHelperText>{props.errorMessage}</FormHelperText>}
    </FormControl>
  );
}

const autoCompletePropList = [
  'autoComplete',
  'autoHighlight',
  'autoSelect',
  'blurOnSelect',
  'ChipProps',
  'clearOnBlur',
  'clearOnEscape',
  'clearText',
  'closeIcon',
  'closeText',
  'debug',
  'defaultValue',
  'disableClearable',
  'disableCloseOnSelect',
  'disabled',
  'disabledItemsFocusable',
  'disableListWrap',
  'disablePortal',
  'filterSelectedOptions',
  'forcePopupIcon',
  'freeSolo',
  'fullWidth',
  'getLimitTagsText',
  'getOptionDisabled',
  'getOptionLabel',
  'getOptionSelected',
  'groupBy',
  'handleHomeEndKeys',
  'id',
  'includeInputInList',
  'inputValue',
  'limitTags',
  'ListboxComponent',
  'ListboxProps',
  'loading',
  'loadingText',
  'multiple',
  'noOptionsText',
  'onChange',
  'onClose',
  'onHighlightChange',
  'onInputChange',
  'onOpen',
  'open',
  'openOnFocus',
  'openText',
  'options',
  'PaperComponent',
  'PopperComponent',
  'popupIcon',
  'renderGroup',
  'renderInput',
  'renderOption',
  'renderTags',
  'selectOnFocus',
  'size',
  'value'
];

const selectPropList = [
  'autoWidth',
  'children',
  'classes',
  'defaultValue',
  'disabled',
  'displayEmpty',
  'IconComponent',
  'id',
  'input',
  'inputProps',
  'label',
  'labelId',
  'labelWidth',
  'MenuProps',
  'multiple',
  'native',
  'onChange',
  'onClose',
  'onOpen',
  'open',
  'renderValue',
  'SelectDisplayProps',
  'value',
  'variant'
];

export const BaseSelect = React.memo(Select);

export default React.memo(withFormsy(Select));
