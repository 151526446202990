import { loadPastCases } from 'app/store/data/casesSlice';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const pastCasesLimitIncrement = 20;
const LOAD_MORE_STATES = {
  PENDING: 'pending',
  NOT_PENDING: 'not_pending'
};
const usePastCases = () => {
  const dispatch = useDispatch();
  const pastCases = useSelector(({ data }) => data.cases.pastCases);
  const pastCasesLimit = useSelector(({ data }) => data.cases.pastCasesLimit);
  const pastCasesLimitInitValue = useSelector(({ data }) => data.cases.pastCasesLimitInitValue);
  const [pastCasesLoaded, setPastCasesLoaded] = useState(false);
  const loadMoreState = useRef(LOAD_MORE_STATES.NOT_PENDING);
  const loadInitPastCases = () => {
    return dispatch(loadPastCases(0, pastCasesLimitInitValue));
  };

  const handleFirstLoad = async () => {
    await loadInitPastCases();
    setPastCasesLoaded(true);
  };

  const [canLoadMore, setCanLoadMore] = useState(true);
  const enableLoadMore = () => {
    loadMoreState.current = LOAD_MORE_STATES.NOT_PENDING;
    setCanLoadMore(true);
  };
  const loadMorePastCases = () => {
    dispatch(loadPastCases(pastCases.length, pastCasesLimitIncrement));
    loadMoreState.current = LOAD_MORE_STATES.PENDING;
    setTimeout(() => {
      if (loadMoreState.current === LOAD_MORE_STATES.PENDING) {
        setCanLoadMore(false);
      }
    }, 2000);
  };
  const slicedCases = pastCases.slice(0, pastCasesLimit);
  const totalCases = pastCases.length;
  return {
    loadInitPastCases,
    pastCasesLimitIncrement,
    loadMorePastCases,
    pastCasesLoaded,
    slicedCases,
    totalCases,
    canLoadMore,
    enableLoadMore,
    handleFirstLoad
  };
};

export default usePastCases;
