import React from 'react';
import i18n from 'app/services/i18n';
import {
  CASE_STATE,
  caseStateDebriefPlus,
  PATIENT_GENDER,
  PATIENT_TYPE,
  RESIDENT_ROLE,
  TRACKABLE_GOAL_RESULT,
  USER_CASE_ROLES
} from 'app/consts';
import { getBasicFieldsChangedBy, getDebounceUpdate } from './shared';
// eslint-disable-next-line import/prefer-default-export
export const getDebriefFields = (
  kase,
  user,
  handleCaseValueUpdate,
  userRoleInCase,
  attendingFieldValues,
  handleCreateCaseAsset,
  handleUpdateCaseAsset,
  handleSubmitCaseAsset,
  handleDeleteCaseAsset,
  getUploadcareSignature,
  formCaseState
) => {
  const {
    id,
    result,
    resultOther,
    residentRole,
    patientType,
    patientGender,
    assets,
    // intraopComplications,
    // intraopComplicationsDetails,
    // summary,
    feedback,
    learned,
    trackableGoalResult
  } = kase;
  const trackableGoalResultOptions = [
    { label: i18n.t('Unmet'), value: TRACKABLE_GOAL_RESULT.NOT_MET },
    { label: i18n.t('Partially'), value: TRACKABLE_GOAL_RESULT.PARTIALLY },
    { label: i18n.t('Met'), value: TRACKABLE_GOAL_RESULT.MET }
  ];
  const resultOptions = [
    { label: i18n.t('As planned'), value: 'planned' },
    { label: i18n.t('Add your own'), value: 'other' }
  ];
  const patientTypeOptions = [
    { label: i18n.t('Adult'), value: PATIENT_TYPE.ADULT },
    { label: i18n.t('Pediatric'), value: PATIENT_TYPE.PEDIATRIC }
  ];
  const patientGenderOptions = [
    { label: i18n.t('Male'), value: PATIENT_GENDER.MALE },
    { label: i18n.t('Female'), value: PATIENT_GENDER.FEMALE }
  ];
  const residentRoleOptions = [
    { label: i18n.t('Assistant'), value: RESIDENT_ROLE.ASSISTANT },
    { label: i18n.t('Senior'), value: RESIDENT_ROLE.SENIOR },
    { label: i18n.t('Lead'), value: RESIDENT_ROLE.LEAD }
  ];

  const debounceFeedBackUpdate = getDebounceUpdate('feedback', handleCaseValueUpdate);

  // const debounceSummaryUpdate = getDebounceUpdate('summary', handleCaseValueUpdate);
  const debounceResultOtherUpdate = getDebounceUpdate('resultOther', handleCaseValueUpdate);
  // const debounceIntraopComplicationsDetailsUpdate = getDebounceUpdate(
  //   'intraopComplicationsDetails',
  //   handleCaseValueUpdate
  // );
  const debounceLearnedUpdate = getDebounceUpdate('learned', handleCaseValueUpdate);

  const fields = [];

  // Removing 'result' field for now
  if (false) {
    fields.push({
      name: 'result',
      label: i18n.t('Result'),
      type: 'select',
      changedBy: getBasicFieldsChangedBy('result', kase, attendingFieldValues),
      options: resultOptions,
      value: result ? resultOptions.find(opt => opt.value === result) : '',
      hasOther: true,
      onChange: (newValue, formRef) => {
        handleCaseValueUpdate('result', newValue, 'select');
        if (newValue.value !== 'other') {
          const resultOtherInput = formRef.current.inputs.find(
            input => input.props.name === 'resultOther'
          );
          if (resultOtherInput && resultOtherInput.getValue() !== '') {
            resultOtherInput.setValue('');
            debounceResultOtherUpdate('');
          }
        }
      },
      otherFieldProps: {
        value: resultOther,
        onChange: debounceResultOtherUpdate,
        changedBy: getBasicFieldsChangedBy('resultOther', kase, attendingFieldValues)
      }
    });
  }

  if (
    kase.resident &&
    ((userRoleInCase === USER_CASE_ROLES.RESIDENT && feedback && feedback !== '') ||
      userRoleInCase === USER_CASE_ROLES.ATTENDING)
  ) {
    fields.push({
      name: 'feedback',
      label:
        userRoleInCase === USER_CASE_ROLES.RESIDENT
          ? i18n.t('feedback_from_att', { attending: kase.attending.nickName })
          : i18n.t('your_feedback_to_res', { resident: kase.resident.nickName }),
      placeholder: i18n.t('feedback_hint'),
      changedBy: getBasicFieldsChangedBy('feedback', kase, attendingFieldValues),
      type: 'text',
      value: feedback,
      minRows: 2,
      multiline: true,
      onChange: debounceFeedBackUpdate,
      disabled: userRoleInCase === USER_CASE_ROLES.RESIDENT
    });
  }

  fields.push({
    name: 'learned',
    changedBy: getBasicFieldsChangedBy('learned', kase, attendingFieldValues),
    label: i18n.t(
      formCaseState === CASE_STATE.ARCHIVED
        ? 'archived_leassons_learned_title'
        : 'debrief_leassons_learned_title'
    ),
    placeholder: i18n.t('debrief_leassons_learned_hint'),
    type: 'text',
    value: learned,
    multiline: true,
    onChange: debounceLearnedUpdate
  });

  if (userRoleInCase === USER_CASE_ROLES.RESIDENT && !user.isPa) {
    const collapsibleCaseLogHandler = formRef => {
      // Field will display if it has a value or if toggled
      const caseLogToggleInput = formRef.current.inputs.find(
        input => input.props.name === 'caseLogToggle'
      );
      return caseLogToggleInput && caseLogToggleInput.getValue() === true;
    };

    fields.push({
      name: 'caseLogToggle',
      type: 'checkbox',
      value: false,
      className: 'section-toggle',
      label: (
        <>
          <img
            className="w-40 ml-16 -mr-36 float-right"
            style={{ minWidth: '2rem' }}
            src="assets/images/icons/log.svg"
            alt=""
          />
          <span className="label">{i18n.t('Collect data for your case logs')}</span>
          <span className="sub-label font-bold mb-4">{i18n.t('debrief_case_logs_subtitle')}</span>
          <span className="sub-label expand">{i18n.t('Click to expand >')}</span>
          <span className="sub-label collapse">{i18n.t('Click to collapse')}</span>
        </>
      ),
      shouldRender: () => userRoleInCase === USER_CASE_ROLES.RESIDENT
    });

    if (caseStateDebriefPlus(formCaseState)) {
      const cptCodeString = parseCptCode(kase);

      if (
        cptCodeString &&
        kase.procedureData.cptCode !== `0` &&
        kase.procedureData.cptCode !== `1`
      ) {
        fields.push({
          name: 'cptCode',
          label: 'CPT code',
          type: 'info',
          variant: 'plain',
          value: cptCodeString,
          disabled: true,
          shouldRender: collapsibleCaseLogHandler
        });
      }
    }
    fields.push(
      {
        name: 'residentRole',
        label: i18n.t('Resident role'),
        type: 'select',
        options: residentRoleOptions,
        value: residentRole ? residentRoleOptions.find(opt => opt.value === residentRole) : '',
        onChange: newValue => {
          handleCaseValueUpdate('residentRole', newValue, 'select');
        },
        shouldRender: collapsibleCaseLogHandler
      },
      {
        name: 'patientType',
        label: i18n.t('Patient type'),
        type: 'radio',
        className: 'horizontal',
        options: patientTypeOptions,
        value: patientType ? patientTypeOptions.find(opt => opt.value === patientType) : '',
        onChange: newValue => {
          handleCaseValueUpdate('patientType', newValue, 'select');
        },
        shouldRender: collapsibleCaseLogHandler
      },
      {
        name: 'patientGender',
        label: i18n.t('Patient gender'),
        type: 'radio',
        className: 'horizontal',
        options: patientGenderOptions,
        value: patientGender ? patientGenderOptions.find(opt => opt.value === patientGender) : '',
        onChange: newValue => {
          handleCaseValueUpdate('patientGender', newValue, 'select');
        },
        shouldRender: collapsibleCaseLogHandler
      }
    );
  }

  return fields;
};

const parseCptCode = kase => {
  return (
    kase.procedureData.cptCode && (
      <div className="font-normal">
        {kase.procedureData.cptCode}
        {kase.procedureData.otherCptCode && (
          <div>
            {kase.procedureData.otherCptCode}
            {kase.procedureData.otherCptCodeComment && (
              <span> ({kase.procedureData.otherCptCodeComment})</span>
            )}
          </div>
        )}
      </div>
    )
  );
};
