import React, { useRef, useCallback, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import uploadcare from 'uploadcare-widget';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { BaseTextField as TextField } from 'app/components/forms/TextField';
import { Widget } from '@uploadcare/react-widget';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    '& .uploadcare--widget__button, & .uploadcare--widget__dragndrop-area, & .uploadcare--widget__text':
      {
        display: 'none'
      },
    '& .uploadcare--progress': {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      margin: 'auto',
      width: '6vw',
      height: '6vw'
    }
  },
  preview: {
    margin: '0 auto',
    height: '160px'
  },
  '@global': {
    '.uploadcare--preview__back': {
      display: 'none'
    }
  }
}));

const MAX_FILE_SIZE = process.env.REACT_APP_UPLOADCARE_MAX_FILE_SIZE
  ? process.env.REACT_APP_UPLOADCARE_MAX_FILE_SIZE
  : 25;

const checkMaxFileSize = size => {
  return fileInfo => {
    if (fileInfo.size !== null && fileInfo.size > size * 1024 * 1024) {
      throw new Error('fileMaximumSize');
    }
  };
};

const MediaUpload = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const uploadcareKey = useSelector(({ metaData }) => metaData.enum.uploadcareKey);
  const widgetRef = useRef();

  const [open, setOpen] = React.useState(false);

  const [asset, setAsset] = React.useState({
    file: {},
    desc: ''
  });

  const [token, setToken] = useState({
    signature: false,
    expire: false
  });

  const { getSignature } = props;
  const handleGetSignature = useCallback(async () => {
    const tokenData = await getSignature();
    setToken(tokenData);
    return tokenData;
  }, [getSignature]);

  useEffect(() => {
    let timer;
    const setGetSigntureInterval = async () => {
      const tokenData = await handleGetSignature();
      timer = setInterval(() => {
        setToken({
          signature: false,
          expire: false
        });
        handleGetSignature();
      }, tokenData.expire * 1000 - Date.now());
    };
    setGetSigntureInterval();
    return () => (timer ? clearInterval(timer) : () => {});
  }, [handleGetSignature]);

  const handleClose = () => {
    setAsset({
      file: {},
      desc: ''
    });
    setOpen(false);
  };

  const handleDesc = desc => {
    setAsset({
      ...asset,
      desc
    });
  };
  const handleUpload = async fileInfo => {
    const uploadedAsset = await props.handleUpload({
      ...asset,
      file: fileInfo
    });

    const file = uploadcare.fileFrom('uploaded', fileInfo.uuid, {
      // publicKey: 'demopublickey'
      publicKey: uploadcareKey,
      cdnBase: process.env.REACT_APP_UPLOAD_CARE_CDN,
      secureExpire: token.expire,
      secureSignature: token.signature,
      validators: [checkMaxFileSize(MAX_FILE_SIZE)],
      imagesOnly: true
    });

    const cropWidget = uploadcare.openDialog(file, 'preview', {
      crop: 'free, 16:9, 4:3, 5:4, 1:1',
      effects: 'crop,rotate',
      tabs: '',
      // publicKey: 'demopublickey'
      publicKey: uploadcareKey,
      cdnBase: process.env.REACT_APP_UPLOAD_CARE_CDN,
      previewUrlCallback: originalUrl => {
        return `${originalUrl}?token=${uploadedAsset.token}`;
      }
    });
    cropWidget.done(res => {
      res.done(async croppedFileInfo => {
        const cropAsset = await props.handleUpdate({
          id: uploadedAsset.id,
          ...asset,
          file: croppedFileInfo
        });
        setAsset({
          token: cropAsset.token,
          id: uploadedAsset.id,
          ...asset,
          file: croppedFileInfo
        });
        setOpen(true);
      });
    });
  };

  const handleSubmit = async () => {
    await props.handleSubmit(asset);
    handleClose();
  };

  // Wait for the key & token to be ready.
  // Also renders a new widget between uploads.
  const renderWidget = uploadcareKey && token.expire && !asset.file.uuid;
  return (
    <div className={classes.root}>
      <IconButton
        disabled={!token.signature || props.disabled}
        aria-label="add"
        onClick={() => widgetRef.current.openDialog(null)}
        className="hover:bg-transparent h-full"
        size="large"
      >
        <AddIcon />
      </IconButton>
      {renderWidget && (
        <Widget
          ref={widgetRef}
          imageShrink="1600x1600"
          imagesOnly
          onChange={handleUpload}
          systemDialog
          // publicKey="demopublickey"
          publicKey={uploadcareKey}
          cdnBase={process.env.REACT_APP_UPLOAD_CARE_CDN}
          secureExpire={token.expire}
          secureSignature={token.signature}
          validators={[checkMaxFileSize(MAX_FILE_SIZE)]}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{t('Add image to case')}</DialogTitle>
        <DialogContent>
          {asset.file && asset.file.cdnUrl && (
            <img
              className={classes.preview}
              src={`${asset.file.cdnUrl}-/resize/x160/-/format/auto/?token=${asset.token}`}
              alt="preview"
            />
          )}
          <TextField
            placeholder="Description"
            className="form-desc my-10"
            value={asset.desc || ''}
            onChange={handleDesc}
            type="text"
            multiline
            rows={3}
            variant="outlined"
            fullWidth
          />
          {/* <DialogContentText className="text-xs">{t('Add a description.')}</DialogContentText> */}
        </DialogContent>
        <DialogActions className="flex justify-right px-20">
          <Button autoFocus onClick={handleSubmit} variant="outlined" color="primary">
            {t('Done')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(MediaUpload, () => true);
