/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    height: '100%',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    paddingTop: '100px',
    '& .MuiAlert-message': {
      display: 'flex'
    },
    '& a': {
      textDecoration: 'underline'
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '500px',
    maxWidth: '500px'
  },
  message: {
    margin: '0 auto'
  }
}));

const Acud = props => {
  const { t } = useTranslation();
  const classes = useStyles(props);

  return (
    <Alert
      severity="info"
      icon={false}
      classes={{
        root: classes.root
      }}
    >
      <div className={classes.content}>
        <Typography>
          <div style={{ fontSize: 16 }}>
            <Trans i18nKey="acud_welcome" />
          </div>
        </Typography>
        <img
          style={{ height: '160px' }}
          className="my-auto mx-auto"
          src="assets/images/icons/heart.svg"
          alt=""
        />
        <Typography>
          <div style={{ fontSize: 16 }}>
            <Trans
              i18nKey="empty_cases_welcome_c_or_board"
              components={{
                a: <a href={t('whats_in_it_att')} />,
                b: <a href={t('whats_in_it_anesth')} />,
                c: <a href={t('whats_in_it_followers')} />,
                d: <a href={t('whats_in_it_res')} />,
                e: <a href={t('whats_in_it_pa')} />
              }}
            />
          </div>
        </Typography>
      </div>
    </Alert>
  );
};

export default Acud;
