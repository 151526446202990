import React, { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import FormLabel from '@mui/material/FormLabel';

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: '10px',
    border: '1px solid',
    borderColor: theme.palette.grey[400],
    width: '548px',
    maxWidth: 'calc(90% + 8px)',
    padding: '22px 14px'
  },
  step: {
    color: theme.palette.grey[500]
  },
  done: {
    color: 'black',
    fontWeight: 'bold'
  }
}));

export default function StepsDebriefView(props) {
  const { t } = useTranslation();
  const { stepsTimeLine, doneAt } = props;
  const classes = useStyles(props);

  if (!stepsTimeLine.length) {
    return null;
  }
  return (
    <div className={clsx('my-10', classes.container)}>
      <FormLabel component="legend">{t('PROCEDURE REPORT')}</FormLabel>
      {stepsTimeLine.map(step => (
        <div key={step.id} className={clsx('mt-16', classes.step)}>
          <div className="inline text-xs mr-6">{step.time}</div>
          <div className="inline text-sm font-medium">{step.title}</div>
          <div className="text-xs font-light italic">{step.comment}</div>
        </div>
      ))}
      {doneAt !== '' && (
        <div key="isDone" className={clsx('mt-16', classes.step)}>
          <div className="inline text-xs mr-6">{doneAt}</div>
          <div className="inline text-sm font-medium">Completed</div>
        </div>
      )}
    </div>
  );
}
