export const OptionSynonyms = {
  'Blood in the room': ['blood loss'],
  'Bougy retractor': ['bougy'],
  BrainLab: ['brain lab', 'navigation'],
  Dex: ['dexamethasone'],
  'Doro head holder': ['doro'],
  Fluoro: ['fluoroscopy'],
  'Greenberg retractors': ['greenberg'],
  iMRI: ['intraop mri'],
  'Intraoperative CT': ['intraop CT'],
  'Jackson with pads': ['jackson w/pads'],
  'Jackson with Wilson frame': ['jackson w/wilson'],
  'Layla bar': ['layla'],
  'Maryland Bipolar': ['Maryland'],
  'NIM tube': ['NIM'],
  'Noras head holder': ['Noras'],
  'Pre-flip baseline': ['baseline'],
  'Prep fat graft': ['fat graft'],
  'Robotic Bulldogs': ['bulldogs'],
  'Skyhook retractor': ['skyhook'],
  'St. Jude': ['st jude'],
  'Standard or Airseal Insufflator': ['Insufflator'],
  'Sterile gloves': ['gloves'],
  'Sterile gowns': ['gowns'],
  'Taylor retractor': ['taylor'],
  Ultrasound: ['US'],
  Vanc: ['vancomycin'],
  'VarioGuide/Navigus': ['Navigus', 'varioguide'],
  'Vascular doppler': ['doppler']
};

export const OptionIgnore = {
  both: true,
  central: true,
  high: true,
  local: true,
  low: true,
  'n/a': true,
  no: true,
  none: true,
  tbd: true,
  unknown: true,
  yes: true,
  other: true
};
