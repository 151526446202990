import _ from '@lodash';
import MuiCheckbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { withFormsy } from 'formsy-react';
import React from 'react';

function Checkbox(props) {
  const importedProps = _.pick(props, [
    'checkedIcon',
    'classes',
    'color',
    'disabled',
    'disableRipple',
    'icon',
    'id',
    'indeterminate',
    'indeterminateIcon',
    'inputProps',
    'inputRef',
    'onChange',
    'variant'
  ]);

  // An error message is returned only if the component is invalid
  const { errorMessage, value } = props;

  const changeValue = event => {
    if (props.isFormDisabled) return;
    if (props.setValue) {
      if (props.confirmValue) {
        // Allow parent comp to hook-in before setting value
        props.confirmValue(event.target.checked, props.setValue);
      } else {
        props.setValue(event.target.checked);
      }
    }

    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <FormControl
      error={Boolean((!props.isPristine && props.showRequired) || errorMessage)}
      className={props.className}
    >
      <FormControlLabel
        control={
          <MuiCheckbox {...importedProps} type="checkbox" checked={value} onChange={changeValue} />
        }
        label={props.label}
      />
      {Boolean(errorMessage) && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}

export const BaseCheckbox = React.memo(Checkbox);

export default React.memo(withFormsy(Checkbox));
