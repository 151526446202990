import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import _ from '@lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultSettings } from 'app/store/fuse/settingsSlice';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MomentAdapter from '@date-io/moment';
import { deleteCaseSteps, getCaseSteps } from 'app/store/data/casesSlice';
import { wakelockEnable } from './helper';
import StepsDebriefView from './StepsDebriefView';

const SUBMIT_FUNCS = {
  START: 'start',
  CONTINUE: 'continue',
  RESTART: 'restart'
};
const dateLib = new MomentAdapter();
const getButtons = (state, stepsStarted, isDone) => {
  const buttons = [];
  if (!stepsStarted) {
    return [
      {
        id: 'start',
        label: 'Start',
        func: SUBMIT_FUNCS.START
      }
    ];
  }
  buttons.push({
    id: 'restart',
    label: 'Restart',
    func: SUBMIT_FUNCS.RESTART
  });
  if (!isDone) {
    buttons.push({
      id: 'continue',
      label: 'Resume',
      func: SUBMIT_FUNCS.CONTINUE
    });
  }
  return buttons;
};

export default function StepsBriefSection(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [stepsMenu, setStepsMenu] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const caseId = props.case.id;
  const stepsMeta = [...props.case.procedureData.steps]
    .filter(s => s.order > 0)
    .sort((a, b) => a.order - b.order);
  const [stepsTimeLine, setStepsTimeLine] = React.useState([]);
  const [doneAt, setDoneAt] = React.useState('');
  const stepsStarted = stepsTimeLine.length > 0;
  const dispatch = useDispatch();
  const settings = useSelector(({ fuse }) => fuse.settings.current);

  const navigateToStep = (preview = false) => {
    const path = `/procedure-steps/${caseId}`;
    const args = [];
    if (preview) {
      args.push('preview=true');
    }
    args.push(`pathname=${location.pathname}`);
    const qs = args.length > 0 ? `?${args.join('&')}` : '';
    history.push(`${path}${qs}`);
  };

  const loadPsteps = async () => {
    const data = await getCaseSteps(caseId);
    const parsedData = data.map(step => {
      const stepMeta = stepsMeta.find(meta => meta.id === step.stepId);
      const { title } = stepMeta;
      const { start, stepId } = step;
      const time = dateLib.date(start).format('HH:mm');
      return {
        id: stepId,
        title,
        time,
        end: step.end
      };
    });
    setStepsTimeLine(parsedData);
    if (data.length === stepsMeta.length && data.every(step => step.isDone)) {
      const lastStep = data[data.length - 1];
      const doneAtTime = dateLib.date(lastStep.end).format('HH:mm');
      setDoneAt(doneAtTime);
    }
    setDataLoaded(true);
  };
  const buttons = getButtons(props.case.state, stepsStarted, doneAt !== '');

  const handleClick = async func => {
    switch (func) {
      case SUBMIT_FUNCS.START:
        await wakelockEnable();
        navigateToStep();
        break;
      case SUBMIT_FUNCS.CONTINUE:
        await wakelockEnable();
        navigateToStep();
        break;
      case SUBMIT_FUNCS.RESTART:
        await deleteCaseSteps(caseId);
        await wakelockEnable();
        navigateToStep();
        break;
      case 'preview':
        navigateToStep(true);
        break;
      default:
        console.log('Not implemented yet');
    }
    if (!settings.layout.config.navbar.folded) {
      dispatch(setDefaultSettings(_.set({}, 'layout.config.navbar.autoFolded', true)));
      dispatch(setDefaultSettings(_.set({}, 'layout.config.navbar.folded', true)));
    }
  };
  if (!dataLoaded) {
    return null;
  }
  return (
    <>
      <FormLabel className="mt-16" component="legend">
        {t('Procedure Whiteboard')}
      </FormLabel>
      <Button
        aria-label={t('Steps')}
        startIcon={<Icon style={{ fontSize: '28px' }}>slideshow</Icon>}
        aria-owns={stepsMenu ? 'steps-menu' : null}
        aria-haspopup="true"
        onClick={event => setStepsMenu(event.currentTarget)}
      >
        {t('Steps')}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={stepsMenu}
        open={Boolean(stepsMenu)}
        onClose={() => setStepsMenu(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {buttons.map(button => (
          <MenuItem key={button.id} onClick={() => handleClick(button.func)}>
            {t(button.label)}
          </MenuItem>
        ))}
      </Menu>
      <StepsDebriefView
        doneAt={doneAt}
        caseId={caseId}
        stepsMeta={stepsMeta}
        stepsTimeLine={stepsTimeLine}
      />
    </>
  );
}
