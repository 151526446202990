import React, { Fragment } from 'react';
import { VelocityComponent } from 'velocity-react';
import clsx from 'clsx';
import { useTranslation, Trans } from 'react-i18next';
// eslint-disable-next-line import/no-cycle
import Form from 'app/components/forms/DynamicForm';
import Comments from 'app/components/comments/Comments';
import Icon from '@mui/material/Icon';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import toast from 'app/services/toastService/toast';
import { randomTeamAppreciateEmoji } from 'app/utils';
import { caseStateDebriefPlus, FEEDBACK_ADD_INFO_OPTIONS, USER_CASE_ROLES } from 'app/consts';

const BriefForm = ({
  kase,
  showBrief,
  setShowBrief,
  handledAnimationComplete,
  formClasses,
  setFormClasses,
  briefFields,
  userRoleInCase,
  briefedByRes,
  briefApprovedByAtt,
  wasBriefReviewed,
  tab,
  recommendations
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      {/* Brief-form toggle (shown in debrief/past) */}
      {caseStateDebriefPlus(kase.state) && userRoleInCase !== USER_CASE_ROLES.NONE && (
        <>
          <div
            className={clsx('pt-12 pb-12 -mt-6 bg-white z-999', {
              sticky: showBrief
            })}
            style={{ top: '-8px' }}
          >
            <Button
              variant="text"
              color="primary"
              size="small"
              className="font-400 text-10 pl-0 py-0 mt-0"
              startIcon={
                <Icon fontSize="inherit">{!showBrief ? 'grading' : 'arrow_back_ios_new'}</Icon>
              }
              onClick={() =>
                briefedByRes || briefApprovedByAtt
                  ? setShowBrief(!showBrief)
                  : toast.warning(t('case_not_briefed'))
              }
            >
              {!showBrief ? `${t('View Brief')}` : `${t('Back to debrief')}`}
            </Button>
          </div>
          <VelocityComponent
            animation={`transition.slideRight${showBrief ? 'In' : 'Out'}`}
            duration={400}
            complete={handledAnimationComplete}
          >
            <div>
              {!wasBriefReviewed && (
                <Alert severity="info" className="mb-12">
                  <Trans
                    i18nKey="brief_not_reviewed"
                    values={{ attending: kase.attending.nickName.trim() }}
                  />
                </Alert>
              )}
              <Form
                kase={kase}
                // Brief form (view only)
                className={clsx(formClasses)}
                fields={briefFields}
                disabled
                tab={tab}
                recommendations={recommendations}
              />
              {/* Private notes in brief read-only view. Not putting the comments attribute on purpose,
                since if it's empty + disabled, it is hidded */}
              <Comments
                case={kase}
                formClasses={{ classes: formClasses, set: setFormClasses }}
                disabled
              />
            </div>
          </VelocityComponent>
        </>
      )}
    </Fragment>
  );
};

export default BriefForm;
