import React, { useEffect } from 'react';
import StepsList from 'app/components/pSteps/StepsList';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import urlParams from 'app/services/urlParams';
import { loadCase, getCaseSteps, startStep, endStep } from 'app/store/data/casesSlice';

const sortByOrder = (a, b) => a.order - b.order;
const PSteps = () => {
  const { caseId } = useParams();
  const previewMode = urlParams.get('preview') === 'true';
  const pathname = urlParams.get('pathname') || '/cases/my-cases';
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const openCase = useSelector(({ data }) => data.cases.openCase);
  const [stepsTimeLine, setStepsTimeLine] = React.useState(null);

  const loadCaseById = (id, editCaseAttributes) => {
    return dispatch(loadCase(id, user.data.id, editCaseAttributes));
  };
  const loadPsteps = async () => {
    const data = await getCaseSteps(caseId);
    setStepsTimeLine(data);
  };

  const caseDataLoaded = !!openCase?.case?.id && stepsTimeLine;
  useEffect(() => {
    if (!caseDataLoaded || openCase.case.id !== caseId) {
      loadCaseById(caseId);
      loadPsteps(caseId);
    }
  }, []);
  const handleStartStep = async stepId => {
    if (previewMode) {
      return;
    }
    if (stepsTimeLine.find(step => step.stepId === stepId)) {
      return;
    }
    await startStep(caseId, stepId);
    await loadPsteps();
  };
  const handleEndStep = async stepId => {
    if (previewMode) {
      return;
    }
    await endStep(caseId, stepId);
    await loadPsteps();
  };

  const steps =
    caseDataLoaded && openCase.case.hasStepsData ? openCase.case.procedureData.steps : [];
  const sortedSteps = [...steps].sort(sortByOrder);
  return caseDataLoaded ? (
    <StepsList
      caseId={caseId}
      steps={sortedSteps}
      stepsTimeLine={stepsTimeLine}
      handleStartStep={handleStartStep}
      handleEndStep={handleEndStep}
      previewMode={previewMode}
      pathname={pathname}
    />
  ) : null;
};

export default PSteps;
