import React, { Fragment } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import ConfirmButton from 'app/components/confirmButton/ConfirmButton';
import DropMenu from 'app/components/dropMenu/DropMenu';
import Button from '@mui/material/Button';
import { CASE_STATE, USER_CASE_ROLES } from 'app/consts';
import { useSelector } from 'react-redux';

function CasesListItemDropMenu({
  tab,
  kase,
  userRoleInCase,
  handleClick,
  handleShare,
  handleDelete,
  reorderCase,
  placeInDay
}) {
  const { t } = useTranslation();
  const shareSupported = Boolean(navigator.share);
  const calculatedDisplayIds = useSelector(({ data }) => data.cases.calculatedDisplayIds);

  return (
    <div style={{ position: 'absolute', right: 16, top: 2 }}>
      <DropMenu
        buttonProps={{
          className: clsx('kebab-menu p-8 -mr-12 order-last', {
            'mt-auto':
              (tab === 'my-cases' && kase.state === CASE_STATE.COMPLETED) ||
              (tab === 'or-board' && userRoleInCase === USER_CASE_ROLES.NONE)
          })
        }}
      >
        <Button
          title={t('view_case')}
          aria-label={t('view_case')}
          onClick={e => {
            handleClick(kase, false, userRoleInCase);
          }}
          className="px-16 hover:bg-transparent"
          disableRipple
          variant="text"
        >
          <Icon>preview</Icon>
          <Typography variant="inherit" className="ml-12">
            {t('view_case')}
          </Typography>
        </Button>
        <Button
          title={t('change_assignment')}
          aria-label={t('change_assignment')}
          onClick={e => {
            handleClick(kase, true, userRoleInCase);
          }}
          className="px-16 hover:bg-transparent"
          disableRipple
          variant="text"
        >
          <Icon>edit</Icon>
          <Typography variant="inherit" className="ml-12">
            {t('change_assignment')}
          </Typography>
        </Button>
        {shareSupported && kase.caseStateLogs?.some(l => l.toState === CASE_STATE.READY) && (
          <Button
            title={t('Share plan')}
            aria-label={t('Share plan')}
            onClick={e => {
              handleShare(kase.id);
            }}
            className="px-16 hover:bg-transparent"
            disableRipple
            variant="text"
          >
            <Icon>share</Icon>
            <Typography variant="inherit" className="ml-12">
              {t('Share plan')}
            </Typography>
          </Button>
        )}
        {handleDelete && (
          <ConfirmButton
            label={t('Delete')}
            icon="delete"
            title={t('delete_case_popup_title', {
              attending: kase.attending.nickName
            })}
            message={t('delete_case_popup_msg', {
              title:
                calculatedDisplayIds[kase.id] ||
                kase.displayId ||
                kase.procedureTitle ||
                kase.description ||
                'case',
              day: new Date(kase.caseDate).toLocaleDateString('en-US', {
                weekday: 'long'
              })
            })}
            onConfirm={handleDelete}
            buttonProps={{
              className: 'px-16 hover:bg-transparent',
              disableRipple: true,
              variant: 'text'
            }}
            confirmationProps={{
              ok: t('Delete')
            }}
          />
        )}
        {tab === 'or-board' && (
          <Button
            title={t('move_up')}
            aria-label={t('move_up')}
            className="px-16 hover:bg-transparent"
            disableRipple
            variant="text"
            onClick={() => reorderCase(kase, 'up')}
            disabled={placeInDay === 'first' || placeInDay === 'alone'}
          >
            <Icon>arrow_upward</Icon>
            <Typography variant="inherit" className="ml-12">
              {t('move_up')}
            </Typography>
          </Button>
        )}
        {tab === 'or-board' && (
          <Button
            title={t('move_down')}
            aria-label={t('move_down')}
            className="px-16 hover:bg-transparent"
            disableRipple
            variant="text"
            onClick={() => reorderCase(kase, 'down')}
            disabled={placeInDay === 'last' || placeInDay === 'alone'}
          >
            <Icon>arrow_downward</Icon>
            <Typography variant="inherit" className="ml-12">
              {t('move_down')}
            </Typography>
          </Button>
        )}
      </DropMenu>
    </div>
  );
}

export default CasesListItemDropMenu;
