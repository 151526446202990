import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { caseStateInBriefToReady, USER_CASE_ROLES } from 'app/consts';
import { useSelector } from 'react-redux';
import EditAttributes from './EditAttributes';
import ChangedBy from '../userAvatar/ChangedByAvatar';
import { getMetaFieldsChangedBy } from './formHelpers/shared';

const EditCaseAttributes = props => {
  const { t } = useTranslation();
  const { case: kase, userRoleInCase, loadCaseById } = props;
  const calculatedDisplayIds = useSelector(({ data }) => data.cases.calculatedDisplayIds);

  const showMetaChangedBy =
    caseStateInBriefToReady(kase.state) && userRoleInCase === USER_CASE_ROLES.RESIDENT;
  const dateChangedBy =
    showMetaChangedBy && getMetaFieldsChangedBy('caseDate', kase, kase.attendingValues);
  const procedureTitleChangedBy =
    showMetaChangedBy && getMetaFieldsChangedBy('procedureTitle', kase, kase.attendingValues);

  return (
    <EditAttributes case={kase} loadCaseById={loadCaseById}>
      <Typography variant="body2" className="mt-10 text-gray-600">
        {kase.formatedDate} {dateChangedBy && <ChangedBy inline small user={dateChangedBy} />}
      </Typography>
      {(calculatedDisplayIds[kase.id] || kase.displayId) && (
        <Typography variant="h6" component="div">
          {calculatedDisplayIds[kase.id] || kase.displayId}
        </Typography>
      )}
      <Typography variant={kase.displayId ? 'caption' : 'h6'} className="text-gray-600 -mt-2 mb-8">
        {kase.procedureTitle}{' '}
        {procedureTitleChangedBy && <ChangedBy inline small user={procedureTitleChangedBy} />}
      </Typography>
      <Typography variant="inherit" component="div" className="capitalize mb-12">
        {t(kase.state)}
        <span className="mx-8">•</span>
        {kase.attending.nickName}
        {kase.resident ? ` / ${kase.resident.nickName}` : ''}
      </Typography>
    </EditAttributes>
  );
};

export default EditCaseAttributes;
