import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import FusePageSimple from '@fuse/core/FusePageSimple/FusePageSimple';
import DepartmentDashboard from 'app/components/dashboard/DepartmentDashboard';
import DepartmentData from 'app/components/dashboard/DepartmentData';

import {
  departmentDashboardDataSelector,
  departmentDashboardReportSelector,
  loadDashboardReport,
  loadDepartmentDashboardData
} from 'app/store/data/dashboardSlice';

function MyDepartmentPage(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dashboardData = useSelector(departmentDashboardDataSelector);
  const reportData = useSelector(departmentDashboardReportSelector);

  const getDepartmentDashboardData = useCallback(() => {
    dispatch(loadDepartmentDashboardData());
  }, [dispatch]);

  const getDashboardReport = useCallback(
    (from, until, siteId, attendingIds) => {
      dispatch(loadDashboardReport(from, until, siteId, attendingIds));
    },
    [dispatch]
  );

  return (
    <FusePageSimple
      header={
        <div className="p-24 pl-40">
          <h2>{t('ANALYTICS')}</h2>
        </div>
      }
      content={
        <div className="md:p-24">
          <Switch>
            <Route exact path="/analytics/dash">
              <DepartmentDashboard
                getDashboardData={getDepartmentDashboardData}
                data={dashboardData}
                tab="dash"
              />
            </Route>
            <Route exact path="/analytics/data">
              <DepartmentData getDashboardData={getDashboardReport} data={reportData} tab="data" />
            </Route>
          </Switch>
        </div>
      }
    />
  );
}

export default MyDepartmentPage;
