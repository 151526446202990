import React, { Fragment, useEffect, useRef, useState } from 'react';
import _ from '@lodash';

import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import withReducer from 'app/store/withReducer';
import ConfirmationDialog from 'app/components/confirmDialog/ConfirmDialog';
import apiService from 'app/services/apiService';
import toast from 'app/services/toastService/toast';
import { getUserRoleInCase } from 'app/components/caseViews/formHelpers/shared';
import { getFormFields } from 'app/components/caseViews/formHelpers/form';
import amplService from 'app/services/amplService/amplService';
import {
  CASE_STATE,
  caseStateDebriefPlus,
  caseStateInBriefAndReview,
  caseStateInBriefToReady,
  FORM_STAGE,
  REMEMBER_PREFERENCE_OPTIONS,
  USER_CASE_ROLES
} from 'app/consts';
import { updateLastSeen } from 'app/store/data/casesSlice';
import {
  createCaseAsset,
  deleteCaseAsset,
  getUploadcareSignature,
  moveToNextState,
  submitBriefForm,
  submitCareTeamBriefFormAttendingValues,
  submitCaseAsset,
  updateCaseAsset,
  updateCaseValue,
  updateComments,
  updateDescription,
  updatePmmValues
} from './store/caseViewSlice';
import { findOptionInDescription } from './formHelpers/PMMFields';
import Debug from './Debug';
import reducer from './store';
import ShareCase from './ShareCase';
import CaseFollowers from './CaseFollowers';
import SavedPrefsInfoBox from './SavedPrefsInfoBox';
import CaseInfoBox from './CaseInfoBox';
import EditCaseAttributes from './EditCaseAttributes';
import TemplateInsightConfirmationDialog from './TemplateInsightConfirmationDialog';
import DescriptionMismatchDialog from './DescriptionMismatchDialog';
import BriefDebriefView from './caseViewComponents/BriefDebriefView';
import useTemplateRecommendations from './hooks/useTemplateRecommendations';

const tmpSubmitValues = {};

function CaseView(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const { role: userRoles } = user;
  const userRoleInCase = getUserRoleInCase(props.case, user.data);
  const [formClasses, setFormClasses] = useState([]);
  const [showBrief, setShowBrief] = useState(false);
  const [confirmSubmitWithDraftComment, setConfirmSubmitWithDraftComment] = useState(false);
  const [shareAfterSubmit, setShareAfterSubmit] = useState(false);

  const [templateSuggestion, setTemplateSuggestion] = useState(false);
  const [templateSuggestionDone, setTemplateSuggestionDone] = useState(false);

  const [confirmSubmitWithCommentQuestion, setConfirmSubmitWithCommentQuestion] = useState(false);
  const [confirmSubmitWithCommentQuestionMessage, setConfirmSubmitWithCommentQuestionMessage] =
    useState('');
  const [confirmSubmitWithCommentQuestionTitle, setConfirmSubmitWithCommentQuestionTitle] =
    useState('');
  const [assets, setAssets] = useState([]);
  const [descriptionMismatchDialogInput, setDescriptionMismatchDialogInput] = useState(null);

  const { id: caseId } = props.case;

  const onCloseUpdateTemplate = ok => {
    updateAttendingCaseDefaults(templateSuggestion, ok);

    setTemplateSuggestionDone(true);
    setTemplateSuggestion(false);
  };

  const onCloseDescriptionMismatch = (ok, newDesc) => {
    if (ok) {
      dispatch(updateDescription(caseId, newDesc));
      toast.success(t('description_updated'));
    }
    setDescriptionMismatchDialogInput(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      const caseAssets = await apiService.getCaseAssets(caseId);

      setAssets(caseAssets);
    };
    if (caseId) {
      fetchData();
    }
  }, [props.case.assets, caseId, userRoleInCase]);

  const commentsSubsrciption = useRef();

  useEffect(() => {
    const fetchData = async () => {
      if (commentsSubsrciption.current) {
        commentsSubsrciption.current.unsubscribe();
        commentsSubsrciption.current = null;
      }
      commentsSubsrciption.current = await apiService.subscribeToCaseComments(caseId, data => {
        console.log('commentsSubsrciption', data);
        if (data?.comments) {
          dispatch(updateComments(caseId, data.comments));
        }
      });
    };
    fetchData();
    return () => {
      if (commentsSubsrciption.current) {
        console.debug('commentsSubsrciption.current', commentsSubsrciption.current);
        commentsSubsrciption.current.unsubscribe();
      }
    };
  }, [caseId]);
  useEffect(() => {
    const myFollower = props.case.caseFollowers.find(f => f.userId === user.data.id);
    if (myFollower) {
      dispatch(updateLastSeen(caseId, user.data.id));
    }
  }, []);

  const { updateAttendingCaseDefaults, evaluteValueToRecommend } = useTemplateRecommendations({
    userId: user.data.id,
    case: props.case
  });

  const [isPmmValuesDirty, setIsPmmValuesDirty] = useState(props.case.caseFieldValues?.length > 0);

  const handleCaseValueUpdate = (field, value, type = 'text', multi = false) => {
    amplService.sendCaseEvent(amplService.EVENTS.UPDATE_CASE, props.case, { field });
    dispatch(updateCaseValue(caseId, field, value, type, multi, userRoleInCase));
  };

  const handleDescriptionOnUnselect = (values, oldValue) => {
    let removedValue;
    if ((!Array.isArray(values) && values?.value) || (!values && oldValue?.value)) {
      removedValue = oldValue;
    } else {
      const removedArr = _.difference(oldValue, values);
      if (removedArr.length === 1) {
        [removedValue] = removedArr;
      }
    }
    if (removedValue) {
      const optionFromDesc = findOptionInDescription(removedValue.value, props.case.description);
      if (optionFromDesc) {
        setDescriptionMismatchDialogInput(optionFromDesc);
      }
    }
  };

  const handlePmmValueUpdate = (
    fieldId,
    values,
    otherValues,
    type = 'text',
    multi = false,
    oldValue = [],
    otherChanged = false
  ) => {
    setIsPmmValuesDirty(true);
    amplService.sendCaseEvent(amplService.EVENTS.UPDATE_CASE, props.case, { fieldId });
    dispatch(
      updatePmmValues(
        caseId,
        fieldId,
        values,
        otherValues,
        type,
        true,
        userRoleInCase,
        oldValue,
        otherChanged
      )
    );

    const s = evaluteValueToRecommend(
      fieldId,
      values,
      otherValues,
      type,
      multi,
      oldValue,
      otherChanged
    );
    setTemplateSuggestion(s);

    handleDescriptionOnUnselect(values, oldValue);
  };

  const handleCreateCaseAsset = asset => {
    const stage = caseStateInBriefToReady(props.case.state) ? FORM_STAGE.BRIEF : FORM_STAGE.DEBRIEF;
    return dispatch(createCaseAsset(caseId, asset.file.uuid, stage));
  };

  const handleUpdateCaseAsset = asset => {
    return dispatch(updateCaseAsset(caseId, asset.id, asset.file.uuid, asset.file));
  };

  const handleSubmitCaseAsset = asset => {
    return dispatch(submitCaseAsset(caseId, asset.id, asset.desc));
  };

  const handledDeleteCaseAsset = asset => {
    return dispatch(deleteCaseAsset(asset.id));
  };

  const handledAnimationComplete = elements => {
    elements.forEach(el => {
      // Remove transform when done. so it won't affect submit fab position.
      // https://stackoverflow.com/a/31829509/1660055
      el.style.transform = 'none';
    });
  };

  const getToastOnSubmitMsg = (state, attendingOnly) => {
    let msgName;
    let msgLength;

    if (
      props.totalNextCases > 0 &&
      props.totalNextCases + 1 - props.nextCases.length !== props.totalNextCases + 1
    ) {
      // Do not show toast if we're suggestion to fill another case
      return null;
    }

    switch (state) {
      case CASE_STATE.IN_BRIEF: {
        if (!attendingOnly) {
          msgName = 'brief_toast';
          msgLength = 8;
        }
        break;
      }
      case CASE_STATE.BRIEF_IN_REVIEW: {
        // Toast only resident, because attending also has the share popup
        if (userRoleInCase === USER_CASE_ROLES.RESIDENT) {
          msgName = 'brief_toast';
          msgLength = 8;
        }
        break;
      }
      case CASE_STATE.IN_DEBRIEF:
      case CASE_STATE.DEBRIEF_IN_REVIEW: {
        msgName = 'debrief_toast';
        msgLength = 8;
        break;
      }
      default: // Do nothing.
    }

    if (msgName && msgLength) {
      return t(`${msgName}_${_.random(1, msgLength)}`);
    }

    return null;
  };

  const submitForm = async (model, formRef) => {
    const msg = getToastOnSubmitMsg(props.case.state, !props.case.resident);
    props.setShouldMoveToNextCase(true);
    if (msg) {
      toast.success(msg);
    }

    setConfirmSubmitWithDraftComment(false);
    setConfirmSubmitWithCommentQuestion(false);

    if (
      userRoleInCase === USER_CASE_ROLES.ATTENDING &&
      [CASE_STATE.IN_BRIEF, CASE_STATE.BRIEF_IN_REVIEW].includes(props.case.state)
    ) {
      const rememberPreferencesValue = formRef.current.getCurrentValues().rememberPreferences;
      await dispatch(submitBriefForm(caseId, fields, formRef.current.getCurrentValues()));
      if (rememberPreferencesValue !== REMEMBER_PREFERENCE_OPTIONS.NOT_THIS_TIME) {
        await dispatch(
          submitCareTeamBriefFormAttendingValues(
            caseId,
            props.case.procedureId,
            rememberPreferencesValue
          )
        );
      }
    }

    // If resident filled a case in brief in review, move it back and then move to brief-in-review to
    // send the notification to attending
    if (
      userRoleInCase === USER_CASE_ROLES.RESIDENT &&
      props.case.state === CASE_STATE.BRIEF_IN_REVIEW
    ) {
      await apiService.updateCaseState(props.case.id, CASE_STATE.IN_BRIEF);
      await apiService.deleteCaseStateLogs(
        props.case.id,
        CASE_STATE.IN_BRIEF,
        CASE_STATE.BRIEF_IN_REVIEW
      );
      await apiService.deleteCaseStateLogs(
        props.case.id,
        CASE_STATE.BRIEF_IN_REVIEW,
        CASE_STATE.IN_BRIEF
      );
    }
    await dispatch(moveToNextState(props.case, userRoleInCase));

    props.setCaseSubmittedComplete(true);
  };

  const showFollowerBriefNotReadyMessage =
    userRoleInCase === USER_CASE_ROLES.NONE && caseStateInBriefAndReview(props.case.state);

  const fields =
    !showFollowerBriefNotReadyMessage && props.case
      ? getFormFields(
          { ...props.case, assets },
          user.data,
          handleCaseValueUpdate,
          handlePmmValueUpdate,
          userRoleInCase,
          handleCreateCaseAsset,
          handleUpdateCaseAsset,
          handleSubmitCaseAsset,
          handledDeleteCaseAsset,
          getUploadcareSignature,
          isPmmValuesDirty,
          userRoles,
          props.nextCases,
          // For nursing/antesth, always show brief, even if case has moved to debrief
          userRoleInCase === USER_CASE_ROLES.NONE ? CASE_STATE.READY : false
        )
      : [];

  const briefFields =
    caseStateDebriefPlus(props.case.state) && !showFollowerBriefNotReadyMessage && props.case
      ? getFormFields(
          { ...props.case, assets },
          user.data,
          handleCaseValueUpdate,
          handlePmmValueUpdate,
          userRoleInCase,
          handleCreateCaseAsset,
          handleUpdateCaseAsset,
          handleSubmitCaseAsset,
          handledDeleteCaseAsset,
          getUploadcareSignature,
          isPmmValuesDirty,
          userRoles,
          props.nextCases,
          CASE_STATE.READY
        )
      : [];

  // consider as briefed if got to ready state, or resident filled the brief
  const briefedByRes = props.case?.caseStateLogs?.some(
    l => l.toState === CASE_STATE.BRIEF_IN_REVIEW && l.createdById === props.case.residentId
  );
  const briefApprovedByAtt = props.case?.caseStateLogs?.some(l => l.toState === CASE_STATE.READY);

  const wasBriefReviewed =
    !props.case.resident || // If case doesn't have a resident, treat it as reviewed
    (props.case && props.case.caseStateLogs?.some(l => l.toState === CASE_STATE.READY));

  // Add current user as follower if not already follower and not attending/resident
  const myFollowerToAdd =
    [
      CASE_STATE.IN_BRIEF,
      CASE_STATE.BRIEF_IN_REVIEW,
      CASE_STATE.READY,
      CASE_STATE.IN_DEBRIEF
    ].includes(props.case.state) &&
    props.followEnabled &&
    user.data.id !== props.case.attendingId &&
    user.data.id !== props.case.residentId &&
    !props.case.caseFollowers.some(f => f.userId === user.data.id)
      ? user.data
      : null;

  const isTemplateInsightConfirmationDialogOpen = !templateSuggestionDone && !!templateSuggestion;

  return props.case ? (
    <Fragment>
      {props.case.caseStateLogs?.some(l => l.toState === CASE_STATE.READY) && (
        <ShareCase
          onClick={() => {
            props.handleShare(caseId);
          }}
        />
      )}
      <CaseInfoBox
        userRoleInCase={userRoleInCase}
        case={props.case}
        totalNextCases={props.totalNextCases}
        nextCases={props.nextCases}
      />
      <EditCaseAttributes
        userRoleInCase={userRoleInCase}
        case={props.case}
        loadCaseById={props.loadCaseById}
      />
      <Debug case={props.case} />
      {props.options && (
        <CaseFollowers
          caseId={props.case.id}
          caseFollowers={props.case.caseFollowers}
          users={props.options.careTeam.filter(u =>
            u.userSites.some(s => s.siteId === props.case.siteId)
          )}
          handleSetFollowers={props.handleSetFollowers}
          myFollowerToAdd={myFollowerToAdd}
          disabled={props.case.state === CASE_STATE.ARCHIVED}
        />
      )}
      {/* Follow info-box */}
      {myFollowerToAdd && (
        <Alert severity="info" className="mb-12 text-14">
          <Trans i18nKey="you_are_now_following" />
        </Alert>
      )}
      {/* Follow info-box */}
      {!myFollowerToAdd && showFollowerBriefNotReadyMessage && (
        <Alert severity="info" className="mb-12 text-14">
          <Trans
            i18nKey="follow_case_info"
            values={{ attending: props.case.attending.nickName.trim() }}
          />
        </Alert>
      )}
      {/* Saved preferences info-box */}
      <SavedPrefsInfoBox case={props.case} userRoleInCase={userRoleInCase} />

      <BriefDebriefView
        userRoleInCase={userRoleInCase}
        briefedByRes={briefedByRes}
        briefApprovedByAtt={briefApprovedByAtt}
        wasBriefReviewed={wasBriefReviewed}
        kase={props.case}
        showBrief={showBrief}
        setShowBrief={setShowBrief}
        handledAnimationComplete={handledAnimationComplete}
        formClasses={formClasses}
        setFormClasses={setFormClasses}
        briefFields={briefFields}
        fields={fields}
        tmpSubmitValues={tmpSubmitValues}
        setConfirmSubmitWithDraftComment={setConfirmSubmitWithDraftComment}
        setConfirmSubmitWithCommentQuestionTitle={setConfirmSubmitWithCommentQuestionTitle}
        user={user}
        setConfirmSubmitWithCommentQuestionMessage={setConfirmSubmitWithCommentQuestionMessage}
        setConfirmSubmitWithCommentQuestion={setConfirmSubmitWithCommentQuestion}
        submitForm={submitForm}
        caseId={caseId}
        tab={props.tab}
      />

      <ConfirmationDialog
        open={confirmSubmitWithDraftComment}
        onClose={ok =>
          ok
            ? submitForm(tmpSubmitValues.model, tmpSubmitValues.formRef)
            : setConfirmSubmitWithDraftComment(false)
        }
        title={t('Discard comment?')}
        message={t('confirm_case_submit')}
        ok={t('Yes, discard')}
        cancel={t('Go back')}
        actionsProps={{ className: 'justify-between' }}
        okProps={{ variant: 'text', color: 'inherit' }}
        cancelProps={{ variant: 'outlined', color: 'primary' }}
      />
      <ConfirmationDialog
        open={confirmSubmitWithCommentQuestion}
        onClose={ok =>
          ok
            ? submitForm(tmpSubmitValues.model, tmpSubmitValues.formRef)
            : setConfirmSubmitWithCommentQuestion(false)
        }
        title={confirmSubmitWithCommentQuestionTitle}
        message={confirmSubmitWithCommentQuestionMessage}
        ok={t('Continue')}
        cancel={t('Back and answer')}
        actionsProps={{ className: 'justify-between' }}
        okProps={{ variant: 'text', color: 'inherit' }}
        cancelProps={{ variant: 'outlined', color: 'primary' }}
      />
      <ConfirmationDialog
        open={shareAfterSubmit}
        onClose={ok => {
          if (ok) {
            props.handleShare(caseId, 'after case popup');
          } else {
            props.setCaseSubmittedComplete(true);
          }
          setShareAfterSubmit(false);
        }}
        title={
          !props.case.resident
            ? t('share_case_after_confirm_title_attending_only')
            : t('share_case_after_confirm_title')
        }
        message={
          !props.case.resident
            ? t('share_case_after_confirm_body_attending_only')
            : t('share_case_after_confirm_body')
        }
        ok={t('1-Click share')}
        cancel={t('Done')}
        actionsProps={{ className: 'justify-between' }}
        okProps={{ variant: 'outlined', color: 'primary' }}
        cancelProps={{ variant: 'text', color: 'inherit' }}
      />
      <TemplateInsightConfirmationDialog
        open={isTemplateInsightConfirmationDialogOpen}
        onClose={onCloseUpdateTemplate}
        values={templateSuggestion}
      />
      <DescriptionMismatchDialog
        open={!!descriptionMismatchDialogInput}
        onClose={onCloseDescriptionMismatch}
        description={props.case?.description}
        input={descriptionMismatchDialogInput}
      />
    </Fragment>
  ) : null;
}

export default withReducer('caseViews', reducer)(React.memo(CaseView));
