import { createSlice } from '@reduxjs/toolkit';
import apiService from 'app/services/apiService';
import toast from 'app/services/toastService/toast';
import bl from 'app/bl/index';
import { CASE_STATE } from 'app/consts';
import { lite } from '../../services/apiService/api/lite';

export const myCasesDataSelector = ({
  data: {
    dashboard: {
      myCasesData: {
        briefDoneCases,
        briefsToReviewAsAttending,
        casesCountByStateAsAssistant,
        casesCountByStateAsAttending,
        totalCasesAsAssistant,
        totalAddonCases,
        departmentData: {
          depBriefDoneCases,
          depCasesCountByState,
          depTotalCases,
          depTotalAddonCases
        },
        loaded
      }
    }
  }
}) => {
  const debriefDoneCases =
    CASE_STATE.DEBRIEF_IN_REVIEW in casesCountByStateAsAssistant
      ? casesCountByStateAsAssistant[CASE_STATE.DEBRIEF_IN_REVIEW]
      : 0;

  const briefReviewedCases =
    CASE_STATE.READY in casesCountByStateAsAttending
      ? casesCountByStateAsAttending[CASE_STATE.READY]
      : 0;

  const debriefReviewedCases =
    CASE_STATE.COMPLETED in casesCountByStateAsAttending
      ? casesCountByStateAsAttending[CASE_STATE.COMPLETED]
      : 0;

  const debriefsToReviewAsAttending =
    CASE_STATE.DEBRIEF_IN_REVIEW in casesCountByStateAsAttending
      ? casesCountByStateAsAttending[CASE_STATE.DEBRIEF_IN_REVIEW]
      : 0;

  const depBriefReviewedCases =
    CASE_STATE.READY in depCasesCountByState ? depCasesCountByState[CASE_STATE.READY] : 0;

  const depDebriefReviewedCases =
    CASE_STATE.COMPLETED in depCasesCountByState ? depCasesCountByState[CASE_STATE.COMPLETED] : 0;

  const depDebriefDoneCases =
    CASE_STATE.DEBRIEF_IN_REVIEW in depCasesCountByState
      ? depCasesCountByState[CASE_STATE.DEBRIEF_IN_REVIEW]
      : 0;

  const totalBriefCases = totalCasesAsAssistant - totalAddonCases;

  const depTotalBriefCases = depTotalCases - depTotalAddonCases;

  const rates = bl.dashboard.calcRates({
    briefDoneCases,
    totalBriefCases,
    depBriefDoneCases,
    depTotalBriefCases,
    debriefDoneCases,
    totalCasesAsAssistant,
    depDebriefDoneCases,
    depTotalCases,
    briefReviewedCases,
    briefsToReviewAsAttending,
    depBriefReviewedCases,
    debriefReviewedCases,
    debriefsToReviewAsAttending,
    depDebriefReviewedCases
  });
  return {
    briefRate: rates.totalBriefRate,
    briefReviewRate: rates.briefReviewRate,
    debriefRate: rates.totalDebriefRate,
    depBriefedCases: depBriefReviewedCases,
    loaded
  };
};

export const departmentDashboardDataSelector = ({
  data: {
    dashboard: {
      departmentDashboardData: {
        totalCases,
        casesBySite,
        depBriefDoneCases,
        briefsToReviewAsAttending,
        briefsToReviewAsAttendingCases,
        briefsReviewedAsAttendingCases,
        casesCountByState,
        totalAddonCases,
        feedbackCount,
        casesByType,
        casesByAttending,
        casesByAssistant,
        casesByMonth
      }
    }
  }
}) => {
  const briefReviewedCases =
    CASE_STATE.READY in casesCountByState ? casesCountByState[CASE_STATE.READY] : 0;

  const inDebriefCases =
    CASE_STATE.IN_DEBRIEF in casesCountByState ? casesCountByState[CASE_STATE.IN_DEBRIEF] : 0;

  const debriefDoneCases =
    CASE_STATE.DEBRIEF_IN_REVIEW in casesCountByState
      ? casesCountByState[CASE_STATE.DEBRIEF_IN_REVIEW]
      : 0;

  const debriefReviewedCases =
    CASE_STATE.COMPLETED in casesCountByState ? casesCountByState[CASE_STATE.COMPLETED] : 0;

  const totalBriefCases = totalCases - totalAddonCases;

  const huddleRateYearToDate = calcHuddleRateYearToDate(
    briefsToReviewAsAttendingCases,
    briefsReviewedAsAttendingCases
  );

  return {
    casesInChiefy: totalCases,
    briefedCases: briefReviewedCases,
    casesBySite,
    casesYearToDate: casesByMonth.map(parseCasesByMonth),
    assistantsBrief: (depBriefDoneCases / totalBriefCases) * 100,
    attendingsBrief: (briefReviewedCases / briefsToReviewAsAttending) * 100,
    assistantsDebrief: (debriefDoneCases / inDebriefCases) * 100,
    attendingsDebrief: (debriefReviewedCases / debriefDoneCases) * 100,
    feedbackRate: (feedbackCount / debriefDoneCases) * 100,
    casesByType,
    casesByAttending,
    casesByAssistant,
    huddleRateYearToDate
  };
};

export const departmentDashboardReportSelector = ({
  data: {
    dashboard: {
      reportData: {
        from,
        until,
        reportPerUserInDateRange,
        caseFollowersInDateRange,
        caseFollowersInDateRangeLastWeek,
        caseFeedbackInDateRange,
        chatCountInDataRange,
        readyCaseStateLogsTimeInDataRange,
        pStepsInDateRange,
        briefsToReviewCases,
        briefsReviewedCases,
        nonParticipatingAttendings
      }
    }
  }
}) => {
  const { huddleRatePerWeek, huddlesPerWeek } = calcHuddleRatePerWeek(
    from,
    until,
    briefsToReviewCases,
    briefsReviewedCases,
    nonParticipatingAttendings
  );

  return {
    from,
    until,
    reportPerUserInDateRange,
    caseFollowersInDateRange,
    caseFollowersInDateRangeLastWeek,
    caseFeedbackInDateRange,
    chatCountInDataRange,
    readyCaseStateLogsTimeInDataRange,
    pStepsInDateRange,
    briefsToReviewCases,
    briefsReviewedCases,
    nonParticipatingAttendings,
    huddleRatePerWeek,
    huddlesPerWeek
  };
};

const parseCasesByMonth = ({ count, caseMonth }) => {
  const d = new Date(Date.parse(caseMonth));

  return {
    count,
    month: d.getUTCMonth() + 1,
    year: d.getUTCFullYear()
  };
};

const casesListToMap = cases => {
  const casesPerMonth = cases?.map(c => {
    const date = new Date(lite ? c.createdAt : c.caseDate);

    let d;
    if (date.getHours() === 0 && date.getMinutes() === 0 && date.getTimezoneOffset() === 0) {
      // if the date is 00:00 in UTC, just set the day as 1
      d.setDate(1);
    } else {
      d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1, 0, 0, 0));
    }

    return {
      caseMonth: d.toISOString(),
      count: 1
    };
  });

  // merge items in briefsToReviewAsAttendingCasesPerMonth with the same caseMonth
  const casesPerMonthMerged = mergeCasesByMonth(casesPerMonth);

  // generate an array of each month in toReviewCasesPerMonthMerged divided by the same month in casesByMonth
  const casesPerMonthMergedMap = new Map(casesPerMonthMerged.map(c => [c.caseMonth, c.count]));

  return casesPerMonthMergedMap;
};

const calcHuddleRateYearToDate = (toReviewCases, reviewedCases) => {
  const toReviewCasesMap = casesListToMap(toReviewCases);
  const reviewedCasesMap = casesListToMap(reviewedCases);

  // generate an array of each month in reviewedCasesMap divided by the same month in toReviewCasesMap
  const ratioMap = Array.from(toReviewCasesMap.keys()).map(caseMonth => {
    const numCases = reviewedCasesMap.get(caseMonth) || 0;
    const toReviewCasesCount = toReviewCasesMap.get(caseMonth);
    const ratio = Math.round(toReviewCasesCount ? (numCases * 100) / toReviewCasesCount : 0, 0);
    return {
      caseMonth,
      count: ratio
    };
  });

  return ratioMap.map(parseCasesByMonth);
};

const roundDateToWeek = (startDate, dateStr) => {
  const fromDate = new Date(startDate);
  const date = new Date(dateStr);
  const nSecInWeek = 1000 * 60 * 60 * 24 * 7;
  const roundedCaseDateNano =
    fromDate.getTime() + Math.floor((date - fromDate) / nSecInWeek) * nSecInWeek;

  return new Date(roundedCaseDateNano);
};

const casesListToWeekMap = (from, until, cases, nonParticipatingAttendings) => {
  const dateStringOptions = {
    year: '2-digit',
    month: 'short',
    day: '2-digit'
  };

  const emptyMap = new Map();
  // fill emptyMap with empty arrays for each week, including weeks that do not exist in cases
  for (let d = new Date(from); d <= new Date(until); d.setDate(d.getDate() + 7)) {
    const roundedDate = roundDateToWeek(from, d);
    const key = roundedDate.toLocaleDateString('en-US', dateStringOptions);
    emptyMap.set(key, []);
  }

  const casesMap = cases?.reduce((map, c) => {
    if (!nonParticipatingAttendings.some(u => u.id === c.attendingId)) {
      const roundedCaseDate = roundDateToWeek(from, lite ? c.createdAt : c.caseDate);
      const key = roundedCaseDate.toLocaleDateString('en-US', dateStringOptions);

      const val = map.get(key) || [];
      val.push(c);
      map.set(key, val);
    }

    return map;
  }, emptyMap);

  return casesMap;
};

const calcHuddleRatePerWeek = (
  from,
  until,
  toReviewCases,
  reviewedCases,
  nonParticipatingAttendings
) => {
  const toReviewCasesMap = casesListToWeekMap(
    from,
    until,
    toReviewCases,
    nonParticipatingAttendings
  );
  const reviewedCasesMap = casesListToWeekMap(
    from,
    until,
    reviewedCases,
    nonParticipatingAttendings
  );

  // generate an array of each month in reviewedCasesMap divided by the same month in toReviewCasesMap
  const huddleRatePerWeek = Array.from(toReviewCasesMap.keys())
    .map(date => {
      const numCases = reviewedCasesMap.get(date)?.length || 0;
      const toReviewCasesCount = toReviewCasesMap.get(date).length;
      const ratio = Math.round(toReviewCasesCount ? (numCases * 100) / toReviewCasesCount : 0, 0);

      return {
        date,
        ratio
      };
    })
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  const huddlesPerWeek = Array.from(reviewedCasesMap.keys())
    .map(date => {
      const ratio = reviewedCasesMap.get(date)?.length || 0;

      return {
        date,
        ratio
      };
    })
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  return { huddleRatePerWeek, huddlesPerWeek };
};

export const myDashboardDataSelector = ({
  data: {
    dashboard: {
      myDashboardData: {
        totalCases,
        totalCasesAsAttending,
        totalCasesAsAssistant,
        totalAddonCases,
        briefDoneCases,
        briefsToReviewAsAttending,
        briefsToReviewAsAttendingCases,
        briefsReviewedAsAttendingCases,
        depBriefsToReviewAsAttendingCases,
        depBriefsReviewedAsAttendingCases,
        casesCountByStateAsAttending,
        casesCountByStateAsAssistant,
        feedbackCount,
        casesByType,
        casesByAttending,
        casesByAssistant,
        lessons,
        feedbacks,
        casesByMonth,
        departmentData: {
          depFeedbackCount,
          depBriefDoneCases,
          depCasesCountByState,
          depTotalCases,
          depTotalAddonCases
        }
      }
    }
  }
}) => {
  const debriefDoneCases =
    CASE_STATE.DEBRIEF_IN_REVIEW in casesCountByStateAsAssistant
      ? casesCountByStateAsAssistant[CASE_STATE.DEBRIEF_IN_REVIEW]
      : 0;

  const debriefsToReviewAsAttending =
    CASE_STATE.DEBRIEF_IN_REVIEW in casesCountByStateAsAttending
      ? casesCountByStateAsAttending[CASE_STATE.DEBRIEF_IN_REVIEW]
      : 0;

  const depDebriefDoneCases =
    CASE_STATE.DEBRIEF_IN_REVIEW in depCasesCountByState
      ? depCasesCountByState[CASE_STATE.DEBRIEF_IN_REVIEW]
      : 0;

  const briefReviewedCases =
    CASE_STATE.READY in casesCountByStateAsAttending
      ? casesCountByStateAsAttending[CASE_STATE.READY]
      : 0;

  const debriefReviewedCases =
    CASE_STATE.COMPLETED in casesCountByStateAsAttending
      ? casesCountByStateAsAttending[CASE_STATE.COMPLETED]
      : 0;

  const depBriefReviewedCases =
    CASE_STATE.READY in depCasesCountByState ? depCasesCountByState[CASE_STATE.READY] : 0;

  const depDebriefReviewedCases =
    CASE_STATE.COMPLETED in depCasesCountByState ? depCasesCountByState[CASE_STATE.COMPLETED] : 0;

  const totalBriefCases = totalCasesAsAssistant - totalAddonCases;
  const depTotalBriefCases = depTotalCases - depTotalAddonCases;

  const huddleRateYearToDate = calcHuddleRateYearToDate(
    briefsToReviewAsAttendingCases,
    briefsReviewedAsAttendingCases
  );

  const depHuddleRateYearToDate = calcHuddleRateYearToDate(
    depBriefsToReviewAsAttendingCases,
    depBriefsReviewedAsAttendingCases
  );

  const rates = bl.dashboard.calcRates({
    briefDoneCases,
    totalBriefCases,
    depBriefDoneCases,
    depTotalBriefCases,
    debriefDoneCases,
    totalCasesAsAssistant,
    depDebriefDoneCases,
    depTotalCases,
    briefReviewedCases,
    briefsToReviewAsAttending,
    depBriefReviewedCases,
    debriefReviewedCases,
    debriefsToReviewAsAttending,
    depDebriefReviewedCases
  });
  const feedbackRate = feedbackCount / debriefsToReviewAsAttending;
  return {
    casesInChiefy: totalCases,
    casesWhereUserAssistant: totalCasesAsAssistant,
    casesWhereUserAttending: totalCasesAsAttending,
    feedbackRate: {
      user: !Number.isNaN(feedbackRate) ? feedbackRate * 100 : 100,
      avg: (depFeedbackCount / depDebriefDoneCases) * 100
    },
    casesYearToDate: casesByMonth.map(parseCasesByMonth),
    huddleRateYearToDate,
    depHuddleRateYearToDate,
    casesByType,
    casesByAttending,
    casesByAssistant,
    recentLessons: lessons.map(l => ({ procedure: l.procedureTitle, text: l.learned, id: l.id })),
    recentFeedbacks: feedbacks.map(f => ({
      procedure: f.procedureTitle,
      text: f.feedback,
      id: f.id
    })),
    ...rates
  };
};

const mergeCasesByMonth = casesByMonth => {
  return mergeElementsByVal(casesByMonth, 'caseMonth', 'count').sort((c1, c2) =>
    c1.caseMonth.localeCompare(c2.caseMonth)
  );
};

const mergeCasesByType = casesByType => {
  return mergeElementsByVal(casesByType, 'name', 'count');
};

const mergeElementsByVal = (obj, key, val) => {
  const typeToSum = obj
    ? obj.reduce((result, currentValue) => {
        if (!result[currentValue[key]]) {
          result[currentValue[key]] = 0;
        }
        result[currentValue[key]] += currentValue[val];
        return result;
      }, {})
    : {};

  const objMerged = [];
  Object.entries(typeToSum).forEach(v => {
    const element = {};
    [element[key], element[val]] = v;
    objMerged.push(element);
  });

  return objMerged;
};

const parseCasesCountByState = statesData => {
  const parsedData = {};
  for (const stateData of statesData) {
    if (!parsedData[stateData.toState]) {
      parsedData[stateData.toState] = 0;
    }

    parsedData[stateData.toState] += stateData.count;
  }

  return parsedData;
};

export const loadDashboardReport =
  (from, until, siteId, attendingIds) => async (dispatch, getState) => {
    const untilMinus7Days = new Date(until);
    untilMinus7Days.setDate(untilMinus7Days.getDate() - 7);
    const [
      reportPerUserInDateRange,
      caseFollowersInDateRange,
      caseFollowersInDateRangeLastWeek,
      caseFeedbackInDateRange,
      chatCountInDataRange,
      readyCaseStateLogsTimeInDataRange,
      briefsToReviewCases,
      briefsReviewedCases,
      nonParticipatingAttendings
    ] = await Promise.all([
      apiService.getReportPerUserInDateRange(from, until, siteId, attendingIds),
      apiService.getCaseFollowersInDateRange(from, until, siteId, attendingIds),
      apiService.getCaseFollowersInDateRange(untilMinus7Days, until, siteId, attendingIds),
      apiService.getCaseFeedbackInDateRange(from, until, siteId, attendingIds),
      apiService.getChatCountInDataRange(from, until, siteId, attendingIds),
      apiService.getReadyCaseStateLogsTimeInDataRange(from, until, siteId, attendingIds),
      apiService.getWeeklyReportBriefsToReviewCases(from, until, siteId, attendingIds),
      apiService.getWeeklyReportBriefsReviewedCases(from, until, siteId, attendingIds),
      apiService.getNonParticipatingAttendings(siteId, attendingIds)
    ]);

    const caseFollowersInDateRangeMerged = caseFollowersInDateRange.reduce(
      (result, currentValue) => {
        const key = `${currentValue.user.id}-${currentValue.case.site.name}`;
        if (!result[key]) {
          result[key] = {
            ...currentValue,
            count: 0,
            views: 0
          };
        }
        result[key].count += 1;
        result[key].views += !!currentValue.lastSeen; // Increase if not null
        return result;
      },
      {}
    );

    const caseFollowersInDateRangeLastWeekMerged = caseFollowersInDateRangeLastWeek.reduce(
      (result, currentValue) => {
        const key = `${currentValue.user.id}-${currentValue.case.site.name}`;
        if (!result[key]) {
          result[key] = {
            ...currentValue,
            count: 0,
            views: 0
          };
        }
        result[key].count += 1;
        result[key].views += !!currentValue.lastSeen; // Increase if not null
        return result;
      },
      {}
    );

    dispatch(
      setDepartmentDashboardReport({
        from,
        until,
        reportPerUserInDateRange,
        caseFollowersInDateRange: Object.values(caseFollowersInDateRangeMerged),
        caseFollowersInDateRangeLastWeek: Object.values(caseFollowersInDateRangeLastWeekMerged),
        caseFeedbackInDateRange,
        chatCountInDataRange,
        readyCaseStateLogsTimeInDataRange,
        briefsToReviewCases,
        briefsReviewedCases,
        nonParticipatingAttendings
      })
    );
  };

export const loadDepartmentDashboardData = () => async (dispatch, getState) => {
  const [
    totalCases,
    totalAddonCases,
    briefDoneCases,
    briefsToReviewAsAttending,
    briefsToReviewAsAttendingCases,
    briefsReviewedAsAttendingCases,
    casesCountByState,
    feedbackCount,
    casesBySite,
    casesByMonth,
    casesByType,
    casesByAssistant,
    casesByAttending
  ] = await Promise.all([
    apiService.getAllPublishedCasesCount(),
    apiService.getAllAddOnPublishedCasesCount(),
    apiService.getAllBriefDoneCasesCount(),
    apiService.getAllBriefsToReviewAsAttending(),
    apiService.getAllBriefsToReviewAsAttendingCases(),
    apiService.getAllBriefsReviewedAsAttendingCases(),
    apiService.getAllCasesCountByState(),
    apiService.getAllFeedbackCasesCount(),
    apiService.getAllCasesCountBySite(),
    apiService.getAllCurrentYearCasesCountByMonth(),
    apiService.getAllCasesCountByType(),
    apiService.getAllCasesCountByAssistant(),
    apiService.getAllCasesCountByAttending()
  ]);
  dispatch(
    setDepartmentDashboardData({
      totalCases,
      totalAddonCases,
      depBriefDoneCases: briefDoneCases,
      briefsToReviewAsAttending,
      briefsToReviewAsAttendingCases,
      briefsReviewedAsAttendingCases,
      casesCountByState: parseCasesCountByState(casesCountByState),
      feedbackCount,
      casesBySite,
      casesByMonth,
      casesByType,
      casesByAssistant,
      casesByAttending
    })
  );
};

export const loadMyDashboardData = () => async (dispatch, getState) => {
  try {
    const [
      totalCases,
      totalAddonCases,
      feedbackCount,
      feedbacks,
      lessons,
      briefDoneCases,
      briefsToReviewAsAttending,
      briefsToReviewAsAttendingCases,
      briefsReviewedAsAttendingCases,
      depBriefsToReviewAsAttendingCases,
      depBriefsReviewedAsAttendingCases,
      depBriefDoneCases,
      casesCountByTypeAsAssistant,
      casesCountByTypeAsAttending,
      casesByAssistant,
      casesByAttending,
      casesByMonthAsAttending,
      casesByMonthAsAssistant,
      depFeedbackCount,
      depCasesCountByState,
      totalCasesAsAssistant,
      totalCasesAsAttending,
      casesCountByStateAsAssistant,
      casesCountByStateAsAttending,
      depTotalCases,
      depTotalAddonCases
    ] = await Promise.all([
      apiService.getMyPublishedCasesCount(),
      apiService.getMyAddOnPublishedCasesCount(),
      apiService.getMyFeedbackCasesCount(),
      apiService.getMyTopFeedback(),
      apiService.getMyTopLearned(),
      apiService.getMyBriefDoneCasesCount(),
      apiService.getMyBriefsToReviewAsAttending(),
      apiService.getMyBriefsToReviewAsAttendingCases(),
      apiService.getMyBriefsReviewedAsAttendingCases(),
      apiService.getAllBriefsToReviewAsAttendingCases(),
      apiService.getAllBriefsReviewedAsAttendingCases(),
      apiService.careTeamGetAllBriefDoneCasesCount(),
      apiService.getMyCasesCountByTypeAsAssistant(),
      apiService.getMyCasesCountByTypeAsAttending(),
      apiService.getMyCasesCountByAssistant(),
      apiService.getMyCasesCountByAttending(),
      apiService.getMyCurrentYearCasesCountByMonthAsAttending(),
      apiService.getMyCurrentYearCasesCountByMonthAsAssistant(),
      apiService.getCareTeamAllFeedbackCasesCount(),
      apiService.getCareTeamAllCasesCountByState(),
      apiService.getMyPublishedCasesCountAsAssistant(),
      apiService.getMyPublishedCasesCountAsAttending(),
      apiService.getMyCasesCountByStateAsAssistant(),
      apiService.getMyCasesCountByStateAsAttending(),
      apiService.getCareTeamAllPublishedCasesCount(),
      apiService.getCareTeamAllAddOnPublishedCasesCount()
    ]);
    // casesCountByState
    dispatch(
      setMyDashboardData({
        totalCases,
        totalAddonCases,
        feedbackCount,
        feedbacks,
        lessons,
        briefDoneCases,
        briefsToReviewAsAttending,
        briefsToReviewAsAttendingCases,
        briefsReviewedAsAttendingCases,
        depBriefsToReviewAsAttendingCases,
        depBriefsReviewedAsAttendingCases,
        casesByType: mergeCasesByType([
          ...casesCountByTypeAsAssistant,
          ...casesCountByTypeAsAttending
        ]),
        casesByAssistant,
        casesByAttending,
        casesCountByState: parseCasesCountByState([
          ...casesCountByStateAsAssistant,
          ...casesCountByStateAsAttending
        ]),
        casesByMonth: mergeCasesByMonth([...casesByMonthAsAttending, ...casesByMonthAsAssistant]),
        departmentData: {
          depFeedbackCount,
          depCasesCountByState: parseCasesCountByState(depCasesCountByState),
          depTotalCases,
          depTotalAddonCases,
          depBriefDoneCases
        },
        totalCasesAsAssistant,
        totalCasesAsAttending,
        casesCountByStateAsAssistant: parseCasesCountByState(casesCountByStateAsAssistant),
        casesCountByStateAsAttending: parseCasesCountByState(casesCountByStateAsAttending)
      })
    );
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};
export const updateResetStatsAt = () => async (dispatch, getState) => {
  try {
    await apiService.setResetStatsAt();
    dispatch(loadMyCasesDataShort());
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

const checkCase = (c, userId) => {
  const isAddOn = () => c.isAddOn;
  const isResident = () => c.residentId === userId;
  const isAttending = () => c.attendingId === userId;
  const isBriefDone = () => c.caseStateLogs.some(l => l.toState === CASE_STATE.BRIEF_IN_REVIEW);
  return {
    isAddOn,
    isAttending,
    isResident,
    isBriefDone
  };
};

const calcStatsCasesCount = (cases, userId) => {
  const totalCases = cases.length;
  let totalAddon = 0;
  let totalCasesAsAssistant = 0;
  let totalCasesAsAttending = 0;
  let briefAddOn = 0;
  for (const c of cases) {
    const checkCaseF = checkCase(c, userId);

    totalAddon += checkCaseF.isAddOn() ? 1 : 0;
    totalCasesAsAttending += checkCaseF.isAttending() ? 1 : 0;
    totalCasesAsAssistant += checkCaseF.isResident() ? 1 : 0;
    briefAddOn +=
      checkCaseF.isResident() && checkCaseF.isAddOn() && checkCaseF.isBriefDone() ? 1 : 0;
  }

  return {
    totalCases,
    totalAddonCases: totalAddon - briefAddOn,
    totalCasesAsAssistant,
    totalCasesAsAttending
  };
};

export const loadMyCasesDataShort = () => async (dispatch, getState) => {
  try {
    const { id: userId, resetStatsAt: statsResetAt } = getState().auth.user.data;

    const cases = await apiService.getMyPublishedCases(statsResetAt);
    const { totalCasesAsAssistant, totalCasesAsAttending, totalAddonCases } = calcStatsCasesCount(
      cases,
      userId,
      statsResetAt
    );
    const [
      {
        countMyCasesByStateAsAttending: casesCountByStateAsAttending,
        countMyCasesByStateAsAssistant: casesCountByStateAsAssistant,
        countCasesByState: depCasesCountByState
      },
      depTotalAddonCases,
      depTotalCases,
      briefDoneCases,
      briefsToReviewAsAttending,
      depBriefDoneCases
    ] = await Promise.all([
      apiService.getMyCasesDataMerged(statsResetAt),
      apiService.getCareTeamAllAddOnPublishedCasesCount(statsResetAt), // not merged
      apiService.getCareTeamAllPublishedCasesCount(statsResetAt), // not merged
      apiService.getMyBriefDoneCasesCount(statsResetAt),
      apiService.getMyBriefsToReviewAsAttending(statsResetAt),
      apiService.careTeamGetAllBriefDoneCasesCount(statsResetAt)
    ]);

    dispatch(
      setMyCasesData({
        briefDoneCases,
        briefsToReviewAsAttending,
        casesCountByStateAsAssistant: parseCasesCountByState(casesCountByStateAsAssistant),
        casesCountByStateAsAttending: parseCasesCountByState(casesCountByStateAsAttending),
        totalCasesAsAssistant,
        totalCasesAsAttending,
        totalAddonCases,
        statsResetAt,
        departmentData: {
          depCasesCountByState: parseCasesCountByState(depCasesCountByState),
          depTotalAddonCases,
          depTotalCases,
          depBriefDoneCases
        }
      })
    );
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

const initialState = {
  myCasesData: {
    totalCasesAsAssistant: 0,
    totalCasesAsAttending: 0,
    casesCountByStateAsAssistant: {},
    casesCountByStateAsAttending: {},
    totalAddonCases: 0,
    statsResetAt: false,
    departmentData: {
      depCasesCountByState: {},
      depTotalCases: 0,
      depTotalAddOnCases: 0
    },
    loaded: false
  },

  myDashboardData: {
    totalCases: 0,
    totalAddonCases: 0,
    casesCountByState: {},
    feedbackCount: 0,
    lessons: [],
    feedbacks: [],
    casesByMonth: [],
    casesByType: [],
    casesByAssistant: [],
    casesByAttending: [],
    totalCasesAsAssistant: 0,
    totalCasesAsAttending: 0,
    casesCountByStateAsAssistant: {},
    casesCountByStateAsAttending: {},
    departmentData: {
      depFeedbackCount: 0,
      depCasesCountByState: {},
      depTotalCases: 0,
      depTotalAddOnCases: 0
    },
    loaded: false
  },
  departmentDashboardData: {
    totalCases: 0,
    totalAddonCases: 0,
    casesCountByState: {},
    feedbackCount: 0,
    casesBySite: [],
    casesByMonth: [],
    casesByType: [],
    casesByAssistant: [],
    casesByAttending: []
  },
  reportData: {
    from: null,
    until: null,
    reportPerUserInDateRange: [],
    caseFollowersInDateRange: [],
    caseFollowersInDataRangeLastWeek: [],
    caseFeedbackInDateRange: [],
    chatCountInDataRange: null,
    readyCaseStateLogsTimeInDataRange: [],
    pStepsInDateRange: [],
    briefsToReviewCases: [],
    briefsReviewedCases: [],
    nonParticipatingAttendings: []
  }
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setMyDashboardData: (state, action) => {
      state.myDashboardData = { ...action.payload, loaded: true };
    },
    setDepartmentDashboardData: (state, action) => {
      state.departmentDashboardData = action.payload;
    },
    setDepartmentDashboardReport: (state, action) => {
      state.reportData = action.payload;
    },
    setMyCasesData: (state, action) => {
      state.myCasesData = { ...action.payload, loaded: true };
    },
    setResetStatsAt: (state, action) => {
      state.myCasesData.statsResetAt = action.payload;
    }
  },
  extraReducers: {}
});

export const {
  setDepartmentDashboardReport,
  setDepartmentDashboardData,
  setMyDashboardData,
  setMyCasesData,
  setResetStatsAt
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
