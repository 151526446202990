import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { randomColorFromIndex } from './DashboardPieChart';

const parseCasesYearToDate = (data, accumulate) => {
  const years = new Map();
  let lastValid;
  data.forEach(d => {
    if (!years.has(d.year)) {
      const y = new Array(12);
      lastValid = d.month - 1;
      y[lastValid] = d.count;
      years.set(d.year, y);
    } else {
      const y = years.get(d.year);

      if (accumulate) {
        // If there's a gap between the months, fill it with the last valid sum
        if (lastValid !== d.month - 1) {
          const count = d.month - lastValid - 1;
          y.splice(lastValid + 1, 0, ...Array(count).fill(y[lastValid]));
        }
        y[d.month - 1] = y[d.month - 2] + d.count;
        lastValid = d.month - 1;
      } else {
        y[d.month - 1] = d.count;
      }
    }
  });

  return years;
};

function DashboardMonthlyLineChart(props) {
  const theme = useTheme();
  // const { t } = useTranslation();
  const { data, accumulate } = props;

  const yearsToDateMap = parseCasesYearToDate(data, accumulate);
  const yearsToDateArr = Array.from(yearsToDateMap.keys()).map(key => {
    const col = props.colors ? props.colors[key] : randomColorFromIndex(key);
    return {
      data: yearsToDateMap.get(key),
      borderDash: props.dash ? (props.dash[key] ? [5, 5] : undefined) : undefined,
      label: key,
      spanGaps: true,
      fill: false,
      lineTension: 0.4,
      borderColor: col,
      pointBorderColor: col,
      pointBackgroundColor: '#fff'
    };
  });

  return (
    <Card className="flex flex-col justify-between w-full h-full rounded-8 shadow-1 p-16">
      <Typography className="h3" color="textSecondary">
        {props.title}
      </Typography>

      <div className="w-100-p mt-20">
        <Line
          height={props.height || 250}
          data={{
            labels: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec'
            ],
            datasets: yearsToDateArr
          }}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: yearsToDateArr.length > 1
              },
              datalabels: {
                display: false
              }
            },
            tooltips: {
              mode: 'index',
              intersect: false
            },
            hover: {
              mode: 'nearest',
              intersect: true
            },
            scales: {
              y: {
                scaleLabel: {
                  display: Boolean(props.yLabel),
                  labelString: props.yLabel
                },
                ticks: {
                  min: 0,
                  callback(value) {
                    return value % 1 === 0 && (!props.percentage || value <= 100)
                      ? `${value}${props.percentage ? '%' : ''}`
                      : '';
                  }
                },
                beginAtZero: true,
                max: props.percentage ? 110 : undefined
              },
              x: {
                gridLines: {
                  display: false
                },
                ticks: {
                  padding: 5
                }
              }
            }
          }}
        />
      </div>
    </Card>
  );
}

export default React.memo(DashboardMonthlyLineChart);
