import { gql } from '@apollo/client';

export const DEP_SITE_FULL_DATA = gql`
  fragment DepSiteFullData on sites {
    id
    name
    created_at
    updated_at
    is_active
    specialties
    attending_only
    timezone
  }
`;

export const DEP_USER_FULL_DATA = gql`
  fragment DepUserFullData on users {
    id
    nick_name
    first_name
    last_name
    pgy
    avatar
    email
    created_at
    updated_at
    is_pa
    is_cases_scheduler
    is_assigns_residents
    is_nurse_leader
    phone
    roles {
      role
    }
    is_active
    user_sites {
      site_id
      site {
        name
      }
    }
    blank_case_always
    follow_all
    mute_until
    has_pn
    reminder_days
    vendor
  }
`;

export const META_FULL_DATA = gql`
  fragment MetaFullData on meta {
    key
    value
  }
`;
