import React from 'react';
import clsx from 'clsx';
import Paper from '@mui/material/Paper';
import FormLabel from '@mui/material/FormLabel';

function InfoBox(props) {
  let classes;
  const value =
    props.value && props.value.value !== undefined ? props.value.value : props.value || '';

  switch (props.variant) {
    case 'plain':
      classes = {
        paper: '',
        text: `text-16 ${props.label && 'mt-6'}`
      };
      break;
    default:
      // default - orange info box
      classes = {
        paper: 'bg-orange-100 bg-opacity-25 border-l-4 border-orange-500 px-20 py-20',
        text: 'italic font-thin text-lg text-gray-600 pt-10'
      };
  }

  return (
    <Paper
      square
      elevation={props.variant === 'plain' ? 0 : 1}
      className={clsx(classes.paper, props.className)}
    >
      {props.label && <FormLabel component="legend">{props.label}</FormLabel>}
      <div className={clsx(classes.text)}>{value}</div>
    </Paper>
  );
}

export default React.memo(InfoBox);
