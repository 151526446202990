import React from 'react';
import { Trans } from 'react-i18next';
import Typography from '@mui/material/Typography';
import toast from 'app/services/toastService/toast';
import StyledRating from 'app/components/styledRating/StyledRating';

export default function HuddleRating(props) {
  const handleSubmit = async value => {
    try {
      if (props.feedback?.id) {
        props.handlePlanFeedbackUpdate(
          props.feedback.id,
          null,
          null,
          value,
          null,
          null,
          null,
          null
        );
      } else {
        props.submitPlanFeedback(null, null, value, null, null, null, null);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
    props.setRating(value);
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <Typography variant="body1" gutterBottom color="primary" fontWeight="bold">
        <Trans i18nKey="how_did_this_case_go" />
      </Typography>
      <StyledRating
        onChange={newValue => {
          handleSubmit(newValue);
        }}
        value={props.feedback?.rating ? parseFloat(props.feedback.rating) : undefined}
      />
    </div>
  );
}
