import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MediaDialog from './MediaDialog';
import MediaGrid from './MediaGrid';

export default function Media(props) {
  const [openDialog, setOpenDialog] = React.useState(null);

  const handleDelete = asset => {
    props.handleDelete(asset);
  };

  const handleUpload = asset => {
    return props.handleUpload(asset);
  };

  const handleUpdate = asset => {
    return props.handleUpdate(asset);
  };

  const handleSubmit = async asset => {
    await props.handleSubmit(asset);
  };

  if (props.disabled) {
    if (!props.value || props.value.length === 0) {
      return null;
    }
  }

  return (
    <FormControl className="mt-16">
      {props.label && <FormLabel>{props.label}</FormLabel>}

      <MediaGrid
        assets={props.value}
        onClick={setOpenDialog}
        handleDelete={handleDelete}
        handleUpload={handleUpload}
        handleUpdate={handleUpdate}
        handleSubmit={handleSubmit}
        getSignature={props.getSignature}
        disabled={props.disabled}
      />

      <MediaDialog assets={props.value} open={openDialog} setOpen={setOpenDialog} />

      {Boolean(props.errorMessage) && (
        <FormHelperText {...props.FormHelperTextProps}>{props.errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
}
