import MomentAdapter from '@date-io/moment';
import amplService from 'app/services/amplService';
import toast from 'app/services/toastService/toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CASE_STATE, ROLES } from 'app/consts';

const useExport = () => {
  const { t } = useTranslation();
  const user = useSelector(({ auth }) => auth.user);
  const cases = useSelector(({ data }) => data.cases.items);
  const handleExport = () => {
    const dateLib = new MomentAdapter();

    const parseCaseDataToExport = c => {
      const dateOnly = dateLib.date(c.caseDate).format('M/D');
      const siteStr = c.site.name === 'Default' ? '' : c.site.name;
      const attStr = `${c.attending.firstName} ${c.attending.lastName}`;
      const assistantStr = `${c.resident?.firstName} ${c.resident?.lastName}`;
      const procStr = c.procedureTitle.replace(',', '');
      const prvNote =
        c.privateNotes?.length > 0
          ? c.privateNotes[0].note.replaceAll(',', ';').replaceAll('\n', ' | ')
          : '';

      if (!user.role.includes(ROLES.RESIDENT)) {
        return {
          Date: dateOnly,
          Procedure: procStr,
          Site: siteStr,
          Assistant: assistantStr,
          'Private Note': prvNote
        };
      }

      if (user.data.isPa) {
        return {
          Date: dateOnly,
          Procedure: procStr,
          Attending: attStr,
          Site: siteStr,
          'Private Note': prvNote
        };
      }

      // resident
      return {
        Procedure: procStr,
        Date: dateOnly,
        Role: c.residentRole,
        Site: siteStr,
        Attending: attStr,
        'Patient Gender': c.patientGender,
        'Patient Type': c.patientType,
        'Private Note': prvNote
      };
    };

    const parsedData = cases
      .filter(c => c.state === CASE_STATE.ARCHIVED)
      .map(parseCaseDataToExport);

    amplService.sendEvent(amplService.EVENTS.EXPORT_CASE_LOG);
    toast.success(t(`download_success_toast`));

    return parsedData;
  };
  return handleExport;
};

export default useExport;
