import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import auth0Service from 'app/services/auth0Service';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import apiService from 'app/services/apiService';
import * as Sentry from '@sentry/react';
import { useTimeout } from '@fuse/hooks';
import { setUserData } from 'app/auth/store/userSlice';

function Callback(props) {
  const dispatch = useDispatch();
  const [authenticated, setAuthenticated] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const handleAuth = () => {
    apiService.init();
    dispatch(setUserData({ role: [''], data: {} }));
  };
  useTimeout(() => {
    if (!authenticated) {
      console.log('Authentication event not fired', { authenticated, isAuth });
      Sentry.captureException(new Error('Authentication event not fired'), {
        extra: {
          authenticated,
          isAuth
        }
      });
    } else {
      console.log('Callback time out', { authenticated, isAuth });
      Sentry.captureMessage('Callback time out', {
        extra: {
          authenticated,
          isAuth
        }
      });
    }
  }, 2000);
  useEffect(() => {
    setIsAuth(auth0Service.isAuthenticated());
    Sentry.captureMessage('callback useEffect init');
    Sentry.addBreadcrumb({
      category: 'login',
      message: `callback useEffect init`,
      level: Sentry.Severity.Info
    });
    if (auth0Service.isAuthenticated()) {
      handleAuth();
      return;
    }
    auth0Service.onAuthenticated(() => {
      setAuthenticated(true);
      console.log('callback useEffect onAuthenticated');
      Sentry.captureMessage('callback useEffect onAuthenticated');
      Sentry.addBreadcrumb({
        category: 'login',
        message: `callback useEffect onAuthenticated`,
        level: Sentry.Severity.Info
      });
      handleAuth();
      /**
       * Retrieve user data from Auth0
       */
    });
  }, [dispatch]);

  return <FuseSplashScreen />;
}

export default Callback;
