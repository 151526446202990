import React, { Fragment } from 'react';
import CasesList from 'app/components/casesList/CasesList';
import { useSelector, useDispatch } from 'react-redux';
import { loadMyCases, deleteCase, archiveCase } from 'app/store/data/casesSlice';
import PastCases from 'app/pages/cases/casesComponents/PastCases';
import usePastCases from 'app/pages/cases/hooks/usePastCases';
import { CASE_STATE } from 'app/consts';

const MyCases = ({
  casesLoaded,
  loadCaseById,
  newCase,
  handleShare,
  getCases,
  clearCases,
  setTab
}) => {
  const dispatch = useDispatch();
  const { loadInitPastCases } = usePastCases();
  const cases = useSelector(({ data }) => data.cases.items);
  const myCases = cases.filter(item =>
    [
      CASE_STATE.IN_BRIEF,
      CASE_STATE.BRIEF_IN_REVIEW,
      CASE_STATE.READY,
      CASE_STATE.IN_DEBRIEF,
      CASE_STATE.DEBRIEF_IN_REVIEW,
      CASE_STATE.COMPLETED
    ].includes(item.state)
  );

  return (
    <Fragment>
      <CasesList
        cases={myCases}
        totalCases={cases.length}
        loadCaseById={loadCaseById}
        newCase={newCase}
        handleDelete={async kase => {
          await dispatch(deleteCase(kase));
          dispatch(loadMyCases());
        }}
        handleArchive={async kase => {
          await dispatch(archiveCase(kase));
          loadInitPastCases();
          dispatch(loadMyCases());
        }}
        handleShare={handleShare}
        loadCases={getCases}
        tab="my-cases"
        clearCases={clearCases}
        setTab={setTab}
      />
      <br />
      <PastCases
        casesLoaded={casesLoaded}
        dispatch={dispatch}
        loadCaseById={loadCaseById}
        tab="my-past-cases"
        handleShare={handleShare}
      />
    </Fragment>
  );
};

export default MyCases;
