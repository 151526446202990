import { useState } from 'react';
import { loadFullCaseData } from 'app/store/data/casesSlice';
import { shareCaseFilesImpl, shareCaseImpl } from 'app/components/caseViews/ShareCase';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ANALYTICS_SOURCE = 'case list';
const useShare = ({ onAfterShare }) => {
  const [analyticSource, setAnalyticSource] = useState(ANALYTICS_SOURCE);
  const [showFilesDialog, setShowFilesDialog] = useState(false);
  const [caseFiles, setCaseFiles] = useState([]);
  const user = useSelector(({ auth }) => auth.user);
  const { t } = useTranslation();
  const calculatedDisplayIds = useSelector(({ data }) => data.cases.calculatedDisplayIds);

  const handleShare = async (kaseId, source = ANALYTICS_SOURCE) => {
    setAnalyticSource(source);
    const kase = await loadFullCaseData(kaseId, user.data.id);
    await shareCaseImpl(kase, t, calculatedDisplayIds, source);
    setCaseFiles(kase.files);
    if (kase.files.length) {
      setShowFilesDialog(true);
    } else {
      onAfterShare();
    }
  };
  const onShareDialogClose = () => {
    setShowFilesDialog(false);
    setAnalyticSource(ANALYTICS_SOURCE);
    setCaseFiles([]);
    onAfterShare();
  };
  const shareCaseFiles = async () => {
    await shareCaseFilesImpl(caseFiles, analyticSource);
    onShareDialogClose();
  };

  return {
    shareCaseFiles,
    handleShare,
    showFilesDialog,
    onShareDialogClose
  };
};

export default useShare;
