const skyBlue = {
  50: '#ecfbff',
  100: '#d0f4fe',
  200: '#b0edfd',
  300: '#90e5fc',
  400: '#79e0fc',
  500: '#61dafb',
  600: '#59d6fa',
  700: '#4fd0fa',
  800: '#45cbf9',
  900: '#33c2f8',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#d7f3ff',
  A700: '#beecff'
};

export default skyBlue;
