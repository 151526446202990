import React, { Fragment, useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import Formsy from 'formsy-react';
import TextField from 'app/components/forms/TextField';
import Info from 'app/components/forms/InfoBox';
import RadioGroup from 'app/components/forms/RadioGroup';
import Checkbox from 'app/components/forms/Checkbox';
import Select from 'app/components/forms/Select';
import Chips from 'app/components/forms/Chips';
import Switch from 'app/components/forms/Switch';
import OtherTextField from 'app/components/forms/OtherTextField';
import SubmitFab from 'app/components/forms/SubmitFab';
import RememberPreferences from 'app/components/forms/RememberPreferences';
import Media from 'app/components/media/Media';
import LessonsLearned from 'app/components/lessonsLearned/LessonsLearned';

// Field generic schema:
// field = {
//   name: 'NAME',
//   label: 'LABEL',
//   type: 'text/select/chips/dateTime/radio/toggle/fab',
//   value: VALUE,
//   className: 'CLASSES',
//   required: BOOL,
//   disabled: BOOL, // fixed value
//   shouldDisable: (formRef) => return VALUE,
//   shouldRender: (formRef) => return VALUE,
//   validations: see https://github.com/formsy/formsy-react/blob/master/API.md#validators,
//   validationError: see https://github.com/formsy/formsy-react/blob/master/API.md#validationErrors
//   hasOther: BOOL,
//   otherFieldProps: {
//   // these are passed to the 'other' text-input...
//     value: VALUE,
//   },
//   ...any other MUI input propertiesd
// }
import toast from 'app/services/toastService/toast';

const inputs = {
  text: TextField,
  select: Select,
  radio: RadioGroup,
  chips: Chips,
  checkbox: Checkbox,
  switch: Switch,
  rememberPreferences: RememberPreferences,
  media: Media,
  lessonsLearned: LessonsLearned,
  fab: SubmitFab,
  info: Info
};

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiInputLabel-animated': {
      pointerEvents: 'none'
    },
    '& .field-doneButton': {
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut
      })
    },
    '&.hide-submit .field-doneButton': {
      transform: 'scale(0.5)',
      opacity: 0
    }
  },
  field: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&.horizontal': {
      '& .MuiFormGroup-root': {
        flexDirection: 'row'
      },
      '& .MuiFormControlLabel-root': {
        width: theme.spacing(20)
      }
    },
    '&.readonly': {
      pointerEvents: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        opacity: 0.5
      },
      '& .MuiFormLabel-root.Mui-disabled': {
        color: theme.palette.secondary.main
      },
      '& .MuiInputBase-root.Mui-disabled': {
        color: theme.palette.text.primary
      },
      '& .MuiRadio-root': {
        opacity: 0,
        width: 0,
        padding: '9px 0 3px 11px'
      }
    },
    '&.section-toggle': {
      '& .MuiCheckbox-root': {
        opacity: 0,
        padding: 0,
        height: 0,
        width: 11,
        boxSizing: 'border-box'
      },
      '& .label': {
        color: theme.palette.secondary.main,
        fontWeight: 800,
        textTransform: 'uppercase',
        display: 'block',
        fontSize: '1.2rem'
      },
      '& .sub-label': {
        display: 'block',
        color: '#777',
        fontSize: '1.2rem',
        '&.expand': { display: 'block' },
        '&.collapse': { display: 'none' }
      },
      '& .Mui-checked + .MuiFormControlLabel-label .sub-label': {
        '&.expand': { display: 'none' },
        '&.collapse': { display: 'block' }
      }
    }
  },
  otherField: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(2),
    '&.readonly': {
      pointerEvents: 'none'
    }
  },
  fieldWidth: {
    width: 548,
    maxWidth: 'calc(90% + 8px)'
  }
}));

const getRecs = (recommendations, field) => {
  const isDevMode = localStorage.getItem('devMode') === 'true';
  if (!isDevMode) {
    return null;
  }

  const recsArr = [];
  const opts = recommendations?.[field.id]?.options;
  if (opts) {
    Object.entries(opts).forEach(([opt, optVal]) => {
      if (optVal.attRecommendation) {
        recsArr.push(`${opt}: ${optVal.attRecommendation}`);
      }
    });
  }

  return recsArr.length ? <div className="recommendations">💡{recsArr.join(', ')}</div> : null;
};

function DynamicForm(props) {
  const classes = useStyles();
  const [isFormValid, setIsFormValid] = useState(false);
  const [hiddenFields, setHiddenFields] = useState({});
  const [disabledFields, setDisabledFields] = useState({});
  const [otherFields, setOtherFields] = useState({});

  const formRef = useRef(null);
  const { description } = props.kase;
  const [firstRun, setFirstRun] = useState(true);
  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      return;
    }
    const descriptionInput = formRef.current.inputs.find(
      input => input.props.name === 'description'
    );
    descriptionInput.setValue(description);
  }, [description]);
  // Update field visibility state.
  const handleHiddenFields = source => {
    // Conditional fields
    const nextHiddenFields = {};
    // let shouldUpdateHiddenFields = false;
    props.fields
      .filter(field => field.shouldRender)
      .forEach(field => {
        nextHiddenFields[field.name] = !field.shouldRender(formRef);
      });
    setHiddenFields(nextHiddenFields);

    // Disabled fields
    const nextDisabledFields = {};
    props.fields
      .filter(field => field.shouldDisable)
      .forEach(field => {
        nextDisabledFields[field.name] = field.shouldDisable(formRef);
      });
    setDisabledFields(nextDisabledFields);

    // hasOther fields
    let currentValues = false;
    const newOtherFieldsState = {};
    props.fields
      .filter(field => field.hasOther)
      .forEach(field => {
        currentValues = currentValues || formRef.current.getCurrentValues();
        const fieldValues = currentValues[field.name];
        newOtherFieldsState[field.name] = Array.isArray(fieldValues)
          ? fieldValues.some(v => v?.value?.toLowerCase() === 'other')
          : fieldValues?.value?.toLowerCase() === 'other';
      });
    setOtherFields(newOtherFieldsState);
  };
  // Initial
  useEffect(handleHiddenFields, []);

  const handleChange = (currentValues, isChanged) => {
    if (props.onChange) {
      props.onChange(currentValues, isChanged, formRef);
    }

    // Update hiddenFields
    handleHiddenFields();
  };

  const disableButton = () => {
    setIsFormValid(false);
  };

  const enableButton = () => {
    setIsFormValid(true);
  };

  const handleSubmit = async (model, resetModel, updateInputsWithError, event) => {
    disableButton();
    try {
      if (props.submitToServer) {
        await props.submitToServer(model, formRef, resetModel, updateInputsWithError, event);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }

    enableButton();
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' && e.target.type !== 'textarea') {
      // Prevent keyboard submits, allows Enter in textarea or submit buttons
      if (!['textarea', 'submit'].includes(e.target.type)) {
        e.preventDefault();
      }
    }
  };

  const renderField = field => {
    let TagName = inputs[field.type || 'text'];

    const formDisabled = props.disabled;

    // Hide empty fields when form is disabled (i.e. Archived)
    if (formDisabled || field.disabled) {
      if (!field.value || field.value.length === 0) {
        return null;
      }

      // Remove the "Other" option, if not selected
      if (field.hasOther && field.options) {
        if (field.multiple) {
          if (field.value.filter(opt => opt.value.toLowerCase() === 'other').length === 0) {
            field.options = field.options.filter(
              opt => opt.value && opt.value.toLowerCase() !== 'other'
            );
          }
        } else if (field.value.value.toLowerCase() !== 'other') {
          field.options = field.options.filter(opt => opt.value.toLowerCase() !== 'other');
        }
      }
    }

    // Select type override (Select/Chips/Radio)
    if (field.type === 'select') {
      if (formDisabled || field.disabled) {
        // If disabled show (selected only) as a radio list
        TagName = inputs.radio;

        if (field.filterOptions && formRef && formRef.current) {
          // Options filter
          field.options = field.filterOptions(field.options, formRef);
        }
      } else if (field.multiple && field.options.length) {
        // Multi-select is Chips
        TagName = inputs.chips;
      } else if (!field.multiple && field.options.length && field.options.length < 4) {
        // Single-select with 3 or less options is a Radio button group
        TagName = inputs.radio;
      }
    }

    // TextArea
    if (field.multiline) {
      if (formDisabled || field.disabled) {
        TagName = inputs.info;
        field.variant = 'plain';
      } else {
        field.variant = 'standard';
      }
    }

    // Media
    if (field.type === 'media') {
      if (formDisabled) {
        field.disabled = true;
      }
    }

    // Submit
    if (field.type === 'submit') {
      TagName = inputs.fab;
      field.type = 'submit';
      field.disabled = !isFormValid || formDisabled || props.disableSubmit;
      field.isFormValid = inputs.isFormValid;
    }

    // Enable field highlight similar to a selected Chip
    if ([inputs.text, inputs.select].includes(TagName)) {
      field.chipLike = true;
    }
    if (field.hasOther) {
      field.otherFieldProps.chipLike = true;
    }

    return (
      <Fragment key={field.name}>
        <TagName
          {...field}
          tab={props.tab}
          key={field.name}
          className={clsx(
            `field-${field.name}`,
            classes.field,
            field.className,
            { required: field.required },
            { hidden: hiddenFields[field.name] },
            { readonly: (formDisabled || field.disabled) && !field.alwaysEditable },
            {
              [classes.fieldWidth]: [
                inputs.text,
                inputs.select,
                inputs.radio,
                inputs.checkbox
              ].includes(TagName)
            }
          )}
          formRef={formRef}
          disabled={formDisabled || field.disabled || disabledFields[field.name]}
          required={field.required && !hiddenFields[field.name]}
          changedBy={field.changedBy}
        />
        {field.hasOther && (
          <OtherTextField
            key={`${field.name}-other`}
            {...field}
            {...field.otherFieldProps}
            formRef={formRef}
            isVisible={!hiddenFields[field.name] && otherFields[field.name]}
            className={clsx(classes.otherField, classes.fieldWidth, { readonly: formDisabled })}
            disabled={formDisabled || field.disabled}
          />
        )}
        {getRecs(props.recommendations, field)}
      </Fragment>
    );
  };

  return (
    <Formsy
      className={clsx(classes.root, props.className, 'flex flex-col justify-center')}
      ref={formRef}
      onKeyDown={handleKeyDown}
      onValidSubmit={handleSubmit}
      onValid={enableButton}
      onInvalid={disableButton}
      onChange={handleChange}
      disabled={props.disabled}
    >
      {props.fields.map(field => renderField(field))}
    </Formsy>
  );
}

export default DynamicForm;
