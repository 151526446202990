import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { withRouter } from 'react-router-dom';
import ffService from 'app/services/ffService/ffService';
import OfflinePopup from 'app/components/offlinePopup/OfflinePopup';
import { setEnum, setLoading, setLoggedInUserData } from './store/enumSlice';

class MetaData extends Component {
  async componentDidMount() {
    const { location } = this.props;
    const { pathname } = location;
    if (pathname === '/login' && !this.props.loggedIn) {
      this.props.setLoading(false);
    } else {
      this.props.setLoading(true);
    }
    if (pathname === '/login' && this.props.loggedIn) {
      this.props.history.push('/');
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Component will update:
    // 1. after logging in (render SplashScreen)
    // 2. after done enum.loading (render children)

    return (
      nextProps.loggedIn !== this.props.loggedIn ||
      this.props.enum.loading !== nextProps.enum.loading ||
      this.props.onLineStatus !== nextProps.onLineStatus
    );
  }

  async componentDidUpdate() {
    // Call setEnum() after finished loading.
    if (this.props.loggedIn && this.props.enum.loaded === false) {
      const user = await this.props.setLoggedInUserData();

      if (user?.id) {
        await ffService.init(user.id);
      }
      await this.props.setEnum();
      this.props.setLoading(false);
    }
  }

  render() {
    return this.props.loggedIn && !this.props.enum.loaded ? (
      <FuseSplashScreen />
    ) : (
      <div style={{ width: '100%' }}>
        {!this.props.isOnline && <OfflinePopup />}
        {this.props.children}
      </div>
    );
  }
}

function mapStateToProps({ auth, metaData }, ownProps) {
  return {
    loggedIn: auth.user.loggedIn,
    enum: metaData.enum,
    onLineStatus: ownProps.isOnline
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEnum,
      setLoggedInUserData,
      setLoading
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MetaData));
