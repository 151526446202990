import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';
import Imports from './Imports';

const ImportsConfig = {
  settings: {
    layout: {
      config: {
        footer: {
          display: true
        }
      }
    }
  },
  auth: authRoles.depAdmin,
  routes: [
    {
      path: '/imports',
      component: Imports
    }
  ]
};

export default ImportsConfig;
