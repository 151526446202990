import { useEffect, useState } from 'react';
import _ from '@lodash';

import apiService from 'app/services/apiService';
import { USER_CASE_ROLES } from 'app/consts';
import amplService from 'app/services/amplService/amplService';
import {
  initTemplateRecommendations,
  recommendTemplateAdd,
  recommendTemplateRemove
} from './templateRecommendations';

function useTemplateRecommendations(props) {
  const [fieldsSummary, setFieldsSummary] = useState({});
  const { userId, case: kase, onCaseRecommendations } = props;

  useEffect(() => {
    const init = async () => {
      const fs = await initTemplateRecommendations(userId, kase, onCaseRecommendations);
      setFieldsSummary(fs);
    };

    if (kase.procedureId) {
      init();
    }
  }, [kase.procedureId]);

  const updateAttendingCaseDefaults = async (suggestTemplate, ok) => {
    const attendingDefaults = await apiService.getDefaults(
      false,
      kase.procedureId,
      'brief',
      USER_CASE_ROLES.ATTENDING,
      kase.attendingId,
      false
    );
    const ad = JSON.parse(attendingDefaults);
    const { values } = ad;
    let { stop_insights: stopInsights } = ad;

    if (ok) {
      let fieldValues;

      if (suggestTemplate.isAdd) {
        if (values[suggestTemplate.fieldId] && suggestTemplate.multi) {
          fieldValues = [...values[suggestTemplate.fieldId].values, suggestTemplate.value];
        } else {
          fieldValues = [suggestTemplate.value];
          values[suggestTemplate.fieldId] = {
            values: [],
            otherValues: []
          };
        }
      } else {
        fieldValues = values[suggestTemplate.fieldId].values.filter(
          v => v !== suggestTemplate.value
        );
      }

      values[suggestTemplate.fieldId].values = fieldValues;

      await apiService.submitCareTeamDefaults(
        kase.procedureId,
        values,
        'brief',
        USER_CASE_ROLES.ATTENDING,
        false
      );
    } else {
      stopInsights = stopInsights || {};
      stopInsights[suggestTemplate.fieldId] = stopInsights[suggestTemplate.fieldId] || [];

      stopInsights[suggestTemplate.fieldId] = [
        ...stopInsights[suggestTemplate.fieldId],
        suggestTemplate.value
      ];

      await apiService.updateCareTeamDefaultsStopInsights(
        kase.procedureId,
        stopInsights,
        'brief',
        USER_CASE_ROLES.ATTENDING
      );
    }

    amplService.sendCaseEvent(amplService.EVENTS.TMPL_SUGGESTION, kase, {
      ...suggestTemplate,
      ok
    });
  };

  const evaluteValueToRecommend = (
    fieldId,
    values,
    otherValues,
    type,
    multi,
    oldValue,
    otherChanged
  ) => {
    console.debug(
      'evaluteValueToRecommend',
      'fieldId',
      fieldId,
      'values',
      values,
      'otherValues',
      otherValues,
      'type',
      type,
      'multi',
      multi,
      'oldValue',
      oldValue
    );
    if (otherChanged) {
      console.debug('other changed');
      return false;
    }
    if (type === 'text') {
      console.debug('Ignoring text fields (need to handle debounce)');
      return false;
    }
    if (
      (Array.isArray(values) && values.length > oldValue.length) ||
      (!Array.isArray(values) && values?.value)
    ) {
      return recommendTemplateAdd(
        kase.attendingDefaults,
        fieldsSummary,
        kase.procedureTitle,
        fieldId,
        values,
        oldValue,
        multi
      );
    }
    if (
      (Array.isArray(values) && values.length < oldValue.length) ||
      (!Array.isArray(values) && !values?.value)
    ) {
      return recommendTemplateRemove(
        kase.attendingDefaults,
        fieldsSummary,
        kase.procedureTitle,
        fieldId,
        values,
        oldValue,
        multi
      );
    }

    console.debug("didn't add/remove");
    return false;
  };

  return { updateAttendingCaseDefaults, evaluteValueToRecommend };
}

export default useTemplateRecommendations;
