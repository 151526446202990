import React from 'react';
import RisksList from './RisksList';
import MediaList from './MediaList';
import MarkdownText from './MarkdownText';

const StepsListItem = ({ step, setOpenedAppendix }) => {
  const { title, text, risks, metaData, stepMedia } = step;
  return (
    <>
      <MarkdownText
        className="list-item-text"
        text={text}
        metaData={metaData}
        setOpenedAppendix={setOpenedAppendix}
      />
      <RisksList risks={risks} />
      <MediaList stepMedia={stepMedia} />
    </>
  );
};

export default StepsListItem;
