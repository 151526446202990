import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';
import PSteps from './PSteps';

const PStepsConfig = {
  settings: {
    layout: {}
  },
  auth: authRoles.careTeam,
  routes: [
    {
      path: '/procedure-steps/:caseId',
      component: PSteps
    },
    {
      path: '/analytics',
      component: () => <Redirect to="/analytics/dash" />
    }
  ]
};

export default PStepsConfig;
