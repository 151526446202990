import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import _ from '@lodash';
import { grey, lightBlue } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import apiService from 'app/services/apiService';
import DropMenu from 'app/components/dropMenu/DropMenu';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ListSubheader from '@mui/material/ListSubheader';
import CasesListItem from 'app/components/casesList/CasesListItem';
import { followCase, MAX_DATE, MIN_DATE, setItems } from 'app/store/data/casesSlice';
import EmptyCasesList from 'app/components/emptyCasesList/EmptyCasesList';
import Fab from '@mui/material/Fab';
import { CASE_STATE, ROLES } from 'app/consts';
import { optionsSelector } from 'app/metaData/store/enumSlice';
import toast from 'app/services/toastService/toast';
import i18n from 'app/services/i18n';
import PublishIcon from '@mui/icons-material/Publish';
import ShareIcon from '@mui/icons-material/Share';
import ResidentPublishPopup from './ResidentPublishPopup';
import OrBoardIntro from './OrBoardIntro';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    paddingBottom: 0
  },
  groupHeader: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.5rem',
    lineHeight: '2.5',
    fontWeight: 800,
    color: 'black',
    backgroundColor: theme.palette.background.default,
    paddingRight: 0
  },
  avatar: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1.5),
    color: grey[800],
    backgroundColor: lightBlue[50]
  },
  avatarIcon: {
    fontSize: 15
  },
  dot: {
    alignSelf: 'normal',
    color: theme.palette.secondary.main,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    marginLeft: '0.5rem'
  },
  fab: {
    position: 'fixed',
    zIndex: 99,
    bottom: theme.spacing(10),
    right: theme.spacing(3),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  }
}));

const groupByKey = kase => {
  const caseDate = new Date(kase.caseDate);
  return caseDate.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  });
};

export default function OrBoardCasesList(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const { role } = useSelector(({ auth }) => auth.user);
  const { id: userId } = useSelector(({ auth }) => auth.user.data);
  const [init, setInit] = useState(false);
  const [publishPopupConfig, setPublishPopupConfig] = useState({ open: false, date: null });
  const { cases, loadCases, tab, clearCases, setTab } = props;
  const grouped = _.groupBy(cases, c => groupByKey(c));
  const isAssignsResidents = role.includes(ROLES.ASSIGNS_RESIDENTS);

  const residentOptions = useSelector(optionsSelector).residents;

  const reorderCase = (c, dir) => {
    const curCaseGroup = grouped[groupByKey(c)];
    const indexInGroup = curCaseGroup.indexOf(c);
    let caseToPass = null;
    if (dir === 'down') {
      caseToPass = curCaseGroup[indexInGroup + 1];
      apiService.updateCaseValues(c.id, {
        indexInDay: caseToPass.indexInDay
      });
      apiService.updateCaseValues(caseToPass.id, {
        indexInDay: c.indexInDay
      });
    }
    if (dir === 'up') {
      caseToPass = curCaseGroup[indexInGroup - 1];
      apiService.updateCaseValues(c.id, {
        indexInDay: caseToPass.indexInDay
      });
      apiService.updateCaseValues(caseToPass.id, {
        indexInDay: c.indexInDay
      });
    }
  };

  useEffect(() => {
    clearCases();
    loadCases();
    setInit(true);
    setTab(tab);
  }, [loadCases, clearCases, tab, setTab]);
  useEffect(() => {
    let sub = false;
    const handle = async () => {
      sub = await apiService.subscribeToOrCases(MIN_DATE, MAX_DATE, orCases => {
        dispatch(setItems(orCases));
      });
    };
    handle();
    return () => {
      if (sub) {
        sub.unsubscribe();
        sub = false;
      }
    };
  }, []);
  const handleClick = async (c, editCaseAttributes) => {
    await props.loadCaseById(c.id, editCaseAttributes);
  };

  const handleClickNew = async () => {
    props.newCase();
  };

  const handleDelete = async kase => {
    await props.handleDelete(kase);
  };

  const handleSelectResident = async (kase, draftResidentId) => {
    if (draftResidentId === null) {
      return;
    }

    const key = groupByKey(kase);
    const group = grouped[key];
    // get all other cases with same attending
    const sameAttOtherCases = group.filter(
      c => c.attendingId === kase.attendingId && c.id !== kase.id
    );

    // if all other cases have no resident nor draft resident, update all
    if (
      sameAttOtherCases.length > 0 &&
      sameAttOtherCases.filter(c => c.residentId !== null || c.draftResidentId !== null).length ===
        0
    ) {
      sameAttOtherCases.forEach(async c => {
        await apiService.updateCaseValues(c.id, {
          draftResidentId
        });
      });
    }
  };

  const handlePublishAll = group => {
    group.forEach(c => {
      if (!c.draftResident) {
        return;
      }
      apiService.updateCaseValues(c.id, {
        draftResidentId: null
      });
      dispatch(followCase(false, c.id, c.draftResident.id, null));
    });

    toast.success(
      i18n.t('draft_publish_all_residents', {
        date: groupByKey(group[0])
      })
    );
    // openPublishDialog(group, true);
  };

  const openPublishDialog = (group, includeDrafts) => {
    // count how many cases each attending has in this group
    const attendingInCases = group.reduce((acc, c) => {
      const att = c.attending.nickName;
      if (acc[att] === undefined) {
        acc[att] = 0;
      } else {
        acc[att] = 1;
      }
      return acc;
    }, {});

    const content = group.reduce((acc, c) => {
      const res = (includeDrafts ? c.draftResident?.nickName : undefined) || c.resident?.nickName;
      if (res) {
        const att = c.attending.nickName;

        let count = '';
        if (attendingInCases[att] > 0) {
          count = ` (${attendingInCases[att]})`;
          attendingInCases[att] += 1;
        }
        acc += `${att}${count} - ${res}\n`;
      }
      return acc;
    }, '');

    setPublishPopupConfig({
      open: true,
      date: groupByKey(group[0]),
      content
    });
  };

  const hasReady = group => {
    return grouped[group].some(
      c =>
        (c.caseFollowers.some(u => u.userId === userId) ||
          c.residentId === userId ||
          c.attendingId === userId) &&
        c.caseStateLogs?.some(l => l.toState === CASE_STATE.READY)
    );
  };

  if (!init) {
    return null;
  }
  return (
    <div style={{ borderTop: '1px solid', borderColor: '#fafafa' }}>
      {cases.length > 0 && <OrBoardIntro />}
      <EmptyCasesList casesCount={cases.length} tab={tab} />
      {Object.keys(grouped).map(
        group =>
          grouped[group] && (
            <List key={group} className={classes.root} subheader={<li />}>
              <ListSubheader className={classes.groupHeader}>
                <Avatar title={_.startCase(group)} className={clsx(classes.avatar)}>
                  <Icon className={classes.avatarIcon}>date_range</Icon>
                </Avatar>
                {group}
                {hasReady(group) && <span className={classes.dot}>{'\u25CF'}</span>}
                {isAssignsResidents && (
                  <DropMenu
                    buttonProps={{
                      className: clsx('kebab-menu ml-auto')
                    }}
                  >
                    <Button
                      title={t('publish_residents', { date: group })}
                      aria-label={t('publish_residents', { date: group })}
                      onClick={() => {
                        handlePublishAll(grouped[group]);
                      }}
                      className="px-16 hover:bg-transparent"
                      disableRipple
                      variant="text"
                      disabled={
                        grouped[group].length === 0 ||
                        !grouped[group].some(c => c.draftResidentId !== null)
                      }
                    >
                      <PublishIcon />
                      <Typography variant="inherit" className="ml-8">
                        {t('publish_residents', { date: group })}
                      </Typography>
                    </Button>
                    {false && (
                      <Button
                        title={t('share_resident_assignment')}
                        aria-label={t('share_resident_assignment')}
                        disabled={
                          grouped[group].length === 0 ||
                          !grouped[group].some(c => c.residentId !== null)
                        }
                        onClick={() => {
                          openPublishDialog(grouped[group], false);
                        }}
                        className="px-16 hover:bg-transparent"
                        disableRipple
                        variant="text"
                      >
                        <ShareIcon />
                        <Typography variant="inherit" className="ml-8">
                          {t('share_resident_assignment')}
                        </Typography>
                      </Button>
                    )}
                  </DropMenu>
                )}
              </ListSubheader>
              {grouped[group].map((c, index) => (
                <CasesListItem
                  kase={c}
                  key={c.id}
                  tab={tab}
                  handleClick={handleClick}
                  handleDelete={handleDelete}
                  handleShare={props.handleShare}
                  reorderCase={reorderCase}
                  caseGroup={grouped[group]}
                  indexInList={index}
                  residentOptions={residentOptions}
                  handleSelectResident={handleSelectResident}
                />
              ))}
            </List>
          )
      )}
      <ResidentPublishPopup config={publishPopupConfig} setConfig={setPublishPopupConfig} />
      {props.newCase &&
        role.some(r => [ROLES.SCHEDULER, ROLES.ATTENDING, ROLES.RESIDENT].includes(r)) && (
          <Fab onClick={() => handleClickNew()} className={classes.fab} aria-label="add">
            <Icon>add</Icon>
          </Fab>
        )}
    </div>
  );
}
