import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles(theme => ({
  action: {
    alignSelf: 'flex-start'
  }
}));

function OrBoardIntro(props) {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const [hideHint, setHideHint] = useState(localStorage.getItem('hide_or_board_hint'));

  return (
    !hideHint && (
      <Alert
        severity="info"
        className="md:mb-24"
        classes={{ action: classes.action }}
        onClose={() => {
          setHideHint(true);
          localStorage.setItem('hide_or_board_hint', true);
        }}
      >
        <Typography>
          <Trans
            i18nKey="OR_board_intro"
            components={{ a: <a href={t('OR_board_intro_page')} /> }} // eslint-disable-line
          />
        </Typography>
      </Alert>
    )
  );
}

export default OrBoardIntro;
