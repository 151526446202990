import React, { useState } from 'react';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import toast from 'app/services/toastService/toast';
import { FEEDBACK_HOW_TO_IMPROVE_OPTIONS } from 'app/consts';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  buttons: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '400px'
  },
  button: {
    display: 'block',
    width: '100%',
    padding: '8px',
    marginBottom: theme.spacing(1),
    textAlign: 'left',
    textTransform: 'none',
    flexBasis: '31%',
    boxSizing: 'borderBox',
    '&:not(:nth-child(3n))': {
      marginRight: '8px'
    },
    '&.MuiButton-text': {
      background: '#f2f2f2',
      color: '#666'
    },
    '& .subtitle': {
      color: 'red',
      opacity: '.85',
      fontSize: '1.3rem'
    }
  },
  buttonText: {
    whiteSpace: 'pre',
    fontWeight: 'normal',
    textAlign: 'center',
    lineHeight: '2rem'
  }
}));

const options = [
  {
    value: FEEDBACK_HOW_TO_IMPROVE_OPTIONS.FCOTS,
    title: 'fcots'
  },
  {
    value: FEEDBACK_HOW_TO_IMPROVE_OPTIONS.TURNOVER,
    title: 'turnover'
  },
  {
    value: FEEDBACK_HOW_TO_IMPROVE_OPTIONS.TIME_WASTE,
    title: 'time_waste'
  },
  {
    value: FEEDBACK_HOW_TO_IMPROVE_OPTIONS.RESOURCE_WASTE,
    title: 'resource_waste'
  },
  {
    value: FEEDBACK_HOW_TO_IMPROVE_OPTIONS.EQUIPMENT_NOT_USED,
    title: 'equipment_not_used'
  },
  {
    value: FEEDBACK_HOW_TO_IMPROVE_OPTIONS.SUPPLY_WASTE,
    title: 'supply_waste'
  }
];

export default function HowToImprove(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [selections, setSelections] = useState(props.feedback?.howToImproveSelection || []);
  const [textValue, setTextValue] = useState(props.feedback?.howToImproveText || '');
  const isSelected = value => {
    return selections.includes(value);
  };
  const buttonOnClick = value => {
    let sel;
    if (isSelected(value)) {
      sel = selections.filter(s => s !== value);
    } else {
      sel = [...selections, value];
    }
    setSelections(sel);
    props.setHowToImproveSelection(sel);
    handleSubmit(sel, textValue);
  };
  const handleSubmit = async (sel, text) => {
    try {
      if (props.feedback?.id) {
        props.handlePlanFeedbackUpdate(props.feedback.id, null, null, null, sel, text, null, null);
      } else {
        props.submitPlanFeedback(null, null, null, sel, text, null, null);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  return (
    <div className={clsx(classes.container)}>
      <Typography variant="body1" gutterBottom color="primary" fontWeight="bold">
        <Trans i18nKey="what_can_be_improved" />
      </Typography>
      <div className={clsx(classes.buttons)}>
        {options.map(option => (
          <Button
            key={option.value}
            className={clsx(classes.button)}
            onClick={() => buttonOnClick(option.value)}
            variant={isSelected(option.value) ? 'contained' : 'text'}
            color="primary"
            disabled={props.disabled}
          >
            <div className={classes.buttonText}>
              <Trans
                i18nKey={option.title}
                components={{
                  strong: <span style={{ fontWeight: 'bold' }} />
                }}
              />
            </div>
          </Button>
        ))}
      </div>
      {false && (
        <TextField
          name={props.feedback?.id || 'new_feedback'}
          value={textValue}
          className={clsx({ hidden: props.disabled || !(selections?.length > 0) }, 'w-full')}
          placeholder={t('plan_useful_text_hint')}
          multiline
          onChange={event => {
            setTextValue(event.target.value);
            handleSubmit(selections, event.target.value);
          }}
          disabled={props.disabled}
        />
      )}
    </div>
  );
}
