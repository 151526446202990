import React, { useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  image: {
    maxWidth: '80%',
    margin: '10px auto',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      maxWidth: 'none',
      margin: '0px'
    }
  },
  topLeft: {
    top: '120px',
    left: '40px',
    objectPosition: 'top left'
  },
  topRight: {
    top: '120px',
    right: '40px',
    objectPosition: 'top right'
  },
  bottomRight: {
    bottom: '120px',
    right: '40px',
    objectPosition: 'bottom right'
  },
  bottomLeft: {
    bottom: '120px',
    left: '40px',
    objectPosition: 'bottom left'
  },
  bottomCenter: {
    bottom: '120px',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  centerRight: {
    bottom: '50%',
    right: '40px',
    [theme.breakpoints.up('md')]: {
      transform: 'translateY(50%)'
    }
  },
  centerLeft: {
    bottom: '50%',
    left: '40px',
    [theme.breakpoints.up('md')]: {
      transform: 'translateY(50%)'
    }
  }
}));

const MediaListItem = ({ props, media }) => {
  const { title, mediaUrl, metaData, mediaType } = media;
  const classes = useStyles(props);
  const { width, height, alignment } = metaData;
  const windowWidth = useRef(window.innerWidth).current;
  const windowHeight = useRef(window.innerHeight).current;
  const isMobile = windowWidth < windowHeight;

  let calculatedWidth;
  let calculatedHeight;
  if (!isMobile) {
    calculatedWidth = width;
    calculatedHeight = height;
    if (windowWidth !== 1920) {
      const factor = (windowWidth / 1920) * 0.9;

      // width is a string with a number and then 'px' or '%' at the end. Use regex to put the number in variable w, and the 'px' or '%' in variable unit
      if (width) {
        const { w, unit } = width.match(/(?<w>\d+)(?<unit>px|%)/).groups;
        calculatedWidth = `${w * factor}${unit}`;
      }
      if (height) {
        const { h, unit } = height.match(/(?<h>\d+)(?<unit>px|%)/).groups;
        calculatedHeight = `${h * factor}${unit}`;
      }
    }
  }

  return (
    <img
      className={clsx(classes.image, !isMobile && (classes[alignment] || classes.bottomRight))}
      style={{
        width: calculatedWidth,
        height: calculatedHeight,
        objectFit: 'contain'
      }}
      src={mediaUrl}
      alt={title}
      role="presentation"
    />
  );
};

export default MediaListItem;
