import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  success: false,
  error: {
    username: null,
    password: null
  }
};

const registerSlice = createSlice({
  name: 'auth/register',
  initialState,
  reducers: {
    registerSuccess: (state, action) => {
      state.success = true;
    },
    registerError: (state, action) => {
      state.success = false;
      state.error = action.payload;
    }
  },
  extraReducers: {}
});

export const { registerSuccess, registerError } = registerSlice.actions;

export default registerSlice.reducer;
