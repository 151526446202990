import React, { Fragment } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Switch, Route, Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FusePageSimple from '@fuse/core/FusePageSimple';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import General from 'app/components/adminViews/General';
import Sites from 'app/components/adminViews/Sites';
import Users from 'app/components/adminViews/Users';

const useStyles = makeStyles(theme => ({
  tabs: {
    backgroundColor: '#fff'
  },
  tab: {
    '&:not(.Mui-selected)': {
      color: '#62719287'
    }
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius
  }
}));

const tabs = [
  { path: '/admin/general', label: 'GENERAL', component: () => <General /> },
  { path: '/admin/sites', label: 'SERVICES', component: () => <Sites /> },
  { path: '/admin/users', label: 'USERS', component: () => <Users /> }
];

function Admin(props) {
  const classes = useStyles(props);
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <FusePageSimple
      header={
        <div className="p-24 pl-40">
          <h2>{t('ADMINISTRATION')}</h2>
        </div>
      }
      content={
        <Fragment>
          <Tabs
            value={location.pathname}
            classes={{ root: classes.tabs }}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            centered
          >
            {tabs.map(tab => (
              <Tab
                key={t(tab.label)}
                label={t(tab.label)}
                value={tab.path}
                component={Link}
                to={tab.path}
                classes={{ root: classes.tab }}
              />
            ))}
          </Tabs>
          <div className={clsx('md:m-24', classes.content)}>
            <Switch>
              {tabs.map(tab => (
                <Route key={t(tab.label)} path={tab.path} render={tab.component} />
              ))}
            </Switch>
          </div>
        </Fragment>
      }
    />
  );
}

export default Admin;
