import { gql } from '@apollo/client';

import { PRIVATE_NOTE_FULL_DATA } from './fragments/privateNote';

const create = gql`
  mutation CareTeamCreatePrivateNote($object: private_notes_insert_input!) {
    insert_private_notes_one(
      object: $object
      on_conflict: { constraint: private_notes_created_by_id_case_id_key, update_columns: [note] }
    ) {
      ...PrivateNoteFullData
    }
  }
  ${PRIVATE_NOTE_FULL_DATA}
`;
const updatePrivateNote = gql`
  mutation CareTeamUpdatePrivateNote($id: uuid!, $note: String) {
    update_private_notes_by_pk(pk_columns: { id: $id }, _set: { note: $note }) {
      ...PrivateNoteFullData
    }
  }
  ${PRIVATE_NOTE_FULL_DATA}
`;

const deletePrivateNote = gql`
  mutation CareTeamDeletePrivateNote($id: uuid!) {
    delete_private_notes_by_pk(id: $id) {
      id
    }
  }
`;

const getCasePrivateNote = gql`
  query CareTeamGetCasePrivateNote($case_id: uuid!, $user_id: uuid!) {
    private_notes(where: { created_by_id: { _eq: $user_id }, case_id: { _eq: $case_id } }) {
      ...PrivateNoteFullData
    }
  }
  ${PRIVATE_NOTE_FULL_DATA}
`;

const queries = { getCasePrivateNote };

const mutations = {
  create,
  deletePrivateNote,
  updatePrivateNote
};

const api = {
  queries,
  mutations
};

export default api;
