import { SplitFactory } from '@splitsoftware/splitio-browserjs/full';
import FF_CONFIG from './ffServiceConfig';

const factory = SplitFactory({
  core: {
    authorizationKey: FF_CONFIG.sdkKey,
    // key represents your internal user id, or the account id that
    // the user belongs to.
    // This could also be a cookie you generate for anonymous users
    key: 'key'
  }
});

class FFService {
  client = null;

  isInit = false;

  async init(userId) {
    console.log('init', userId);
    this.client = factory.client(userId);
    return new Promise(resolve => {
      const timer = setTimeout(() => {
        console.log('SDK_READY event not fired, timeout resolve');
        resolve();
      }, 2000);

      this.client.on(this.client.Event.SDK_READY, () => {
        this.isInit = true;
        clearTimeout(timer);
        resolve();
      });
    });
  }

  isFlagOn(flagName) {
    if (!this.isInit) {
      console.log('client not initialized');
      return false;
    }
    return this.client.getTreatment(flagName) === 'on';
  }

  getFlagConfig(flagName) {
    if (!this.isFlagOn(flagName)) {
      return null;
    }
    return this.client.getTreatmentWithConfig(flagName);
  }
}
const instance = new FFService();

export default instance;
