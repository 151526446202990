import React, { Fragment, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { grey, lightBlue } from '@mui/material/colors';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Icon from '@mui/material/Icon';
import Fab from '@mui/material/Fab';
import SitesDialog from 'app/components/adminViews/SitesDialog';
import { useDispatch, useSelector } from 'react-redux';
import { loadSites, loadUsers, submitNewSite, submitSiteData } from 'app/store/data/adminSlice';
import { optionsSelector } from '../../metaData/store/enumSlice';

const useStyles = makeStyles(theme => ({
  root: {},
  fab: {
    position: 'fixed',
    zIndex: 99,
    bottom: theme.spacing(10),
    right: theme.spacing(3),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  avatar: {
    color: grey[800],
    backgroundColor: lightBlue[50]
  },
  column: {
    width: '20%',
    flex: 'none'
  }
}));

export default function Sites(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openSite, setOpenSite] = useState(false);
  const { sites, users } = useSelector(({ data }) => data.admin);
  const getSites = useCallback(() => {
    dispatch(loadSites());
  }, [dispatch]);
  const getUsers = useCallback(() => {
    dispatch(loadUsers());
  }, [dispatch]);
  useEffect(() => {
    getSites();
    getUsers();
  }, [getSites, getUsers]);

  const options = useSelector(optionsSelector);

  const specialtyOptions = options.procedures.reduce((acc, procedure) => {
    if (!acc.some(e => e.specialtyId === procedure.specialtyId)) {
      acc.push({ specialtyId: procedure.specialtyId, specialtyTitle: procedure.specialtyTitle });
    }
    return acc;
  }, []);

  const handleClick = site => {
    if (site !== 'new') {
      setOpenSite({ ...site, admins: [] });
    } else {
      setOpenSite({ id: 'new' });
    }
  };

  const specialtiesToString = specialties => {
    return specialties
      .map(spId => specialtyOptions.find(spOp => spOp.specialtyId === spId)?.specialtyTitle)
      .join(', ');
  };

  const siteSurgeonsToString = siteId => {
    return users
      .filter(
        u => u.userSites.find(s => s.siteId === siteId) && u.roles.find(r => r.role === 'attending')
      )
      .map(u => u.nickName)
      .join(', ');
  };
  const siteNonSurgeonsToString = siteId => {
    return users
      .filter(
        u =>
          u.userSites.find(s => s.siteId === siteId) && !u.roles.find(r => r.role === 'attending')
      )
      .map(u => u.nickName)
      .join(', ');
  };

  return (
    <Fragment>
      <div className={clsx('flex flex-col', classes.root)}>
        <List className={classes.list}>
          <ListItem key="header">
            <Avatar title="" className={clsx(classes.avatar)} style={{ visibility: 'hidden' }}>
              <Icon>person_pin_circle</Icon>
            </Avatar>
            <ListItemText
              className={classes.column}
              primary={<Typography variant="h5">Name</Typography>}
            />
            <ListItemText
              className={classes.column}
              primary={<Typography variant="h5">Specialties</Typography>}
            />
            <ListItemText
              className={classes.column}
              primary={<Typography variant="h5">Timezone</Typography>}
            />
            <ListItemText primary={<Typography variant="h5">Surgeons</Typography>} />
            {/* <ListItemText
                primary={<Typography variant="body3">{siteNonSurgeonsToString(s.id)}</Typography>}
              /> */}
          </ListItem>
          {sites.map(s => (
            <ListItem
              key={s.id}
              className={s.isActive ? '' : 'text-gray-400'}
              onClick={() => handleClick(s)}
              button
            >
              <ListItemAvatar>
                <Avatar title={s.name} className={clsx(classes.avatar)}>
                  <Icon>person_pin_circle</Icon>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className={classes.column}
                primary={<Typography variant="body1">{s.name}</Typography>}
              />
              <ListItemText
                className={classes.column}
                primary={
                  <Typography variant="body3">{specialtiesToString(s.specialties)}</Typography>
                }
              />
              <ListItemText
                className={classes.column}
                primary={<Typography variant="body3">{s.timezone}</Typography>}
              />
              <ListItemText
                primary={<Typography variant="body3">{siteSurgeonsToString(s.id)}</Typography>}
              />
              {/* <ListItemText
                primary={<Typography variant="body3">{siteNonSurgeonsToString(s.id)}</Typography>}
              /> */}
            </ListItem>
          ))}
        </List>
      </div>
      <Fab onClick={() => handleClick('new')} className={classes.fab} aria-label="add">
        <Icon>add</Icon>
      </Fab>
      <SitesDialog
        submitData={async (id, { sitename, active, specialties, attendingOnly, timezone }) => {
          if (id === 'new') {
            await dispatch(submitNewSite(sitename, active, specialties, attendingOnly, timezone));
          } else {
            await dispatch(
              submitSiteData(id, sitename, active, specialties, attendingOnly, timezone)
            );
          }
        }}
        users={users}
        closeDialog={() => setOpenSite(false)}
        site={openSite}
      />
    </Fragment>
  );
}
