import React, { useState } from 'react';
import _ from '@lodash';
import { withFormsy } from 'formsy-react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import ChangedBy from 'app/components/userAvatar/ChangedByAvatar';
import { isToday } from 'app/components/caseViews/timeHelper';
import MobileDatePicker from '@mui/lab/MobileDatePicker';

function DateTime(props) {
  const importedProps = _.pick(props, [
    'ampm',
    'id',
    'label',
    'value',
    'allowKeyboardControl',
    'disabled',
    'disableFuture',
    'disablePast',
    'format',
    'variant'
  ]);

  const [isOpen, setIsOpen] = useState(false);

  // An error message is returned when the component is invalid
  const { errorMessage } = props;

  const changeValue = event => {
    if (props.isFormDisabled || !event || !event.isValid()) return;

    if (props.onChange) {
      props.onChange(event.toDate());
    }
  };

  const onOpen = () => {
    if (!props.defaultToTomorrowIfBlank) {
      return;
    }
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    props.setValue(tomorrow);
  };

  const onAccept = newValue => {
    if (!newValue) {
      return;
    }

    if (props.confirmValue) {
      // Allow parent comp to hook-in before setting value
      props.confirmValue(newValue.toDate(), props.setValue);
    } else {
      props.setValue(newValue.toDate());
    }
  };

  return (
    <FormControl
      className={props.className}
      error={Boolean((!props.isPristine && props.showRequired) || errorMessage)}
      required={props.required}
    >
      {props.changedBy && !props.isFormDisabled && <ChangedBy user={props.changedBy} />}

      <MobileDatePicker
        {...importedProps}
        onChange={changeValue}
        renderInput={inputProps => (
          <TextField
            {...inputProps}
            onKeyDown={e => e.preventDefault()}
            sx={{
              '& .MuiInputBase-input': {
                caretColor: 'transparent',
                cursor: 'pointer'
              }
            }}
            onClick={() => setIsOpen(true)}
          />
        )}
        InputLabelProps={{
          shrink: true
        }}
        onAccept={onAccept}
        onOpen={onOpen}
        onClose={() => setIsOpen(false)}
        minutesStep={5}
        open={isOpen}
      />
      {Boolean(props.errorMessage) && <FormHelperText>{props.errorMessage}</FormHelperText>}
    </FormControl>
  );
}

export default React.memo(withFormsy(DateTime));
