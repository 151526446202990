import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false
};

const a2hsSlice = createSlice({
  name: 'a2hs',
  initialState,
  reducers: {
    showA2HS: (state, action) => {
      state.open = action.payload;
    }
  },
  extraReducers: {}
});

export const {
  // actions
  showA2HS
} = a2hsSlice.actions;

export default a2hsSlice.reducer;
