import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import ConfirmationDialog from 'app/components/confirmDialog/ConfirmDialog';

export default function ConfirmButton(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClick = e => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = confirm => {
    setOpen(false);

    if (confirm) {
      props.onConfirm();
    }
  };

  return (
    <Fragment>
      <Button
        title={props.label || t('Delete')}
        aria-label={props.label || t('Delete')}
        onClick={handleClick}
        {...props.buttonProps}
      >
        <Icon>{props.icon || 'delete'}</Icon>
        <Typography variant="inherit" className="ml-12">
          {props.label}
        </Typography>
      </Button>

      <ConfirmationDialog
        open={open}
        onClose={handleClose}
        message={props.message}
        title={props.title}
        ok={props.confirmationProps?.ok}
      />
    </Fragment>
  );
}
