import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { Pie } from 'react-chartjs-2';
import { red, blue, green, yellow, orange, purple } from '@mui/material/colors';

const parsePieDate = data => {
  const pieData = {};

  data.forEach(d => {
    pieData[d.name] = d.count;
  });

  return {
    labels: Object.keys(pieData),
    datasets: [
      {
        data: Object.values(pieData),
        backgroundColor: randomColorFromContext
      }
    ]
  };
};

export const randomColorFromContext = context => {
  const { index } = context;
  return randomColorFromIndex(index);
};

export const randomColorFromIndex = index => {
  const colors = [red, blue, green, yellow, orange, purple];
  const hues = [
    // '50',
    // '100',
    '300',
    '200',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
    'A100',
    'A200',
    'A400'
  ];
  return colors[index % colors.length][hues[index % hues.length]];
};

function DashboardPieChart(props) {
  const { t } = useTranslation();
  const [height, setHeight] = useState(0);
  const pieRef = useRef(null);
  const pieData = parsePieDate(props.data);

  useEffect(() => {
    if (pieRef.current) {
      // Measure the height of the legend and provide a fixed total height
      // so that it does not cause the pie itself to squeeze down.
      const legendHeight = pieRef.current.legend.height;
      const pieWidth = Math.min(pieRef.current.width * 0.8, 280);
      setHeight(pieWidth + legendHeight);
    }
  }, [pieData]);

  return (
    <Card className="flex flex-col justify-between w-full h-full rounded-8 shadow-1 p-16">
      <Typography className="h3" color="textSecondary">
        {props.title || t('CASES BY TYPE')}
      </Typography>

      <div className="w-100-p mt-20">
        {height > 0 ? (
          <Pie
            key="1"
            height={height}
            data={pieData}
            options={{
              maintainAspectRatio: false,
              legend: {
                position: 'bottom'
              }
            }}
          />
        ) : (
          // Dummy pie for measuring the legend
          <Pie key="2" height={400} data={pieData} ref={pieRef} />
        )}
      </div>
    </Card>
  );
}

export default React.memo(DashboardPieChart);
