// import { useEffect, useMemo, useState } from 'react';
import qs from 'query-string';
import history from '@history';

export function get(paramName) {
  const queryParams = qs.parse(window.location.search);
  return queryParams[paramName];
}

export function set(paramName, value, state) {
  if (get(paramName) !== value) {
    const newURL = qs.stringifyUrl({
      url: window.location.pathname,
      query: {
        [paramName]: value
      }
    });
    history.push(newURL, state);
  }
}
