import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation, Trans } from 'react-i18next';
import auth0Service from 'app/services/auth0Service';
import makeStyles from '@mui/styles/makeStyles';
import Snackbar from '@mui/material/Snackbar';
import Grow from '@mui/material/Grow';
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { ROLES } from 'app/consts';
import { ReactComponent as IconMegaphone } from './megaphone.svg';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root': {
      color: theme.palette.text.primary,
      background: '#fff',
      padding: '10px 20px 20px',
      border: '1px solid #a3a3a3'
    }
  },
  megaphone: {
    display: 'block',
    width: '100px',
    height: '100px',
    margin: '0 auto'
  },
  closeBtn: {
    position: 'absolute',
    right: 8,
    top: 8
  }
}));

function WhatsNew(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const tokenData = auth0Service.getTokenData(); // Show after login
  const seenVersion = parseInt(localStorage.getItem('WhatsNewVersion'), 10);
  const currentVersion = parseInt(t('whats_new_version'), 10);
  let shouldRender = false;
  const userRole = useSelector(({ auth }) => auth.user.role);

  if (tokenData) {
    if (!seenVersion && currentVersion === 1) {
      // First "WhatsNew". show it to everyone.
      shouldRender = true;
      localStorage.setItem('WhatsNewVersion', currentVersion);
    } else if (!seenVersion) {
      // New user, store current "WhatsNewVersion" so that the next one will show.
      localStorage.setItem('WhatsNewVersion', currentVersion);
    } else if (seenVersion < currentVersion) {
      // New "What's New" was published. show it.
      shouldRender = true;
      localStorage.setItem('WhatsNewVersion', currentVersion);
    } else {
      // Do nothing. current version already seen.
    }
  }

  // Show version 13 only to attendings and residents
  if (
    shouldRender &&
    currentVersion === 13 &&
    !userRole.some(r => [ROLES.ATTENDING, ROLES.RESIDENT].includes(r))
  ) {
    shouldRender = false;
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    shouldRender && (
      <Snackbar
        className={clsx(classes.root)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        TransitionComponent={Grow}
        open={open}
        onClose={handleClose}
        action={
          <IconButton
            className={clsx(classes.closeBtn)}
            aria-label="close"
            color="inherit"
            onClick={handleClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        }
        message={
          <div className="text-18">
            <SvgIcon
              className={clsx(classes.megaphone)}
              component={IconMegaphone}
              alt="What's New"
              viewBox="0 0 74 74"
            />
            <h2 className="text-center font-bold text-22 mt-20 mb-28">
              <Trans i18nKey="whats_new_title" />
            </h2>
            <Trans
              i18nKey="whats_new"
              components={{ a: <a href={t('contact_us_email')} /> }} // eslint-disable-line
            />
          </div>
        }
      />
    )
  );
}

export default React.memo(WhatsNew);
