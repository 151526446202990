import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import FusePageSimple from '@fuse/core/FusePageSimple';
import apiService from 'app/services/apiService';
import Dialog from '@mui/material/Dialog';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import toast from 'app/services/toastService/toast';

const useStyles = makeStyles(theme => ({
  layoutRoot: {},
  action: {
    alignSelf: 'flex-start'
  }
}));
const header = [
  'line',
  'insert',
  'update',
  'delete',
  'msg',
  'hasWarning',
  'hasError',
  'ignore',
  'irrelevant',
  'success'
];

const headerMap = {
  insert: 'Insert',
  update: 'Update',
  delete: 'Delete',
  line: 'Input',
  msg: 'Message',
  hasWarning: 'Warning',
  hasError: 'Error',
  ignore: 'No change',
  irrelevant: 'Irrelevant',
  success: 'Success'
};

function ImportsPage(props) {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const [data, setData] = useState([]);
  const [isShowXlsDialog, setIsShowXlsDialog] = useState(false);
  const [xlsResult, setXlsResult] = useState(null);
  const [curId, setCurId] = useState(null);
  useEffect(() => {
    const loadImports = async () => {
      const imports = await apiService.getDepAdminImports();
      setData(imports);
    };
    loadImports();
  }, []);
  const pendingProcess = data.filter(item => item.status === 'ready_to_process');
  const otherFiles = data.filter(item => item.status !== 'ready_to_process');
  const dryRunImport = async id => {
    try {
      const result = await apiService.importXls(id, true);
      setXlsResult(result);
      setIsShowXlsDialog(true);
      setCurId(id);
    } catch (error) {
      console.log('error', error);
      toast.error(`Error: ${error.message}`);
    }
  };
  const runImport = async () => {
    if (!curId) {
      return;
    }
    try {
      const result = await apiService.importXls(curId, false);

      setXlsResult(result);
      console.log('result', result);

      let errors = 0;
      let updated = 0;
      let added = 0;
      let toDelete = 0;
      result.results.forEach(row => {
        if (row.hasError) {
          errors += 1;
          return;
        }
        if (row.update) {
          updated += 1;
          return;
        }
        if (row.delete) {
          toDelete += 1;
          return;
        }
        if (row.insert) {
          added += 1;
          return;
        }
        if (row.ignore || row.irrelevant) {
          return;
        }

        throw new Error('Unknown result. Should be insert/update/delete/ignore/irrelevant.');
      });
      if (errors > 0) {
        toast.warning(
          `Added: ${added}. Updated: ${updated}. Deleted: ${toDelete}. Error: ${errors}`
        );
      } else {
        toast.success(`Added: ${added}. Updated: ${updated}. Deleted: ${toDelete}`);
      }
      setIsShowXlsDialog(false);
    } catch (error) {
      console.log('error', error);
      toast.error(`Error: ${error.message}`);
    }
  };
  const handleDone = () => {
    setIsShowXlsDialog(false);
    setCurId(null);
  };

  const getHeaderCount = name => {
    return xlsResult?.results.filter(row => row[name]).length || 0;
  };
  const getHeader = name => {
    return `${headerMap[name]} (${getHeaderCount(name)})`;
  };

  return (
    <FusePageSimple
      classes={{
        root: classes.layoutRoot
      }}
      header={
        <div className="p-24 pl-40">
          <h2>{t('SCHEDULE')}</h2>
        </div>
      }
      content={
        <>
          <div>pending process</div>
          <div className="md:p-24">
            {pendingProcess.map(item => (
              <div key={item.key}>
                <span>{item.key}</span>
                <Button
                  className={classes.button}
                  onClick={() => {
                    dryRunImport(item.id);
                  }}
                >
                  dry run
                </Button>
              </div>
            ))}
          </div>
          <div>other files</div>
          <div className="md:p-24">
            {otherFiles.map(item => (
              <div key={item.key}>
                <span>{item.key}</span> - <span>{item.status}</span>
              </div>
            ))}
          </div>
          <div>
            <Dialog
              open={isShowXlsDialog}
              className={classes.dialog}
              onClose={handleDone}
              fullWidth
              maxWidth="lg"
            >
              {xlsResult && (
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {header.map(name => (
                          <TableCell key={name} align="center">
                            {getHeader(name)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {xlsResult.results.map((row, key) => (
                        <TableRow
                          key={key}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          {header.map(name => (
                            <TableCell key={name + key} align="center">
                              {row[name].toString() === 'true'
                                ? '+'
                                : row[name].toString() === 'false'
                                ? ''
                                : row[name].toString()}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <div className="flex justify-end my-20">
                    <Button
                      className="mx-8"
                      onClick={runImport}
                      variant="contained"
                      color="primary"
                    >
                      {`Apply (${getHeaderCount('success')})`}
                    </Button>
                    <Button
                      className="ml-8 mr-16"
                      onClick={handleDone}
                      variant="contained"
                      color="primary"
                    >
                      close
                    </Button>
                  </div>
                </div>
              )}
            </Dialog>
          </div>
        </>
      }
    />
  );
}

export default ImportsPage;
