import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import menus from './footerMenuConfig';

const useStyles = makeStyles(theme => ({
  root: {
    height: theme.spacing(8),
    '& button': {
      paddingBottom: theme.spacing(2)
    }
  }
}));

function FooterMenu(props) {
  const classes = useStyles(props);
  const history = useHistory();
  const location = useLocation();
  const [menu, setMenu] = useState();
  const user = useSelector(({ auth }) => auth.user);

  useEffect(() => {
    const section = location.pathname.split('/')[1];
    let sectionMenu =
      section === 'cases'
        ? !user.role.some(r => ['attending', 'resident'].includes(r))
          ? menus[section].filter(m => m.url === '/cases/or-board')
          : menus[section]
        : menus[section];
    const devMode = localStorage.getItem('devMode');
    if (devMode !== 'true') {
      sectionMenu = sectionMenu?.filter(m => !m.dev);
    }
    setMenu(sectionMenu);
  }, [location]);

  const handleChange = (event, newValue) => {
    history.push(menu[newValue].url);
  };

  const getMenuVal = () => {
    return menu?.findIndex(item => item.url === location.pathname);
  };

  return menu?.length > 1 ? (
    <BottomNavigation
      className={classes.root}
      value={getMenuVal()}
      onChange={handleChange}
      showLabels
    >
      {menu.map(item => (
        <BottomNavigationAction key={item.url} {...item} />
      ))}
    </BottomNavigation>
  ) : null;
}

export default React.memo(FooterMenu);
