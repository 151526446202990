import i18n from '../../services/i18n';
import { ROLES } from '../../consts';

export const huddlesPerWeekToArr = huddlesPerWeek => {
  const arr = [['Date', 'Huddles per week']];
  Object.values(huddlesPerWeek).forEach(({ date, ratio }) => {
    arr.push([date, ratio]);
  });
  return arr;
};

export const usersToArr = users => {
  const activeUsers = users.filter(user => user.isActive);
  const vals = activeUsers.map(user => {
    const createdPast7Days =
      new Date(user.createdAt) > new Date(new Date().setDate(new Date().getDate() - 7));
    const name = `${user.nickName}${createdPast7Days ? 'New! ✨' : ''}`;
    return [name, user.roles.map(r => i18n.t(r.role)).join(', ')];
  });

  return [['Name', 'Roles'], ...vals];
};

export const mergeArrs = arrs => {
  // arrs is an array of two-dimensional arrays
  // the output of this function should be a two-dimensional array, where each row is the concatenation of the rows of the input arrays
  // for example, if arrs = [[[1, 2], [3, 4], [5, 6]], [[7, 8]]], the output should be [[1, 2, 7, 8], [3, 4,,], [5, 6,,]]
  const maxLength = Math.max(...arrs.map(arr => arr.length));
  const newArr = [];
  for (let i = 0; i < maxLength; i += 1) {
    const row = [];
    arrs.forEach(arr => {
      // if arr[i] is undefined, push array with empty strings, in the amount of length of arr[0]
      row.push(...(arr[i] || Array(arr[0].length).fill('')));
    });
    newArr.push(row);
  }

  // convert newArr to a string, tab between columns, newline between rows
  const str = newArr.map(row => row.join('\t')).join('\n');
  return str;
};

export const getTzOffset = timeZone => {
  const date = new Date();
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  const offsetHours = (tzDate.getTime() - utcDate.getTime()) / (1000 * 60 * 60);

  const prefix = offsetHours > 0 ? '+' : '-';
  return `${prefix}${Math.abs(offsetHours * 100)
    .toString()
    .padStart(4, '0')}`;
};

export const calcRowTotal = (nonParticipatingAttendings, reportPerUserInDateRange) => {
  if (!reportPerUserInDateRange) {
    return {};
  }

  let rt = {
    key: 'total',
    site: 'All',
    name: 'Total',
    resAllCount: 0,
    resBriefedCount: 0,
    attAllCount: 0,
    attBriefedCount: 0,
    attDebriefCount: 0
  };

  rt = reportPerUserInDateRange.reduce((row, current) => {
    // sum if not nonParticipatingUsers
    if (!nonParticipatingAttendings.some(u => u.id === current.userId)) {
      row.resAllCount += current.resAllCount;
      row.resBriefedCount += current.resBriefedCount;
      row.attAllCount += current.attAllCount;
      row.attBriefedCount += current.attBriefedCount;
      row.attDebriefCount += current.attDebriefCount;
    }
    return row;
  }, rt);

  rt.resBriefPercent = `${Math.round((rt.resBriefedCount * 100) / rt.resAllCount)}%`;
  rt.attBriefPercent = `${Math.round((rt.attBriefedCount * 100) / rt.attAllCount)}%`;
  rt.attDebriefPercent = `${Math.round((rt.attDebriefCount * 100) / rt.attBriefedCount)}%`;

  return rt;
};

export const calcTimeline = readyCaseStateLogsTimeInDataRange => {
  if (!readyCaseStateLogsTimeInDataRange) {
    return {};
  }

  const timeline = {};
  timeline.total = {
    after6am: 0,
    between10pm6am: 0,
    between7pm10pm: 0,
    between4pm7pm: 0,
    before4pm: 0
  };

  readyCaseStateLogsTimeInDataRange.forEach(row => {
    const caseDate = new Date(row.case.caseDate);
    const createdAt = new Date(row.createdAt);
    const nickname = row.case.attending.nickName;

    const diff = (createdAt - caseDate) / (1000 * 60 * 60);

    if (!timeline[nickname]) {
      timeline[nickname] = {
        after6am: 0,
        between10pm6am: 0,
        between7pm10pm: 0,
        between4pm7pm: 0,
        before4pm: 0,
        isPerfect: true
      };
    }

    if (diff >= 6) {
      timeline[nickname].after6am += 1;
      timeline.total.after6am += 1;
    } else if (diff >= -2) {
      timeline[nickname].between10pm6am += 1;
      timeline.total.between10pm6am += 1;
    } else if (diff >= -5) {
      timeline[nickname].between7pm10pm += 1;
      timeline.total.between7pm10pm += 1;
    } else if (diff >= -8) {
      timeline[nickname].between4pm7pm += 1;
      timeline.total.between4pm7pm += 1;
    } else {
      timeline[nickname].before4pm += 1;
      timeline.total.before4pm += 1;
    }
  });

  return timeline;
};

export const applyTzOffset = (tz, moment) => {
  if (!tz) {
    throw Error('No timezone');
  }
  const tzOffset = getTzOffset(tz);
  return moment.utcOffset(tzOffset, true);
};

export const applyTzOffsetReverse = (tz, moment) => {
  if (!tz) {
    throw Error('No timezone');
  }
  const tzOffset = getTzOffset(tz);
  return moment.utcOffset(-tzOffset, true);
};

export const userToRoles = user => {
  if (!user.roles) {
    return '';
  }
  return user.roles
    .filter(
      r =>
        ![ROLES.DEPARTMENT_ADMIN, ROLES.INVITER, ROLES.CARE_TEAM, 'department_director'].includes(
          r.role
        )
    )
    .map(r => i18n.t(r.role))
    .join(', ');
};

export const calcFollowers = (caseFollowersInRange, totalHuddles) => {
  // set totalFollowers = sum of dashboardData.caseFollowersInDateRange.count
  const totalFollowers = caseFollowersInRange.reduce((acc, row) => acc + row.count, 0);
  console.log(totalFollowers);
  const totalViews = caseFollowersInRange.reduce((acc, row) => acc + row.views, 0);
  console.log(totalViews);

  const followersAvg = totalFollowers / totalHuddles;
  const followersViewed = totalViews / totalHuddles;
  return { followersAvg, followersViewed };
};
