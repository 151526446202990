import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import amplService from 'app/services/amplService';
import { caseStateInBriefAndReview } from 'app/consts';
import { parseCaseValuesToText, parseCaseCommentsToText } from './store/caseViewSlice';

export const shareCaseFilesImpl = async (filesObjects, srcForAnalytics) => {
  const files = [];
  for (const { url, name, type } of filesObjects) {
    // eslint-disable-next-line no-await-in-loop
    const fetchedAsset = await fetch(url);
    // eslint-disable-next-line no-await-in-loop
    const blob = await fetchedAsset.blob();
    const file = new File([blob], name, {
      type,
      lastModified: new Date()
    });
    files.push(file);
  }

  const shareData = {
    files
  };
  amplService.sendEvent(amplService.EVENTS.SHARE_MEDIA, { source: srcForAnalytics });

  try {
    await navigator.share(shareData);
  } catch (err) {
    if (!err.toString().includes('AbortError')) {
      console.error(`Error: ${err}`);
    }
  }
};

const useStyles = makeStyles(theme => ({
  shareButton: {
    position: 'absolute',
    right: theme.spacing(6),
    top: theme.spacing(1.5),
    padding: theme.spacing(0.5),
    zIndex: 9999,
    color: theme.palette.grey[500],
    backgroundColor: '#ffffffaa'
  }
}));

export async function shareCaseImpl(kase, t, calculatedDisplayIds, srcForAnalytics) {
  const basePath = window.location.origin;
  const fieldValues = parseCaseValuesToText(kase.fieldValues);
  const commentsArr = parseCaseCommentsToText(kase.comments);
  // @todo: move to t with params
  const title = calculatedDisplayIds[kase.id] || kase.displayId || kase.procedureTitle;

  const caseValuesAsText = [
    // kase.formatedDate,
    title,
    kase.description,
    `${t('Attending')}: ${kase.attending.nickName}${
      caseStateInBriefAndReview(kase.state) ? ' (pending review)' : ''
    }`,
    kase.resident ? `Resident: ${kase.resident.nickName}` : '',
    '-------------------',
    ...fieldValues,
    '-------------------',
    ...commentsArr,
    `${t('See more at')} ${basePath}/cases/or-board?case=${kase.id}`
  ]
    .filter(row => row !== '')
    .join('\n\n');

  const shareData = {
    title,
    text: caseValuesAsText
  };

  amplService.sendEvent(amplService.EVENTS.SHARE_CASE, { source: srcForAnalytics });

  try {
    await navigator.share(shareData);
  } catch (err) {
    if (!err.toString().includes('AbortError')) {
      console.error(`Error: ${err}`);
    }
  }
}

const ANALYTICS_SOURCE = 'inside case';

export default function ShareCase(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [needToExport, setNeedToExport] = useState(false);
  const [showFilesDialog, setShowFilesDialog] = useState(false);
  const { case: kase, handleShare } = props;

  useEffect(() => {
    const handle = async () => {
      if (needToExport) {
        handleShare(kase.id, ANALYTICS_SOURCE);
      }
    };
    handle();
  }, [needToExport, kase, t]);

  const shareSupported = Boolean(navigator.share);

  return shareSupported ? (
    <IconButton
      title={t('Share Case')}
      className={clsx(classes.shareButton)}
      onClick={props.onClick}
      size="large"
    >
      <Icon fontSize="small">share</Icon>
    </IconButton>
  ) : null;
}
