import { combineReducers } from '@reduxjs/toolkit';
import auth from 'app/auth/store';
import metaData from 'app/metaData/store';
import fuse from './fuse';
import data from './data';

const createReducer = asyncReducers =>
  combineReducers({
    auth,
    fuse,
    metaData,
    data,
    ...asyncReducers
  });

export default createReducer;
