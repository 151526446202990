import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'app/services/i18n';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';

const numAttr = num => {
  let label;
  let color;
  if (Number.isNaN(Number(num.user))) {
    label = i18n.t('N/A');
    color = 'gray';
  } else if (num.user > 90) {
    label = i18n.t('Excellent');
    color = 'green';
  } else if (num.user >= 80) {
    label = i18n.t('Good');
    color = 'blue';
  } else if (num.user < num.avg) {
    label = i18n.t('Below average');
    color = 'orange';
  }
  return {
    label,
    color
  };
};

const parseNum = num => {
  const n = numAttr(num);
  return (
    <Typography className={`text-${n.color}-600`}>
      {Number.isNaN(Number(num.user))
        ? 'N/A'
        : parseFloat(num.user.toFixed(1)) + (num.user > 0 ? '%' : '')}
    </Typography>
  );
};

const parseChip = num => {
  const n = numAttr(num);
  return num.user > 0 && n.label ? (
    <Chip
      size="small"
      label={n.label}
      className={`text-xs bg-${n.color}-100 text-${n.color}-900`}
    />
  ) : null;
};

const useStyles = makeStyles(theme => ({
  table: {
    '& tr': {
      '&:last-child td': {
        borderBottom: 'none'
      }
    },
    '& td': {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      '&:first-child': {
        paddingLeft: theme.spacing(1.5)
      },
      '&:last-child': {
        paddingRight: theme.spacing(1.5)
      }
    }
  }
}));

export default function DashboardStatsTable(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data } = props;

  const { casesWhereUserAssistant, casesWhereUserAttending } = data;
  return (
    <TableContainer component={Paper} className="h-full">
      <Table className={classes.table} aria-label="Stats table">
        <TableBody>
          <TableRow>
            <TableCell>{t('Cases in Chiefy')}</TableCell>
            <TableCell className="text-right text-xl font-bold">{data.casesInChiefy}</TableCell>
            <TableCell />
          </TableRow>
          {/* Resident brief rate */}
          {casesWhereUserAssistant > 0 && data.briefRate.user > 0 && (
            <TableRow>
              <TableCell>{t('Your huddle rate')}</TableCell>
              <TableCell className="text-right font-semibold">{parseNum(data.briefRate)}</TableCell>
              <TableCell>{parseChip(data.briefRate)}</TableCell>
            </TableRow>
          )}
          {/* Resident debrief rate
          {casesWhereUserAssistant > 0 && data.debriefRate.user > 0 && (
            <TableRow>
              <TableCell>{t('Debrief rate')}</TableCell>
              <TableCell className="text-right font-semibold">
                {parseNum(data.debriefRate)}
              </TableCell>
              <TableCell>{parseChip(data.debriefRate)}</TableCell>
            </TableRow>
          )}
          */}
          {/* Attending brief review rate */}
          {casesWhereUserAttending > 0 && data.briefReviewRate.user > 0 && (
            <TableRow>
              <TableCell>{t('Your huddle rate')}</TableCell>
              <TableCell className="text-right font-semibold">
                {parseNum(data.briefReviewRate)}
              </TableCell>
              <TableCell>{parseChip(data.briefReviewRate)}</TableCell>
            </TableRow>
          )}
          {/* Attending debrief review rate
          {casesWhereUserAttending > 0 && data.debriefReviewRate.user > 0 && (
            <TableRow>
              <TableCell>{t('Digital debrief')}</TableCell>
              <TableCell className="text-right font-semibold">
                {parseNum(data.debriefReviewRate)}
              </TableCell>
              <TableCell>{parseChip(data.debriefReviewRate)}</TableCell>
            </TableRow>
          )}
           */}
          {/* feedback rate
          {casesWhereUserAttending > 0 && data.feedbackRate.user > 0 && (
            <TableRow>
              <TableCell>{t('Feedback rate')}</TableCell>
              <TableCell className="text-right font-semibold">
                {parseNum(data.feedbackRate)}
              </TableCell>
              <TableCell>{parseChip(data.feedbackRate)}</TableCell>
            </TableRow>
          }
          */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
