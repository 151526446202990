import { gql } from '@apollo/client';

import { PLAN_FEEDBACK_FULL_DATA } from './fragments/planFeedback';

const create = gql`
  mutation CareTeamCreatePlanFeedback($object: plan_feedbacks_insert_input!) {
    insert_plan_feedbacks_one(object: $object) {
      ...PlanFeedbackFullData
    }
  }
  ${PLAN_FEEDBACK_FULL_DATA}
`;
const updatePlanFeedback = gql`
  mutation CareTeamUpdatePlanFeedback($id: uuid!, $object: plan_feedbacks_set_input!) {
    update_plan_feedbacks_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...PlanFeedbackFullData
    }
  }
  ${PLAN_FEEDBACK_FULL_DATA}
`;

const deletePlanFeedback = gql`
  mutation CareTeamDeletePlanFeedback($id: uuid!) {
    delete_plan_feedbacks_by_pk(id: $id) {
      id
    }
  }
`;

const queries = {};

const mutations = {
  create,
  deletePlanFeedback,
  updatePlanFeedback
};

const api = {
  queries,
  mutations
};

export default api;
