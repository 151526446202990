import MomentAdapter from '@date-io/moment';
import { isToday, isTomorrow } from 'app/components/caseViews/timeHelper';
import { CASE_STATE } from 'app/consts';

export const getNextCases = (cases, user, curCaseId) => {
  return cases.filter(c => c.id !== curCaseId && isCaseInNextCases(user, c));
};

export const isCaseInNextCases = (user, c) => {
  // Handle upcoming cases today/tomorrow, user is resident and in brief or attending and in brief-in-review
  const caseDate = new Date(c.caseDate);

  return (
    (isToday(caseDate) || isTomorrow(caseDate)) &&
    ((c.residentId === user.data.id && c.state === CASE_STATE.IN_BRIEF) ||
      (c.attendingId === user.data.id && c.state === CASE_STATE.BRIEF_IN_REVIEW))
  );
};

export const localISODate = ts => {
  // toISOString will always return the date at UTC timezone
  // We shift it with getTimezoneOffset to get the local date
  const d = new Date(ts);
  const tzoffset = d.getTimezoneOffset() * 60000; // time-zone offset in ms
  return new Date(d - tzoffset).toISOString().slice(0, 10);
};

export const formatGroupDate = isoDate => {
  // Format a readable date notation from group header
  // group header (isoDate) is generated in localISODate()
  return new Date(`${isoDate}T00:00:00`).toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  });
};
