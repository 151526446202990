import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';

const useStyles = makeStyles(theme => ({
  root: {}
}));

export default function UserAvatar(props) {
  const classes = useStyles();
  const { user } = props;

  if (!user) {
    return null;
  }

  const name = user.nickName || '';
  const initials = (user.firstName.substring(0, 1) + user.lastName.substring(0, 1)).toUpperCase();
  const photo = user.photoURL || user.avatar || '';

  return user ? (
    photo ? (
      <Avatar
        className={clsx(classes.root, 'user-avatar', props.className)}
        alt={name}
        src={photo}
        sx={props.sx}
      >
        {initials}
      </Avatar>
    ) : (
      <Avatar
        className={clsx(classes.root, 'user-avatar', props.className)}
        alt={name}
        sx={props.sx}
      >
        {initials}
      </Avatar>
    )
  ) : (
    <Avatar
      className={clsx(classes.root, 'user-avatar', props.className)}
      alt="user photo"
      src="assets/images/avatars/profile.jpg"
    />
  );
}
