import { createSlice } from '@reduxjs/toolkit';
import apiService from 'app/services/apiService';
import auth0Service from 'app/services/auth0Service';
import { setUserData } from 'app/auth/store/userSlice';
import _ from '@lodash';
import toast from 'app/services/toastService/toast';
import { CARE_TEAM_ROLES, ROLES, USER_CASE_ROLES } from 'app/consts';

const CACHED_PROCEDURES_KEY = 'cachedProcedures';
const CACHED_UPLOADCARE_KEY = 'cachedUploadcareKey';
const CACHED_ONT_VERSION_KEY = 'cachedOntVersion';
const CACHED_METAS_KEY = 'cachedMetas';

const processSp = spr => {
  return JSON.parse(spr);
};

const getItemFromCache = key => localStorage.getItem(key);

const checkIfCacheValid = curOntVer => {
  const curCachedOntVersion = localStorage.getItem(CACHED_ONT_VERSION_KEY);
  return curCachedOntVersion === curOntVer;
};

export const setLoggedInUserData = () => async (dispatch, getState) => {
  try {
    const tokenData = auth0Service.getTokenData();
    const userId = tokenData['https://hasura.io/jwt/claims']['x-hasura-user-id'];
    const role = tokenData['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'];
    let isDepartmentDirectorOnly = false;
    let user;

    if (role.includes('department_admin')) {
      user = await apiService.getAdminUser(userId);
    } else if (role.includes('care_team')) {
      user = await apiService.getCareTeamUser(userId);
    } else if (role.includes('department_director')) {
      user = await apiService.getDepDirectorUser(userId);
      isDepartmentDirectorOnly = true;
    } else {
      user = await apiService.getFollowerUser(userId);
    }

    dispatch(
      setUserData({
        role,
        data: {
          nickName: user.nickName,
          resetStatsAt: user.resetStatsAt,
          photoURL: user.avatar,
          email: user.email,
          shortcuts: [],
          id: user.id,
          pgy: user.pgy,
          firstName: user.firstName,
          lastName: user.lastName,
          isPa: user.isPa,
          isCasesScheduler: user.isCasesScheduler,
          blankCaseAlways: user.blankCaseAlways,
          createdAt: user.createdAt,
          followAll: user.followAll,
          muteUntil: user.muteUntil ?? [],
          isAssignsResidents: user.isAssignsResidents,
          isNurseLeader: user.isNurseLeader,
          reminderDays: user.reminderDays,
          isActive: user.isActive
        }
      })
    );

    if (isDepartmentDirectorOnly) {
      dispatch(setUsers([user]));
    }

    dispatch(setLoaded(true));
    return user;
  } catch (err) {
    console.log(err);
  }
  return null;
};

const loadSp = async spr => {
  const ontVersion = apiService.currentOntVersion();
  const specialitiesToLoad = processSp(spr);
  const specialitiesPromises = specialitiesToLoad.map(sp =>
    apiService.getOnt(`procedures-by-speciality/${sp}.json`, ontVersion)
  );
  const spValues = await Promise.all(specialitiesPromises);
  const procedures = [];
  for (const spVal of spValues) {
    // eslint-disable-next-line no-await-in-loop
    const prFile = await fetch(spVal.ont.signedUrl);
    // eslint-disable-next-line no-await-in-loop
    const prJson = await prFile.json();
    procedures.push(
      ...prJson.procedures.map(p => ({
        ...p,
        specialtyId: prJson.id,
        specialtyTitle: prJson.name
      }))
    );
  }

  return procedures;
};

const HASURA_KEYS = {
  CLAIMS: 'https://hasura.io/jwt/claims',
  SITES_IDS: 'x-hasura-user-sites-ids',
  ALLOWED_ROLES: 'x-hasura-allowed-roles'
};
const IS_SAML = 'is_saml';
const TZ = 'tz';
const ATTENDING_ONLY_FLOW = 'attending_only_flow';
const SPECIALTIES = 'specialties';
const isTrue = meta => meta?.value === 'true';

const parseTextToArray = text => (text ? JSON.parse(text.replace('{', '[').replace('}', ']')) : []);

const loadMetas = async (dispatch, getState) => {
  if (checkIfCacheValid(getState().metaData.enum.curOntVer) && getItemFromCache(CACHED_METAS_KEY)) {
    return JSON.parse(getItemFromCache(CACHED_METAS_KEY));
  }
  const { meta } = await apiService.getMetas([IS_SAML, ATTENDING_ONLY_FLOW, SPECIALTIES, TZ]);
  localStorage.setItem(CACHED_METAS_KEY, JSON.stringify(meta));
  return meta;
};

const loadProcedures = async (getState, specialties) => {
  if (
    checkIfCacheValid(getState().metaData.enum.curOntVer) &&
    getItemFromCache(CACHED_PROCEDURES_KEY)
  ) {
    return JSON.parse(getItemFromCache(CACHED_PROCEDURES_KEY));
  }
  const proceduresUnsorted = await loadSp(specialties);
  if (proceduresUnsorted.length) {
    localStorage.setItem(CACHED_PROCEDURES_KEY, JSON.stringify(proceduresUnsorted));
  }
  return proceduresUnsorted;
};

export const setEnum = () => async (dispatch, getState) => {
  try {
    const tokenData = auth0Service.getTokenData();
    const { [HASURA_KEYS.ALLOWED_ROLES]: role, [HASURA_KEYS.SITES_IDS]: sitesIds } =
      tokenData[HASURA_KEYS.CLAIMS];
    const userSitesIds = parseTextToArray(sitesIds);
    const meta = await loadMetas(dispatch, getState);
    const isSaml = meta.find(m => m.key === IS_SAML);
    const tz = meta.find(m => m.key === TZ);
    const specialties = meta.find(m => m.key === SPECIALTIES).value;

    dispatch(setTz(tz?.value));
    if (isTrue(isSaml)) {
      dispatch(setIsSaml(true));
    }

    const proceduresUnsorted = await loadProcedures(getState, specialties);

    const proceduresByCases =
      role.includes(ROLES.CARE_TEAM) || role.includes(ROLES.DEPARTMENT_ADMIN)
        ? await apiService.getCasesByProcedureId()
        : [];

    const proceduresByCasesMap = new Map();
    proceduresByCases.forEach(obj => {
      if (!proceduresByCasesMap[obj.procedureId]) {
        proceduresByCasesMap[obj.procedureId] = {};
      }
      if (!proceduresByCasesMap[obj.procedureId][obj.siteId]) {
        proceduresByCasesMap[obj.procedureId][obj.siteId] = {};
      }
      proceduresByCasesMap[obj.procedureId][obj.siteId][obj.userId] = obj.count;
    });

    const proceduresWithCount = [];
    proceduresWithCount.push(
      ...proceduresUnsorted.map(p => ({
        ...p,
        countPerSiteAndAtt: proceduresByCasesMap[p.id]
      }))
    );

    dispatch(setProcedures(proceduresWithCount));

    if (role.includes('care_team')) {
      let uploadcareKey;
      if (
        checkIfCacheValid(getState().metaData.enum.curOntVer) &&
        getItemFromCache(CACHED_UPLOADCARE_KEY)
      ) {
        uploadcareKey = getItemFromCache(CACHED_UPLOADCARE_KEY);
      } else {
        uploadcareKey = (await apiService.getCareTeamUploadcareKey()).metaByPk.value;
        localStorage.setItem(CACHED_UPLOADCARE_KEY, uploadcareKey);
      }
      dispatch(setUploadcareKey(uploadcareKey));
    }

    // After updating everything, update the ont cached version
    if (!checkIfCacheValid(getState().metaData.enum.curOntVer)) {
      localStorage.setItem(CACHED_ONT_VERSION_KEY, getState().metaData.enum.curOntVer);
    }

    const promises = [apiService.getAllowedSites(userSitesIds), apiService.getAllowedUsers()];

    const values = await Promise.all(promises);
    dispatch(setSites(values[0].sites));
    dispatch(setUsers(values[1].users));
    const { sites, users } = getState().metaData.enum;
    apiService.setEnums({ sites, users });
    dispatch(setMetaLoaded(true));
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }
};

const initialState = {
  sites: [],
  procedures: [],
  users: [],
  experiences: [],
  loading: false,
  curOntVer: '',
  loaded: false,
  metaLoaded: false,
  uploadcareKey: '',
  isSaml: false,
  tz: ''
};

export const optionsSelector = ({ metaData }) => ({
  ...metaData.enum,
  attendings: metaData.enum.users.filter(u =>
    u.roles.map(r => r.role).includes(USER_CASE_ROLES.ATTENDING)
  ),
  residents: metaData.enum.users.filter(u =>
    u.roles.map(r => r.role).includes(USER_CASE_ROLES.RESIDENT)
  ),
  careTeam: metaData.enum.users.filter(u =>
    u.roles.map(r => r.role).some(role => CARE_TEAM_ROLES.includes(role))
  )
});
const enumSlice = createSlice({
  name: 'enum',
  initialState,
  reducers: {
    setSites: (state, action) => {
      state.sites = action.payload;
    },
    setIsSaml: (state, action) => {
      state.isSaml = action.payload;
    },
    setTz: (state, action) => {
      state.tz = action.payload;
    },
    setProcedures: (state, action) => {
      state.procedures = action.payload
        .map(p => ({
          value: p.id,
          name: p.name,
          countPerSiteAndAtt: p.countPerSiteAndAtt,
          specialtyTitle: p.specialtyTitle,
          specialtyId: p.specialtyId
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    resetEnums: () => {
      return _.merge({}, initialState);
    },
    setCurOntVer: (state, action) => {
      state.curOntVer = action.payload;
    },
    setLoaded: (state, action) => {
      state.loaded = action.payload;
    },
    setMetaLoaded: (state, action) => {
      state.metaLoaded = action.payload;
    },
    setUploadcareKey: (state, action) => {
      state.uploadcareKey = action.payload;
    }
  },
  extraReducers: {}
});

export const {
  setSites,
  setProcedures,
  setIsSaml,
  setTz,
  setUsers,
  setExperiences,
  setLoading,
  resetEnums,
  setCurOntVer,
  setLoaded,
  setMetaLoaded,
  setUploadcareKey
} = enumSlice.actions;

export default enumSlice.reducer;
