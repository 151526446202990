import _ from '@lodash';
import { createSlice } from '@reduxjs/toolkit';
import { addItem, updateItem } from 'app/store/data/casesSlice';
import apiService from 'app/services/apiService';
import humps from 'lodash-humps';
import toast from 'app/services/toastService/toast';
import i18n from 'app/services/i18n';
import MomentAdapter from '@date-io/moment';
import { isToday, isTomorrow } from 'app/components/caseViews/timeHelper';
import amplService from 'app/services/amplService/amplService';
import { CASE_STATE, caseStateDebriefPlus, FORM_STAGE, USER_CASE_ROLES } from 'app/consts';
import { getUserRoleInCase } from '../formHelpers/shared';

export const isCurrentUserToHandleCase = (kase, currentUser) => {
  return getUserRoleInCase(kase, currentUser) !== USER_CASE_ROLES.NONE;
};

const getCaseMetaData = async procedure => {
  const ontVersion = apiService.currentOntVersion();
  const ontUrl = await apiService.getOnt(`procedure-by-id/${procedure.value}.json`, ontVersion);
  const pFile = await fetch(ontUrl.ont.signedUrl);
  return humps(await pFile.json());
};

export const newCaseToState = (caseDate, attendingOnly, isResidentOwnCase) => {
  const dateLib = new MomentAdapter();
  const now = dateLib.moment();
  const today = dateLib.moment();
  today.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const isDateInPast = today.isAfter(dateLib.date(caseDate));

  let caseState;

  if (isDateInPast) {
    caseState = CASE_STATE.COMPLETED;
  } else if (
    attendingOnly ||
    (now.hours() >= 19 && isTomorrow(caseDate)) ||
    (!isResidentOwnCase && isToday(caseDate))
  ) {
    caseState = CASE_STATE.BRIEF_IN_REVIEW;
  } else {
    caseState = CASE_STATE.IN_BRIEF;
  }

  return caseState;
};

const parseCaseWithoutProcedure = (
  { caseDate, site, attending, resident, displayId, description, room },
  state,
  isSchedule,
  isResidentOwnCase,
  indexInDay,
  caseState
) => {
  const newCaseState =
    caseState ||
    (isSchedule ? CASE_STATE.NEW : newCaseToState(caseDate, !resident, isResidentOwnCase));

  return {
    caseDate,
    displayId,
    attendingId: attending ? attending.id : null,
    residentId: resident ? resident.id : null,
    siteId: site.id,
    state: newCaseState,
    ontologyVersion: state.metaData.enum.curOntVer,
    description,
    indexInDay: indexInDay || new Date().getTime(),
    room
  };
};

const parseCase = (
  { caseDate, procedure, site, attending, resident, displayId, description, room },
  pJson,
  state,
  isSchedule,
  isResidentOwnCase,
  indexInDay,
  caseState
) => {
  const newCaseState =
    caseState ||
    (isSchedule ? CASE_STATE.NEW : newCaseToState(caseDate, !resident, isResidentOwnCase));

  return {
    caseDate,
    displayId,
    attendingId: attending ? attending.id : null,
    residentId: resident ? resident.id : null,
    procedureId: procedure.value,
    siteId: site.id,
    state: newCaseState,
    procedureData: pJson,
    ontologyVersion: state.metaData.enum.curOntVer,
    procedureTitle: procedure.name,
    procedureTypeId: pJson.subType.procedureType.id,
    procedureTypeTitle: pJson.subType.procedureType.name,
    specialtyId: pJson.specialty.id,
    specialtyTitle: pJson.specialty.name,
    attendingDefaults: pJson.specialty.attendingDefaults,
    description,
    indexInDay: indexInDay || new Date().getTime(),
    room
  };
};

const changedFields = (curValue, newValue) => {
  const fields = [];
  const curAttendingId = curValue.attending ? curValue.attending.id : null;
  const curResidentId = curValue.resident ? curValue.resident.id : null;
  if (new Date(curValue.caseDate).getTime() !== newValue.caseDate.getTime()) {
    fields.push('caseDate');
  }
  if (curValue.procedureId !== newValue.procedureId) {
    fields.push('procedureId');
  }
  if (curValue.procedureTitle !== newValue.procedureTitle) {
    fields.push('procedureTitle');
  }
  if (curResidentId !== newValue.residentId) {
    fields.push('resident');
  }
  if (curAttendingId !== newValue.attendingId) {
    fields.push('attending');
  }
  if (curValue.siteId !== newValue.siteId) {
    fields.push('siteId');
  }
  if (curValue.description !== newValue.description) {
    fields.push('description');
  }
  if (curValue.displayId !== newValue.displayId) {
    fields.push('displayId');
  }
  return fields;
};

export const createCase = (formData, isUserAdmin, isSchedule) => async (dispatch, getState) => {
  let newCase;

  try {
    const state = getState();

    const currentUser = _.get(state, 'auth.user.data');
    const currentUserId = currentUser.id;

    const isResidentOwnCase = currentUserId === formData.resident?.id;
    const isAttendingOwnCase = currentUserId === formData.attending.id;

    const hasProcedure = !!formData.procedure;
    const pJson = hasProcedure ? await getCaseMetaData(formData.procedure) : {};

    const parsedCase = hasProcedure
      ? parseCase(formData, pJson, state, isSchedule, isResidentOwnCase, null, null)
      : parseCaseWithoutProcedure(formData, state, isSchedule, isResidentOwnCase, null, null);

    const attending = isUserAdmin
      ? await apiService.getAdminUser(parsedCase.attendingId)
      : await apiService.getCareTeamUser(parsedCase.attendingId);

    parsedCase.attendingDefaults = hasProcedure
      ? await apiService.getDefaults(
          isUserAdmin,
          parsedCase.procedureId,
          FORM_STAGE.BRIEF,
          USER_CASE_ROLES.ATTENDING,
          parsedCase.attendingId,
          attending.blankCaseAlways
        )
      : null;

    newCase = isUserAdmin
      ? await apiService.adminCreateCase(parsedCase)
      : await apiService.createCase(parsedCase);

    newCase.insertCasesOne.site = apiService.getSiteFromId(newCase.insertCasesOne.siteId);

    amplService.sendCaseEvent(amplService.EVENTS.ADD_CASE, newCase.insertCasesOne, {
      isResidentValid: !!newCase.insertCasesOne.residentId
    });

    newCase.insertCasesOne.resident = newCase.insertCasesOne.residentId
      ? apiService.getUserFromId(newCase.insertCasesOne.residentId)
      : null;
    newCase.insertCasesOne.attending = newCase.insertCasesOne.attendingId
      ? apiService.getUserFromId(newCase.insertCasesOne.attendingId)
      : null;
    if (isCurrentUserToHandleCase(newCase.insertCasesOne, currentUser)) {
      // toast.success(i18n.t('great_new_case'), 1500);
    } else if (!isSchedule && isAttendingOwnCase) {
      if (newCase.insertCasesOne.residentId) {
        toast.success(
          i18n.t('attending_own_case_success_with_res', {
            resident: newCase.insertCasesOne.resident.nickName
          })
        );
      }
    } else if (
      !isSchedule &&
      !isResidentOwnCase &&
      !isAttendingOwnCase &&
      newCase.insertCasesOne.residentId
    ) {
      toast.success(
        i18n.t('care_team_not_own_case_success_with_res', {
          resident: newCase.insertCasesOne.resident.nickName
        })
      );
    } else if (isResidentOwnCase && parsedCase.state === CASE_STATE.IN_DEBRIEF) {
      toast.success(i18n.t('care_team_own_case_in_debrief'));
    }

    if (isAttendingOwnCase || isResidentOwnCase || isSchedule) {
      dispatch(addItem(newCase.insertCasesOne));
    }
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    throw err;
  }

  return newCase?.insertCasesOne;
};

const shouldMoveToReady = (diffFields, caseDate, fromState) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  let moveToReady = false;
  if (
    diffFields.includes('caseDate') &&
    new Date(caseDate).getTime() >= today.getTime() &&
    caseStateDebriefPlus(fromState)
  ) {
    moveToReady = true;
  }

  return moveToReady;
};

const deleteCaseStateLogsAfterReady = async (caseId, caseStateLogs) => {
  // in caseStateLogs, find the time of the first move to Ready
  const readyTime =
    caseStateLogs.find(
      log =>
        [CASE_STATE.IN_BRIEF, CASE_STATE.BRIEF_IN_REVIEW].includes(log.fromState) &&
        log.toState === CASE_STATE.READY
    )?.createdAt || new Date(0);

  await apiService.deleteCaseStateLogsAfterTime(caseId, readyTime);
};

export const updateCaseValues =
  (caseId, formData, isUserAdmin, isSchedule, userRoleInCase) => async (dispatch, getState) => {
    try {
      const state = getState();
      const curCase = state.data.cases.openCase.case;
      const hasProcedure = !!formData.procedure;
      const pJson = hasProcedure ? await getCaseMetaData(formData.procedure) : false;

      const isResidentOwnCase = userRoleInCase === USER_CASE_ROLES.RESIDENT;

      const parsedCase = hasProcedure
        ? parseCase(
            formData,
            pJson,
            state,
            isSchedule,
            isResidentOwnCase,
            curCase.indexInDay,
            curCase.state
          )
        : parseCaseWithoutProcedure(
            formData,
            state,
            isSchedule,
            isResidentOwnCase,
            curCase.indexInDay,
            curCase.state
          );

      const diffFields = changedFields(curCase, parsedCase);
      if (diffFields.length === 0) {
        return curCase;
      }

      const { attendingValues } = curCase;
      const newAttedValues = {
        caseFields: { ...attendingValues.caseFields },
        pmmFields: [...attendingValues.pmmFields],
        metaFields: { ...attendingValues.metaFields }
      };

      // Update attending values
      diffFields.forEach(field => {
        if (userRoleInCase === USER_CASE_ROLES.ATTENDING) {
          newAttedValues.metaFields[field] = parsedCase[field];
        } else if (
          userRoleInCase === USER_CASE_ROLES.RESIDENT &&
          field in attendingValues.metaFields
        ) {
          delete newAttedValues.metaFields[field];
        }
      });
      parsedCase.attendingValues = newAttedValues;

      let updatedCase = false;
      /* if form is different, need to re-create the case */
      const recreate =
        curCase.state !== CASE_STATE.NEW &&
        pJson &&
        curCase.procedureData &&
        curCase.procedureData.subType.form.id !== pJson?.subType?.form?.id;

      // If procedure has changed (make sure that they are not differnet because of null/undefined)
      if (
        (curCase.procedureId !== parsedCase.procedureId &&
          (curCase.procedureId || parsedCase.procedureId)) ||
        recreate
      ) {
        // Reset PMM and case fields because all the values are cleared. Leave meta fields.
        parsedCase.attendingValues.pmmFields = [];
        parsedCase.attendingValues.caseFields = {};

        const attending = isUserAdmin
          ? await apiService.getAdminUser(parsedCase.attendingId)
          : await apiService.getCareTeamUser(parsedCase.attendingId);

        parsedCase.attendingDefaults = parsedCase.procedureId
          ? await apiService.getDefaults(
              isUserAdmin,
              parsedCase.procedureId,
              FORM_STAGE.BRIEF,
              USER_CASE_ROLES.ATTENDING,
              parsedCase.attendingId,
              attending.blankCaseAlways
            )
          : null;
      }

      if (recreate) {
        if (isUserAdmin) {
          await apiService.adminDeleteCase({ id: caseId }, false);
        } else {
          await apiService.deleteCase({ id: caseId }, false);
        }
        updatedCase = (
          isUserAdmin
            ? await apiService.adminCreateCase(parsedCase)
            : await apiService.createCase(parsedCase)
        ).insertCasesOne;
      } else {
        // If case moved to today (or later), move to Ready state
        const moveToReady = shouldMoveToReady(diffFields, parsedCase.caseDate, parsedCase.state);
        if (moveToReady) {
          parsedCase.state = CASE_STATE.READY;
        }

        updatedCase = (
          isUserAdmin
            ? await apiService.adminUpdateCaseValues(caseId, parsedCase)
            : await apiService.updateCaseValues(caseId, parsedCase)
        ).updateCasesByPk;

        if (moveToReady) {
          await deleteCaseStateLogsAfterReady(caseId, curCase.caseStateLogs);
        }
      }
      updatedCase.resident = updatedCase.residentId
        ? apiService.getUserFromId(updatedCase.residentId)
        : null;
      updatedCase.attending = updatedCase.attendingId
        ? apiService.getUserFromId(updatedCase.attendingId)
        : null;
      updatedCase.site = {
        name: apiService.getSiteFromId(updatedCase.siteId)
      };
      if (!isSchedule) {
        dispatch(updateItem(updatedCase));
      }
      amplService.sendCaseEvent(amplService.EVENTS.UPDATE_CASE_ATTRIBUTES, updatedCase);

      return updatedCase;
    } catch (err) {
      console.log(err);
      toast.error(err.message);
      throw err;
    }
  };
const initialState = {};

const newCaseSlice = createSlice({
  name: 'newCase',
  initialState,
  reducers: {}
});

export default newCaseSlice.reducer;
