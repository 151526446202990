import { authRoles } from '../auth';

const navigationConfig = [
  {
    id: 'main',
    type: 'group',
    children: [
      {
        id: 'cases',
        translate: 'CASES',
        type: 'item',
        icon: 'format_list_bulleted',
        url: '/cases',
        auth: authRoles.careTeam
      },
      {
        id: 'schedule',
        translate: 'SCHEDULE',
        type: 'item',
        icon: 'alarm_add',
        url: '/schedule',
        auth: authRoles.casesAdmin // TODO this doesn't work. Still doesn't have the role.
      },
      {
        id: 'analytics',
        translate: 'ANALYTICS',
        type: 'item',
        icon: 'business',
        url: '/analytics',
        auth: authRoles.depDirector
      },
      {
        id: 'admin',
        translate: 'ADMINISTRATION',
        type: 'item',
        icon: 'admin_panel_settings',
        url: '/admin',
        auth: authRoles.depAdmin
      },
      {
        id: 'imports',
        translate: 'IMPORTS',
        type: 'item',
        icon: 'admin_panel_settings',
        url: '/imports',
        auth: authRoles.depAdmin
      },
      {
        id: 'logout',
        translate: 'LOGOUT',
        type: 'item',
        icon: 'power_settings_new',
        url: '/logout',
        auth: authRoles.notGuest
      }
    ]
  }
];

export default navigationConfig;
