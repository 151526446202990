import CasesList from 'app/components/casesList/CasesList';
import { deleteCase, setPastCasesLimit } from 'app/store/data/casesSlice';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useExport from 'app/pages/cases/hooks/useExport';
import usePastCases from 'app/pages/cases/hooks/usePastCases';

const PastCases = ({ loadCaseById, handleShare, dispatch, casesLoaded }) => {
  const [pastCasesCurLength, setPastCasesCurLength] = useState(0);
  const pastCasesLimit = useSelector(({ data }) => data.cases.pastCasesLimit);
  const pastCasesLimitInitValue = useSelector(({ data }) => data.cases.pastCasesLimitInitValue);
  const handleExport = useExport();
  const {
    canLoadMore,
    loadInitPastCases,
    loadMorePastCases,
    pastCasesLimitIncrement,
    pastCasesLoaded,
    totalCases,
    slicedCases,
    enableLoadMore,
    handleFirstLoad
  } = usePastCases();
  const dataLoaded = pastCasesLoaded && casesLoaded;
  // When total cases is more then curLength set load more to true.
  useEffect(() => {
    if (totalCases > pastCasesCurLength) {
      enableLoadMore();
      setPastCasesCurLength(totalCases);
      dispatch(setPastCasesLimit(totalCases));
    }
  }, [totalCases]);
  useEffect(() => {
    handleFirstLoad();
  }, []);
  const showPastCases = dataLoaded && totalCases;
  if (!showPastCases) {
    return null;
  }

  return (
    <CasesList
      useLoadMore
      canLoadMore={canLoadMore}
      loadMoreCases={loadMorePastCases}
      casesLimitInitValue={pastCasesLimitInitValue}
      setCasesLimit={setPastCasesLimit}
      casesLimit={pastCasesLimit}
      casesLimitIncrement={pastCasesLimitIncrement}
      cases={slicedCases}
      totalCases={totalCases}
      loadCaseById={loadCaseById}
      handleExport={handleExport}
      tab="my-past-cases"
      handleShare={handleShare}
      handleDelete={async kase => {
        await dispatch(deleteCase(kase));
        loadInitPastCases();
      }}
    />
  );
};

export default PastCases;
