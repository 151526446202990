import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useForm } from '@fuse/hooks';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import DropMenu from 'app/components/dropMenu/DropMenu';
import ConfirmButton from 'app/components/confirmButton/ConfirmButton';
import Button from '@mui/material/Button';
import { newCaseToState } from 'app/components/caseViews/store/newCaseSlice';
import MomentAdapter from '@date-io/moment';
import Alert from '@mui/material/Alert';
import ImportCasesFromFile from 'app/pages/schedule/ImportCasesFromFile';
import { formatGroupDate, localISODate } from 'app/pages/cases/helper';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    paddingBottom: 0,
    paddingTop: 0
  },
  groupHeader: {
    color: '#fff',
    fontSize: '1.4rem',
    lineHeight: '2.5',
    fontWeight: 800,
    backgroundColor: theme.palette.secondary.main
  },
  date: {
    marginTop: theme.spacing(),
    alignSelf: 'baseline'
  },
  fabs: {
    position: 'fixed',
    bottom: theme.spacing(17),
    right: theme.spacing(3),
    zIndex: 2
  },
  listItem: {
    borderBottomWidth: 1,
    borderColor: theme.palette.grey[200]
  },
  evenItem: {
    backgroundColor: theme.palette.grey[50]
  },
  bulkActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 0
  }
}));

const parseItems = items => {
  return items.map((item, index) => ({
    ...item,
    checked: false,
    index
  }));
};

const dateLib = new MomentAdapter();

function ScheduleCasesList(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const userName = useSelector(({ auth }) => auth.user.data.nickName);
  const calculatedDisplayIds = useSelector(({ data }) => data.cases.calculatedDisplayIds);

  const [curTab, setCurTab] = useState(false);
  const { cases, loadCases, tab, clearCases } = props;

  const reload = () => {
    clearCases();
    loadCases();
    setCurTab(tab);
  };

  useEffect(() => {
    reload();
  }, [loadCases, clearCases, tab]);

  const { form, setInForm } = useForm({
    items: parseItems(cases)
  });

  useEffect(() => {
    setInForm(`items`, parseItems(cases));
  }, [cases, setInForm]);

  const haveChecked = form.items.some(item => item.checked);

  const grouped = _.groupBy(form.items, c => localISODate(c.caseDate));

  const sorted = _.sortBy(Object.keys(grouped), d => d);

  const isYou = name => {
    return name === userName ? t('You') : name;
  };

  const renderNames = item => {
    if (item.site?.attendingOnly) {
      return <Fragment>{item.attending && isYou(item.attending.nickName)}</Fragment>;
    }

    return (
      <Fragment>
        {item.attending && isYou(item.attending.nickName)}
        {' / '}
        {item.resident ? (
          isYou(item.resident.nickName)
        ) : (
          <Typography color="secondary" variant="caption">
            {t('?')}
          </Typography>
        )}
      </Fragment>
    );
  };

  const toggleChecked = (index, value) => {
    setInForm(`items[${index}].checked`, !value);
  };

  const handleSubmit = async ev => {
    ev.preventDefault();

    const idsByState = form.items.reduce((resultMap, c) => {
      if (c.checked) {
        const date = dateLib.moment(c.caseDate);
        const state = newCaseToState(dateLib.toJsDate(date), !c.resident, false);
        if (!resultMap[state]) {
          resultMap[state] = [];
        }
        resultMap[state].push(c.id);
      }

      return resultMap;
    }, new Map());

    await props.handleSubmit(idsByState);
  };

  const handleClick = (e, id) => {
    if (id !== 'new') {
      if (e.target && e.target.className.includes('checkbox-container')) {
        return;
      }
      props.loadCaseById(id);
    } else {
      props.newCase();
    }
  };

  const handleDelete = async kase => {
    await props.handleDelete(kase);
  };

  return (
    curTab === tab && (
      <Fragment>
        <form onSubmit={handleSubmit}>
          <Alert severity="success" className="mt-6 mx-10 text-14" icon={false}>
            <Trans i18nKey="💡 To change the order of cases: publish, and then use the 3-dot menu in the OR Board" />
          </Alert>
          <div className={classes.bulkActions}>
            <div>
              <Checkbox
                enabled={(form.items?.length > 0).toString()}
                checked={form.items?.length > 0 && form.items.every(item => item.checked)}
                name="checkedAll"
                onChange={() => {
                  const allChecked = form.items.every(item => item.checked);
                  form.items.forEach((item, index) => {
                    setInForm(`items[${index}].checked`, !allChecked);
                  });
                }}
                onClick={e => e.stopPropagation()}
                onMouseDown={e => e.stopPropagation()}
              />
              <Typography variant="inherent">{t('Select all')}</Typography>
            </div>
            <ImportCasesFromFile
              handleDeleteSelected={() => {
                form.items.forEach((item, index) => {
                  if (item.checked) {
                    handleDelete(item);
                  }
                });
              }}
              onImport={reload}
            />
          </div>
          {sorted.map(group => (
            <List key={group} className={classes.root}>
              <ListSubheader className={classes.groupHeader}>
                {formatGroupDate(group)}
              </ListSubheader>
              {grouped[group].map((checkItem, index) => (
                <ListItem
                  key={checkItem.id}
                  className={clsx(
                    'px-0 items-stretch',
                    classes.listItem,
                    index % 2 === 0 && classes.evenItem
                  )}
                  dense
                  onClick={e => handleClick(e, checkItem.id)}
                >
                  <div
                    className="checkbox-container flex justify-center items-center"
                    style={{ width: '60px' }}
                  >
                    <Checkbox
                      checked={checkItem.checked}
                      name="checked"
                      onChange={() => {
                        toggleChecked(checkItem.index, checkItem.checked);
                      }}
                      onClick={e => e.stopPropagation()}
                      onMouseDown={e => e.stopPropagation()}
                    />
                  </div>
                  <ListItemText
                    classes={{ primary: 'capitalize font-medium' }}
                    primary={renderNames(checkItem)}
                    secondary={
                      calculatedDisplayIds[checkItem.id] ||
                      checkItem.displayId ||
                      checkItem.procedureTitle ||
                      checkItem.description
                    }
                  />
                  <DropMenu>
                    <Button
                      title={t('change_assignment')}
                      aria-label={t('change_assignment')}
                      onClick={e => {
                        handleClick(e, checkItem.id);
                      }}
                      className="px-16 hover:bg-transparent"
                      disableRipple
                      variant="text"
                    >
                      <Icon>edit</Icon>
                      <Typography variant="inherit" className="ml-12">
                        {t('change_assignment')}
                      </Typography>
                    </Button>
                    <ConfirmButton
                      label={t('Delete')}
                      icon="delete"
                      title={t('delete_case_popup_title', {
                        attending: checkItem.attending.nickName
                      })}
                      message={t('delete_case_popup_msg', {
                        title:
                          calculatedDisplayIds[checkItem.id] ||
                          checkItem.displayId ||
                          checkItem.procedureTitle ||
                          checkItem.description ||
                          'case',
                        day: new Date(checkItem.caseDate).toLocaleDateString('en-US', {
                          weekday: 'long'
                        })
                      })}
                      onConfirm={() => handleDelete(checkItem)}
                      buttonProps={{
                        className: 'px-16 hover:bg-transparent',
                        disableRipple: true,
                        variant: 'text'
                      }}
                      confirmationProps={{
                        ok: t('Delete')
                      }}
                    />
                  </DropMenu>
                </ListItem>
              ))}
            </List>
          ))}
        </form>

        <div className={classes.fabs}>
          <Zoom in={haveChecked} style={{ transitionDelay: '200ms' }}>
            <Fab
              variant="extended"
              onClick={handleSubmit}
              className="absolute right-0 bottom-0 top-0"
              aria-label={t('Publish')}
              color="secondary"
            >
              <Icon className="mr-10">send</Icon>
              <Typography variant="caption">{t('Publish')}</Typography>
            </Fab>
          </Zoom>

          <Zoom in={!haveChecked} style={{ transitionDelay: '200ms' }}>
            <Fab
              className="absolute right-0 bottom-0 top-0"
              onClick={e => handleClick(e, 'new')}
              aria-label={t('Add')}
              color="primary"
            >
              <Icon>add</Icon>
            </Fab>
          </Zoom>
        </div>
      </Fragment>
    )
  );
}

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  return _.isEqual(prevProps.cases, nextProps.cases);
}

export default React.memo(ScheduleCasesList, areEqual);
