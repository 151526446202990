import { gql } from '@apollo/client';
import { USER_FULL_DATA } from './fragments/user';

const getAll = gql`
  query GetUsers {
    users(
      order_by: { nick_name: asc }
      where: { roles: { role: { _in: ["resident", "attending"] } } }
    ) {
      ...userFullData
    }
  }
  ${USER_FULL_DATA}
`;
const getAllowedUsers = gql`
  query GetAllowedUsers {
    users(
      order_by: { nick_name: asc }
      where: {
        roles: {
          role: {
            _in: [
              "anesthesia"
              "anesthesia_resident"
              "crna"
              "app"
              "surgical_fellow"
              "resident"
              "neurophysiologist"
              "nurse"
              "nurse_manager"
              "cst"
              "orstaff"
              "attending"
              "vendor_rep"
              "other"
            ]
          }
        }
      }
    ) {
      ...userFullData
    }
  }
  ${USER_FULL_DATA}
`;

const getDepDirectorUser = gql`
  query DepDirectorGetUser($id: uuid!) {
    users_by_pk(id: $id) {
      ...userFullData
    }
  }
  ${USER_FULL_DATA}
`;

const getFollowerUser = gql`
  query GetUser($id: uuid!) {
    users_by_pk(id: $id) {
      ...userFullData
    }
  }
  ${USER_FULL_DATA}
`;

const getAdminUser = gql`
  query AdminGetUser($id: uuid!) {
    users_by_pk(id: $id) {
      ...userFullData
    }
  }
  ${USER_FULL_DATA}
`;

const getCareTeamUser = gql`
  query CareTeamGetUser($id: uuid!) {
    users_by_pk(id: $id) {
      ...userFullData
    }
  }
  ${USER_FULL_DATA}
`;

const getDepAdminUsersMeta = gql`
  query AdminGetUsersMeta {
    users(
      order_by: { nick_name: asc }
      where: { roles: { role: { _in: ["resident", "attending"] } } }
    ) {
      ...userFullData
    }
  }
  ${USER_FULL_DATA}
`;

const updateUserPnStatus = gql`
  mutation CareTeamUpdateUserPnStatus($user_id: uuid!, $has_pn: Boolean) {
    update_users_by_pk(pk_columns: { id: $user_id }, _set: { has_pn: $has_pn }) {
      id
    }
  }
`;

const getIdHash = gql`
  query CareTeamGetIdHash {
    hashId {
      hash
    }
  }
`;
const queries = {
  getAll,
  getAllowedUsers,
  getDepAdminUsersMeta,
  getDepDirectorUser,
  getAdminUser,
  getCareTeamUser,
  getFollowerUser,
  getIdHash
};

const mutations = {
  updateUserPnStatus
};

const api = {
  queries,
  mutations
};

export default api;
