import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { withFormsy } from 'formsy-react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import ChangedBy from 'app/components/userAvatar/ChangedByAvatar';

Chips.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array
};

const useStyles = makeStyles(theme => ({
  root: {},
  buttons: {
    marginTop: theme.spacing(2),
    width: 600,
    maxWidth: '100%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  button: {
    margin: '4px 8px 8px 0',
    width: 270,
    maxWidth: '45%',
    textAlign: 'left',
    justifyContent: 'start',
    textTransform: 'none',
    '&.MuiButton-text': {
      background: '#f2f2f2',
      color: '#888'
    },
    '& .MuiButton-label': {
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }
  }
}));

function Chips(props) {
  const classes = useStyles();

  const { errorMessage } = props;

  function toggleValue(option) {
    if (props.isFormDisabled) return;

    const nextState = getCurrentValue(option)
      ? props.value.filter(el => el.value !== option.value)
      : [...props.value, option];

    props.setValue(nextState);
    if (props.onChange) {
      props.onChange(nextState, props.formRef);
    }
  }

  function getCurrentValue(option) {
    return (
      props.value &&
      props.value.length > 0 &&
      props.value.findIndex(o => o.value === option.value) > -1
    );
  }

  function strToClass(str) {
    return encodeURIComponent(str)
      .toLowerCase()
      .replace(/\.|%[0-9a-z]{2}/gi, '');
  }

  return (
    <FormControl
      className={clsx(classes.root, props.className)}
      error={Boolean((!props.isPristine && props.showRequired) || errorMessage)}
      required={props.required}
    >
      <InputLabel>{props.label}</InputLabel>

      <div className={clsx(classes.buttons)}>
        {props.options.map(option => (
          <Button
            key={option.value}
            className={clsx(classes.button, `val-${strToClass(option.value)}`, {
              selected: getCurrentValue(option)
            })}
            onClick={() => toggleValue(option)}
            variant={getCurrentValue(option) ? 'contained' : 'text'}
            color="primary"
          >
            {option.changedBy && !props.isFormDisabled && (
              <ChangedBy className="right-0 mr-4" user={option.changedBy} />
            )}
            {option.label}
          </Button>
        ))}
      </div>

      {Boolean(props.errorMessage) && <FormHelperText>{props.errorMessage}</FormHelperText>}
    </FormControl>
  );
}

export default React.memo(withFormsy(Chips));
