import React, { useEffect, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import _ from '@lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultSettings } from 'app/store/fuse/settingsSlice';
import { useHistory } from 'react-router-dom';
import { wakelockEnable, wakelockDisable } from './helper';
import StepsListItem from './StepsListItem';
import ProgressStrip from './ProgressStrip';
import NavigationButtons from './NavigationButtons';

const useStyles = makeStyles(theme => ({
  pageContainer: {
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      padding: '20px'
    }
  },
  cardcontainer: {
    backgroundColor: '#FFFFFF',
    padding: '20px 28px 40px 14px',
    overflowY: 'auto',
    [theme.breakpoints.up('md')]: {
      borderRadius: '20px',
      border: '1px solid',
      borderColor: theme.palette.grey[100],
      boxShadow: '6px 2px 3px rgba(0, 0, 0, 0.3)',
      padding: '20px'
    }
  },
  content: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '20px'
    }
  }
}));

const getLastStepIndex = (steps, stepsTimeLine) => {
  if (!stepsTimeLine.length) {
    return 0;
  }
  const lastStep = stepsTimeLine[stepsTimeLine.length - 1];
  const lastStepIndex = steps.findIndex(step => step.id === lastStep.stepId);
  return lastStepIndex > 0 ? lastStepIndex : 0;
};

const StepsList = props => {
  const scrollRef = useRef(null);
  const { caseId, pathname, steps, stepsTimeLine, handleStartStep, handleEndStep, previewMode } =
    props;
  const regSteps = steps.filter(step => step.order !== 0);
  const appendixSteps = steps.filter(step => step.order === 0);
  const [openedAppendix, setOpenedAppendix] = React.useState(false);
  const curAppendixStep = openedAppendix
    ? appendixSteps.find(appendix => appendix.name === openedAppendix)
    : null;
  const [curStepIndex, setCurStepIndex] = React.useState(
    previewMode ? 0 : getLastStepIndex(regSteps, stepsTimeLine)
  );
  useEffect(() => {
    return () => {
      wakelockDisable();
    };
  }, []);
  const submitRef = useRef(false);

  const curStep = regSteps[curStepIndex];
  const history = useHistory();
  const scrollToTop = () => {
    setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }
    });
  };
  const moveBack = async () => {
    await wakelockEnable();
    scrollToTop();
    if (openedAppendix) {
      setOpenedAppendix(null);
      return;
    }
    setCurStepIndex(curStepIndex - 1);
  };
  const moveForward = async () => {
    if (submitRef.current === true) {
      return;
    }
    await wakelockEnable();
    submitRef.current = true;
    scrollToTop();
    if (curStepIndex === 0) {
      await handleStartStep(curStep.id);
    }
    await handleEndStep(curStep.id);
    if (hasNext) {
      setCurStepIndex(curStepIndex + 1);
      const nextStep = regSteps[curStepIndex + 1];
      await handleStartStep(nextStep.id);
    } else {
      wakelockDisable();
      handleClose();
    }
    submitRef.current = false;
  };
  const dispatch = useDispatch();
  const settings = useSelector(({ fuse }) => fuse.settings.current);
  const handleClose = async () => {
    history.push(`${pathname}?case=${caseId}`);
    if (settings.layout.config.navbar.folded && settings.layout.config.navbar.autoFolded) {
      dispatch(setDefaultSettings(_.set({}, 'layout.config.navbar.autoFolded', false)));
      dispatch(setDefaultSettings(_.set({}, 'layout.config.navbar.folded', false)));
    }
  };
  if (regSteps.length === 0) {
    return <div>No steps to display</div>;
  }
  const hasPrev = curStepIndex > 0;
  const hasNext = curStepIndex < regSteps.length - 1;
  const step = regSteps[curStepIndex];
  const classes = useStyles(props);

  const displayedStep = curAppendixStep || step;
  return (
    <div className={clsx('pp-steps-list flex flex-col min-h-full', classes.pageContainer)}>
      <div className={clsx('flex flex-col flex-1 relative', classes.cardcontainer)} ref={scrollRef}>
        <ProgressStrip
          curStepIndex={curStepIndex}
          steps={regSteps}
          appendixTitle={curAppendixStep ? curAppendixStep.title : ''}
          previewMode={previewMode}
          onClose={handleClose}
        />
        <div className={clsx(classes.content)}>
          <StepsListItem
            key={displayedStep.id}
            step={displayedStep}
            setOpenedAppendix={setOpenedAppendix}
          />
        </div>
        <div className="flex justify-center mt-auto">
          <NavigationButtons
            isAppendix={!!curAppendixStep}
            moveBack={moveBack}
            moveForward={moveForward}
            hasPrev={hasPrev}
            hasNext={hasNext}
          />
        </div>
      </div>
    </div>
  );
};

export default StepsList;
