import noSleep from 'app/services/noSleep';
import auth0Service from 'app/services/auth0Service/auth0Service';

export const wakelockEnable = async () => {
  noSleep.enable();
  auth0Service.getNewToken();
};

export const wakelockDisable = () => {
  noSleep.disable();
};
