import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import auth0Service from 'app/services/auth0Service';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import apiService from 'app/services/apiService';
import { setWrongPasswordError, setUserData, logoutUser } from './store/userSlice';

class Auth extends Component {
  state = {
    waitAuthCheck: true
  };

  async componentDidMount() {
    // @todo make order with the login init flow, there is an issue when the event is called before Callback can subscribe to it.
    setTimeout(async () => {
      await this.auth0Check();
      this.setState({ waitAuthCheck: false });
    }, 0);
  }

  auth0Check = () =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async resolve => {
      auth0Service.init(this.props.setWrongPasswordError, success => {
        if (!success) {
          resolve();
        }
        const isAuth = auth0Service.isAuthenticated();

        if (isAuth) {
          apiService.init();
          this.props.setUserData({ role: [''], data: {} });

          resolve();
        } else {
          resolve();
        }
      });

      return Promise.resolve();
    });

  render() {
    return this.state.waitAuthCheck ? <FuseSplashScreen /> : <>{this.props.children}</>;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: logoutUser,
      setUserData,
      setWrongPasswordError
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Auth);
