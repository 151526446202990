import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Formsy from 'formsy-react';
import useMediaQuery from '@mui/material/useMediaQuery';
import toast from 'app/services/toastService/toast';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from 'app/components/forms/TextField';
import Checkbox from 'app/components/forms/Checkbox';
import ConfirmationDialog from 'app/components/confirmDialog/ConfirmDialog';
import Typography from '@mui/material/Typography';
import SubmitFab from 'app/components/forms/SubmitFab';
import { optionsSelector } from 'app/metaData/store/enumSlice';
import Chips from 'app/components/forms/Chips';
import { rawTimeZones } from '@vvo/tzdb';
import _ from '../../../@lodash';
import { BaseSelect as Select } from '../forms/Select';

const timezones = rawTimeZones.map(i => ({
  label: i.rawFormat,
  value: i.name
}));

const useStyles = makeStyles(theme => ({
  paper: {
    color: theme.palette.text.primary,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 900
    }
  },
  content: {
    paddingBottom: theme.spacing(12)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1.5),
    top: theme.spacing(1.5),
    padding: theme.spacing(0.2),
    zIndex: 9999,
    color: theme.palette.grey[500],
    backgroundColor: '#ffffffaa'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SitesDialog(props) {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const formRef = useRef(null);
  const options = useSelector(optionsSelector);

  const specialtyOptions = options.procedures.reduce((acc, procedure) => {
    if (!acc.some(e => e.specialtyId === procedure.specialtyId)) {
      acc.push({ specialtyId: procedure.specialtyId, specialtyTitle: procedure.specialtyTitle });
    }
    return acc;
  }, []);

  const { site } = props;
  console.log('site', site);
  const tz = site.timezone ?? '';
  const curTz = tz ? timezones.find(tzv => tzv.value === tz) : '';
  console.log('curTz', curTz);

  useEffect(() => {
    setTimezone(curTz);
  }, [curTz]);
  const [isFormValid, setIsFormValid] = useState(false);

  const [values, setValues] = useState(site);

  const [confirmation, setConfirmation] = useState({
    open: false,
    message: ''
  });

  useEffect(() => {
    setValues(site);
  }, [site]);

  const handleDeactivateConfirm = (newValue, setValue) => {
    const currentValue = formRef.current.getModel().active;
    if (currentValue && !newValue) {
      setConfirmation({
        open: true,
        message: t(
          `Users will not be able to schedule new cases in this site. They will still be able to access this site’s case log.`
        ),
        setValue: () => setValue(newValue)
      });
    } else {
      setValue(newValue);
    }
  };

  const handleConfirm = confirm => {
    if (confirm) {
      confirmation.setValue();
    }
    setConfirmation({ open: false });
  };

  const handleClose = () => {
    props.closeDialog();
  };

  const handleSubmit = async model => {
    setIsFormValid(false);
    try {
      const data = {
        sitename: model.sitename,
        active: model.active,
        specialties: model.specialties.map(s => s.value),
        attendingOnly: model.attendingOnly,
        timezone: timezone.value
      };
      await props.submitData(site.id, data);
      props.closeDialog();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
    setIsFormValid(true);
  };

  const [timezone, setTimezone] = useState(curTz);
  console.log(formRef?.current?.getModel());
  return values ? (
    <Dialog
      classes={{
        paper: clsx(classes.paper, 'w-full')
      }}
      TransitionComponent={Transition}
      onClose={handleClose}
      open={props.site !== false}
      fullScreen={fullScreen}
    >
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={handleClose}
        size="large"
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Formsy
          className={clsx('flex flex-col', classes.form)}
          onValidSubmit={handleSubmit}
          onValid={() => setIsFormValid(true)}
          onInvalid={() => setIsFormValid(false)}
          ref={formRef}
        >
          <h1 style={{ marginBottom: '0' }}>
            {site.id === 'new' ? t('ADD SERVICE') : t('EDIT SERVICE')}
          </h1>
          <h5 style={{ marginTop: '0', color: 'gray' }}>{site.id}</h5>

          <TextField
            name="sitename"
            className="my-16"
            label={t('NAME')}
            placeholder={t('Loremipsumsurgery')}
            formRef={props.formRef}
            value={values.name}
            required
          />

          <Select
            formRef={formRef}
            name="timezone"
            className="my-16"
            label={t('TIME ZONE')}
            options={timezones}
            value={timezone}
            onChange={(value, form) => {
              setTimezone(value);
            }}
            disableClearable
            required
          />

          <Chips
            label={t('SPECIALTIES')}
            className="my-16"
            name="specialties"
            formRef={formRef}
            value={
              values.specialties
                ? values.specialties.map(s => ({
                    value: s
                  }))
                : []
            }
            options={specialtyOptions.map(({ specialtyId, specialtyTitle }) => ({
              label: specialtyTitle,
              value: specialtyId
            }))}
            onChange={(value, form) => {
              // setValues(values);
            }}
            validations="minLength:1"
            required
          />

          <Checkbox
            className="mt-0"
            name="attendingOnly"
            value={_.isUndefined(site.attendingOnly) ? true : site.attendingOnly}
            label={t('Attending only')}
          />

          <Checkbox
            className="mt-0"
            name="active"
            value={_.isUndefined(site.isActive) ? true : site.isActive}
            label={t('Active')}
            confirmValue={handleDeactivateConfirm}
          />

          <Typography className="mb-72" variant="caption">
            {t(
              'New cases can’t be scheduled in an inactive site. Past cases in a deactivated site will be kept.'
            )}
          </Typography>

          <SubmitFab type="submit" disabled={!isFormValid} />
        </Formsy>

        <ConfirmationDialog
          open={confirmation.open}
          onClose={handleConfirm}
          message={confirmation.message}
        />
      </DialogContent>
    </Dialog>
  ) : null;
}

export default SitesDialog;
