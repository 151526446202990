import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const PRIVATE_NOTE_FULL_DATA = gql`
  fragment PrivateNoteFullData on private_notes {
    id
    case_id
    note
    created_by_id
    created_at
    updated_at
  }
`;
