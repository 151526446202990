import Button from '@mui/material/Button';
import React from 'react';
import { useDispatch } from 'react-redux';
import { dbgMoveToPrevState, moveToNextState } from './store/caseViewSlice';

const debugMode = false;
const Debug = props => {
  const dispatch = useDispatch();
  if (!debugMode) {
    return null;
  }
  return (
    <div>
      <Button
        variant="outlined"
        type="button"
        onClick={() => dispatch(dbgMoveToPrevState(props.case))}
      >
        dbgMoveToPrevState
      </Button>
      <Button
        variant="outlined"
        type="button"
        onClick={() => dispatch(moveToNextState(props.case))}
      >
        moveToNextState
      </Button>
    </div>
  );
};

export default Debug;
