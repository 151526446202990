import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import i18n from 'app/services/i18n';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    marginBottom: '4rem',
    '& a': {
      textDecoration: 'underline'
    }
  },
  message: {
    margin: '0 auto'
  }
}));

function getGreeting(name) {
  const hoursNow = new Date().getHours();
  let str;

  if (hoursNow >= 22 || hoursNow <= 1) {
    str = 'greeting_night';
  } else if (hoursNow >= 18) {
    str = 'greeting_evening';
  } else if (hoursNow >= 12) {
    str = 'greeting_afternoon';
  } else if (hoursNow >= 4) {
    str = 'greeting_morning';
  } else {
    str = 'greeting_middle_night';
  }

  return i18n.t(str, { name });
}

function EmptyCasesList(props) {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const userName = useSelector(({ auth }) => auth.user.data.nickName);
  const loaded = useSelector(({ data }) => data.cases.loaded);

  const shouldRender = loaded && props.casesCount === 0;

  const isOrBoard = props.tab === 'or-board';

  const greeting = `${getGreeting(userName)}`;
  return shouldRender ? (
    <Alert
      severity="info"
      icon={false}
      classes={{
        root: classes.root,
        message: classes.message
      }}
    >
      <Typography>
        <Trans
          i18nKey={isOrBoard ? 'empty_cases_welcome_a_or_board' : 'empty_cases_welcome_a_my_cases'}
          values={{ greeting }}
        />
      </Typography>
      <img
        className="w-96 h-96 my-20 mx-auto"
        src="assets/images/emptyCasesList/emptyCasesList.svg"
        alt=""
      />
      {isOrBoard || (
        <Typography>
          <Trans i18nKey="empty_cases_welcome_b_my_cases" />
        </Typography>
      )}
      <Typography>
        <Trans
          i18nKey={isOrBoard ? 'empty_cases_welcome_c_or_board' : 'empty_cases_welcome_c_my_cases'}
          components={{ a: <a href={t('whats_in_it_att')} />, b: <a href={t('whats_in_it_anesth')} />, c: <a href={t('whats_in_it_followers')} />, d: <a href={t('whats_in_it_res')} />, e: <a href={t('whats_in_it_pa')} /> }} // eslint-disable-line
        />
      </Typography>
    </Alert>
  ) : null;
}

export default EmptyCasesList;
