import React, { useRef } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { Bar } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const useStyles = makeStyles(theme => ({
  title: {
    maxWidth: 'calc(100% - 44px)'
  },
  chartHeader: {
    height: '34px',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between'
  },
  drillupButton: {
    marginRight: '12px'
  },
  yAxisTitle: {
    fontSize: 14,
    color: theme.palette.grey[800]
  }
}));

function DashboardBarChart(props) {
  Chart.register(ChartDataLabels);

  const classes = useStyles(props);
  const { data } = props;
  const chartRef = useRef(null);
  const onClick = event => {
    if (props.onBarClicked) {
      const clickedElements = chartRef.current.getElementsAtEventForMode(
        event,
        'y',
        { axis: 'x', intersect: false },
        true
      );
      if (clickedElements.length > 0) {
        props.onBarClicked(clickedElements);
      }
    }
  };

  const chartData = {
    labels: data.labels,
    datasets: [
      {
        backgroundColor: '#4285F4',
        borderColor: '#4285F4',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(97, 218, 251,0.8)',
        data: data.values,
        barPercentage: 0.5
      }
    ]
  };

  return (
    <Card className="flex flex-col justify-between w-full h-full rounded-8 shadow-1 p-16">
      {props.title && (
        <Typography className={clsx(classes.title, 'h3')} color="textSecondary">
          {props.title}
        </Typography>
      )}
      {props.total && (
        <Typography className={clsx(classes.title, 'text-5xl -mb-8')} color="textPrimary">
          {props.total}
        </Typography>
      )}
      {props.subtitle && (
        <Typography className={clsx(classes.title, 'h5 mb-12')} color="textPrimary">
          {props.subtitle}
        </Typography>
      )}

      {chartData.labels && (
        <div className="w-100-p relative">
          {props.yAxisTitle && (
            <div className={classes.chartHeader}>
              {props.yAxisTitle && <div className={classes.yAxisTitle}>{props.yAxisTitle}</div>}
              {props.isDrilledDown && (
                <IconButton
                  title="Back"
                  className={classes.drillupButton}
                  onClick={() => props.onBarClicked(null)}
                  size="small"
                >
                  <Icon fontSize="small">keyboard_arrow_left</Icon>
                </IconButton>
              )}
            </div>
          )}
          <Bar
            data={chartData}
            ref={chartRef}
            onClick={onClick}
            height={props.yAxisTitle ? 40 + (props.height || 250) : props.height || 250}
            options={{
              layout: {
                padding: {
                  top: 40
                }
              },
              indexAxis: props.indexAxis || 'x',
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false
                },
                datalabels: {
                  align: 'end',
                  anchor: 'end',
                  display: true,
                  formatter: (value, ctx) => {
                    return value || '';
                  },
                  color: 'black'
                }
              },
              tooltips: {
                mode: 'index',
                intersect: false
              },
              hover: {
                mode: 'nearest',
                intersect: true
              },
              scales: {
                y: {
                  scaleLabel: {
                    display: Boolean(props.yLabel),
                    labelString: props.yLabel
                  },
                  ticks: {
                    min: 0,
                    crossAlign: props.labelAlignY,
                    callback(value) {
                      // eslint-disable-next-line react/no-this-in-sfc
                      const valueLegend = this.getLabelForValue(value);
                      if (valueLegend.length > 16) {
                        return `${valueLegend.slice(0, 13)}...`;
                      }
                      return valueLegend;
                    },
                    color: 'black'
                  }
                },
                x: {
                  gridLines: {
                    display: false
                  },
                  ticks: {
                    // maxTicksLimit: props.isDrilledDown ? 11 : Math.max(...data.values) + 1,
                    padding: 5,
                    color: 'black'
                    // callback(value, index, values) {
                    //   if (props.isDrilledDown) {
                    //     return `${Math.floor((index / (values.length - 1)) * 100)}%`;
                    //   }
                    //   // eslint-disable-next-line react/no-this-in-sfc
                    //   return this.getLabelForValue(value);
                    // }
                  }
                }
              }
            }}
          />
        </div>
      )}
      {props.bottomtitle && (
        <Typography className="h6" color="textSecondary">
          {props.bottomtitle}
        </Typography>
      )}
    </Card>
  );
}

export default React.memo(DashboardBarChart);
