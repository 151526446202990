import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { selectFooterTheme } from 'app/store/fuse/settingsSlice';
import AppBar from '@mui/material/AppBar';
import FooterMenu from 'app/components/footerMenu/FooterMenu';

function FooterLayout1(props) {
  const footerTheme = useSelector(selectFooterTheme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={footerTheme}>
        <AppBar id="fuse-footer" className="relative z-10" color="default">
          <FooterMenu />
        </AppBar>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default React.memo(FooterLayout1);
