import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ApiService from 'app/services/apiService';
import { useDebounce } from '@fuse/hooks';
import { ROLES } from 'app/consts';

const procWeight = (termsArr, procName, termsCount, sumTermsCount, count, sumCount) => {
  const w = termsArr.reduce((acc, term) => {
    // The more times a term appears in the procedure name, the less weight it has
    const termsWeight = procName.toLowerCase().includes(term)
      ? (sumTermsCount - termsCount[term]) / sumTermsCount
      : 0;
    return acc + termsWeight;
  }, 0);

  // The more times a procedure was done, the more weight it has
  const countWeight = count / sumCount || 0;
  const isDevMode = localStorage.getItem('devMode') === 'true';
  if (isDevMode) {
    console.log(procName, 'w: ', w, 'countWeight:', countWeight, 'total:', w + countWeight);
  }
  return w + countWeight;
};

const filterByNeuroTerms = (procs, termsStr) => {
  const termsStrLower = termsStr.toLowerCase();
  const cervical = termsStrLower.match(/cervical|[Cc]\d/)?.length > 0;
  const thoracic = termsStrLower.match(/thorac|[Tt]\d/)?.length > 0;
  const lumbar = termsStrLower.match(/lumbar|[Ll]\d/)?.length > 0;
  const sacral = termsStrLower.match(/sacr|[Ss]\d/)?.length > 0;
  const anterior = termsStrLower.includes('anterior');
  const posterior = termsStrLower.includes('posterior');

  const arrToFilter = [];
  if (!cervical) {
    arrToFilter.push('cervical');
  }
  if (!thoracic) {
    arrToFilter.push('thorac');
  }
  if (!lumbar) {
    arrToFilter.push('lumbar');
  }
  if (!sacral) {
    arrToFilter.push('sacr');
  }
  let ps = procs;
  if (arrToFilter.length !== 4) {
    ps = ps.filter(proc => {
      return !arrToFilter.some(term => proc.name.toLowerCase().includes(term));
    });
  }

  let termToFilter;
  if (anterior) {
    termToFilter = 'posterior';
  }
  if (posterior) {
    termToFilter = 'anterior';
  }
  if (termToFilter) {
    ps = ps.filter(proc => {
      return !proc.name.toLowerCase().includes(termToFilter);
    });
  }

  return ps;
};

const filterByTermsAndOccurences = (procs, termsStr, siteId, attendingId) => {
  const termsArr = termsStr
    .split(/[\s,.(){}|\\?+*^$-]/)
    .filter(
      term => term && term.length > 2 && !['undefined', 'null', 'with', 'for', 'and'].includes(term)
    )
    .map(t => t.toLowerCase());

  // if we didn't find any procedures that this attending did, just use all procedures
  let suggestions = procs.filter(
    proc => termsArr.length === 0 || termsArr.some(term => proc.name.toLowerCase().includes(term))
  );
  // Count how many times each term appears in all procedures
  const termsCount = {};
  let sumTermsCount = 0;
  termsArr.forEach(term => {
    termsCount[term] = suggestions.reduce((acc, proc) => {
      const incr = proc.name.toLowerCase().includes(term) ? 1 : 0;
      sumTermsCount += incr;
      return acc + incr;
    }, 0);
  });

  // Sum all counts per site
  const sumCount = suggestions.reduce((acc, proc) => {
    const count = proc.countPerSiteAndAtt?.[siteId]?.[attendingId] || 0;
    return acc + count;
  }, 0);

  suggestions = suggestions.map(proc => {
    const count = proc.countPerSiteAndAtt?.[siteId]?.[attendingId] || 0;
    return {
      ...proc,
      weight: procWeight(termsArr, proc.name, termsCount, sumTermsCount, count, sumCount)
    };
  });

  // sort by weights and then by count per site
  suggestions = suggestions.sort((a, b) => {
    return b.weight - a.weight;
  });

  const isDevMode = localStorage.getItem('devMode') === 'true';
  if (isDevMode) {
    console.debug('suggestions', suggestions);
  }

  return suggestions;
};

const filterByPastCases = async (ps, description, displayId, siteId, isUserAdmin) => {
  // Find past cases with the same description or display id
  const desc = description || 'NOMATCH!';
  const dispId = displayId || 'NOMATCH!';
  const result = await ApiService.getCasesByDescriptionOrDisplayId(
    desc,
    dispId,
    siteId,
    isUserAdmin
  );

  if (result.casesAggregate.nodes.length === 0) {
    return ps;
  }

  const newPs = ps.filter(proc => {
    return result.casesAggregate.nodes.some(node => {
      return node.procedureId === proc.value;
    });
  });
  newPs.forEach(proc => {
    proc.pastCasesMatch = true;
  });

  return newPs;
};

const getOptions = async (procedures, displayId, description, siteId, attendingId, isUserAdmin) => {
  let ps = [];

  if (!siteId) {
    return ps;
  }

  try {
    let terms = '';

    if (description || displayId) {
      terms = ''.concat(description, ' ', displayId);
    }

    ps = filterByNeuroTerms(procedures, terms);
    ps = filterByTermsAndOccurences(ps, terms, siteId, attendingId);
    ps = await filterByPastCases(ps, description, displayId, siteId, isUserAdmin);

    return ps;
  } catch (err) {
    console.log(err);
  }

  return ps;
};

const useProcedureSuggestions = ({ procedures, displayId, description, siteId, attendingId }) => {
  const [procSuggestions, setProcSuggestions] = useState();

  const user = useSelector(({ auth }) => auth.user);
  const isUserAdmin = user.role.includes(ROLES.DEPARTMENT_ADMIN);

  const debouncedGetOptions = useDebounce(
    async (p, did, desc, sid, attId) => {
      const ps = await getOptions(p, did, desc, sid, attId, isUserAdmin);
      setProcSuggestions(ps);
    },
    750,
    {
      leading: true,
      trailing: true
    }
  );

  useEffect(() => {
    const runDebounceGetOptions = async () => {
      const ps = debouncedGetOptions(procedures, displayId, description, siteId, attendingId);
    };
    runDebounceGetOptions();
  }, [displayId, description, siteId, attendingId]);

  return procSuggestions;
};

export default useProcedureSuggestions;
